import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import DropDown from "../../../Common/DropDown";

const EditUserModal = (props) => {

    const data = props.data;

    const TOKEN = localStorage.getItem('fms-access-token');

    const [firstName, setFirstName] = useState(data.first_name);
    const [lastName, setLastName] = useState(data.last_name);
    const [role, setRole] = useState(data.role_id);
    const [email, setEmail] = useState(data.role_id);

    useEffect(() => {
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setRole(data.role_id);
        setEmail(data.email);
    }, [data])

    const addNewUser = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'user/' + data.id;
        axios({
            method: "put",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeEditModal').click();
                document.getElementById('editUserModalForm').reset();
                props.reloadGrid();
                window.location.reload();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div className="modal fade" id="editUserModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <form onSubmit={addNewUser} id="editUserModalForm" className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">Update User</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body text-left">
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label>First Name<span className="text-danger">*</span></label>
                                <input type="text" name="first_name" value={firstName} onChange={(event) => setFirstName(event.target.value)} required={true} className="form-control" />
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Last Name<span className="text-danger">*</span></label>
                                <input type="text" name="last_name" value={lastName} onChange={(event) => setLastName(event.target.value)} required={true} className="form-control" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Email<span className="text-danger">*</span></label>
                            <input type="email" name="email" required={true} readOnly={true} value={email} className="form-control" required/>
                        </div>
                        <div className="form-group">
                            <label>Role<span className="text-danger">*</span></label>
                            <DropDown url="roles" value={role} option_label="role_name" onchange={(event) => setRole(event.target.value)} name="user_roles" id="edit_user_role" required/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeEditModal" className="btn btn-outline-secondary" data-dismiss="modal">CLOSE</button>
                        <button type="submit" className="btn btn-primary">UPDATE</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditUserModal;