import React, { useEffect, useState } from "react";
import "emotion-ratings/dist/emotion-ratings.js";
import "survey-creator/survey-creator.css";
import axios from 'axios';
import * as widgets from "surveyjs-widgets";
import * as SurveyKo from "survey-knockout";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as SurveyCreator from "survey-creator";
import '../survey.css'
import Loader from "../../../Common/Loader";

const SurveyContent = (props) => {

    var creator;

    const TOKEN = localStorage.getItem('fms-access-token');

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    const history = useHistory();

    const [surveyQuestionsJson, setSurveyQuestionsJson] = useState("");
    const [surveyDetails, setSurveyDetails] = useState({});
    const [enableDisableTab, setEnableDisableTab] = useState(false);
    const [surveyName, setSurveyName] = useState("");
    const [loader, setLoader] = useState(true);

    setTimeout(() => {
        setLoader(false);
    }, 3000)

    useEffect(() => {
        if (props.location.state) {
            setSurveyQuestionsJson(props.location.state.surveyInfo.json);
            setSurveyDetails(props.location.state.surveyInfo);
            setSurveyName(props.location.state.surveyInfo.name)
            if (props.location.state.surveyInfo.json.length > 28) {
                setEnableDisableTab(false);
            } else {
                setEnableDisableTab(true);
            }
        } else {
            history.push('/surveys');
        }
    }, [props.location.state]);


    const options = {
        showEmbededSurveyTab: true,
        showLogicTab: true,
        showTranslationTab: true,
        questionTypes: ["checkbox", "comment", "dropdown", "barrating", "file", "html", "matrix", "matrixdropdown", "matrixdynamic", "multipletext", "panel", "paneldynamic", "radiogroup", "rating", "text", "boolean"]
    };

    SurveyCreator.StylesManager.applyTheme("modern");

    SurveyKo.surveyLocalization.locales["ar-ae"] = {};
    SurveyKo.surveyLocalization.localeNames["ar-ae"] = "عربي";

    useEffect(() => {
        creator = new SurveyCreator.SurveyCreator(null, options);

        creator.saveSurveyFunc = saveMySurvey;
        creator.showToolbox = "right";
        creator.showPropertyGrid = "right";
        creator.rightContainerActiveItem("toolbox");

        let x = creator.toolbox.getItemByName('emotionsratings')

        if (!x) {
            widgets.emotionsratings(SurveyKo);
        } else {
            x.iconName = 'custom-emotionratings';
        }

        creator.toolbox.addItem({
            name: "emotionsratings",
            isCopied: true,
            iconName: "custom-emotionratings-colored",
            title: "Emotions Ratings (colored)",
            json: {
                "type": "emotionsratings",
                "choices": [1, 2, 3, 4, 5, 6],
                emotions: ['angryColored', 'sadColored', 'neutralColored', 'happyColored', 'veryHappyColored', 'notApplicable']
            }
        });

        creator.toolbox.addItem({
            name: "nps",
            isCopied: true,
            iconName: "custom-nps",
            title: "NPS",
            json: {
                "type": "rating",
                title: "How likely is it that you would recommend us to your friends and family?",
                rateMin: 0,
                rateMax: 10,
                minRateDescription: "Highly Unlikely",
                maxRateDescription: "Highly Likely"
            }
        });

        creator.render("surveyCreatorContainer");

        creator.text = surveyQuestionsJson;
        document.getElementsByClassName('svd_commercial_container')[0].style.display = 'none';
        document.getElementsByClassName('navbar-default container-fluid nav nav-tabs editor-tabs svd-tabs svd-light-bg-color')[0].style.display = 'none';
    }, [surveyQuestionsJson])


    var mainColor = props.primaryColor;
    var mainHoverColor = "#727cf5";
    var textColor = "#4a4a4a";
    var headerColor = "#4a4a4a";
    var headerBackgroundColor = "#4a4a4a";
    var bodyContainerBackgroundColor = "#f8f8f8";

    var defaultThemeColorsSurvey = SurveyCreator.StylesManager.ThemeColors["default"];
    defaultThemeColorsSurvey["$main-color"] = mainColor;
    defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
    defaultThemeColorsSurvey["$text-color"] = textColor;
    defaultThemeColorsSurvey["$header-color"] = headerColor;
    defaultThemeColorsSurvey["$header-background-color"] = headerBackgroundColor;
    defaultThemeColorsSurvey["$body-container-background-color"] = bodyContainerBackgroundColor;

    var defaultThemeColorsEditor = SurveyCreator.StylesManager.ThemeColors["default"];
    defaultThemeColorsEditor["$primary-color"] = mainColor;
    defaultThemeColorsEditor["$secondary-color"] = mainColor;
    defaultThemeColorsEditor["$primary-hover-color"] = mainHoverColor;
    defaultThemeColorsEditor["$primary-text-color"] = textColor;
    defaultThemeColorsEditor["$selection-border-color"] = mainColor;


    const saveMySurvey = () => {
        let URL = process.env.REACT_APP_API_KEY + 'survey/save_survey';
        const formData = new FormData();
        formData.append('name', document.getElementById('survey_name').value);
        formData.append('json', creator.text);
        formData.append('status', "created");
        formData.append('survey_id', surveyDetails.id);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setSurveyDetails(response.data.data);
                if (response.data.data.json.length > 28) {
                    setEnableDisableTab(false);
                } else {
                    setEnableDisableTab(true);
                }
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const updateSurveyName = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyDetails.id);
        let URL = process.env.REACT_APP_API_KEY + 'survey/surveyNameEdit';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setSurveyDetails(response.data.data);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div className="content">
            <div className="survey-top-bar">
                <div className="row border-bottom" style={{ height: '70px' }}>
                    <div className="col-md-4 d-flex" style={{ alignItems: 'center' }}>
                        <Link to="/"><span className="logo-lg mx-2"><img src={logo ? logo : "/assets/images/logo.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '60px', paddingTop: '4px', paddingBottom: '4px' }} /></span></Link>
                        <Link to="/surveys" className=""><i className="dripicons-arrow-thin-left font-24 mr-1 text-dark"></i></Link>
                        <div className="form-group m-0 mr-2 ">
                            <form onSubmit={updateSurveyName} className="input-group survey-top-bar-input">
                                <input type="text" name="name" id="survey_name" defaultValue={surveyName} className="form-control form-control-white border-light survey-input" placeholder="Survey Name" />
                                <div className="input-group-append cursor-pointer">
                                    <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                        <i className="mdi mdi-content-save font-20"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        {/* <input className="form-control survey-input pt-1" defaultValue={surveyName} type="text" id="survey_name" /> */}
                    </div>
                    <div className="col-md-8 d-flex justify-content-between">
                        <ul className="nav nav-tabs nav-bordered justify-content-center" style={{ alignItems: 'end' }}>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-content', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link active">
                                    <i className="mdi text-primary mdi-numeric-1-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Content</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} aria-disabled={enableDisableTab} data-toggle="tab" aria-expanded="true" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-2-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Design</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-3-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Workflow</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} aria-disabled={enableDisableTab} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-4-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Share</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-results', state: { surveyInfo: surveyDetails } }} aria-disabled={enableDisableTab} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-5-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Results</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="mr-2 mt-1"></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{ marginTop: '4.3rem' }}>
                    <div className="tab-content">
                        <div className="tab-pane show active">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="nav nav-pills bg-nav-pills nav-justified d-md-none">
                                        <li className="nav-item">
                                            <Link onClick={() => { document.getElementsByClassName('nav-item svd-tab')[0].click() }} data-toggle="tab" aria-expanded="false" className="nav-link rounded-0 active">
                                                <span className=" d-md-block">Questions</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link onClick={() => { document.getElementsByClassName('nav-item svd-tab')[5].click() }} data-toggle="tab" aria-expanded="true" className="nav-link rounded-0">
                                                <span className="d-md-block">Translation</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link onClick={() => { document.getElementsByClassName('nav-item svd-tab')[2].click() }} data-toggle="tab" aria-expanded="true" className="nav-link rounded-0">
                                                <span className="d-md-block">Logic Jump</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-page" style={{ marginLeft: 0, padding: '0px 0px 0px 0px' }}>
                                <div className="row">
                                    <div className="col-12 d-flex surveyCreatorSideNav">
                                        <div className="nav flex-column nav-pills d-none d-sm-none d-md-block text-center" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ minWidth: 'max-content', position: 'fixed' }}>
                                            <span onClick={() => { document.getElementsByClassName('nav-item svd-tab')[0].click() }} className="nav-link active show cursor-pointer" id="v-pills-home-tab" data-toggle="pill" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                                <i className="uil uil-file-question-alt d-block"></i>
                                                <span className="d-none d-md-block">Questions</span>
                                            </span>
                                            <span onClick={() => { document.getElementsByClassName('nav-item svd-tab')[5].click() }} className="nav-link cursor-pointer" id="v-pills-profile-tab" data-toggle="pill" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                                <i className="mdi mdi-google-translate d-block"></i>
                                                <span className="d-none d-md-block">Translation</span>
                                            </span>
                                            <span onClick={() => { document.getElementsByClassName('nav-item svd-tab')[2].click() }} className="nav-link cursor-pointer" id="v-pills-settings-tab" data-toggle="pill" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                                <i className="dripicons-network-3 d-block"></i>
                                                <span className="d-none d-md-block">Logic Jump</span>
                                            </span>
                                        </div>
                                        <div id="surveyCreatorContainer" className="p-0" />
                                    </div>
                                </div>
                            </div>
                            {loader ? <Loader /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SurveyContent;
