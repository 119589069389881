import { Link } from "react-router-dom";
import ViewImports from "./ViewImports";
import Loader from "../Common/Loader";
import AddAudienceModal from "./AddAudienceModal";
import DropDown from "../Common/DropDown";
import GridView from "../Common/GridView";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { permissionName } from "../../Utils/string_formatting";

const Audience = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [audiances, setAudiances] = useState([]);
    const [columns, setColumns] = useState([{ dataField: '', text: '' }]);
    const [loader, setLoader] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState([]);
    const [tags, setTags] = useState([]);
    const [totalSize, setTotalSize] = useState(0);

    const fetchTags = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setTags(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchPrimaryFields = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getPrimaryFields';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                generateColumns(response.data.data)
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchAudienceUploads = (page = 1, size = 10) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceFilter';
        const formData = new FormData(document.getElementById('audienceFilterForm'));
        formData.append('single_page_count', size);
        formData.append('page_number', page);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            let arr = [];
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    arr.push({ id: response.data.data[i]._id.$oid, ...response.data.data[i].primary_data })//, ...response.data.data[i].secondary_data
                }
            }
            setAudiances(arr);
            setTotalSize(response.data.audience_count);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const generateColumns = (data) => {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i] === "id" || data[i] === "reg_no" || data[i] === "data_set_id" || data[i] === "org_id" || data[i] === "source_type" || data[i] === "is_active" || data[i] === "created_by" || data[i] === "created_on" || data[i] === "modified_by") {
                //do nothing
            } else if (data[i] === "first_name") {
                arr.push({
                    dataField: data[i],
                    text: permissionName(data[i]),
                    sort: true,
                    formatter: issueDetailsAction
                });
            } else {
                arr.push({
                    dataField: data[i],
                    text: permissionName(data[i]),
                    sort: true
                });
            }
        }

        arr.push({
            dataField: 'id',
            classes: 'py-0 text-center',
            text: 'View',
            headerStyle: { textAlign: 'center' },
            formatter: viewAudienceBtn
        })
        setColumns(arr);
    }

    useEffect(fetchAudienceUploads, []);
    useEffect(fetchPrimaryFields, []);
    useEffect(fetchTags, []);

    const viewAudienceBtn = (cell) => {
        return (
            <Link to={"audience-details/" + cell} className="mdi mdi-text-search text-primary cursor-pointer font-20" ></Link>
        )
    }

    const issueDetailsAction = (cell, row) => {
        return <Link className="text-primary" to={"audience-details/" + row.id}>{cell}</Link>
    }

    const deleteAudience = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/deleteAudience';
        axios({
            method: "post",
            url: URL,
            data: { delete_id: toBeDeleted },
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            toast.success(response.data.message);
            fetchAudienceUploads();
            setToBeDeleted([]);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const gridFilter = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceFilter';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            let arr = [];
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    arr.push({ id: response.data.data[i]._id.$oid, ...response.data.data[i].primary_data })//, ...response.data.data[i].secondary_data
                }
                //generateColumns(arr);
            }
            setAudiances(arr);
            setTotalSize(response.data.audience_count);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const selectRow = {
        mode: 'checkbox',
        onSelect: (row, isSelect) => {
            if (isSelect) {
                //usersArray.push(row.id);
                setToBeDeleted(toBeDeleted => [...toBeDeleted, row.id]);
            } else {
                const arr = toBeDeleted.filter((item) => item !== row.id);
                setToBeDeleted(arr);
            }
        },
        onSelectAll: (isSelect, rows) => {
            if (isSelect) {
                let usersArray = [];
                for (let i = 0; i < rows.length; i++) {
                    usersArray.push(rows[i].id);
                }
                setToBeDeleted(usersArray);
            } else {
                //usersArray = [];
                setToBeDeleted([]);
            }
        }
    };

    const search = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceFilter';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setAudiances(response.data.data);
            setLoader(false);
            let arr = [];
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    arr.push({ id: response.data.data[i]._id.$oid, ...response.data.data[i].primary_data })//, ...response.data.data[i].secondary_data
                }
            }
            setAudiances(arr);
            setTotalSize(response.data.audience_count);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <div className="form-group m-0 mr-2">
                                                <form onSubmit={search} className="input-group">
                                                    <input type="text" name="search_string" className="form-control form-control-white border-light" placeholder="Search" />
                                                    <div type="submit" className="input-group-append cursor-pointer">
                                                        <button className="input-group-text bg-white border-light text-dark py-0">
                                                            <i className="mdi mdi-magnify font-20"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                            <button type="button" data-toggle="modal" data-target="#viewImportsModal" className="btn btn-outline-primary btn-sm-block mr-2"><i className="mdi mdi-upload mr-1"></i>IMPORTS</button>
                                            <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ADD CONTACT</button>
                                            <div className="dropdown-menu dropdown-menu-border-top">
                                                <Link data-toggle="modal" data-target="#addAudienceModal" className="dropdown-item">Add Manually</Link>
                                                <Link to="import-options" className="dropdown-item" href="#">Import</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Audience</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Audience</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">

                            <div className="row bg-white rounded mx-0 py-2 d-flex justify-content-between">
                                <div className="col-md-8">
                                    <form className="row" id="audienceFilterForm" onSubmit={gridFilter} onChange={() => document.getElementById('submitFilter').click()}>
                                        <div className="col-md-4">
                                            <div className="form-group mx-2">
                                                <label  >All Contacts</label>
                                                <select name="contacts" className="form-control">
                                                    <option value="All Contacts">All Contacts</option>
                                                    <option value="Active Contacts">Active Contacts</option>
                                                    <option value="Unsubscribed">Unsubscribed</option>
                                                    <option value="Bounced">Bounced</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mx-2">
                                                <label>Tags</label>
                                                <DropDown url="audience/audienceDataTags" value={tags} option_label="name" onchange={(event) => setTags(event.target.value)} name="tags" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mx-2">
                                                <label>Source Type</label>
                                                <select name="source_type" className="form-control">
                                                    <option value="0">--Select--</option>
                                                    <option value="CSV">CSV</option>
                                                    <option value="GOOGLE_SHEET">Google Sheets</option>
                                                    <option value="MANUAL">Manual</option>
                                                </select>
                                            </div>
                                        </div>
                                        <input type="submit" id="submitFilter" className="d-none" />
                                    </form>
                                </div>
                                <div className="col-md-4">
                                    <button type="button" className="btn btn-danger float-right mt-2 mr-2" onClick={deleteAudience} style={{ padding: '0.45rem 0.9rem' }} disabled={!toBeDeleted.length}>
                                        <i className="mdi mdi-delete"></i>
                                    </button>
                                </div>
                            </div>

                            <GridView
                                classes="table-hover-custom"
                                gridData={audiances}
                                columns={columns}
                                totalSize={totalSize}
                                sizePerPage={10}
                                keyField="id"
                                selectRow={selectRow}
                                fetchData={(page, size) => fetchAudienceUploads(page, size)} />

                            {loader ? <Loader /> : null}

                        </div>
                    </div>
                    <AddAudienceModal fetchAudience={fetchAudienceUploads} />

                    <ViewImports />
                </div>
            </div>
        </div>
    );
}

export default Audience;