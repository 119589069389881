import { useState } from "react";
import { useDropzone } from "react-dropzone";

const FileDragDropUpload = (props) => {

    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.csv',
        multiple: false,
        onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles);
            props.csvData(acceptedFiles);
        }
    });

    const removeAllFiles = () => {
        setFiles([]);
        props.csvData([]);
    }

    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
                {files.length > 0 ?
                    <aside id="viewFileContainer">
                        <img src="/assets/images/csv.png" alt="" className="csv-img" />
                        <p>{files[0].name}</p>
                        <button type="button" onClick={() => removeAllFiles()} className="btn btn-light" style={{ height: 'fit-content' }}><i className="mdi mdi-delete-outline"></i> </button>
                    </aside>
                    :
                    <div id="inputFileContainer">
                        <div className="dz-message needsclick">
                            <i className="h1 text-muted dripicons-cloud-upload"></i>
                            <h4>Drop .csv file here or click to upload.</h4>
                        </div>
                    </div>
                }
                <input name="csvFile" {...getInputProps()} />
            </div>
        </section>
    );
}

export default FileDragDropUpload;