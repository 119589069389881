import React from "react";
import { useHistory } from "react-router-dom";

const SegmentInfoModal = () => {

    const history = useHistory();

    const openAddSegment = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        history.push({
            pathname: '/add-segment',
            state: { segmentInfo: { name: formData.get('name'), description: formData.get('description') } }
        });
        document.getElementById('closeModal').click();
    }

    return (
        <div className="modal fade" id="segmentInfoModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <form onSubmit={openAddSegment} id="addTeamForm" className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">Add New Segment</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Segment Name<span className="text-danger">*</span></label>
                                    <input type="text" name="name" required={true} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Segment Description<span className="text-danger">*</span></label>
                                    <textarea className="form-control" required={true} name="description"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeModal" className="btn btn-outline-secondary" data-dismiss="modal">CLOSE</button>
                        <button type="submit" className="btn btn-primary">CONTINUE</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SegmentInfoModal;