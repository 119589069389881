import { useEffect, useState } from "react";
import axios from "axios";

const GoogleFontsDropDown = (props) => {

    const [data, setData] = useState([]);

    const fetchData = () => {
        var arr = [];
        let URL = 'https://www.googleapis.com/webfonts/v1/webfonts?key=' + process.env.REACT_APP_API_GOOGLE_FONTS;
        axios({
            method: "get",
            url: URL
        }).then((response) => {
            for (let i = 0; i < response.data.items.length; i++) {
                arr.push({ 'id': response.data.items[i].family, 'label': response.data.items[i].family })
            }
            setData(arr);
            document.getElementById('googleFontsDropDown').value = props.defaultSelected;
        });
    }

    useEffect(fetchData, []);

    return (
        <select required={props.required} id={props.id} name={props.name} disabled={props.disabled} onChange={props.onchange} value={props.value} className={"form-control " + props.className}>
            <option value={0} key={0}>--Select--</option>
            {data.map((e, key) => {
                return <option key={key} value={e.id}>{e.label}</option>;
            })}
        </select>
    );
}

export default GoogleFontsDropDown;