import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteModal from "../../../Common/DeleteModal";
import AddUserModal from "./AddUserModal";
import EditUserModal from "./EditUserModal";
import UsersGridView from './UsersGridView';
import Loader from "../../../Common/Loader";

const Users = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [users, setUsers] = useState([]);
    const [userData, setUserData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [deleteUrl, setDeleteUrl] = useState([]);
    const [loader, setLoader] = useState(false);

    const fetchUsers = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'addUser';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setUsers(response.data.data);
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(fetchUsers, []);

    const fetchRoles = () => {
        let URL = process.env.REACT_APP_API_KEY + 'roles';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            processRoles(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchRoles, []);

    const processRoles = (data) => {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
            arr.push({ value: data[i].id, label: data[i].role_name })
        }
        setRoles(arr);
    }

    const search = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'addUser?search_string=' + formData.get('search');
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            setUsers(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <div className="form-group m-0 mr-2">
                                                <form onSubmit={search} className="input-group">
                                                    <input type="text" name="search" className="form-control form-control-white border-light" placeholder="Search" />
                                                    <div className="input-group-append cursor-pointer">
                                                        <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                                            <i className="mdi mdi-magnify font-20"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                            <button type="button" data-toggle="modal" data-target="#addUserModal" className="btn btn-primary btn-sm-block float-right">ADD USER</button>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><Link to="/settings">Settings</Link></li>
                                        <li className="breadcrumb-item"><a>User Settings</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Users</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Users</h4>
                                    <p>Invite your team members to your account.<br />Add, edit and remove your team members using User Settings.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <UsersGridView gridData={users} setUserData={setUserData} setDeleteUrl={setDeleteUrl} />
                        </div>
                    </div>
                    <AddUserModal reloadGrid={fetchUsers} />
                    <EditUserModal data={userData} roles={roles} reloadGrid={fetchUsers} />
                    <DeleteModal deleteUrl={deleteUrl} reloadGrid={fetchUsers} />
                    {loader ? <Loader /> : null}
                </div>
            </div>
        </div>
    );
}

export default Users;