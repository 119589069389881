import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import Loader from '../Common/Loader';
import axios from 'axios'
import ReactApexChart from 'react-apexcharts'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Select from "react-select";
import { permissionName } from "../../Utils/string_formatting";
import GridView from '../Common/GridView';
import { dateFormat } from '../../Utils/date_formatting';
import { Link } from 'react-router-dom';

const Trend = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [loader, setLoader] = useState(false);
    const [doughnutCount, setDoughnutCount] = useState([]);
    const [doughnutLabels, setDoughnutLabels] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const [lineChartDates, setLineChartDates] = useState([]);
    const [channel, setChannel] = useState("sms")
    const [gridData, setGridData] = useState([])
    const [startDate, setStartDate] = useState(moment().subtract(29, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [groupBy, setGroupBy] = useState('days')
    const [countOrContact, setCountOrContact] = useState('count')
    const [np, setNP] = useState('#')
    const [tags, setTags] = useState([]);
    const [shares, setShares] = useState([]);
    const [segments, setSegments] = useState([]);
    const [survey, setSurvey] = useState([]);
    const [filteredData, setFilteredData] = useState(null);
    const [csvHeader, setCsvHeader] = useState('Days')
    const [contactColumn, setContactColumn] = useState([{ dataField: '', text: '' }])
    const [totalSize, setTotalSize] = useState([]);
    const [filterBy, setFilterBy] = useState('Success');

    const onDateChanged = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const label = startDate.format('MMMM D, YYYY') + ' - ' + endDate.format('MMMM D, YYYY');

    const fetchTags = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setTags(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchSegments = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setSegments(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchShares = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getShareData';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: data[i].share_name })
                }
                setShares(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchSurvey = () => {
        var arr = [];
        let URL = process.env.REACT_APP_API_KEY + 'survey/allSurvey';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then((response) => {
            for (let i = 0; i < response.data.data.length; i++) {
                arr.push({ value: response.data.data[i].id, label: response.data.data[i].name })
            }
            setSurvey(arr);
        });
    }

    const fetchAllDetails = () => {
        setLoader(true)
        const formData = new FormData();
        if (filteredData) {
            for (let key in filteredData) {
                formData.append(key, filteredData[key]);
            }
        }
        let URL = process.env.REACT_APP_API_KEY + 'reports/trends';
        formData.append('channel', channel)
        formData.append('from_date', startDate.format('YYYY-MM-DD'))
        formData.append('to_date', endDate.format('YYYY-MM-DD'))
        formData.append('groupBy', groupBy)
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then((response) => {
            setLoader(false)
            if (response.data.status === 1) {
                setDoughnutCount(response.data.data.doughnut.count);
                setDoughnutLabels(response.data.data.doughnut.labels);

                setLineChartData(response.data.data.linechart.values)
                setLineChartDates(response.data.data.linechart.label)

                setGridData(response.data.data.tableview);
                setTotalSize(response.data.total_count)
            } else if (response.data.status === 0) {
                setDoughnutCount([]);
                setDoughnutLabels([]);

                setLineChartData([])
                setLineChartDates([])

                setGridData([])
                setTotalSize(0)
            }
        });
    }

    useEffect(fetchAllDetails, [channel, groupBy, startDate, endDate, filteredData]);
    useEffect(fetchTags, []);
    useEffect(fetchShares, []);
    useEffect(fetchSegments, []);
    useEffect(fetchSurvey, [])

    const gridFilter = (event) => {
        event.preventDefault();
        var formData = new FormData(event.target);
        setFilteredData(Object.fromEntries(formData))
    }

    // const donutChart = () => {
    //     setLoader(true)
    //     var formData = new FormData();

    //     if (filteredData) {
    //         for (let key in filteredData) {
    //             formData.append(key, filteredData[key]);
    //         }
    //     }

    //     formData.append('channel', channel)
    //     formData.append('from_date', startDate.format('YYYY-MM-DD'))
    //     formData.append('to_date', endDate.format('YYYY-MM-DD'))

    //     let URL = process.env.REACT_APP_API_KEY + 'reports/doughnutchart';
    //     axios({
    //         method: 'post',
    //         url: URL,
    //         data: formData,
    //         headers: {
    //             Authorization: 'Token ' + TOKEN
    //         }
    //     }).then(function (response) {
    //         if (response.data.status === 1) {
    //             setDoughnutCount(response.data.data.doughnut.count);
    //             setDoughnutLabels(response.data.data.doughnut.labels);
    //         } else {
    //             setDoughnutCount([]);
    //             setDoughnutLabels([]);
    //         }
    //         setLoader(false);
    //     }).catch(function (error) {
    //         setLoader(false);
    //         toast.error(error.toString());
    //     });
    // }

    const issueDetailsAction = (cell, row) => {
        return <Link className="text-primary" to={"audience-details/" + row.nested_data_id} target="_blank">{cell}</Link>
    }

    const detailedTable = (page = 1, size = 10, sortBy = 'first_name', orderBy = 'asc') => {
        setLoader(true)
        var formData = new FormData();

        if (filteredData) {
            for (let key in filteredData) {
                formData.append(key, filteredData[key]);
            }
        }

        formData.append('channel', channel)
        formData.append('from_date', startDate.format('YYYY-MM-DD'))
        formData.append('to_date', endDate.format('YYYY-MM-DD'))
        formData.append('groupBy', groupBy)
        formData.append('data_type', np)
        formData.append('page_count', size);
        formData.append('page_no', page);
        formData.append('status_filter', filterBy)
        formData.append('sortby', sortBy)
        formData.append('orderby', orderBy === 'desc' ? false : true)
        formData.append('data_for', countOrContact)
        let URL = countOrContact === 'count' ? process.env.REACT_APP_API_KEY + 'reports/detailedview' : process.env.REACT_APP_API_KEY + 'reports/contactdetailedview';

        axios({
            method: 'post',
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                if (response.data.data_for === 'contact') {
                    setGridData(response.data.data)
                    setTotalSize(response.data.total_count)
                    let arr = [];
                    let outputArr = ['created_on', 'status'];
                    arr = response.data.data
                    for (let i = 0; i < arr.length; i++) {
                        let temp = Object.keys(arr[i]);
                        for (let j = 0; j < temp.length; j++) {
                            if (temp[j] === "_id" || temp[j] === "reg_no" || temp[j] === "data_set_id" || temp[j] === "org_id" || temp[j] === "source_type"
                                || temp[j] === "is_active" || temp[j] === "created_by" || temp[j] === "modified_by" || temp[j] === "nested_data_id"
                                || temp[j] === "repeatedData" || temp[j] === "repeatedColumn" || temp[j] === "wrongGender" || temp[j] === "emailValid" || temp[j] === "phoneValid") {
                            }
                            else {
                                outputArr.push(temp[j])
                            }
                        }
                    }
                    let s = new Set(outputArr)
                    const newArr = [...s];
                    //to swap success column in 4 position 
                    // let temp1 = newArr[newArr.length - 1]
                    // newArr[newArr.length - 1] = newArr[4]
                    // newArr[4] = temp1;

                    let col = [];
                    for (let k = 0; k < newArr.length; k++) {
                        if (newArr[k] === "created_on") {
                            col.push({
                                dataField: newArr[k],
                                text: permissionName(newArr[k]),
                                formatter: dateFormat,

                            })
                        }
                        else if (newArr[k] === "first_name") {
                            col.push({
                                dataField: newArr[k],
                                text: permissionName(newArr[k]),
                                sort: true,
                                formatter: issueDetailsAction
                            })

                        } else {
                            col.push({ dataField: newArr[k], text: permissionName(newArr[k]), sort: true });
                        }
                    }
                    if (col.length > 0) {
                        setContactColumn(col);
                    } else {
                        setContactColumn([{ dataField: '', text: '' }])
                    }
                }
                else if (response.data.data_for === 'count') {
                    setGridData(response.data.data);
                    setTotalSize(response.data.total_count)
                }
            }
            else {
                setGridData([]);
            }
            setLoader(false);
        })
            .catch(function (error) {
                setLoader(false);
                toast.error(error.toString());
            })
    }

    // const lineChart = () => {
    //     setLoader(true)
    //     var formData = new FormData();

    //     if (filteredData) {
    //         for (let key in filteredData) {
    //             formData.append(key, filteredData[key]);
    //         }
    //     }

    //     formData.append('channel', channel)
    //     formData.append('from_date', startDate.format('YYYY-MM-DD'))
    //     formData.append('to_date', endDate.format('YYYY-MM-DD'))
    //     formData.append('groupBy', groupBy)

    //     let URL = process.env.REACT_APP_API_KEY + 'reports/linechart';
    //     axios({
    //         method: 'post',
    //         url: URL,
    //         data: formData,
    //         headers:
    //         {
    //             Authorization: 'Token ' + TOKEN
    //         }
    //     }).then(function (response) {
    //         if (response.data.status === 1) {
    //             setLineChartData(response.data.data.values)
    //             setLineChartDates(response.data.data.label)
    //         } else {
    //             setLineChartData([])
    //             setLineChartDates([])
    //         }
    //         setLoader(false);
    //     }).catch(function (error) {
    //         setLoader(false);
    //         toast.error(error.toString());
    //     })
    // }

    useEffect(() => {
        detailedTable();
    }, [filterBy, countOrContact, np])

    // useEffect(() => {
    //     lineChart();
    //     donutChart();
    //     detailedTable();
    // }, [channel, groupBy, startDate, endDate, filteredData])

    const trendRadialChart = {

        series: doughnutCount,
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '20px',
                        },
                        value: {
                            fontSize: '16px',
                        },
                        total: {
                            show: true,
                            label: '',
                            formatter: function (w) {
                                return w.config.labels[0]
                            }
                        }
                    }
                }
            },
            labels: doughnutLabels
        },

    };
    const trendLineGraph = {
        series: lineChartData,
        yaxis: [
            {
                seriesName: 'success',
            },
            {
                seriesName: 'failed',
            },
            {
                seriesName: 'clicked'
            },
            {
                seriesName: 'responded',
            },
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 3,
                curve: 'straight',
                dashArray: 0
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                }
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                categories: lineChartDates,
            },

            tooltip: {
                y: [
                    {
                        title: {
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val;
                            }
                        }
                    }
                ]
            },
            grid: {
                borderColor: '#f1f1f1',
            }
        },
    }

    const gridColumns =
        countOrContact === 'count' ? [{
            dataField: 'label',
            text: csvHeader
        },
        {
            dataField: 'Processed',
            text: 'Processed',
        },
        {
            dataField: 'Success',
            text: 'Success',
        }, {
            dataField: 'Failed',
            text: 'Failed',
        }, {
            dataField: 'Clicked',
            text: 'Clicked',
        },
        {
            dataField: 'Responded',
            text: 'Responded',
        }
        ]
            :
            contactColumn;

    const MyExportCSV = (props) => {
        const handleClick = () => {
            props.onExport();
        };
        return (
            <div>
                <button type="button" className="btn btn-outline-primary" onClick={() => handleClick()}>Download<i className='mdi mdi-download ml-1'></i></button>
            </div>
        );
    };

    const defaultSorted = [{
        dataField: 'first_name',
        order: 'asc'
    }];

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <button type="button" data-toggle="modal" data-target="#right-modal" className="btn btn-outline-primary btn-sm-block mr-2"><i className="mdi mdi-tune mr-1"></i>Filters</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="right-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-lg modal-right" style={{ minWidth: 400, maxWidth: 600 }}>
                                        <form onSubmit={gridFilter} id="sidePopupFilterForm" class="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title" id="myLargeModalLabel">Filter Survey Responses</h4>
                                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                                                </button>
                                            </div>
                                            <div class="modal-body" style={{ maxWidth: '400px' }}>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Surveys</label>
                                                        <Select options={survey} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"survey_id"} />
                                                    </div>
                                                    <div className="col-md-12 form-group">
                                                        <label>Segments</label>
                                                        <Select options={segments} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"segments"} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Tags</label>
                                                        <Select options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"tags"} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Shares</label>
                                                        <Select options={shares} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"share_id"} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer align-footer-bottom">
                                                <button type="button" id="closeFilterModal" class="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                                                <button type="submit" class="btn btn-primary">Apply Filters</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active" aria-current="page">Trend Analysis</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Trend Analysis</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="row">
                                        <div className="col-md-3 pb-0">
                                            <select name="channel" className="form-control" onChange={(event) => setChannel(event.target.value)}>
                                                <option value="sms">Channel: SMS</option>
                                                <option value="url">Channel: URL</option>
                                                <option value="qr">Channel: QR</option>
                                                <option value="socialMedia">Channel: SOCIAL MEDIA</option>
                                            </select>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="d-flex justify-content-end">
                                                <div className="">
                                                    <select name="groupBy" className="form-control text-primary"
                                                        onChange={(event) => { setGroupBy(event.target.value); setCsvHeader(event.target.value) }} disabled={countOrContact === 'contact'}>
                                                        <option value="Days">Group By: Days</option>
                                                        <option value="Week">Group By: Week</option>
                                                        <option value="Month">Group By: Month</option>
                                                        <option value="Year">Group By: Year</option>
                                                        <option value="Survey">Group By: Survey</option>
                                                        <option value="Share">Group By: Share</option>
                                                    </select>
                                                </div>
                                                &nbsp;
                                                &nbsp;
                                                <div className="">
                                                    <div className="form-group">
                                                        <DateRangePicker
                                                            initialSettings={{
                                                                startDate: startDate.toDate(),
                                                                endDate: endDate.toDate(),
                                                                ranges: {
                                                                    Today: [moment().toDate(), moment().toDate()],
                                                                    Yesterday: [
                                                                        moment().subtract(1, 'days').toDate(),
                                                                        moment().subtract(1, 'days').toDate(),
                                                                    ],
                                                                    'Last 7 Days': [
                                                                        moment().subtract(6, 'days').toDate(),
                                                                        moment().toDate(),
                                                                    ],
                                                                    'Last 30 Days': [
                                                                        moment().subtract(29, 'days').toDate(),
                                                                        moment().toDate(),
                                                                    ],
                                                                    'This Month': [
                                                                        moment().startOf('month').toDate(),
                                                                        moment().endOf('month').toDate(),
                                                                    ],
                                                                    'Last Month': [
                                                                        moment().subtract(1, 'month').startOf('month').toDate(),
                                                                        moment().subtract(1, 'month').endOf('month').toDate(),
                                                                    ],
                                                                },
                                                            }}
                                                            onCallback={onDateChanged}>
                                                            <div id="reportrange" className="form-control">
                                                                <i className="fa fa-calendar"></i>&nbsp;
                                                                <span>{label}</span> <i className="fa fa-caret-down"></i>
                                                            </div>
                                                        </DateRangePicker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body p-0 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <ReactApexChart options={trendLineGraph.options} series={trendLineGraph.series} type="line" height={350} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <ReactApexChart options={trendRadialChart.options} series={trendRadialChart.series} type="radialBar" height={350} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToolkitProvider
                        keyField="id"
                        data={gridData}
                        columns={gridColumns}
                        exportCSV>
                        {
                            props => (
                                <div>
                                    <div className="row mb-2">
                                        <div className="col-md-12 my-3">
                                            <h4 className="">Detailed Data Table</h4>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex">
                                                        <div className="">
                                                            <div className="btn-group mr-2" role="group" aria-label="Basic outlined example">
                                                                <button type="button" className={countOrContact === 'count' ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => setCountOrContact('count')}>Count</button>
                                                                <button type="button" className={countOrContact === 'contact' ? "btn btn-primary" : "btn btn-outline-primary"} onClick={() => setCountOrContact('contact')}>Contact</button>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <select name="status_filter" className="form-control text-primary"
                                                                onChange={(event) => { setFilterBy(event.target.value) }} disabled={countOrContact === 'count'} >
                                                                <option value="Success">Filter By:Success</option>
                                                                <option value="Failed">Filter By:Failed</option>
                                                                <option value="Responded">Filter By:Responded</option>
                                                                <option value="Clicked">Filter By:Clicked</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="d-flex justify-content-end">
                                                        <div className="btn-group mr-2">
                                                            <button className={np === '#' ? "fc-prev-button btn btn-primary" : "fc-next-button btn btn-outline-primary"} type="button" onClick={() => setNP('#')} disabled={countOrContact === 'contact'}>#</button>
                                                            <button className={np === '%' ? "fc-prev-button btn btn-primary" : "fc-next-button btn btn-outline-primary"} type="button" onClick={() => setNP('%')} disabled={countOrContact === 'contact'}>%</button>
                                                        </div>
                                                        <MyExportCSV {...props.csvProps} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <GridView
                                        {
                                        ...props.baseProps}
                                        gridData={gridData}
                                        columns={gridColumns}
                                        totalSize={totalSize}
                                        sizePerPage={10}
                                        keyField="id"
                                        defaultSorted={defaultSorted}
                                        fetchData={(page, size, sortBy, orderBy) => detailedTable(page, size, sortBy, orderBy)} />
                                </div>
                            )
                        }
                    </ToolkitProvider >
                </div>
            </div>
            {loader ? <Loader /> : null}
        </div>
    )
}

export default Trend