import { useEffect, useState } from "react";

const AddCondition = (props) => {

    const [choices, setChoices] = useState([]);

    useEffect(() => {
        if (props.selectedQuestions) {
            let obj = JSON.parse(props.selectedQuestions);
            renderInputFields(obj);
        }
    }, [props]);

    const generateInputField = (event) => {
        let obj = JSON.parse(event.target.value);
        renderInputFields(obj);
    }

    const renderInputFields = (obj) => {
        let qType = obj.type;
        if (qType === 'radiogroup') {
            setChoices(obj.choices);
        } else if (qType === 'checkbox') {
            setChoices(obj.choices);
        } else {
            setChoices([]);
        }
    }

    return (
        <div className="row">
            <div className="col-md-2">
                <div className="form-group mx-2">
                    <select required={true} className="form-control" defaultValue={props.joinCondition} name="sl_join_condition">
                        <option value='and'>And</option>
                        <option value='or'>Or</option>
                    </select>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group mx-2">
                    <select required={true} className="form-control" defaultValue={props.selectedQuestions} name="sl_question" onChange={(event) => generateInputField(event)}>
                        <option value="" key={0}>--Select Question--</option>
                        {props.questions.map((obj, index) => {
                            return <option value={JSON.stringify(obj)} key={index}>{obj.title}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="col-md-2">
                <div className="form-group mx-2">
                    <select required={true} className="form-control" defaultValue={props.selectedConditions} name="sl_check_condition">
                        {props.conditions.map((obj, index) => {
                            return <option value={obj.value} key={index}>{obj.name}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="col-md-3">
                <div className="form-group mx-2">
                    {choices.length === 0 ?
                        <input className="form-control" required={true} defaultValue={props.selectedAnswer} type="text" name="sl_answer" />
                        :
                        <select required={true} className="form-control" defaultValue={props.selectedAnswer} name="sl_answer">
                            <option value="" key={0}>--Select--</option>
                            {choices.map((obj, index) => {
                                return <option value={obj.value} key={index}>{obj.text}</option>
                            })}
                        </select>
                    }
                </div>
            </div>
            <div className="col-md-1">
                <i onClick={(event) => event.target.parentElement.parentElement.remove()} className="cursor-pointer mdi mdi-delete mr-1 font-24 text-danger"></i>
            </div>
        </div>
    )
}

export default AddCondition;