import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
//import { firstCharCaps, permissionName } from "../../../../Utils/string_formatting";
//import PermissionsGridView from "../../../Common/PermissionsGridView";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import $ from 'jquery';


import Select from "react-select";
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import { permissionName } from '../../../../Utils/string_formatting';
const { getTree } = QbUtils;


const UpdateRole = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const history = useHistory();

    //var permissionsArray = [];

    const [roleName, setRoleName] = useState("");
    const [roleDescription, setRoleDescription] = useState("");
    //const [permissionsUI, setPermissionsUI] = useState([]);
    //const [permissions, setPermissions] = useState([]);


    const [fields, setFields] = useState({});
    const [immTree, setImmTree] = useState("");
    const [newImmTree, setNewImmTree] = useState("");
    const [sqlQuery, setSqlQuery] = useState("");
    const [mongoQuery, setMongoQuery] = useState("");

    const [tree, setTree] = useState();

    const [audience, setAudience] = useState(false);
    const [segments, setSegments] = useState(false);
    const [surveys, setSurveys] = useState(false);
    const [responses, setResponses] = useState(false);
    const [analytics, setAnalytics] = useState(false);
    const [trendAnalysis, setTrendAnalysis] = useState(false);
    const [issues, setIssues] = useState(false);

    const [sharesSelected, setSharesSelected] = useState([]);
    const [shares, setShares] = useState([]);


    const [settingManage, setSettingManage] = useState(false);

    useEffect(() => {
        setRoleName(props.location.state.roleDetails.role_name);
        setRoleDescription(props.location.state.roleDetails.role_desc);
        //processPermissions(props.location.state.roleDetails.permissions);
        setMongoQuery(props.location.state.roleDetails.mongo_query);
        setImmTree(JSON.parse(props.location.state.roleDetails.query_tree));
        setSettingManage(props.location.state.roleDetails.permissions.settings_manage);
        setAudience(props.location.state.roleDetails.permissions.show_audience);
        setSegments(props.location.state.roleDetails.permissions.show_segments);
        setSurveys(props.location.state.roleDetails.permissions.show_survey);
        setResponses(props.location.state.roleDetails.permissions.show_response);
        setAnalytics(props.location.state.roleDetails.permissions.show_analytics);
        setTrendAnalysis(props.location.state.roleDetails.permissions.show_trends);
        setIssues(props.location.state.roleDetails.permissions.show_issues);
        setSharesSelected(props.location.state.roleDetails.share_list);
    }, [props.location.state]);


    const fetchShares = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getShareData';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: data[i].share_name })
                }
                setShares(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchShares, []);

    const fetchPrimarySecondaryFields = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getAllFields';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            generateFields(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchPrimarySecondaryFields, []);

    const generateFields = (data) => {
        let obj = {};
        //let keys = Object.keys(data[0]);
        for (let i = 0; i < data.length; i++) {
            if (data[i] === "" || data[i] === "id" || data[i] === "data_set_id" || data[i] === "org_id") {
                //do nothing
            } else {
                obj = Object.assign(obj, { [data[i]]: { label: permissionName(data[i]), type: 'text' } });
            }
        }
        setFields(obj);
    }


    // const processPermissions = (allPermissions) => {
    //     let UI = [];
    //     let keys = Object.keys(allPermissions);
    //     for (let i = 0; i < keys.length; i++) {
    //         let arr = [];
    //         let list = allPermissions[keys[i]];
    //         let selectedPermissions = [];
    //         $.each(list, function (key, value) {
    //             if (value[Object.keys(value)[0]] === true) {
    //                 selectedPermissions.push(keys[i] + '_' + Object.keys(list[key])[0])
    //             }
    //         });
    //         for (let j = 0; j < list.length; j++) {
    //             arr.push({ id: keys[i] + '_' + Object.keys(list[j])[0], name: permissionName(Object.keys(list[j])[0]), value: Object.keys(list[j])[0] })
    //         }
    //         UI.push(<PermissionsGridView gridData={arr} gridName={firstCharCaps(keys[i])} selectRow={selectRow(selectedPermissions)} />)
    //         setPermissions(permissions => [...permissions, selectedPermissions]);
    //     }
    //     setPermissionsUI(UI);
    // }

    // const selectRow = (selectedPermissions) => {
    //     return ({
    //         mode: 'checkbox',
    //         clickToSelect: true,
    //         hideSelectAll: true,
    //         selected: selectedPermissions,
    //         onSelect: (row, isSelect) => {
    //             if (isSelect) {
    //                 permissionsArray.push(row.id);
    //             } else {
    //                 let index = permissionsArray.indexOf(row.id);
    //                 if (index > -1) {
    //                     permissionsArray.splice(index, 1);
    //                 }
    //             }
    //             setPermissions(permissionsArray)
    //         },
    //         onSelectAll: (isSelect, rows) => {
    //             if (isSelect) {
    //                 for (let i = 0; i < rows.length; i++) {
    //                     permissionsArray.push(rows[i].id);
    //                 }
    //             } else {
    //                 permissionsArray = [];
    //             }
    //             setPermissions(permissionsArray)
    //         }
    //     })
    // };



    useEffect(() => {
        $(document).on('click', '.filterToggleMenu', function (e) {
            e.preventDefault();
            if ($('.filterToggleMenu').hasClass('collapsed')) {
                $('.filterToggleMenu i').removeClass("mdi-eye").addClass("mdi-eye-off");
            } else {
                $('.filterToggleMenu i').removeClass("mdi-eye-off").addClass("mdi-eye");
            }
        });
    }, []);

    const config = {
        ...MaterialConfig,
        fields: fields
    };

    useEffect(() => {
        const queryValue = { "id": QbUtils.uuid(), "type": "group" };
        let initValue = immTree && Object.keys(immTree).length > 0 ? (immTree) : queryValue;
        var initTree = QbUtils.checkTree(QbUtils.loadTree(initValue), config);
        setTree(initTree);
    }, [fields]);


    const renderBuilder = (props) => (
        <div className="query-builder-container" style={{ padding: '10px' }}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    )

    const onChange = (immutableTree, config) => {
        setTree(immutableTree);
        setMongoQuery(JSON.stringify(QbUtils.mongodbFormat(immutableTree, config)));
        setSqlQuery(JSON.stringify(QbUtils.sqlFormat(immutableTree, config)));

        let jsonTree = getTree(immutableTree);
        setNewImmTree(jsonTree);
    }


    const renderResult = ({ tree: immutableTree, config }) => (
        <div className="query-builder-result">
            {/* <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div> */}
            {/* <div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre></div> */}
            <div><pre style={{ whiteSpace: 'pre-wrap', fontSize: 14, fontWeight: 600 }}>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div>
            {/* <div><pre style={{ whiteSpace: 'pre-wrap', fontSize: 14, fontWeight: 600 }}>{(QbUtils.sqlFormat(immutableTree, config))}</pre></div> */}
            {/* <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div> */}
        </div>
    );

    const updateRole = (event) => {
        event.preventDefault();
        //const formData = new FormData(event.target);
        //formData.append('permissions', permissions);
        const formData = new FormData();

        if (mongoQuery) {
            formData.append('mongo_query', mongoQuery);
        } else {
            formData.append('mongo_query', "{}");
        }

        if (sqlQuery) {
            formData.append('sql_query', sqlQuery);
        } else {
            formData.append('sql_query', "{}");
        }

        // formData.append('mongo_query', mongoQuery);
        // formData.append('sql_query', sqlQuery);

        formData.append('role_name', roleName);
        //formData.append('query_tree', JSON.stringify(tree));
        formData.append('query_tree', JSON.stringify(newImmTree));
        formData.append('role_desc', roleDescription);
        formData.append('settings_manage', settingManage);


        formData.append('show_audience', audience);
        formData.append('show_segments', segments);
        formData.append('show_survey', surveys);
        formData.append('show_response', responses);
        formData.append('show_analytics', analytics);
        formData.append('show_trends', trendAnalysis);
        formData.append('show_issues', issues);

        let e = document.getElementsByName("share_list");
        let arr = [];

        for (let i = 0; i < e.length; i++) {
            arr.push(e[i].value)
        }

        formData.append('share_list', arr);

        let URL = process.env.REACT_APP_API_KEY + 'roles/' + props.location.state.roleDetails.id;

        axios({
            method: "put",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                props.reloadProfileDetails();
                //document.getElementById('updateRoleForm').reset();
                history.push('/roles');
                window.location.reload();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div className="content-page">
            <div className="content">
                {/* <form onSubmit={updateRole} id="updateRoleForm" className="container-fluid"> */}
                <div id="updateRoleForm" className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <button type="button" onClick={updateRole} className="btn btn-primary">UPDATE</button>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><Link to="/settings">Settings</Link></li>
                                        <li className="breadcrumb-item"><a>User Settings</a></li>
                                        <li className="breadcrumb-item"><Link to="/roles">Roles</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Update Role</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/roles"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>{roleName}</h4>
                                    <p>{roleDescription}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="name">Name of Role<span className="text-danger">*</span></label>
                                        <input className="form-control" value={roleName} onChange={(event) => setRoleName(event.target.value)} type="text" placeholder="Enter role name" name="role_name" required />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label for="name">Description of Role<span className="text-danger">*</span></label>
                                        <input className="form-control" value={roleDescription} onChange={(event) => setRoleDescription(event.target.value)} type="text" placeholder="Enter role description" name="role_desc" required />
                                    </div>
                                </div>
                            </div>

                            <h4 className="mt-2">Menu Access</h4>
                            <div className="row  ml-0">
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="audience" checked={audience} onChange={() => setAudience(!audience)} />
                                    <label className="custom-control-label" for="audience">Audience</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="segments" checked={segments} onChange={() => setSegments(!segments)} />
                                    <label className="custom-control-label" for="segments">Segments</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="surveys" checked={surveys} onChange={() => setSurveys(!surveys)} />
                                    <label className="custom-control-label" for="surveys">Surveys</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="responses" checked={responses} onChange={() => setResponses(!responses)} />
                                    <label className="custom-control-label" for="responses">Responses</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="analytics" checked={analytics} onChange={() => setAnalytics(!analytics)} />
                                    <label className="custom-control-label" for="analytics">Analytics</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="trendAnalysis" checked={trendAnalysis} onChange={() => setTrendAnalysis(!trendAnalysis)} />
                                    <label className="custom-control-label" for="trendAnalysis">Trend Analysis</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="issues" checked={issues} onChange={() => setIssues(!issues)} />
                                    <label className="custom-control-label" for="issues">All Issues</label>
                                </div>
                            </div>

                            <h4 className="mt-3">Other Access</h4>
                            <div className="row">
                                <div className="custom-control custom-switch ml-2">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={settingManage} onChange={() => setSettingManage(!settingManage)} />
                                    <label className="custom-control-label" for="customSwitch1">Settings Access</label>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="name">Shares</label>
                                        {shares ? <Select defaultValue={props.location.state.roleDetails.share_list} options={shares} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"share_list"} /> : null}
                                    </div>
                                </div>
                            </div>

                            {/* <h4>Manage Permissions</h4>
                            <div className="row">
                                {permissionsUI}
                            </div> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h4 className="mt-3">Filter By</h4>
                            <div className="card p-3">
                                <div className="d-flex justify-content-between">
                                    {renderResult({ tree, config })}
                                    {mongoQuery ?
                                        <button className="filterToggleMenu" style={{ border: 'none', color: '#313a46', backgroundColor: 'transparent', fontSize: '24px', cursor: 'pointer' }} data-toggle="collapse" data-target="#queryBuilderContainer"><i className="mdi mdi-eye"></i></button>
                                        : null
                                    }
                                </div>
                                <div id="queryBuilderContainer" className="collapse show">
                                    <Query  {...config} value={tree} onChange={onChange} renderBuilder={renderBuilder} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </form> */}
            </div>
        </div >
    );
}

export default UpdateRole;
