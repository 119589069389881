import React, { useState, useEffect } from 'react';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { permissionName } from '../../Utils/string_formatting';
import Loader from '../Common/Loader';
import GridView from '../Common/GridView';
import { dateFormat } from '../../Utils/date_formatting';

const ViewSegment = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [audiances, setAudiances] = useState([]);
    const [columns, setColumns] = useState([{ dataField: '', text: '' }]);
    const [segmentName, setSegmentName] = useState("");
    const [segmentDescription, setSegmentDescription] = useState("");
    const [loader, setLoader] = useState(false);
    const [totalSize, setTotalSize] = useState(0);

    const fetchAudienceUploads = (page = 1, size = 10) => {
        setLoader(true);
        const formData = new FormData();
        let URL = process.env.REACT_APP_API_KEY + 'audience/getSingleSegment';
        formData.append('single_page_count', size);
        formData.append('page_number', page);
        formData.append('segment_id', props.match.params.id);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {

                setSegmentName(response.data.segment_data.name)
                setSegmentDescription(response.data.segment_data.description);

                let arr = []
                for (let i = 0; i < response.data.data.length; i++) {
                    arr.push({ id: response.data.data[i]._id.$oid, ...response.data.data[i].primary_data })// , ...response.data.data[i].secondary_data })
                }
                generateColumns(arr);
                setAudiances(arr);
                setTotalSize(response.data.audience_count);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setLoader(false);
        });
    }

    useEffect(fetchAudienceUploads, [])

    const issueDetailsAction = (cell, row) => {
        return <Link className="text-primary" to={"/audience-details/" + row.id}>{cell}</Link>
    }

    const generateColumns = (data) => {
        let arr = [];
        let keys = Object.keys(data[0]);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === "id" || keys[i] === "data_set_id" || keys[i] === "org_id" || keys[i] === "source_type" || keys[i] === "is_active" || keys[i] === "created_by" || keys[i] === "modified_by") {
                //do nothing
            } else if (keys[i] === "first_name") {
                arr.push({
                    dataField: keys[i],
                    text: permissionName(keys[i]),
                    formatter: issueDetailsAction,
                    sort: true
                });
            } else if (keys[i] === "created_on") {
                arr.push({
                    dataField: keys[i],
                    text: permissionName(keys[i]),
                    formatter: dateFormat,
                    sort: true
                });
            } else {
                arr.push({
                    dataField: keys[i],
                    text: permissionName(keys[i]),
                    sort: true
                });
            }
        }
        setColumns(arr);
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item"><Link to="/segments">Segments</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">View Segment</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/segments"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>{segmentName}</h4>
                                    <p>{segmentDescription}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {loader ? <Loader /> : null}
                            <GridView
                                gridData={audiances}
                                columns={columns}
                                totalSize={totalSize}
                                sizePerPage={10}
                                keyField="id"
                                fetchData={(page, size) => fetchAudienceUploads(page, size)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewSegment;