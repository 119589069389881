import axios from "axios";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { dateFormat } from "../../../../Utils/date_formatting";
import Loader from "../../../Common/Loader";

const UsersGridView = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [loader, setLoader] = useState(false);

    const reInviteUser = (id) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'reInviteUser?user_id=' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                toast.success(response.data.message);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const setStatus = (cell) => {
        return cell ? <span className="badge badge-success-lighten  m-0 round-badge text-center">Active</span> : <span className="badge badge-danger-lighten  round-badge text-center">Inactive</span>;
    }

    const setActionButtons = (cell, row) => {
        return (
            <div className="dropdown float-right">
                <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical text-secondary font-20"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                    <Link className="dropdown-item notify-item" onClick={() => props.setUserData(row)} data-toggle="modal" data-target="#editUserModal"><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></Link>
                    <Link className="dropdown-item notify-item" onClick={() => props.setDeleteUrl('user/' + row.user_id)} data-toggle="modal" data-target="#deleteModal"><i className="mdi mdi-delete mr-1"></i><span>Delete</span></Link>
                    {row.is_active ? null :
                        <Link className="dropdown-item notify-item" onClick={() => reInviteUser(row.user_id)} ><i className="mdi mdi-email-sync mr-1"></i><span>Reinvite</span></Link>
                    }
                </div>
            </div>
        );
    }

    const columns = [{
        dataField: 'name',
        text: 'Name',
        sort: true
    }, {
        dataField: 'email',
        text: 'Email',
        sort: true
    }, {
        dataField: 'role_name',
        text: 'Role'
    }, {
        dataField: 'modified_on',
        text: 'Modified On',
        formatter: dateFormat
    }, {
        dataField: 'is_active',
        text: 'Status',
        formatter: setStatus
    }, {
        dataField: 'id',
        classes: 'py-0',
        formatter: setActionButtons
    }];

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <>
            <BootstrapTable
                keyField='id'
                data={props.gridData}
                bootstrap4
                bordered={false}
                hover
                columns={columns}
                pagination={paginationFactory(options)}
                noDataIndication="No record found" />
            {loader ? <Loader /> : null}
        </>
    );
}

export default UsersGridView;