import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ChangePasswordModal from "./ChangePasswordModal";
import Loader from "../../Common/Loader";

const Profile = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [pic, setPic] = useState("");
    const [loginType, setLoginType] = useState(null);
    const [loader, setLoader] = useState(false);

    const fetchProfileDetails = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'getUserData';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setFirstName(response.data.data.first_name);
            setLastName(response.data.data.last_name);
            setEmail(response.data.data.email);
            setPic(response.data.data.profile_pic);
            setLoginType(response.data.data.login_type);
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(fetchProfileDetails, []);

    const updateProfilePic = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'updateProfilePic';
        if (formData.get('profile_pic').size > 0) {
            axios({
                method: "post",
                url: URL,
                data: formData,
                headers: {
                    Authorization: 'Token ' + TOKEN
                }
            }).then(function (response) {
                fetchProfileDetails();
                toast.success(response.data.message);
                setLoader(false);
                props.reloadProfileDetails();
            }).catch(function (error) {
                setLoader(false);
                toast.error(error.toString());
            });
        }
    }

    const updateProfileDetails = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'getUserData';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            toast.success(response.data.message);
            document.getElementById('profilePicForm').reset();
            props.reloadProfileDetails();
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Profile Settings</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Profile Settings</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-5">
                            <div className="card text-center">
                                <form className="card-body" onSubmit={updateProfilePic} id="profilePicForm">
                                    <img src={pic ? process.env.REACT_APP_API_MEDIA_KEY + pic : "/assets/images/users/user.png"} style={{ objectFit: "cover" }} className="rounded-circle avatar-xl img-thumbnail " alt="profile-image" />
                                    <h4 className="mb-2 mt-2">{firstName ? firstName + ' ' + lastName : 'User Name'}</h4>
                                    <button type="button" onClick={() => document.getElementById('browseFile').click()} className="btn btn-primary text-uppercase mb-1 mt-2">Update Profile Image</button>
                                    <input type="file" onChange={() => document.getElementById("submitFOrm").click()} name="profile_pic" id="browseFile" className="form-control-file d-none" />
                                    <button type="submit" id="submitFOrm" className="d-none" />
                                </form>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7">
                            <div className="card">
                                <form onSubmit={updateProfileDetails} className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="profile-first-name" className="form-label">First Name<span className="text-danger">*</span></label>
                                                <input type="text" name="first_name" value={firstName} onChange={event => setFirstName(event.target.value)} className="form-control" placeholder="Enter your first name" id="profile-first-name" required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label for="profile-last-name" className="form-label">Last Name<span className="text-danger">*</span></label>
                                                <input type="text" name="last_name" value={lastName} onChange={event => setLastName(event.target.value)} className="form-control" placeholder="Enter your last name" id="profile-last-name" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label for="profile-email-address" className="form-label">Email Address</label>
                                                <input className="form-control" readOnly={true} type="email" name="email" value={email} placeholder="Enter your email" id="profile-email-address" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-sm-6">
                                            <div className="text-sm-end">
                                                <button type="submit" className="btn btn-primary">
                                                    UPDATE
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            {loginType === "manual" ?
                                                <Link to="#" data-toggle="modal" data-target="#updatePasswordModal" className="text-custom-secondary btn-link fw-semibold text-uppercase float-right">Change Password</Link>
                                                : null}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <ChangePasswordModal />
                        {loader ? <Loader /> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;