import axios from "axios";
import { toast } from "react-toastify";

const CreateTagModal = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const createTag = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeTagModal').click();
                document.getElementById('createTagForm').reset();
                props.fetchTags();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div id="createTagModal" className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="standard-modalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <form onSubmit={createTag} id="createTagForm" className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="standard-modalLabel">Create Tag</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body text-left">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label for="name">Tag Name<span className="text-danger">*</span></label>
                                    <input className="form-control" type="text" placeholder="Enter tag name" name="name" required/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeTagModal" className="btn btn-light" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary">Create Tag</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateTagModal;