import axios from "axios";
import { toast } from "react-toastify";

const DuplicateSurveyPopup = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const createSurvey = (event) => {
        event.preventDefault();
        let URL = process.env.REACT_APP_API_KEY + 'survey/duplicateSurvey';
        const formData = new FormData(event.target);
        formData.append('survey_id', props.duplicateSyrveyId);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                document.getElementById('closeDuplicateSurveyPopup').click();
                toast.success(response.data.message);
                props.reloadGrid();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div id="duplicate-survey-modal" className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <form onSubmit={createSurvey} className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Duplicate Survey</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Name<span className="text-danger">*</span></label>
                            <input className="form-control" type="text" name="survey_name" required={true} placeholder="Enter name of survey" />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeDuplicateSurveyPopup" className="btn btn-outline-secondary" data-dismiss="modal">CANCEL</button>
                        <button type="submit" className="btn btn-primary">CREATE</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default DuplicateSurveyPopup;