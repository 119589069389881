import { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import { useHistory } from "react-router";

const SideMenu = (props) => {

    const history = useHistory();

    const currentLocation = history.location.pathname;

    const permissions = props.userInfo.permissions;

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    useEffect(() => {
        $(document).on('click', '.side-nav-link', function (e) {
            if (window.innerWidth < 768) {
                $(".navbar-custom button").trigger("click");
            }
        });
        if (window.innerWidth < 768) {
            document.body.setAttribute("data-leftbar-compact-mode", "");
        } else {
            document.body.setAttribute("data-leftbar-compact-mode", "condensed");
        }
    });

    const expandCollapse = () => {
        if (window.innerWidth >= 576) {
            if (document.body.getAttribute('data-leftbar-compact-mode') === 'condensed') {
                // document.body.removeAttribute("data-leftbar-compact-mode");
                document.body.setAttribute("data-leftbar-compact-mode", "");
                // $('.button-menu-desktop i').removeClass("mdi-arrow-expand-horizontal").addClass("mdi-arrow-collapse-horizontal mr-2");
                // $('.button-menu-desktop .sm-expand-close').removeClass("text-center");
                // $('.button-menu-desktop .sm-expand-close').text("Close");
                // $(".button-menu-desktop").css({"margin-left": "6px", "padding": "15px 20px", "transition":"all 0s"});
            } else {
                document.body.setAttribute("data-leftbar-compact-mode", "condensed");
                // $('.button-menu-desktop i').removeClass("mdi-arrow-collapse-horizontal mr-2").addClass("mdi-arrow-expand-horizontal");
                // $('.button-menu-desktop .sm-expand-close').addClass("text-center");
                // $('.button-menu-desktop .sm-expand-close').text("EXPAND");
                // $(".button-menu-desktop").css({"margin-left": "unset", "padding": "unset", "transition":"all 0s"});
            }
        }
    }

    return (
        <div className="left-side-menu home-side-menu mm-show" style={{ position: 'fixed' }}>
            <Link to="/" className="logo text-center logo-light">
                <span className="logo-lg">
                    <img src={logo ? logo : "/assets/images/logo.png"} style={{ objectFit: "contain", maxWidth: '200px', padding: '4px' }} alt="" height="60" />
                </span>
                <span className="logo-sm">
                    <img src={logo ? logo : "/assets/images/logo_sm.png"} style={{ objectFit: "contain", maxWidth: '60px', padding: '4px' }} alt="" height="60" />
                </span>
            </Link>
            <Link to="/" className="logo text-center logo-dark">
                <span className="logo-lg">
                    <img src={logo ? logo : "/assets/images/logo-dark.png"} style={{ objectFit: "contain", maxWidth: '200px', padding: '4px' }} alt="" height="60" />
                </span>
                <span className="logo-sm">
                    <img src={logo ? logo : "/assets/images/logo_sm_dark.png"} style={{ objectFit: "contain", maxWidth: '60px', padding: '4px' }} alt="" height="60" />
                </span>
            </Link>
            <div className="h-100 mm-active" id="left-side-menu-container" data-simplebar="init"><div className="simplebar-wrapper" style={{ margin: "0px" }}><div className="simplebar-height-auto-observer-wrapper"><div className="simplebar-height-auto-observer"></div></div><div className="simplebar-mask"><div className="simplebar-offset" style={{ right: '0px' }}><div className="simplebar-content-wrapper" style={{ height: '100%', overflow: 'hidden scroll' }}><div className="simplebar-content" style={{ padding: '0px' }}>
                <ul className="metismenu side-nav mm-show">
                    <li className="side-nav-item">
                        <Link to="/" className="side-nav-link">
                            <i className={"mdi mdi-view-dashboard " + (currentLocation === "/" ? "text-primary" : null)}></i>
                            <span className={(currentLocation === "/" ? "text-primary" : null)}>Dashboard</span>
                        </Link>
                    </li>
                    {permissions.show_audience ?
                        <li className="side-nav-item">
                            <Link to="/audience" className="side-nav-link">
                                <i className={"mdi mdi-account-multiple " + (currentLocation === "/audience" || currentLocation === "/import-options" ? "text-primary" : null)}></i>
                                <span className={(currentLocation === "/audience" || currentLocation === "/import-options" ? "text-primary" : null)}>Audience</span>
                            </Link>
                        </li>
                        : null}
                    {permissions.show_segments ?
                        <li className="side-nav-item">
                            <Link to="/segments" className="side-nav-link">
                                <i className={"mdi mdi-chart-pie " + (currentLocation === "/segments" || currentLocation === "/view-segment" || currentLocation === "/add-segment" || currentLocation === "/edit-segment" ? "text-primary" : null)}></i>
                                <span className={(currentLocation === "/segments" || currentLocation === "/view-segment" || currentLocation === "/edit-segment" ? "text-primary" : null)}>Segments</span>
                            </Link>
                        </li>
                        : null}
                    {permissions.show_survey ?
                        <li className="side-nav-item">
                            <Link to="/surveys" className="side-nav-link">
                                <i className={"mdi mdi-clipboard-text " + (currentLocation === "/surveys" ? "text-primary" : null)}></i>
                                <span className={(currentLocation === "/surveys" ? "text-primary" : null)}>Surveys</span>
                            </Link>
                        </li>
                        : null}
                    {permissions.show_response ?
                        <li className="side-nav-item">
                            <Link to="/responses" className="side-nav-link">
                                <i className={"mdi mdi-message-arrow-left " + (currentLocation === "/responses" ? "text-primary" : null)}></i>
                                <span className={(currentLocation === "/responses" ? "text-primary" : null)}>Responses</span>
                            </Link>
                        </li>
                        : null}
                    {permissions.show_issues ?
                        <li className="side-nav-item">
                            <Link to="/all-issues" className="side-nav-link">
                                <i className={"mdi mdi-ticket-confirmation-outline " + (currentLocation === "/all-issues" ? "text-primary" : null)}></i>
                                <span className={(currentLocation === "/all-issues" ? "text-primary" : null)}>All Issues</span>
                            </Link>
                        </li>
                        : null}
                    {/* <li className="side-nav-item">
                        <Link to="/" className="side-nav-link">
                            <i className="mdi mdi-trending-up"></i>
                            <span>Journey</span>
                        </Link>
                    </li>

                    */}

                    <li className="side-nav-item">
                        <Link to="/my-issues" className="side-nav-link">
                            <i className={"mdi mdi-alert text-danger " + (currentLocation === "/my-issues" ? "text-primary" : null)}></i>
                            {/* <i className={"mdi mdi-beta font-16 text-danger"} style={{marginLeft: "-4px"}}></i> */}

                            <span className={(currentLocation === "/my-issues" ? "text-primary" : null)}>My Issues (beta)</span>
                        </Link>
                    </li>

                    {permissions.show_trends ?
                        <li className="side-nav-item">
                            <Link to="/trend-analysis" className="side-nav-link">
                                <i className={"mdi mdi-trending-up " + (currentLocation === "/trend-analysis" ? "text-primary" : null)}></i>
                                <span className={(currentLocation === "/trend-analysis" ? "text-primary" : null)}>Trend Analysis</span>
                            </Link>
                        </li>
                        : null}
                    {/* <li className="side-nav-item">
                        <Link to="/audience-details" className="side-nav-link">
                            <i className={"mdi mdi-message-arrow-left " + (currentLocation === "/audience-details" ? "text-primary" : null)}></i>
                            <span className={(currentLocation === "/audience-details" ? "text-primary" : null)}>Audience Details</span>
                        </Link>
                    </li> */}


                    <li className="side-nav-item">
                        <Link to="/response-analysis" className="side-nav-link">
                            <i className="mdi mdi-chart-bar"></i>
                            <span>Response Analysis</span>
                        </Link>
                    </li>
                    {permissions.show_analytics ?
                        <li className="side-nav-item">
                            <Link to="/analytics" className="side-nav-link">
                                <i className={"mdi mdi-chart-donut-variant " + (currentLocation === "/analytics" ? "text-primary" : null)}></i>
                                <span className={(currentLocation === "/analytics" ? "text-primary" : null)}>Analytics</span>
                            </Link>
                        </li>
                        : null}
                    <li className="side-nav-item">
                        <Link to="/advanced-analytics" className="side-nav-link">
                            <i className={"mdi mdi-chart-donut-variant " + (currentLocation === "/advanced-analytics" ? "text-primary" : null)}></i>
                            <span className={(currentLocation === "/advanced-analytics" ? "text-primary" : null)}>Advanced Analytics</span>
                        </Link>
                    </li>
                    {/* <li className="side-nav-item">
                        <Link to="/settings" className="side-nav-link">
                            <i className="mdi mdi-cog"></i>
                            <span>Settings</span>
                        </Link>
                    </li> */}
                </ul>
                <div className="clearfix"></div>
            </div>
            </div></div></div><div className="simplebar-placeholder" style={{ width: '240px', height: '2318px' }}></div></div><div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}><div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}></div></div><div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}><div className="simplebar-scrollbar" style={{ height: '201px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }}></div></div></div>
            <Link className="sideMenuToggle button-menu-desktop-expand d-none d-sm-none d-md-block text-center" onClick={expandCollapse} style={{ transition: "all 0s" }}>
                <i className="uil  uil-angle-double-right"></i>
            </Link>
            <Link className="sideMenuToggle button-menu-desktop-close d-none d-sm-none d-md-block text-center" onClick={expandCollapse} style={{ marginLeft: "176px", transition: "all 0s" }}>
                <i className="uil uil-angle-double-left"></i>
            </Link>
        </div>
    );
}

export default SideMenu;