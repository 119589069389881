import axios from "axios";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import { permissionName, trimString } from "../../Utils/string_formatting";
import GridView from "../Common/GridView";
import { dateFormat } from "../../Utils/date_formatting";

const IssuesGridView = ({ gridData, fetchIssues, userInfo, totalSize }) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [priorities, setPriorities] = useState([]);
    const [status, setStatus] = useState([]);

    const fetchPriorities = () => {
        let URL = process.env.REACT_APP_API_KEY + 'master/getIssuePriorities';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setPriorities(response.data.data)
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchStatus = () => {
        let URL = process.env.REACT_APP_API_KEY + 'master/getIssueStatus';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setStatus(response.data.data)
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchPriorities, []);
    useEffect(fetchStatus, []);

    const changeStatus = (data, id) => {
        for (let i = 0; i < status.length; i++) {
            if (Number(id) === status[i].id) {
                document.getElementById('custom_status_' + data.id).style.color = status[i].color;
                document.getElementById('custom_status_' + data.id).style.backgroundColor = status[i].color + '27';
                break;
            }
        }

        let URL = process.env.REACT_APP_API_KEY + 'issues/changeIssueStatus/' + data.id + '?status=' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                for (let i = 0; i < status.length; i++) {
                    if (Number(id) === status[i].id) {
                        if (status[i].status === 'closed') {
                            fetchIssues()
                        }
                        break;
                    }
                }
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const changePriority = (data, id) => {
        let URL = process.env.REACT_APP_API_KEY + 'issues/changeIssuePriority/' + data.id + '?priority=' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });

    }

    const issueDetailsAction = (cell, row) => {
        return <Link title={cell} className="text-primary font-weight-bold" to={{ pathname: "/issue-details/" + row.id }}><b>{trimString(cell, 30)}</b></Link>
    }

    const priorityAction = (cell, row) => {
        return (
            <select className="form-control m-0 select-custom" style={{ minWidth: 130 }} onChange={(event) => changePriority(row, event.target.value)} defaultValue={row.priority}>
                {priorities.map((obj, i) => {
                    return <option value={obj.id}>{obj.color} {permissionName(obj.priority)}</option>
                })}
            </select>
        )
    }

    const checkOwner = (row) => {
        for (let i = 0; i < row?.owner_list?.length; i++) {
            if (row.owner_list[i].owner_email === userInfo.email) {
                return false;
            }
        }
        if (row?.owner_list?.length === 0) {
            return false;
        }
        return true;
    }

    const enableDisableStatus = (row) => {
        let statusValue = null;

        for (let i = 0; i < status.length; i++) {
            if (row.status === status[i].id) {
                statusValue = status[i].status;
                break
            }
        }

        if (statusValue === 'open' || statusValue === 'work_in_progress' || statusValue === 'resolved') {
            return false
        }

        if (statusValue === 'overdue' || statusValue === 'reopened' || statusValue === 'closed') {
            if (checkOwner(row)) {
                return true
            }
        }
    }

    const addStatusCheck = (obj, row) => {
        if (obj.status === 'overdue') {
            return <option disabled={true} value={obj.id}>{permissionName(obj.status)}</option>
        } else if (obj.status === 'reopened' || obj.status === 'closed') {
            if (checkOwner(row)) {
                return <option disabled={true} value={obj.id}>{permissionName(obj.status)}</option>
            } else {
                return <option value={obj.id}>{permissionName(obj.status)}</option>
            }
        } else {
            let statusValue = null;

            for (let i = 0; i < status.length; i++) {
                if (row.status === status[i].id) {
                    statusValue = status[i].status;
                    break
                }
            }

            if ('closed' === statusValue) {
                if (obj.status === 'resolved' || obj.status === 'reopened') {
                    return <option value={obj.id}>{permissionName(obj.status)}</option>
                } else {
                    return <option disabled={true} value={obj.id}>{permissionName(obj.status)}</option>
                }
            } else {
                return <option value={obj.id}>{permissionName(obj.status)}</option>
            }
        }
    }

    const statusAction = (cell, row) => {
        let txtColor = '#000000';
        let bgColor = '#ffffff';
        for (let i = 0; i < status.length; i++) {
            if (row.status === status[i].id) {
                txtColor = status[i].color;
                bgColor = status[i].color + '27';
                break;
            }
        }

        return (
            <>
                <select style={{ color: txtColor, backgroundColor: bgColor, minWidth: 170 }} className="form-control m-0 select-custom" id={'custom_status_' + row.id} disabled={enableDisableStatus(row)} name='status' onChange={(event) => changeStatus(row, event.target.value)} defaultValue={row.status}>
                    {status.map((obj, i) => {
                        return addStatusCheck(obj, row)
                    })}
                </select>

                {/* <select style={{ color: txtColor, backgroundColor: bgColor, minWidth: 170 }} className="form-control m-0 select-custom" id={'custom_status_' + row.id} onChange={(event) => changeStatus(row, event.target.value)} defaultValue={row.status}>
                    {status.map((obj, i) => {
                        return <option value={obj.id}>{permissionName(obj.status)}</option>
                    })}
                </select> */}
            </>
        )
    }

    const setAssignedTo = (cell, row) => {
        if (row.assigned_list.length > 1) {
            let names = "";
            row.assigned_list.map((obj) => {
                names += obj.assigned_to_name + '\n';
            })
            return (
                <span title={names} className="cursor-pointer">
                    {row.assigned_list[0]?.assigned_to_name + ' +' + (row.assigned_list.length - 1).toString()}
                </span>
            )
        } else {
            return row.assigned_list[0]?.assigned_to_name;
        }
    }

    const audienceDetailsAction = (cell, row) => {
        return <Link className="text-primary" to={row.nested_data_id ? "audience-details/" + row.nested_data_id : "audience"}>{cell}</Link>
    }

    const columns = [{
        dataField: 'name',
        text: 'Issue',
        sort: true,
        formatter: issueDetailsAction
    }, {
        dataField: 'org_issue_id',
        text: 'Issue ID',
        formatter: issueDetailsAction
    }, {
        dataField: 'audience_name',
        text: 'Name',
        formatter: audienceDetailsAction
    }, {
        dataField: 'sub_tasks_count',
        text: 'Sub Tasks'
    }, {
        dataField: 'survey_name',
        text: 'Survey Name'
    }, {
        dataField: 'id',
        text: 'Assigned To',
        formatter: setAssignedTo
    }, {
        dataField: 'created_on',
        text: 'Issue Date',
        formatter: dateFormat
    }, {
        dataField: 'tat_time_left',
        text: 'TAT',
        formatter: (cell) => { return parseInt(cell / 86400) + ':' + (new Date(cell % 86400 * 1000)).toUTCString().replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, "$1:$2:$3") }
    }, {
        dataField: 'priority',
        text: 'Priority',
        formatter: priorityAction
    }, {
        dataField: 'status',
        text: 'Status',
        formatter: statusAction
    }];

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <GridView
            classes="table-hover-custom"
            gridData={gridData}
            columns={columns}
            totalSize={totalSize}
            sizePerPage={10}
            keyField="id"
            fetchData={(page, size) => fetchIssues(page, size)} />
    );
}

export default IssuesGridView;