const statusMapHelpers = {
    all_status: 'Total',
    open: 'Open',
    work_in_progress: 'WIP',
    resolved: 'Resolved',
    overdue: 'Overdue',
    closed: 'Closed',
    reopened: 'Reopened',
};

const statusLabelMapHelpers = {
    open: 'Open',
    work_in_progress: 'Work In Progress',
    resolved: 'Resolved',
    overdue: 'Overdue',
    closed: 'Closed',
    reopened: 'Reopened',
};

const statusToNumMapHelpers = {
    open: 1,
    work_in_progress: 2,
    resolved: 3,
    overdue: 4,
    closed: 5,
    reopened: 6,
};

const statusToValueMapHelpers = {
    Open: 'open',
    'Work In Progress': 'work_in_progress',
    Resolved: 'resolved',
    Overdue: 'overdue',
    Closed: 'closed',
    Reopened: 'reopened',
};
export {
    statusMapHelpers,
    statusToValueMapHelpers,
    statusToNumMapHelpers,
    statusLabelMapHelpers,
}
