import axios from "axios";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { permissionName } from "../../../../../Utils/string_formatting";
import Loader from "../../../../Common/Loader";
import paginationFactory from 'react-bootstrap-table2-paginator';
import GridView from "../../../../Common/GridView";

const AudienceGridView = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    var usersArray = [];

    const [audiances, setAudiances] = useState([]);
    const [columns, setColumns] = useState([{ dataField: '', text: '' }]);
    const [loader, setLoader] = useState(false);
    const [totalSize, setTotalSize] = useState(0);

    const fetchAudienceUploads = (page = 1, size = 10) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/getAllAudience?single_page_count=' + size + "&page_number=" + page;
        const formData = new FormData();
        formData.append('single_page_count', size);
        formData.append('page_number', page);
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            let arr = [];
            if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                    arr.push({ id: response.data.data[i]._id.$oid, ...response.data.data[i].primary_data })//, ...response.data.data[i].secondary_data
                }
                generateColumns(arr);
            }
            setAudiances(arr);
            setTotalSize(response.data.audience_count);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(fetchAudienceUploads, []);

    const generateColumns = (data) => {
        let arr = [];
        let keys = Object.keys(data[0]);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === "phone" || keys[i] === "first_name" || keys[i] === "last_name") {
                arr.push({
                    dataField: keys[i],
                    text: permissionName(keys[i])
                })
            }
        }
        setColumns(arr);
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                usersArray.push(row.id);
            } else {
                let index = usersArray.indexOf(row.id);
                if (index > -1) {
                    usersArray.splice(index, 1);
                }
            }
            props.individualUsers(usersArray);
        },
        onSelectAll: (isSelect, rows) => {
            if (isSelect) {
                for (let i = 0; i < rows.length; i++) {
                    usersArray.push(rows[i].id);
                }
            } else {
                usersArray = [];
            }
            props.individualUsers(usersArray);
        }
    };

    return (
        <>
            <GridView
                gridData={audiances}
                columns={columns}
                totalSize={totalSize}
                sizePerPage={10}
                keyField="id"
                selectRow={selectRow}
                fetchData={(page, size) => fetchAudienceUploads(page, size)} />
            {loader ? <Loader /> : null}
        </>
    );
}

export default AudienceGridView;