import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const DeleteModal = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [deleteText, setDeleteText] = useState("");
    const [deleteButton, setDeleteButton] = useState(true);

    useEffect(() => {
        if (deleteText === "delete" || deleteText === "Delete") {
            setDeleteButton(false);
        } else {
            setDeleteButton(true);
        }
    }, [deleteText])

    const submitDelete = () => {
        const formData = new FormData();
        let URL = process.env.REACT_APP_API_KEY + props.deleteUrl;
        axios({
            method: "delete",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeDeleteModal').click();
                setDeleteText("");
                props.reloadGrid();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div id="deleteModal" className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="danger-header-modalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header modal-colored-header bg-danger">
                        <h4 className="modal-title" id="danger-header-modalLabel">Do you want to delete?</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <p>Enter the word 'Delete' in the given below field to delete the existing record. Record once deleted can not recovered.</p>
                                <input className="form-control" value={deleteText} onChange={(event) => setDeleteText(event.target.value)} type="text" placeholder="Type 'Delete' here" name="name" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeDeleteModal" className="btn btn-light" data-dismiss="modal">Close</button>
                        <button type="button" onClick={submitDelete} disabled={deleteButton} className="btn btn-danger">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteModal;