import DeleteModal from "../../../../Common/DeleteModal";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AddSmsModal from "./AddSmsModal";
import ServiceCard from "../../../../Common/ServiceCard";
import SmsGridView from "./SmsGridView";
import ViewSmsModal from "./ViewSmsModal";
import EditSmsModal from "./EditSmsModal";
import { Link } from "react-router-dom";
import Loader from "../../../../Common/Loader";

const SmsIntegration = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [sspAvailed, setSspAvailed] = useState([]);
    const [sspData, setSspData] = useState([]);
    const [sspOptionsUI, setSspOptionsUI] = useState([]);
    const [deleteUrl, setDeleteUrl] = useState([]);
    const [loader, setLoader] = useState(false);

    const fetchSspAvailed = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/getChannelsSms';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setSspAvailed(response.data.data);
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    const fetchSspProviders = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/getChannels?channel_type=sms';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                let arr = [];
                for (let i = 0; i < response.data.data.length; i++) {
                    let data = response.data.data[i];
                    arr.push(<ServiceCard src={process.env.REACT_APP_API_MEDIA_KEY + data.img} name={data.name} id={data.id} modalId="#addSmsModal" />)
                }
                setSspOptionsUI(arr);
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(fetchSspAvailed, []);
    useEffect(fetchSspProviders, []);

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3 mb-0 pb-0">
                                    <li className="breadcrumb-item"><Link>Settings</Link></li>
                                    <li className="breadcrumb-item"><a>Integrations</a></li>
                                    <li className="breadcrumb-item"><Link to="/channel-integration">Channel</Link></li>
                                    <li className="breadcrumb-item active">SMS</li>
                                </ol>
                            </nav>
                            <button type="button" data-toggle="modal" data-target="#addSmsModal" className="btn btn-primary btn-sm-block float-right">Add SSP</button>
                            <div className="page-title-box">
                                <h4 className="page-title"><Link to="/channel-integration"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>SMS Service Provider</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3 className="text-secondary m-3">Your SMS Service Provider (SSP) List</h3>
                                        <SmsGridView gridData={sspAvailed} setSspData={setSspData} setDeleteUrl={setDeleteUrl} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3 className="text-secondary m-3">Available SMS Service Providers (SSP)</h3>
                                        <hr />
                                        <div className="card">
                                            <div className="row ml-2 mt-1">
                                                {sspOptionsUI}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AddSmsModal gridData={sspAvailed} fetchSspAvailed={fetchSspAvailed} />
                    <EditSmsModal sspData={sspData} fetchSspAvailed={fetchSspAvailed} />
                    <ViewSmsModal sspData={sspData} />
                    <DeleteModal deleteUrl={deleteUrl} reloadGrid={fetchSspAvailed} />
                    {loader ? <Loader /> : null}
                </div>
            </div>
        </div>
    );
}

export default SmsIntegration;