import React from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { dateFormat } from "../../../../Utils/date_formatting";

const AddTeamModal = (props) => {

    var usersArray = [];

    const TOKEN = localStorage.getItem('fms-access-token');

    const setStatus = (cell) => {
        return cell ? <span className="badge badge-outline-success">Active</span> : <span className="badge badge-outline-danger">Inactive</span>;
    }

    const columns = [{
        dataField: 'name',
        text: 'Name'
    }, {
        dataField: 'email',
        text: 'Email'
    }, {
        dataField: 'is_active',
        text: 'Status',
        formatter: setStatus
    }, {
        dataField: 'role_name',
        text: 'Role'
    }, {
        dataField: 'modified_on',
        text: 'Modified On',
        formatter: dateFormat
    },];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: usersArray,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                usersArray.push(row.user_id);
            } else {
                let index = usersArray.indexOf(row.user_id);
                if (index > -1) {
                    usersArray.splice(index, 1);
                }
            }
        },
        onSelectAll: (isSelect, rows) => {
            if (isSelect) {
                for (let i = 0; i < rows.length; i++) {
                    usersArray.push(rows[i].user_id);
                }
            } else {
                usersArray = [];
            }
        }
    };

    const addTeam = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('org_users', usersArray);
        let URL = process.env.REACT_APP_API_KEY + 'addTeam';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeAddTeamModal').click();
                document.getElementById('addTeamForm').reset();
                props.fetchTeams();
                usersArray = [];
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <div className="modal fade" id="addTeamModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <form onSubmit={addTeam} id="addTeamForm" className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">Add Team</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Team Name<span className="text-danger">*</span></label>
                                    <input type="text" name="name" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <label>Team Description<span className="text-danger">*</span></label>
                                    <textarea className="form-control" name="description" required></textarea>
                                </div>
                                <label>Users</label>
                                <BootstrapTable
                                    keyField='id'
                                    data={props.gridData}
                                    bootstrap4
                                    bordered={false}
                                    selectRow={selectRow}
                                    hover
                                    columns={columns}
                                    pagination={paginationFactory(options)}
                                    noDataIndication="No record found" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeAddTeamModal" className="btn btn-outline-secondary" data-dismiss="modal">CLOSE</button>
                        <button type="submit" className="btn btn-primary">CREATE</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddTeamModal;