import { Link } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { permissionName } from "../../Utils/string_formatting";
import Loader from "../Common/Loader";
import { tatDateTime } from "../../Utils/date_formatting";
import UpdateAudience from "./UpdateAudience";
import $ from 'jquery';

const AudienceDetails = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [audienceDetails, setAudienceDetails] = useState([]);
    const [audienceSurveyDetails, setAudienceSurveyDetails] = useState([]);
    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("0");
    const [loader, setLoader] = useState(false);
    const [audienceIssues, setAudienceIssues] = useState([]);
    const [surveyIssues, setSurveyIssues] = useState([]);
    const [surveyResponse, setSurveyResponse] = useState([]);

    const audienceId = props.match.params.id;

    useEffect(() => {
        fetchAudienceDetails();
        fetchAudienceSurveyDetails();
        fetchStatus();
        fetchAudienceIssues(null);
    }, [])

    useEffect(() => {

    })
    const fetchAudienceDetails = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/getSingleAudience/' + audienceId;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setAudienceDetails(response.data.data)
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchAudienceSurveyDetails = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSurveyDetails/' + audienceId;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setAudienceSurveyDetails(response.data.data)
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchStatus = () => {
        let URL = process.env.REACT_APP_API_KEY + 'master/getIssueStatus';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setStatus(response.data.data)
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchAudienceIssues = (statusId) => {
        let URL = '';
        if (statusId) {
            URL = process.env.REACT_APP_API_KEY + 'issues/getIssuesForSingleAudience/' + audienceId + '?status=' + statusId;
        } else {
            URL = process.env.REACT_APP_API_KEY + 'issues/getIssuesForSingleAudience/' + audienceId;
        }
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setAudienceIssues(response.data.data)
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(() => {
        fetchAudienceIssues(selectedStatus);
    }, [selectedStatus])

    const closeUnwantedTabs = () => {
        for (let i = 0; i < audienceSurveyDetails.length; i++) {
            for (let j = 0; j < 100; j++) {
                if (document.getElementById("issue-more-details_" + i + '_' + j)) {
                    document.getElementById("issue-more-details_" + i + '_' + j).classList.remove("show");
                }
            }
        }

        for (let i = 0; i < audienceSurveyDetails.length; i++) {
            for (let j = 0; j < 100; j++) {
                if (document.getElementById("response-more-details_" + i + '_' + j)) {
                    document.getElementById("response-more-details_" + i + '_' + j).classList.remove("show");
                }
            }
        }
    }

    const fetchResponsesBySurvey = (responseId, surveyId, index) => {

        closeUnwantedTabs()

        if (!isNaN(responseId)) {
            document.getElementById("issue-more-details_" + index).classList.remove("show");
            setLoader(true);
            const formData = new FormData();
            formData.append('response_id', responseId)
            formData.append('survey_id', surveyId)
            let URL = process.env.REACT_APP_API_KEY + 'audience/getSingleAudienceResponseInfo';
            axios({
                method: "post",
                url: URL,
                data: formData,
                headers: {
                    Authorization: 'Token ' + TOKEN
                }
            }).then(function (response) {
                setLoader(false);
                if (response.data.status === 1) {
                    setSurveyResponse(response.data.data)
                } else if (response.data.status === 0) {
                    //toast.error(response.data.message);
                } else {
                    toast.error('Someting went wrong');
                }
            }).catch(function (error) {
                setLoader(false);
                toast.error(error.toString());
            });
        } else {
            setSurveyResponse([])
        }
    }

    const fetchIssueDetailsBySurvey = (responseId, surveyId, index) => {

        closeUnwantedTabs()

        document.getElementById("response-more-details_" + index).classList.remove("show");
        setLoader(true);
        const formData = new FormData();
        formData.append('response_id', responseId)
        formData.append('survey_id', surveyId)
        let URL = process.env.REACT_APP_API_KEY + 'issues/assignedIssueList';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                setSurveyIssues(response.data.data)
            } else if (response.data.status === 0) {
                //toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const setAssignedTo = (data) => {
        if (data.assigned_list.length > 1) {
            let names = "";
            data.assigned_list.map((obj) => {
                names += obj.assigned_to_name + '\n';
            })
            return (
                <span title={names}>
                    {data.assigned_list[0]?.assigned_to_name + ' +' + (data.assigned_list.length - 1).toString()}
                </span>
            )
        } else {
            return data.assigned_list[0]?.assigned_to_name;
        }
    }

    const genrateTransactionFields = (data) => {

        let outputArr = [];
        let temp = Object.keys(data);
        for (let j = 0; j < temp.length; j++) {

            if (temp[j] === "_id" || temp[j] === "survey_data" || temp[j] === "email" || temp[j] === "address" || temp[j] === "first_name" || temp[j] === "city" || temp[j] === "dob"
                || temp[j] === "gender" || temp[j] === "last_name" || temp[j] === "tags" || temp[j] === "phone" || temp[j] === "data_set_id" || temp[j] === "org_id" || temp[j] === "source_type"
                || temp[j] === "is_active" || temp[j] === "created_by" || temp[j] === "modified_by" || temp[j] === "nested_data_id"
                || temp[j] === "repeatedData" || temp[j] === "repeatedColumn" || temp[j] === "wrongGender" || temp[j] === "emailValid" || temp[j] === "phoneValid") {
            }
            else {
                outputArr.push(temp[j])
            }
        }
        let s = new Set(outputArr)
        const newArr = [...s];
        return newArr;
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                {/* <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <button type="button" className="btn btn-primary btn-sm-block float-right" data-toggle="modal" data-target="" >Set Resolution Due</button>
                                        </div>
                                    </div>
                                </div> */}
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item"><Link to="/audience">Audience</Link></li>
                                        <li className="breadcrumb-item active">Audience Details</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/audience"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>{audienceDetails.primary_data?.first_name} {audienceDetails.primary_data?.second_name}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body px-3 pt-3 pb-0">
                                    <div className="row">
                                        <div className="col-md-1">
                                            <div class="avatar-md">
                                                <span class="avatar-title bg-primary rounded font-22">{audienceDetails.primary_data?.first_name[0]}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-4 mb-2">
                                                    <p class="mb-0 text-muted">
                                                        <span class="text-nowrap me-2"><i class="mdi mdi-email"></i> Mail :</span>
                                                        <span class="text-dark ml-1">{audienceDetails.primary_data?.email}</span>
                                                    </p>
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <p class="mb-0 text-muted">
                                                        <span class="text-nowrap me-2"><i class="mdi mdi-phone"></i> Phone :</span>
                                                        <span class="text-dark ml-1">{audienceDetails.primary_data?.phone}</span>
                                                    </p>
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <p class="mb-0 text-muted">
                                                        <span class="text-nowrap me-2"><i class="mdi mdi-gender-male-female"></i> Gender :</span>
                                                        <span class="text-dark ml-1">{audienceDetails.primary_data?.gender}</span>
                                                    </p>
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <p class="mb-0 text-muted">
                                                        <span class="text-nowrap me-2"><i class="mdi mdi-cake-variant"></i> D.O.B :</span>
                                                        <span class="text-dark ml-1">{audienceDetails.primary_data?.dob}</span>
                                                    </p>
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <p class="mb-0 text-muted">
                                                        <span class="text-nowrap me-2"><i class="mdi mdi-map-marker"></i> City :</span>
                                                        <span class="text-dark ml-1">{audienceDetails.primary_data?.city}</span>
                                                    </p>
                                                </div>
                                                <div className="col-md-4 mb-2">
                                                    <p class="mb-0 text-muted">
                                                        <span class="text-nowrap me-2"><i class="mdi mdi-pin"></i> Address :</span>
                                                        <span class="text-dark ml-1">{audienceDetails.primary_data?.address}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-12 d-flex justify-content-between align-items-center mt-n1">
                                                    <ul class="nav nav-tabs nav-bordered ">
                                                        <li class="nav-item">
                                                            <a href="#overview" data-toggle="tab" aria-expanded="false" class="nav-link active">
                                                                <i class="mdi mdi-home-variant d-md-none d-block"></i>
                                                                <span class="d-none d-md-block">Overview</span>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="#timeline-b1" data-toggle="tab" aria-expanded="true" class="nav-link">
                                                                <i class="mdi mdi-account-circle d-md-none d-block"></i>
                                                                <span class="d-none d-md-block">Timeline</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <button type="button" class="btn btn-sm btn-outline-primary" data-toggle="modal" data-target="#updateAudienceDetails"><i class="mdi mdi-pencil"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-9">
                            <div class="tab-content">
                                <div class="tab-pane show active" id="overview">
                                    <h3 className="mt-n1 mb-3">Overview</h3>

                                    {audienceSurveyDetails.length === 0 ?
                                        <div className="card">
                                            <div className="card-body p-3">
                                                <h4 className="text-center">No Record Found</h4>
                                            </div>
                                        </div> :

                                        audienceSurveyDetails.map((objj, indexOut) => {
                                            return <>
                                                <div className="card">
                                                    <div className="card-body py-2 px-3">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="d-flex justify-content-between">
                                                                    <h4>Transaction - {indexOut + 1}</h4>
                                                                    <h5 class="text-muted font-weight-normal">{objj.created_on}</h5>
                                                                </div>
                                                                <hr className="mt-0" />
                                                                <div class="row">
                                                                    <div class="col-md-11">
                                                                        <div class="row">
                                                                            {genrateTransactionFields(objj).map((tranObj, i) => {
                                                                                return i <= 3 ? <div class="col-md-3">
                                                                                    <h5 class="text-muted font-weight-normal">{permissionName(tranObj)}</h5>
                                                                                    <p>{objj[tranObj]}</p>
                                                                                </div> : null
                                                                            })}
                                                                        </div>
                                                                        <div class="row collapse " id={"transaction-more-details_" + indexOut}>
                                                                            {genrateTransactionFields(objj).map((tranObj, i) => {
                                                                                return i > 3 ? <div class="col-md-3">
                                                                                    <h5 class="text-muted font-weight-normal">{permissionName(tranObj)}</h5>
                                                                                    <p>{objj[tranObj]}</p>
                                                                                </div> : null
                                                                            })}
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-1">
                                                                        {genrateTransactionFields(objj).length > 4 ?
                                                                            <a href={"#transaction-more-details_" + indexOut}
                                                                                onClick={() => {
                                                                                    document.getElementById("rotate-arrow-outer_" + indexOut).classList.toggle('mdi-rotate-180');
                                                                                }}
                                                                                type="button" data-toggle="collapse" aria-expanded="true" class="btn btn-sm btn-outline-dark mt-2">
                                                                                <i id={"rotate-arrow-outer_" + indexOut} class="uil uil-angle-down"></i>
                                                                            </a>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <h5>Survey Details</h5>
                                                                {objj?.survey_data?.length === 0 ?
                                                                    <h6 className="text-center">No Survey Details Found</h6>
                                                                    :
                                                                    objj?.survey_data?.map((obj, index) => {
                                                                        return <div className="card bg-home">
                                                                            <div className="card-body p-3">
                                                                                <div className="row">
                                                                                    <div className="col-md-11">
                                                                                        <div className="row">
                                                                                            <div className="col-md-3">
                                                                                                <h5 class="text-muted font-weight-normal">Survey Name</h5>
                                                                                                <p>{obj.survey_name}</p>
                                                                                            </div>
                                                                                            <div className="col-md-3">
                                                                                                <h5 class="text-muted font-weight-normal">Share Date</h5>
                                                                                                <p>{obj.started_time}</p>
                                                                                            </div>
                                                                                            <div className="col-md-3">
                                                                                                <h5 class="text-muted font-weight-normal">Channel</h5>
                                                                                                <p>{permissionName(obj.channel_type)}</p>
                                                                                            </div>
                                                                                            <div className="col-md-3">
                                                                                                <h5 class="text-muted font-weight-normal">Response Date</h5>
                                                                                                <p>{obj.response_date}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row collapse" id={"audience-more-details_" + indexOut + '_' + index}>
                                                                                            <div className="col-md-3">
                                                                                                <h5 class="text-muted font-weight-normal">Share Name</h5>
                                                                                                <p>{obj.share_name}</p>
                                                                                            </div>
                                                                                            {/* <div className="col-md-3">
                                                                                        <h5 class="text-muted font-weight-normal">Source</h5>
                                                                                        <p>{obj.source}</p>
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <h5 class="text-muted font-weight-normal">IP Address</h5>
                                                                                        <p>{obj.ip_address}</p>
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <h5 class="text-muted font-weight-normal">Location of Respondent</h5>
                                                                                        <p>{obj.location}</p>
                                                                                    </div> */}
                                                                                        </div>
                                                                                        <hr />
                                                                                        <div className="font-15">
                                                                                            <ul class="nav mb-3 audience-details">
                                                                                                <li class="nav-item">
                                                                                                    <a href={"#response-more-details_" + indexOut + '_' + index} onClick={() => fetchResponsesBySurvey(obj.response_id, obj.survey_id, indexOut + '_' + index)} type="button" data-toggle="collapse" aria-expanded="false" class="text-muted cursor-pointer mr-4">
                                                                                                        <i class="mdi mdi-message-processing me-1 mr-1"></i>
                                                                                                        <span className="text-dark">Response</span>
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li class="nav-item">
                                                                                                    <a href={"#issue-more-details_" + indexOut + '_' + index} onClick={() => fetchIssueDetailsBySurvey(obj.response_id, obj.survey_id, indexOut + '_' + index)} type="button" data-toggle="collapse" aria-expanded="false" class="text-muted cursor-pointer">
                                                                                                        <i class="mdi mdi-share-all me-1 mr-1"></i>
                                                                                                        <span className="text-dark">Issues</span>
                                                                                                    </a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div class="audience-details-content">
                                                                                            <div className="row collapse" id={"response-more-details_" + indexOut + '_' + index}>
                                                                                                <div className="col-md-12 mt-2">
                                                                                                    <h4>Response</h4>
                                                                                                    {surveyResponse.length === 0 ? <p>No Response Found</p> :
                                                                                                        surveyResponse.map((obj, i) => {
                                                                                                            return <div className="col-md-12 mt-2">
                                                                                                                <div className="faq-question-q-box font-12">Q.{i + 1}</div>
                                                                                                                <h4 className="faq-question" data-wow-delay=".1s">{obj.question?.default ? obj.question?.default : obj.question}</h4>
                                                                                                                {Array.isArray(obj.answer) ?
                                                                                                                    obj.answer.map((obj) => {
                                                                                                                        return <p className="faq-answer mb-1">{obj.default}</p>
                                                                                                                    })
                                                                                                                    :
                                                                                                                    obj.answer === "" ? <><p className="mb-4"></p></> :
                                                                                                                        <p className="faq-answer mb-2">{obj.answer}</p>
                                                                                                                }
                                                                                                            </div>
                                                                                                        })}
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row collapse" id={"issue-more-details_" + indexOut + '_' + index}>
                                                                                                <div className="col-md-12 mt-2">
                                                                                                    <h4>Issues</h4>
                                                                                                    {surveyIssues.length === 0 ? <p>No Issue Found</p> :
                                                                                                        surveyIssues.map((obj) => {
                                                                                                            return <div className="card mb-2 shadow-none border">
                                                                                                                <div className="px-2">
                                                                                                                    <div className="row align-items-center">
                                                                                                                        <div className="col-md-3">
                                                                                                                            <h6><i className="mdi mdi-clock text-muted mr-1"></i>{obj.name}</h6>
                                                                                                                        </div>
                                                                                                                        <div className="col-md-3">
                                                                                                                            <h5><i className="mdi mdi-account text-muted mr-1"></i>{setAssignedTo(obj)}</h5>
                                                                                                                        </div>
                                                                                                                        <div className="col-md-4">
                                                                                                                            <h6><i className="mdi mdi-clock text-muted mr-1"></i>{tatDateTime(obj.tat_time_left)}</h6>
                                                                                                                        </div>
                                                                                                                        <div className="col-md-2">
                                                                                                                            <Link className="text-primary float-right" to={{ pathname: "/issue-details/" + obj.id }}>View Details</Link>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        })}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-1">
                                                                                        <a href={"#audience-more-details_" + indexOut + '_' + index}
                                                                                            onClick={() => {
                                                                                                document.getElementById("rotate-arrow-inner_" + indexOut + '_' + index).classList.toggle('mdi-rotate-180');
                                                                                            }} type="button" data-toggle="collapse" aria-expanded="true" class="btn btn-sm btn-outline-dark mt-2"><i id={"rotate-arrow-inner_" + indexOut + '_' + index} class="uil uil-angle-down"></i></a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        })}
                                </div>

                                <div class="tab-pane" id="timeline-b1">
                                    <h3 className="mt-n1 mb-3">Timeline</h3>
                                    <div className="card">
                                        <div className="card-body p-3">
                                            {audienceSurveyDetails.length === 0 ? <h4 className="text-center">No Record Found</h4> :
                                                audienceSurveyDetails.map((objj, index) => {
                                                    return <><h4>Transaction - {index + 1}</h4>
                                                        {objj?.survey_data?.length === 0 ?
                                                            <>
                                                                <h6 className="text-center">No Transaction Details Found</h6>
                                                                <hr className="mt-0" />
                                                            </>
                                                            :
                                                            <div className="ml-2">
                                                                {objj?.survey_data?.map((obj, index) => {
                                                                    return <>
                                                                        <h5>Survey Name : {obj.survey_name}</h5>
                                                                        <div class="timeline-alt audience-details-timeline pt-2 pb-0">
                                                                            <div class="timeline-item">
                                                                                <i class="mdi mdi-share timeline-icon"></i>
                                                                                <div class="timeline-item-info">
                                                                                    <p class="mb-0 pb-2 text-muted fw-bold">
                                                                                        Survey Shared : <span className="text-dark">{obj.timeline_data.survey_shared}</span>

                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="timeline-item">
                                                                                <i class="mdi mdi-cursor-default-click timeline-icon"></i>
                                                                                <div class="timeline-item-info">
                                                                                    <p class="mb-0 pb-2 text-muted">
                                                                                        Survey Clicked : <span className="text-dark">{obj.timeline_data.survey_clicked}</span>

                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="timeline-item">
                                                                                <i class="mdi mdi-reply timeline-icon"></i>
                                                                                <div class="timeline-item-info">
                                                                                    <p class="mb-0 pb-2 text-muted">
                                                                                        Survey Responded : <span className="text-dark">{obj.timeline_data.survey_responded}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="timeline-item">
                                                                                <i class="mdi mdi-clock timeline-icon"></i>
                                                                                <div class="timeline-item-info">
                                                                                    <p class="mb-0 pb-2 text-muted">
                                                                                        Issue Status : <span className="text-dark">{obj.timeline_data.issue_status}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </>
                                                                })}
                                                            </div>
                                                        }
                                                    </>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body p-3">
                                    <div class="mb-2" data-select2-id="5">
                                        <label>Status</label>
                                        <select className="form-control m-0" name='status' value={selectedStatus} onChange={(event) => setSelectedStatus(event.target.value)}>
                                            <option value={0}>--All--</option>
                                            {status.map((obj, i) => {
                                                return <option value={obj.id}>{permissionName(obj.status)}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="mt-3">
                                        {audienceIssues.length === 0 ? <h4 className="text-center">No Record Found</h4> :
                                            audienceIssues.map((obj) => {
                                                return <>
                                                    <h4>{obj.issue_name}</h4>
                                                    <p><b>Assign To : </b>{obj?.assigned_list[0]?.assigned_to_name}</p>
                                                    <p><b>Status : </b>{permissionName(obj?.status)}</p>
                                                    <Link
                                                        to={obj.issue_type === 'create_issue' ? { pathname: "/issue-details/" + obj.issue_id } : { pathname: "/issue-details/sub-task-details/" + obj.issue_id }}
                                                        class="btn btn-outline-primary btn-block">View {obj.issue_type === 'create_issue' ? "Issue" : "Sub-Task"}</Link>
                                                    <hr />
                                                </>
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <UpdateAudience audienceId={audienceId} fetchAudience={fetchAudienceDetails} />
                        {loader ? <Loader /> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AudienceDetails;
