import SurveyInfoPopup from "./CreateSurvey/SurveyInfoPopup";
import SurveyGridView from "./SurveyGridView";
import Loader from '../Common/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from "react";

const Survey = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [surveys, setSurveys] = useState([]);
    const [loader, setLoader] = useState(false);

    const fetchSurveys = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'survey/allSurvey';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            setSurveys(response.data.data);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(fetchSurveys, []);

    const search = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'survey/allSurvey?search_string=' + formData.get('search');
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            setSurveys(response.data.data);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <div className="form-group m-0 mr-2">
                                                <form onSubmit={search} className="input-group">
                                                    <input type="text" name="search" className="form-control form-control-white border-light" placeholder="Search" />
                                                    <div className="input-group-append cursor-pointer">
                                                        <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                                            <i className="mdi mdi-magnify font-20"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                            {/* <div className="dropdown">
                                                <a data-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false"  type="" className="btn btn-outline-primary btn-sm-block mr-2 dropdown-toggle arrow-none"><i className="mdi mdi-tune mr-1"></i>Filter</a>
                                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-menu-border-top">
                                                    <a className="dropdown-item notify-item">
                                                        <i className="mdi mdi-circle-medium mr-1"></i>
                                                        <span>All</span>
                                                    </a>
                                                    <a  className="dropdown-item notify-item">
                                                        <i className="mdi mdi-circle-medium mr-1 text-danger"></i>
                                                        <span>Created</span>
                                                    </a>
                                                    <a className="dropdown-item notify-item">
                                                        <i className="mdi mdi-circle-medium mr-1 text-warning"></i>
                                                        <span>Designed</span>
                                                    </a>
                                                    <a  className="dropdown-item notify-item">
                                                        <i className="mdi mdi-circle-medium mr-1 text-success"></i>
                                                        <span>Shared</span>
                                                    </a>
                                                </div>
                                            </div> */}
                                            {/* <button type="button" className="btn btn-primary btn-sm-block float-right" data-toggle="modal" data-target="#surveyTemplateModal"><i className="uil-file-edit-alt mr-1"></i>Create Survey</button> */}
                                            <button type="button" className="btn btn-primary btn-sm-block float-right" data-toggle="modal" data-target="#survey-info-modal">CREATE SURVEY</button>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Surveys</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Surveys</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <SurveyInfoPopup />
                            <div className="row">
                                <div className="col-md-12 survey-table">
                                    <SurveyGridView gridData={surveys} fetchSurveys={fetchSurveys}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loader ? <Loader /> : null}
                </div>
            </div>
        </div>
    );
}

export default Survey;


