import React, { useEffect } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { getGuestToken, superSetDashboardList } from '../../AdvancedAnalytics/config';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';



const Reports = () => {
  useEffect(() => {
    embedDashboard({
      id: '681d500c-48cb-4085-ad88-a5eced55b565',
      supersetDomain: 'http://216.48.186.176:8088',
      mountPoint: document.getElementById("reports"),
      fetchGuestToken: () => 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7Imxhc3RfbmFtZSI6InVzZXIiLCJmaXJzdF9uYW1lIjoiYWRtaW4iLCJ1c2VybmFtZSI6ImFkbWluIn0sInJlc291cmNlcyI6W3siaWQiOiI2ODFkNTAwYy00OGNiLTQwODUtYWQ4OC1hNWVjZWQ1NWI1NjUiLCJ0eXBlIjoiZGFzaGJvYXJkIn1dLCJybHNfcnVsZXMiOltdLCJpYXQiOjE2Njc3NTQwOTIuNDMzMjEwNiwiZXhwIjoxNjY3NzU0MzkyLjQzMzIxMDYsImF1ZCI6Imh0dHA6Ly9zdXBlcnNldDo4MDg4LyIsInR5cGUiOiJndWVzdCJ9.La4lJnN6KxlZuQakeurhGC3DkkjKuB1DYVvrjYvhB1U',
      dashboardUiConfig: { hideTitle: true },
    });
    const kimsDashboard = document.getElementById("reports").firstChild;
    if (kimsDashboard) {
      kimsDashboard.style.width = '100%';
      kimsDashboard.style.height = '1000px';
    }
  }, []);

  return (
    <div
      id='reports'
      style={{
        width: '100%',
        height: '100%'
      }}
    >
    </div>
  );
}

export default Reports;