import { useState } from "react";
import { Link } from "react-router-dom";

const SurveyThrottling = () => {

    const [times, setTimes] = useState("");
    const [days, setDays] = useState("");
    const [checked, setChecked] = useState(false);

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3 mb-0 pb-0">
                                    <li className="breadcrumb-item"><Link to="/settings">Settings</Link></li>
                                    <li className="breadcrumb-item"><a>General Settings</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Survey Throttling</li>
                                </ol>
                            </nav>
                            <div className="page-title-box">
                                <h4 className="page-title">Survey Throttling</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-1">
                                <form className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex">
                                                <p className="mr-3 font-weight-bold">Don’t Share Survey to same person no more than</p>
                                                <input type="checkbox" checked={checked} id="switch0" onChange={event => setChecked(event.target.checked)} data-switch="none" />
                                                <label for="switch0" data-on-label="" data-off-label=""></label>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Number of times?<span className="text-danger">*</span></label>
                                                        <input type="text" disabled={!checked} name="first_name" value={times} onChange={event => setTimes(event.target.value)} className="form-control" required/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>How many days?<span className="text-danger">*</span></label>
                                                        <input type="text" disabled={!checked} name="last_name" value={days} onChange={event => setDays(event.target.value)} className="form-control" required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <small>Eg:- If you enter 3 times in 30 days, surveys across the platform will be sent to contacts only 3 times in 30days.</small><br />
                                            <button type="submit" className="btn btn-primary mt-4">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SurveyThrottling;