import React, { useEffect, useState } from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loader from "../../../Common/Loader";
import { dateFormat } from "../../../../Utils/date_formatting";
import { permissionName, trimString } from "../../../../Utils/string_formatting";
import DeleteModal from "../../../Common/DeleteModal";

const SurveyWorkFlow = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    const history = useHistory();

    const [surveyDetails, setSurveyDetails] = useState({});
    const [enableDisableTab, setEnableDisableTab] = useState(false);
    const [surveyName, setSurveyName] = useState("");
    const [loader, setLoader] = useState(false);
    const [issues, setIssues] = useState([]);
    const [deleteUrl, setDeleteUrl] = useState([]);

    const fetchIssues = (id = surveyDetails.id) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'issues/add_issue?survey_id=' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setIssues(response.data.data);
            }
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    useEffect(() => {
        if (props.location.state) {
            setSurveyDetails(props.location.state.surveyInfo);
            setSurveyName(props.location.state.surveyInfo.name)
            fetchIssues(props.location.state.surveyInfo.id);
            if (props.location.state.surveyInfo.json.length > 28) {
                setEnableDisableTab(false);
            } else {
                setEnableDisableTab(true);
            }
        } else {
            history.push('/surveys');
        }
    }, [props.location.state]);

    const updateSurveyName = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyDetails.id);
        let URL = process.env.REACT_APP_API_KEY + 'survey/surveyNameEdit';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setSurveyDetails(response.data.data);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const setActionButtons = (cell, row) => {
        return (
            <div className="dropdown float-right">
                <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical text-secondary font-20"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-border-top">
                    <Link className="dropdown-item notify-item" to={{ pathname: '/survey-update-work-flow', state: { surveyInfo: surveyDetails, workFlowInfo: row } }}><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></Link>
                    <span className="dropdown-item notify-item cursor-pointer" onClick={() => setDeleteUrl(row.workflow_delete_url + '/' + row.id)} data-toggle="modal" data-target="#deleteModal"><i className="mdi mdi-delete mr-1"></i><span>Delete</span></span>
                </div>
            </div>
        );
    }

    const changeStatus = (id) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'issues/changeCreateIssueStatus/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                fetchIssues(surveyDetails.id);
                toast.success(response.data.message);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    const statusAction = (cell, row) => {
        return (
            <div className="custom-control custom-switch ml-2">
                <input type="checkbox" onChange={() => changeStatus(row.id)} className="custom-control-input" id={"customSwitch_" + row.id} checked={row.create_issue_status} />
                <label className="custom-control-label" for={"customSwitch_" + row.id}></label>
            </div>
        )
    }

    const setSegment = (cell, row) => {
        if (row.segment_list.length > 1) {
            let names = "";
            row.segment_list.map((obj, index) => {
                if (index !== 0) {
                    names += obj.name + '\n';
                }
            })
            return (
                <span title={names} className="cursor-pointer">
                    {row.segment_list[0]?.name + ' +' + (row.segment_list.length - 1).toString()}
                </span>
            )
        } else {
            return row.segment_list[0]?.name;
        }
    }

    const columns = [{
        dataField: 'name',
        text: 'Workflow Name',
        sort: true
    }, {
        dataField: 'logic_statement',
        text: 'Issue Logic',
        formatter: (cell) => { return <span title={cell}>{trimString(cell, 50)}</span> }
    }, {
        dataField: 'segment_list',
        text: 'Segment',
        formatter: setSegment
    }, {
        dataField: 'workflow_name',
        text: 'Action',
        sort: true,
        formatter: (cell) => { return permissionName(cell) }
    }, {
        dataField: 'id',
        text: 'Last Modified',
        sort: true,
        formatter: (cell, row) => { return dateFormat(row.modified_on) + ' by ' + row.modified_by }
    }, {
        dataField: 'created_by',
        text: 'Status',
        formatter: statusAction
    }, {
        dataField: 'id',
        text: '',
        formatter: setActionButtons
    }];

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <div className="content">
            <div className="survey-top-bar">
                <div className="row border-bottom" style={{ height: '70px' }}>
                    <div className="col-md-4 d-flex" style={{ alignItems: 'center' }}>
                        <Link to="/"><span className="logo-lg mx-2"><img src={logo ? logo : "/assets/images/logo.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '60px', paddingTop: '4px', paddingBottom: '4px' }} /></span></Link>
                        <Link to="/surveys" className=""><i className="dripicons-arrow-thin-left font-24 mr-1 text-dark"></i></Link>
                        <div className="form-group m-0 mr-2 ">
                            <form onSubmit={updateSurveyName} className="input-group survey-top-bar-input">
                                <input type="text" name="name" id="survey_name" defaultValue={surveyName} className="form-control form-control-white border-light survey-input" placeholder="Survey Name" />
                                <div className="input-group-append cursor-pointer">
                                    <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                        <i className="mdi mdi-content-save font-20"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-8 d-flex justify-content-between">
                        <ul className="nav nav-tabs nav-bordered justify-content-center" style={{ alignItems: 'end' }}>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-content', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-1-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Content</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} aria-disabled={enableDisableTab} data-toggle="tab" aria-expanded="true" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-2-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Design</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link active">
                                    <i className="mdi text-primary mdi-numeric-3-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Workflow</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} aria-disabled={enableDisableTab} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-4-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Share</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-results', state: { surveyInfo: surveyDetails } }} aria-disabled={enableDisableTab} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-5-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Results</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="mr-2 mt-1"></div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12" style={{ marginTop: '4.3rem' }}>
                    <div className="tab-content">
                        <div className="tab-pane show active">
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-10 mt-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Link to={{ pathname: '/survey-add-work-flow', state: { surveyInfo: surveyDetails } }} className="btn btn-primary btn-sm-block float-right">ADD WORK FLOW</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <BootstrapTable
                                                keyField="id"
                                                data={issues}
                                                columns={columns}
                                                bordered={false}
                                                bootstrap4
                                                hover
                                                condensed={true}
                                                pagination={paginationFactory(options)}
                                                noDataIndication="No record found" />
                                        </div>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteModal deleteUrl={deleteUrl} reloadGrid={fetchIssues} />

            {loader ? <Loader /> : null}
        </div>
    );
}

export default SurveyWorkFlow;