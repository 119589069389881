import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import DropDown from "../../../Common/DropDown";

const AddUserModal = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [loader, setLoader] = useState(false);

    const addNewUser = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'addUser';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeModal').click();
                document.getElementById('addUserModalForm').reset();
                props.reloadGrid();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    return (
        <div className="modal fade" id="addUserModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <form onSubmit={addNewUser} id="addUserModalForm" className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">Add User</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label>First Name<span className="text-danger">*</span></label>
                                <input type="text" name="first_name" required={true} className="form-control" />
                            </div>
                            <div className="col-md-6 form-group">
                                <label>Last Name<span className="text-danger">*</span></label>
                                <input type="text" name="last_name" required={true} className="form-control" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Email<span className="text-danger">*</span></label>
                            <input type="email" name="email" required={true} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Role<span className="text-danger">*</span></label>
                            <DropDown url="roles" option_label="role_name" name="user_roles" id="user_roles" />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeModal" className="btn btn-outline-secondary" data-dismiss="modal">CLOSE</button>
                        <button type="submit" className="btn btn-primary" disabled={loader}>{loader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null}INVITE</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddUserModal;