import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DropDown from '../../../../Common/DropDown';
import ChannelsInputField from "../../../../Common/ChannelsInputField";
import $ from 'jquery'

const EditSmsModal = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [inputFieldsUI, setInputFieldsUI] = useState([]);

    const ssp = props.sspData;

    const [sspSelect, setSspSelect] = useState(props.sspData.channel_type_id);

    const generateFields = () => {
        let arr = [];
        let obj = $.each(ssp, function (key, value) {
            if (value === "" || value === null) {
                delete ssp[key];
            }
        });
        let keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === "modified_on" || keys[i] === "is_active" || keys[i] === "id" || keys[i] === "created_on" || keys[i] === "channel_type_name" || keys[i] === "channel_type_id") {
                //do nothing
            } else {
                arr.push(<ChannelsInputField name={keys[i]} value={ssp[keys[i]]} />);
            }
        }
        setInputFieldsUI(arr);
    }

    useEffect(() => {
        generateFields()
        setSspSelect(ssp.channel_type_id);
    }, [ssp]);

    const fetchFormFields = (id) => {
        setSspSelect(id);
        let URL = process.env.REACT_APP_API_KEY + 'master/getChannelFields/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                generateFieldsUI(response.data.data);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const generateFieldsUI = (data) => {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
            arr.push(<ChannelsInputField name={data[i].name} />);
        }
        setInputFieldsUI(arr);
    }

    const updateSsp = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'audience/smsKeysAPI/' + ssp.id;
        axios({
            method: "put",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeUpdateSspModal').click();
                document.getElementById('updateSspForm').reset();
                props.fetchSspAvailed();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div className="modal fade" id="editSmsModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <form onSubmit={updateSsp} id="updateSspForm" className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">Edit SSP</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>SSP<span className="text-danger">*</span></label>
                            <DropDown url="audience/getChannels?channel_type=sms" disabled={true} value={sspSelect} option_label="name" onchange={(event) => fetchFormFields(event.target.value)} name="channel_type" id="service-providers-select" />
                            <input type="text" className="d-none" name="channel_type" value={sspSelect} />
                        </div>
                        {inputFieldsUI}
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeUpdateSspModal" className="btn btn-outline-secondary" data-dismiss="modal">CLOSE</button>
                        <button type="submit" className="btn btn-primary">UPDATE</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditSmsModal;