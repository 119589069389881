import React, { useEffect, useState } from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import DropDown from "../../../Common/DropDown";
import { permissionName } from "../../../../Utils/string_formatting";
import Select from 'react-select'
import AddCondition from "./AddCondition";
import Loader from "../../../Common/Loader";

const UpdateWorkFlow = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    const history = useHistory();

    const [surveyDetails, setSurveyDetails] = useState({});
    const [workFlowDetails, setWorkFlowDetails] = useState({});
    const [surveyName, setSurveyName] = useState("");
    const [enableDisableTab, setEnableDisableTab] = useState(false);
    const [loader, setLoader] = useState(false);

    const [tags, setTags] = useState([]);
    const [priorities, setPriorities] = useState(0);
    const [users, setUsers] = useState([]);
    const [team, setTeam] = useState([]);
    const [segments, setSegments] = useState([]);
    const [shares, setShares] = useState([]);
    const [updateURL, setUpdateURL] = useState("");

    const [questions, setQuestions] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [choices, setChoices] = useState([]);
    const [addConditionUI, setAddConditionUI] = useState([]);

    const [tatDay, setTatDay] = useState(0);
    const [tatHour, setTatHour] = useState(0);
    const [tatMinute, setTatMinute] = useState(0);

    const [selectedQuestions, setSelectedQuestions] = useState("");
    const [selectedConditions, setSelectedConditions] = useState("");

    useEffect(() => {
        if (props.location.state) {
            setSurveyDetails(props.location.state.surveyInfo);
            setWorkFlowDetails(props.location.state.workFlowInfo);

            setSelectedQuestions(props.location.state.workFlowInfo?.set_logic_list[0]?.sl_question)
            setSelectedConditions(props.location.state.workFlowInfo?.set_logic_list[0]?.sl_check_condition)

            setSurveyName(props.location.state.surveyInfo.name);
            fetchQuestions(props.location.state.surveyInfo.id);
            setPriorities(props.location.state.workFlowInfo.priority);
            setUpdateURL(props.location.state.workFlowInfo.workflow_update_url + '/');
            setTatDay(parseInt(Number(props.location.state.workFlowInfo.tat) / 86400))
            setTatHour(parseInt(Number((new Date(props.location.state.workFlowInfo.tat % 86400 * 1000)).toUTCString().replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, "$1"))))
            setTatMinute(parseInt(Number((new Date(props.location.state.workFlowInfo.tat % 86400 * 1000)).toUTCString().replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, "$2"))))
            if (props.location.state.surveyInfo.json.length > 28) {
                setEnableDisableTab(false);
            } else {
                setEnableDisableTab(true);
            }
        } else {
            history.push('/surveys');
        }
    }, [props.location.state]);

    const updateSurveyName = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyDetails.id);
        let URL = process.env.REACT_APP_API_KEY + 'survey/surveyNameEdit';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setSurveyDetails(response.data.data);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    const fetchTags = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setTags(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchTeams = () => {
        let URL = process.env.REACT_APP_API_KEY + 'addTeam';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].team_name) })
                }
                setTeam(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchUsers = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'addUser';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].user_id, label: permissionName(data[i].name) })
                }
                setUsers(arr);
            }
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    const fetchQuestions = (id) => {
        let URL = process.env.REACT_APP_API_KEY + 'issues/getQuestionList/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setQuestions(response.data.data);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchConditions = () => {
        let URL = process.env.REACT_APP_API_KEY + 'issues/getConditionList';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setConditions(response.data.data);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchSegments = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setSegments(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchShares = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getShareData';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: data[i].share_name })
                }
                setShares(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchShares, []);
    useEffect(fetchSegments, []);
    useEffect(fetchTeams, []);
    useEffect(fetchUsers, []);
    useEffect(fetchTags, []);
    useEffect(fetchConditions, []);

    const updateIssue = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        if (formData.get('tat_day') === "0" && formData.get('tat_hour') === "0" && formData.get('tat_minute') === "0") {
            toast.error('Select valid TAT values');
        } else {
            setLoader(true);
            const formData = new FormData(event.target);
            formData.append('survey_id', surveyDetails.id);
            let URL = process.env.REACT_APP_API_KEY + updateURL + workFlowDetails.id;
            axios({
                method: "put",
                url: URL,
                data: formData,
                headers: {
                    Authorization: 'Token ' + TOKEN
                }
            }).then(function (response) {
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    history.push({ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } })
                } else if (response.data.status === 0) {
                    toast.error(response.data.message);
                } else {
                    toast.error('Someting went wrong');
                }
                setLoader(false);
            }).catch(function (error) {
                toast.error(error.toString());
                setLoader(false);
            });
        }
    }

    const generateInputField = (event) => {
        setSelectedQuestions(event.target.value);
        let obj = JSON.parse(event.target.value);
        let qType = obj.type;
        if (qType === 'radiogroup' || qType === 'checkbox' || qType === 'dropdown') {
            setChoices(obj.choices);
        } else {
            setChoices([]);
        }
    }

    const addCondition = () => {
        setAddConditionUI(addConditionUI => [...addConditionUI, <AddCondition questions={questions} conditions={conditions} />])
    }

    const selectFormatter = (data) => {
        if (data) {
            for (let i = 0; i < data.length; i++) {
                data[i]['label'] = data[i]['name']
                data[i]['value'] = data[i]['id']
            }
            return data;
        }
    }

    const selectSegmentFormatter = (data) => {
        if (data) {
            for (let i = 0; i < data.length; i++) {
                data[i]['label'] = data[i]['name']
                data[i]['value'] = data[i]['segment_id']
            }
            return data;
        }
    }

    const selectShareFormatter = (data) => {
        if (data) {
            for (let i = 0; i < data.length; i++) {
                data[i]['label'] = data[i]['name']
                data[i]['value'] = data[i]['share_id']
            }
            return data;
        }
    }

    useEffect(() => {
        let logicListLen = props.location.state.workFlowInfo.set_logic_list.length;
        if (logicListLen > 0 && questions.length > 0 && conditions.length > 0) {
            for (let i = 0; i < logicListLen - 1; i++) {
                let obj = props.location.state.workFlowInfo.set_logic_list[i + 1];
                setAddConditionUI(addConditionUI => [...addConditionUI, <AddCondition questions={questions} selectedQuestions={obj.sl_question} conditions={conditions} selectedConditions={obj.sl_check_condition} selectedAnswer={obj.sl_answer} joinCondition={obj.sl_join_condition} />])
            }
        }
    }, [questions, conditions])

    return (
        <div className="content">
            <div className="survey-top-bar">
                <div className="row border-bottom" style={{ height: '70px' }}>
                    <div className="col-md-4 d-flex" style={{ alignItems: 'center' }}>
                        <Link to="/"><span className="logo-lg mx-2"><img src={logo ? logo : "/assets/images/logo.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '60px', paddingTop: '4px', paddingBottom: '4px' }} /></span></Link>
                        <Link to="/surveys" className=""><i className="dripicons-arrow-thin-left font-24 mr-1 text-dark"></i></Link>
                        <div className="form-group m-0 mr-2 ">
                            <form onSubmit={updateSurveyName} className="input-group survey-top-bar-input">
                                <input type="text" name="name" id="survey_name" defaultValue={surveyName} className="form-control form-control-white border-light survey-input" placeholder="Survey Name" />
                                <div className="input-group-append cursor-pointer">
                                    <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                        <i className="mdi mdi-content-save font-20"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-8 d-flex justify-content-between">
                        <ul className="nav nav-tabs nav-bordered justify-content-center" style={{ alignItems: 'end' }}>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-content', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-1-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Content</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} aria-disabled={enableDisableTab} data-toggle="tab" aria-expanded="true" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-2-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Design</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link active">
                                    <i className="mdi text-primary mdi-numeric-3-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Workflow</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} aria-disabled={enableDisableTab} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-4-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Share</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-results', state: { surveyInfo: surveyDetails } }} aria-disabled={enableDisableTab} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-5-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Results</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="mr-2 mt-1"></div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{ marginTop: '4.3rem' }}>
                    <div className="tab-content">
                        <div className="tab-pane show active">
                            <div className="row">
                                <div className="col-md-1"></div>
                                <form onSubmit={updateIssue} className="col-md-10 mt-4">
                                    <div className="d-flex justify-content-between">
                                        <Link to={{ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } }}>
                                            <i className="mdi mdi-arrow-left"></i>
                                            <span>Back</span>
                                        </Link>
                                        <button type="submit" className="btn btn-primary mb-2">UPDATE</button>
                                    </div>
                                    <div className="card p-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group mx-2">
                                                    <label>Workflow Name</label>
                                                    <input className="form-control" required={true} type="text" name="name" defaultValue={workFlowDetails.name} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group mx-2">
                                                    <label>Segment</label>
                                                    {workFlowDetails.segment_list ? <Select defaultValue={selectSegmentFormatter(workFlowDetails.segment_list)} options={segments} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"segments"} /> : null}
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mx-2">
                                                    <label>Shares</label>
                                                    {workFlowDetails.share_list ? <Select defaultValue={selectShareFormatter(workFlowDetails.share_list)} options={shares} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="share_id" /> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group mx-2">
                                                    <label>Conditions</label>
                                                    <div className="row card border mx-0" >
                                                        <div className="col-md-12 px-2 pt-3 pb-1">
                                                            {workFlowDetails?.set_logic_list ?
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="form-group mx-2">
                                                                            <select required={true} className="form-control" value={selectedQuestions} name="sl_question" onChange={(event) => generateInputField(event)}>
                                                                                <option value="" key={0}>--Select Question--</option>
                                                                                {questions.map((obj, index) => {
                                                                                    return <option value={JSON.stringify(obj)} key={index}>{obj.title ? obj.title : obj.name}</option>
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group mx-2">
                                                                            <select required={true} className="form-control" value={selectedConditions} name="sl_check_condition" onChange={(event) => setSelectedConditions(event.target.value)} >
                                                                                {conditions.map((obj, index) => {
                                                                                    return <option value={obj.value} key={index}>{obj.name}</option>
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group mx-2">
                                                                            {choices.length === 0 ?
                                                                                <input required={true} className="form-control" defaultValue={workFlowDetails?.set_logic_list[0]?.sl_answer} type="text" name="sl_answer" />
                                                                                :
                                                                                <select required={true} className="form-control" value={workFlowDetails?.set_logic_list[0]?.sl_answer} name="sl_answer">
                                                                                    <option value="" key={0}>--Select--</option>
                                                                                    {choices.map((obj, index) => {
                                                                                        return <option value={obj.value} key={index}>{obj.text}</option>
                                                                                    })}
                                                                                </select>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                            {addConditionUI}
                                                            <div className="row">
                                                                <div className="col-md-12 m-2">
                                                                    <button type="button" onClick={addCondition} className="btn btn-outline-primary btn-sm mt-n2">Add Condition</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-4">
                                                <div className="form-group mx-2">
                                                    <label>Action</label>
                                                    <DropDown url="master/getWorkflowList" value={action} option_label="name" onchange={(event) => setAction(event.target.value)} name="workflow_type" />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group mx-2">
                                                    <label>Issue Owner</label>
                                                    {workFlowDetails.owner_list ? <Select defaultValue={selectFormatter(workFlowDetails.owner_list)} options={users} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"owner_list"} /> : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mx-2">
                                                    <label>Assign to Users</label>
                                                    {workFlowDetails.assigned_user_list ? <Select defaultValue={selectFormatter(workFlowDetails.assigned_user_list)} options={users} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"user_list"} /> : null}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mx-2">
                                                    <label>Assign to Team</label>
                                                    {workFlowDetails.assigned_team_list ? <Select defaultValue={selectFormatter(workFlowDetails.assigned_team_list)} options={team} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"team_list"} /> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group mx-2">
                                                    <label>Priority</label>
                                                    <DropDown required={true} url="master/getIssuePriorities" value={priorities} option_label="priority" onchange={(event) => setPriorities(event.target.value)} name="priority" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mx-2">
                                                    <label>Turn Around Time (TAT)</label>
                                                    {/* <input className="form-control" type="number" name="tat" defaultValue={workFlowDetails.tat} /> */}
                                                    <div className="d-flex">
                                                        <select required={true} className="form-control" value={tatDay} onChange={(event) => setTatDay(Number(event.target.value))} name="tat_day">
                                                            <option value="">Day</option>
                                                            <option value={0}>0 Days</option>
                                                            <option value={1}>1 Day</option>
                                                            <option value={2}>2 Days</option>
                                                            <option value={3}>3 Days</option>
                                                            <option value={4}>4 Days</option>
                                                            <option value={5}>5 Days</option>
                                                            <option value={6}>6 Days</option>
                                                            <option value={7}>7 Days</option>
                                                            <option value={8}>8 Days</option>
                                                            <option value={9}>9 Days</option>
                                                            <option value={10}>10 Days</option>
                                                            <option value={11}>11 Days</option>
                                                            <option value={12}>12 Days</option>
                                                            <option value={13}>13 Days</option>
                                                            <option value={14}>14 Days</option>
                                                            <option value={15}>15 Days</option>
                                                            <option value={16}>16 Days</option>
                                                            <option value={17}>17 Days</option>
                                                            <option value={18}>18 Days</option>
                                                            <option value={19}>19 Days</option>
                                                            <option value={20}>20 Days</option>
                                                            <option value={21}>21 Days</option>
                                                            <option value={22}>22 Days</option>
                                                            <option value={23}>23 Days</option>
                                                            <option value={24}>24 Days</option>
                                                            <option value={25}>25 Days</option>
                                                            <option value={26}>26 Days</option>
                                                            <option value={27}>27 Days</option>
                                                            <option value={28}>28 Days</option>
                                                            <option value={29}>29 Days</option>
                                                            <option value={30}>30 Days</option>
                                                        </select>
                                                        <select required={true} className="form-control mx-2" value={tatHour} onChange={(event) => setTatHour(Number(event.target.value))} name="tat_hour">
                                                            <option value="">Hour</option>
                                                            <option value={0}>0 Hrs</option>
                                                            <option value={1}>1 Hr</option>
                                                            <option value={2}>2 Hrs</option>
                                                            <option value={3}>3 Hrs</option>
                                                            <option value={4}>4 Hrs</option>
                                                            <option value={5}>5 Hrs</option>
                                                            <option value={6}>6 Hrs</option>
                                                            <option value={7}>7 Hrs</option>
                                                            <option value={8}>8 Hrs</option>
                                                            <option value={9}>9 Hrs</option>
                                                            <option value={10}>10 Hrs</option>
                                                            <option value={11}>11 Hrs</option>
                                                            <option value={12}>12 Hrs</option>
                                                            <option value={13}>13 Hrs</option>
                                                            <option value={14}>14 Hrs</option>
                                                            <option value={15}>15 Hrs</option>
                                                            <option value={16}>16 Hrs</option>
                                                            <option value={17}>17 Hrs</option>
                                                            <option value={18}>18 Hrs</option>
                                                            <option value={19}>19 Hrs</option>
                                                            <option value={20}>20 Hrs</option>
                                                            <option value={21}>21 Hrs</option>
                                                            <option value={22}>22 Hrs</option>
                                                            <option value={23}>23 Hrs</option>
                                                            <option value={24}>24 Hrs</option>
                                                        </select>
                                                        <select required={true} className="form-control" value={tatMinute} onChange={(event) => setTatMinute(Number(event.target.value))} name="tat_minute">
                                                            <option value="">Minute</option>
                                                            <option value={0}>0 Mins</option>
                                                            <option value={1}>1 Min</option>
                                                            <option value={2}>2 Mins</option>
                                                            <option value={3}>3 Mins</option>
                                                            <option value={4}>4 Mins</option>
                                                            <option value={5}>5 Mins</option>
                                                            <option value={6}>6 Mins</option>
                                                            <option value={7}>7 Mins</option>
                                                            <option value={8}>8 Mins</option>
                                                            <option value={9}>9 Mins</option>
                                                            <option value={10}>10 Mins</option>
                                                            <option value={11}>11 Mins</option>
                                                            <option value={12}>12 Mins</option>
                                                            <option value={13}>13 Mins</option>
                                                            <option value={14}>14 Mins</option>
                                                            <option value={15}>15 Mins</option>
                                                            <option value={16}>16 Mins</option>
                                                            <option value={17}>17 Mins</option>
                                                            <option value={18}>18 Mins</option>
                                                            <option value={19}>19 Mins</option>
                                                            <option value={20}>20 Mins</option>
                                                            <option value={21}>21 Mins</option>
                                                            <option value={22}>22 Mins</option>
                                                            <option value={23}>23 Mins</option>
                                                            <option value={24}>24 Mins</option>
                                                            <option value={25}>25 Mins</option>
                                                            <option value={26}>26 Mins</option>
                                                            <option value={27}>27 Mins</option>
                                                            <option value={28}>28 Mins</option>
                                                            <option value={29}>29 Mins</option>
                                                            <option value={30}>30 Mins</option>
                                                            <option value={31}>31 Mins</option>
                                                            <option value={32}>32 Mins</option>
                                                            <option value={33}>33 Mins</option>
                                                            <option value={34}>34 Mins</option>
                                                            <option value={35}>35 Mins</option>
                                                            <option value={36}>36 Mins</option>
                                                            <option value={37}>37 Mins</option>
                                                            <option value={38}>38 Mins</option>
                                                            <option value={39}>39 Mins</option>
                                                            <option value={40}>40 Mins</option>
                                                            <option value={41}>41 Mins</option>
                                                            <option value={42}>42 Mins</option>
                                                            <option value={43}>43 Mins</option>
                                                            <option value={44}>44 Mins</option>
                                                            <option value={45}>45 Mins</option>
                                                            <option value={46}>46 Mins</option>
                                                            <option value={47}>47 Mins</option>
                                                            <option value={48}>48 Mins</option>
                                                            <option value={49}>49 Mins</option>
                                                            <option value={50}>50 Mins</option>
                                                            <option value={51}>51 Mins</option>
                                                            <option value={52}>52 Mins</option>
                                                            <option value={53}>53 Mins</option>
                                                            <option value={54}>54 Mins</option>
                                                            <option value={55}>55 Mins</option>
                                                            <option value={56}>56 Mins</option>
                                                            <option value={57}>57 Mins</option>
                                                            <option value={58}>58 Mins</option>
                                                            <option value={59}>59 Mins</option>
                                                            <option value={60}>60 Mins</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mx-2">
                                                    <label>Tags</label>
                                                    {tags ? <Select defaultValue={selectFormatter(workFlowDetails.tag_list)} options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} className="basic-multi-select" classNamePrefix="select" name={"tags"} menuPlacement="auto"
                                                        menuPortalTarget={document.body} /> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="col-md-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader ? <Loader /> : null}
        </div>
    );
}

export default UpdateWorkFlow;