import axios from "axios";
import { toast } from "react-toastify";

const UpdateUrlNamePopup = ({ surveyId, shareId, shareName, recurringInterval, setRecurringInterval, recurring, setRecurring, reloadGrid }) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const createNewUrl = (event) => {
        event.preventDefault();
        let URL = process.env.REACT_APP_API_KEY + 'audience/shareByUrl/' + shareId;
        const formData = new FormData(event.target);
        formData.append('is_recurring', recurring);
        formData.append('survey_id', surveyId);
        axios({
            method: "put",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                //navigator.clipboard.writeText(encodeURI(response.data.survey_url));
                //toast.success('Survey Short URL copied to clipboard');
                reloadGrid();
                document.getElementById('closeUpdateUrlNamePopup').click();
                document.getElementById('updateNewUrlForm').reset();
                setRecurring(false);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div id="update-url-name-modal" className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <form onSubmit={createNewUrl} id="updateNewUrlForm" className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Update URL Name</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Name<span className="text-danger">*</span></label>
                            <input className="form-control" defaultValue={shareName} type="text" name="share_name" required={true} placeholder="Enter name to create a URL" />
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-12 d-flex">
                                <label className="mr-2">Auto Refresh</label>
                                <input type="checkbox" id="recurring_url_switch" data-switch="none" checked={recurring} onChange={() => setRecurring(!recurring)} />
                                <label for="recurring_url_switch" data-on-label="" data-off-label=""></label>
                            </div>
                        </div>
                        {recurring ?
                            <div className="row mb-2">
                                <div className="form-group col-md-8">
                                    <label>Refresh Duration<span className="text-danger">*</span></label>
                                    <input className="form-control" value={recurringInterval} onChange={(event) => setRecurringInterval(event.target.value)} type="number" min="0" name="recurring_interval" required={true} placeholder="Enter duration in seconds" />
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeUpdateUrlNamePopup" className="btn btn-outline-secondary" data-dismiss="modal">CANCEL</button>
                        <button type="submit" className="btn btn-primary">UPDATE</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UpdateUrlNamePopup;