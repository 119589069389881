import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const ForgotPassword = (props) => {

    const history = useHistory();

    const [loader, setLoader] = useState(false);

    const submitForgotPassword = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'forgetPassword';
        formData.append('token', props.match.params.token);
        if (formData.get('password') === formData.get('confirm_password')) {
            axios({
                method: "post",
                url: URL,
                data: formData
            }).then(function (response) {
                setLoader(false);
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    history.push('/');
                } else if (response.data.status === 0) {
                    toast.error(response.data.message);
                } else {
                    toast.error('Someting went wrong');
                }
            }).catch(function (error) {
                toast.error(error.toString());
            });
        } else {
            setLoader(false);
            toast.error("Enter correct passwords");
        }
    }

    return (
        <div className="account-pages authentication-bg ">
            <div className="container">
                <div className="row justify-content-center ">
                    <div className="col-lg-5">
                        <div className="card mt-5">
                            <div className="card-header pt-4 pb-4 text-center bg-primary">
                                <a href="index.html">
                                    <span><img src="/assets/images/logo.png" alt="" height="18" /></span>
                                </a>
                            </div>
                            <div className="card-body p-3">
                                <form onSubmit={submitForgotPassword} id="forgotPasswordForm">
                                    <div className="text-center w-80 m-auto">
                                        <h5 className="text-dark-50 text-center mt-0 font-weight-bold">Reset Password</h5>
                                        <p className="text-muted">Enter your new password to reset.</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Password<span className="text-danger">*</span></label>
                                        <input className="form-control" type="password" name="password" required={true} placeholder="Enter your password" />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password<span className="text-danger">*</span></label>
                                        <input className="form-control" type="password" name="confirm_password" required={true} placeholder="Confirm your password" />
                                    </div>
                                    <div className="form-group mb-0 text-center">
                                        <button className="btn btn-primary" type="submit">{loader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null} Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;