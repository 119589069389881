import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteModal from "../../../Common/DeleteModal";
import RolesGridView from "./RolesGridView";
import Loader from "../../../Common/Loader";

const Roles = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [roles, setRoles] = useState([]);
    const [deleteUrl, setDeleteUrl] = useState([]);
    const [loader, setLoader] = useState(false);

    const fetchRoles = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'roles';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setRoles(response.data.data);
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(fetchRoles, []);

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <Link type="button" to="create-role" className="btn btn-primary btn-sm-block float-right">CREATE ROLE</Link>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><Link to="/settings">Settings</Link></li>
                                        <li className="breadcrumb-item"><a>User Settings</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Role</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Roles</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <RolesGridView gridData={roles} setDeleteUrl={setDeleteUrl} />
                        </div>
                    </div>
                    <DeleteModal deleteUrl={deleteUrl} reloadGrid={fetchRoles} />
                    {loader ? <Loader /> : null}
                </div>
            </div>
        </div>
    );
}

export default Roles;