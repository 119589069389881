import React, { useState, useEffect } from 'react';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-toastify';
import { permissionName } from '../../Utils/string_formatting';
import { dateFormat } from '../../Utils/date_formatting';
import Loader from '../Common/Loader';
import GridView from '../Common/GridView';

const AddSegment = (props) => {

    const history = useHistory();

    const TOKEN = localStorage.getItem('fms-access-token');

    const [audiances, setAudiances] = useState([]);
    const [columns, setColumns] = useState([{ dataField: '', text: '' }]);
    const [fields, setFields] = useState({});
    const [sqlQuery, setSqlQuery] = useState("");
    const [mongoQuery, setMongoQuery] = useState("");
    const [segmentName, setSegmentName] = useState("");
    const [segmentDescription, setSegmentDescription] = useState("");
    const [previewLoader, setPreviewLoader] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [immTree, setImmTree] = useState("");
    const [loader, setLoader] = useState(false);
    const [totalSize, setTotalSize] = useState(0);

    useEffect(() => {
        if (props.location.state) {
            setSegmentName(props.location.state.segmentInfo.name);
            setSegmentDescription(props.location.state.segmentInfo.description);
        }
    }, [props.location.state])

    const fetchPrimarySecondaryFields = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getAllFields';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            generateFields(response.data.data);
        }).catch(function (error) {
            //toast.error(error.toString());
        });
    }

    const fetchAudienceUploads = (page = 1, size = 10) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentationPreview';
        const formData = new FormData();
        formData.append('single_page_count', size);
        formData.append('page_number', page);
        formData.append('mongo_query', mongoQuery);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            let arr = []
            for (let i = 0; i < response.data.data.length; i++) {
                arr.push({ id: response.data.data[i]._id.$oid, ...response.data.data[i].primary_data })//, ...response.data.data[i].secondary_data
            }
            generateColumns(arr);
            setAudiances(arr);
            setLoader(false);
            setTotalSize(response.data.audience_count);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(fetchAudienceUploads, []);
    useEffect(fetchPrimarySecondaryFields, []);

    const previewResult = () => {
        setPreviewLoader(true);
        const formData = new FormData();
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentationPreview';
        formData.append('mongo_query', mongoQuery);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setPreviewLoader(false);
            if (response.data.status === 1) {
                let arr = []
                for (let i = 0; i < response.data.data.length; i++) {
                    arr.push({ id: response.data.data[i]._id.$oid, ...response.data.data[i].primary_data })// , ...response.data.data[i].secondary_data })
                }
                setAudiances(arr);
                setTotalSize(response.data.audience_count);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setPreviewLoader(false);
            toast.error(error.toString());
        });
    }

    const saveResult = () => {
        setSaveLoader(true);
        const formData = new FormData();
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation';
        formData.append('mongo_query', mongoQuery);
        formData.append('sql_query', sqlQuery);
        formData.append('name', segmentName);
        //formData.append('query_tree', JSON.stringify(tree));
        formData.append('query_tree', JSON.stringify(immTree));
        formData.append('description', segmentDescription);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setSaveLoader(false);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                history.push('segments');
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setSaveLoader(false);
            toast.error(error.toString());
        });
    }

    const generateFields = (data) => {
        let obj = {};
        for (let i = 0; i < data.length; i++) {
            if (data[i] === "" || data[i] === "id" || data[i] === "data_set_id" || data[i] === "org_id") {
                //do nothing
            } else {
                obj = Object.assign(obj, { [data[i]]: { label: permissionName(data[i]), type: 'text' } });
            }
        }
        setFields(obj);
    }

    const generateColumns = (data) => {
        let arr = [];
        let keys = Object.keys(data[0]);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === "id" || keys[i] === "data_set_id" || keys[i] === "org_id" || keys[i] === "source_type" || keys[i] === "is_active" || keys[i] === "created_by" || keys[i] === "modified_by") {
                //do nothing
            }
            else if (keys[i] === "created_on") {
                arr.push({
                    dataField: keys[i],
                    text: permissionName(keys[i]),
                    formatter: dateFormat,
                    sort: true
                });
            } else {
                arr.push({
                    dataField: keys[i],
                    text: permissionName(keys[i]),
                    sort: true
                });
            }
        }
        setColumns(arr);
    }

    const config = {
        ...MaterialConfig,
        fields: fields
    };

    const queryValue = { "id": QbUtils.uuid(), "type": "group" };

    useEffect(() => {
        $(document).on('click', '.filterToggleMenu', function (e) {
            e.preventDefault();
            if ($('.filterToggleMenu').hasClass('collapsed')) {
                $('.filterToggleMenu i').removeClass("mdi-eye").addClass("mdi-eye-off");
            } else {
                $('.filterToggleMenu i').removeClass("mdi-eye-off").addClass("mdi-eye");
            }
        });
    }, []);

    const [tree, setTree] = useState(QbUtils.checkTree(QbUtils.loadTree(queryValue), config));

    const renderBuilder = (props) => (
        <div className="query-builder-container" style={{ padding: '10px' }}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    )

    const onChange = (immutableTree, config) => {
        setTree(immutableTree);
        setMongoQuery(JSON.stringify(QbUtils.mongodbFormat(immutableTree, config)));
        setSqlQuery(JSON.stringify(QbUtils.sqlFormat(immutableTree, config)));
        let jsonTree = QbUtils.getTree(immutableTree);
        setImmTree(jsonTree);
    }

    const renderResult = ({ tree: immutableTree, config }) => (
        <div className="query-builder-result">
            {/* <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div> */}
            {/* <div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre></div> */}
            <div><pre style={{ whiteSpace: 'pre-wrap', fontSize: 14, fontWeight: 600 }}>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div>
            {/* <div><pre style={{ whiteSpace: 'pre-wrap', fontSize: 14, fontWeight: 600 }}>{(QbUtils.sqlFormat(immutableTree, config))}</pre></div> */}
            {/* <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div> */}
        </div>
    );

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <button className="btn btn-primary float-right" type="button" onClick={saveResult} disabled={!mongoQuery || saveLoader}>{saveLoader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null} CREATE</button>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a >Home</a></li>
                                        <li className="breadcrumb-item"><Link to="/segments">Segments</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Create Segment</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/segments"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>{segmentName}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="name">Segment Name</label>
                                        <input className="form-control" type="text" value={segmentName} onChange={(event) => setSegmentName(event.target.value)} placeholder="Enter segment name" name="role_name" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="name">Description</label>
                                        <input className="form-control" type="text" value={segmentDescription} onChange={(event) => setSegmentDescription(event.target.value)} placeholder="Enter segment description" name="role_desc" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h4 className="mt-3">Filter By</h4>
                            <div className="card p-3">
                                <div className="d-flex justify-content-between">
                                    {renderResult({ tree, config })}
                                    {mongoQuery ?
                                        <button className="filterToggleMenu" style={{ border: 'none', color: '#313a46', backgroundColor: 'transparent', fontSize: '24px', cursor: 'pointer' }} data-toggle="collapse" data-target="#queryBuilderContainer"><i className="mdi mdi-eye"></i></button>
                                        : null
                                    }
                                </div>
                                <div id="queryBuilderContainer" className="collapse show">
                                    <Query  {...config} value={tree} onChange={onChange} renderBuilder={renderBuilder} />
                                    <button className="btn btn-primary btn-sm float-right mt-2 mr-1" type="button" onClick={previewResult} disabled={!mongoQuery || previewLoader}>{previewLoader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null}Preview</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h4>Filtered Segments ({totalSize})</h4>
                            <GridView
                                gridData={audiances}
                                columns={columns}
                                totalSize={totalSize}
                                sizePerPage={10}
                                keyField="id"
                                fetchData={(page, size) => fetchAudienceUploads(page, size)} />
                        </div>
                    </div>
                </div>
            </div>
            {loader ? <Loader /> : null}
        </div>
    );
}

export default AddSegment;