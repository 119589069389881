import { dateFormat, dateTimeFormat } from "../../../Utils/date_formatting";
import { permissionName } from "../../../Utils/string_formatting";

const Timeline = ({ issueDetails, timeline }) => {

    return (<>
        <hr />
        <h4>Timeline</h4>
        <div className="timeline-alt pb-0 px-3">
            {timeline?.map((obj) => {
                return <div className="timeline-item">
                    <div className="avatar-sm">
                        <span className="avatar-title rounded">{obj.created_by[0]}</span>
                    </div>
                    <div className="timeline-item-info">
                        <span className="text-dark font-weight-bold mb-1 d-block">{obj.created_by}</span>
                        <small className="text-muted">{dateTimeFormat(obj.created_on)}</small>
                        <ul className="mt-1 mb-2" style={{ paddingLeft: '1rem' }}>
                            {obj.action_list.map((obj) => {
                                return <li> <p className="m-0" dangerouslySetInnerHTML={{ __html: obj }} /></li>
                            })}
                        </ul>
                    </div>
                </div>
            })}

            {/* <div className="timeline-item">
                        <div className="avatar-sm">
                            <span className="avatar-title rounded">
                                K
                            </span>
                        </div>
                        <div className="timeline-item-info">
                            <a href="#" className="text-info font-weight-bold mb-1 d-block">Narayan Hegde</a>
                            <small className="text-muted">Tuesday 5 oct 1:16 pm</small>

                            <p className="mb-0 pb-2">
                                <small>status of SubTask 1 is changed to Resolved</small>
                            </p>
                            <ul>

                                <li>Set group as Escalations</li>
                            </ul>
                        </div>
                    </div>
                    <div className="timeline-item">
                        <div className="avatar-sm">
                            <span className="avatar-title rounded">
                                C
                            </span>
                        </div>
                        <div className="timeline-item-info">
                            <a href="#" className="text-info font-weight-bold mb-1 d-block">Pranay Srivastava</a>
                            <small className="text-muted">Saturday 2 oct 3:21pm</small>

                            <p className="mb-0 pb-2">
                                <small>status of SubTask 1 is changed to Resolved</small>
                            </p>
                        </div>
                    </div> */}
        </div>
    </>
    )
}

export default Timeline;