import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { toast } from 'react-toastify';

const EmbeddedDashboardPage = () => {
  useEffect(() => {
    const { id, embedId } = JSON.parse(localStorage.getItem('ID_OBJ'))
    let URL = process.env.REACT_APP_API_KEY + `reports/dashboard_token/` + id;
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: 'Token ' + localStorage.getItem('fms-access-token'),
      },
    }).then(function (response) {
      embedDashboard({
        id: embedId,
        supersetDomain: localStorage.getItem('SUPERSET_DOMAIN'),
        mountPoint: document.getElementById("reports"),
        fetchGuestToken: () => response.data.token_data.token,
        dashboardUiConfig: { hideTitle: true },
      });

      const dashboard = document.getElementById("reports").firstChild;
      if (dashboard) {
        dashboard.style.width = '100%';
        dashboard.style.height = `${window.innerHeight - 90}px`;
      }
    }).catch(function (error) {
      toast.error(error.toString());
    });
  }, []);
  return (
    <div
      id='reports'
      style={{
        width: '98%',
        height: '50%',
        paddingLeft: '80px',
        paddingTop: '80px'
      }}
    >
    </div>
  );
}

export default EmbeddedDashboardPage;