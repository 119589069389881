import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { adjust } from "../../../Utils/color_formatting";
import TimeZoneDropDown from "../../Common/TimeZoneDropDown";
import $ from 'jquery';
import Loader from '../../Common/Loader';

const Organization = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [organizationName, setOrganizationName] = useState("");
    const [primaryColor, setPrimaryColor] = useState("");
    const [secondaryColor, setSecondaryColor] = useState("");
    const [timezone, setTimezone] = useState("0");
    const [logo, setLogo] = useState(null);
    const [favicon, setFavicon] = useState(null);
    const [branding, setBranding] = useState("default");
    const [brandingImage, setBrandingImage] = useState(null);
    const [loader, setLoader] = useState(false);

    const fetchAccountDetails = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'orgSettings';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            setOrganizationName(response.data.data.org_name);
            setPrimaryColor(response.data.data.primary_color);
            setSecondaryColor(response.data.data.secondry_color);
            setLogo(response.data.data.logo);
            setFavicon(response.data.data.favicon);
            setBrandingImage(response.data.data.branding_file)
            setTimezone(response.data.data.timezone);
            setBranding(response.data.data.branding_type)
            if (response.data.data.logo) {
                setLogo(process.env.REACT_APP_API_MEDIA_KEY + response.data.data.logo);
            }
            if (response.data.data.favicon) {
                setFavicon(process.env.REACT_APP_API_MEDIA_KEY + response.data.data.favicon);
            }
            if (response.data.data.branding_file) {
                setBrandingImage(process.env.REACT_APP_API_MEDIA_KEY + response.data.data.branding_file);
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(fetchAccountDetails, []);

    const setUploadedFile = (event, type) => {
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                if (type === 1) {
                    document.getElementById('logo-img').src = e.target.result;
                } else if (type === 2) {
                    document.getElementById('fav-icon-img').src = e.target.result;
                } else if (type === 3) {
                    document.getElementById('branding-img').src = e.target.result;
                }
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    const updateAccountDetails = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        setLoader(true);
        if (formData.get('logo').size === 0) {
            formData.delete('logo');
        }

        if (formData.get('favicon').size === 0) {
            formData.delete('favicon');
        }

        if (formData.get('branding_file') === null) {
            formData.append('branding_file', '');
        } else if (branding === 'custom' && formData.get('branding_file').size === 0) {
            formData.delete('branding_file');
        }

        let URL = process.env.REACT_APP_API_KEY + 'orgSettings';
        axios({
            method: "put",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            toast.success(response.data.message);
            props.reloadAccountDetails();
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--custom-primary-color', primaryColor ? primaryColor : '#000000');
        document.documentElement.style.setProperty('--custom-primary-dark-color', adjust(primaryColor, -20));
        document.documentElement.style.setProperty('--custom-focus-color', primaryColor + '26');
        document.documentElement.style.setProperty('--custom-secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--custom-focus-secondary-color', secondaryColor + '26');

    }, [primaryColor, secondaryColor])


    return (
        <div className="content-page">
            {loader ? <Loader /> : null}
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3 mb-0 pb-0">
                                    <li className="breadcrumb-item"><Link to="/settings">Settings</Link></li>
                                    <li className="breadcrumb-item"><a>General Settings</a></li>
                                    <li className="breadcrumb-item active">Organization Setting</li>
                                </ol>
                            </nav>
                            <div className="page-title-box">
                                <h4 className="page-title">Organization Setting</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card border mt-1">
                                <form onSubmit={updateAccountDetails} className="">
                                    <div className="row ">
                                        <div className="col-lg-6">
                                            <div className="card mb-1 shadow-none text-center">
                                                <div className="p-2">
                                                    <h4 className="card-title mt-2">Logo</h4>
                                                    <div className="">
                                                        <div className="border rounded logo-fav-icon-container">
                                                            <img src={logo ? logo : "/assets/images/logo_sm.png"} alt="image" id="logo-img" className="width-height-inherit" />
                                                        </div>
                                                        <input type='file' name="logo" id="logoFilePicker" onChange={(event) => setUploadedFile(event, 1)} className="d-none" />
                                                        <button type="button" onClick={() => document.getElementById('logoFilePicker').click()} className="btn btn-outline-primary m-2 btn-sm" style={{ border: 'none' }}>Upload</button>
                                                    </div>
                                                    <small className="text-custom-secondary">Atleast 256x256 PNG or JPG file</small><br />
                                                    <small className="text-muted">Upgrade your business plan to user custom logo </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card mb-1 shadow-none text-center">
                                                <div className="p-2">
                                                    <h4 className="card-title mt-2">Favicon</h4>
                                                    <div className="">
                                                        <div className="border rounded logo-fav-icon-container">
                                                            <img src={favicon ? favicon : "/assets/images/logo_sm.png"} alt="image" id="fav-icon-img" className="width-height-inherit" />
                                                        </div>
                                                        <input type='file' name="favicon" id="faviconFilePicker" onChange={(event) => setUploadedFile(event, 2)} className="d-none" />
                                                        <button type="button" onClick={() => document.getElementById('faviconFilePicker').click()} className="btn btn-outline-primary border-none m-2 btn-sm" style={{ border: 'none' }}>Upload</button>
                                                    </div>
                                                    <small className="text-custom-secondary">Atleast 256x256 PNG or JPG file</small><br />
                                                    <small className="text-muted">Upgrade your business plan to user custom Favicon</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row px-5 py-3">
                                        <div className="col-lg-12">
                                            <div className="card mb-1 shadow-none">
                                                <div className="colors">
                                                    <h4 className="card-title text-left">Colors</h4>
                                                    <p className="text-muted">Define the default colors for your surveys and widgets. You can override them per each survey while designing them.</p>
                                                    <div className="row ">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <div className="form-control form-control-md d-flex align-items-center justify-content-between pr-0">
                                                                    <p className="m-0 font-16 text-dark">Primary Color</p>
                                                                    <input className="form-control color-picker-circular" type="color" value={primaryColor} name="primary_color" onChange={(event) => setPrimaryColor(event.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1"></div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <div className="form-control form-control-md d-flex align-items-center justify-content-between pr-0">
                                                                    <p className="m-0 font-16 text-dark">Secondary Color</p>
                                                                    <input className="form-control color-picker-circular" type="color" value={secondaryColor} name="secondry_color" onChange={(event) => setSecondaryColor(event.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row px-5 pb-3">
                                        <div className="col-lg-12">
                                            <div className="card mb-1 shadow-none">
                                                <div className="branding">
                                                    <h4 className="card-title text-left">HelloApps Branding</h4>
                                                    <p className="text-muted">All Free accounts will have the HelloApps brand mark appearing on the end user survey</p>
                                                    {branding === 'remove' ? null :
                                                        <div className="d-flex">
                                                            <div className="py-1 mr-2">
                                                                <img src={brandingImage ? brandingImage : "/assets/images/logo-dark.png"} height="32" alt="image" id="branding-img" style={{ objectFit: "cover" }} />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="mt-2 d-flex">
                                                        <div className="custom-control custom-radio mr-4">
                                                            <input type="radio" id="customRadio1" name="branding_type" className="custom-control-input" value={"default"} checked={branding === 'default' || branding === null} onClick={(event) => { setBranding(event.target.value); $('#branding-img').src = "/assets/images/logo-dark.png" }} />
                                                            <label className="custom-control-label" for="customRadio1">Default Badge</label><br />
                                                            <span className="badge badge-success-lighten py-1 px-3 round-badge text-center font-weight-normal">FREE</span>
                                                        </div>
                                                        <div className="custom-control custom-radio mr-4">
                                                            <input type="radio" id="customRadio2" name="branding_type" className="custom-control-input" value={"remove"} checked={branding === 'remove'} onClick={(event) => { setBranding(event.target.value) }} />
                                                            <label className="custom-control-label" for="customRadio2">Remove Badge</label><br />
                                                            <span className="badge badge-warning-lighten round-badge text-center font-weight-normal">PREMIUM</span>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio" id="customRadio3" name="branding_type" className="custom-control-input" value={"custom"} checked={branding === 'custom'} onClick={(event) => setBranding(event.target.value)} />
                                                            <label className="custom-control-label" for="customRadio3">Custom Badge</label><br />
                                                            <span className="badge badge-warning-lighten round-badge text-center font-weight-normal">PREMIUM</span>
                                                        </div>
                                                        <div>
                                                            {branding === "custom" ?
                                                                <>
                                                                    <input type='file' name="branding_file" id="brandingFilePicker" onChange={(event) => setUploadedFile(event, 3)} className="d-none" />
                                                                    <button type="button" onClick={() => document.getElementById('brandingFilePicker').click()} className="btn btn-outline-primary ml-2 btn-sm">Upload</button>
                                                                </>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row px-5 py-3">
                                        <div className="col-lg-12">
                                            <div className="card mb-1 shadow-none">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Company Name<span className="text-danger">*</span></label>
                                                                <input type="text" name="org_name" value={organizationName} onChange={event => setOrganizationName(event.target.value)} className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Timezone<span className="text-danger">*</span></label>
                                                                {/* numberToTime */}
                                                                <TimeZoneDropDown url="timezone" onchange={event => setTimezone(event.target.value)} value={timezone} name="timezone" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="px-5 pt-2 pb-3 d-flex justify-content-between">
                                        <button type="submit" className="btn btn-primary">UPDATE</button>
                                        {/* <a className="font-weight-bold text-danger align-self-center"><i className="mdi mdi-delete font-16 mr-1"></i>DELETE ACCOUNT ?</a> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Organization;