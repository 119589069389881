import { dateFormat } from "../../../../Utils/date_formatting";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import DeleteModal from "../../../Common/DeleteModal";
import UpdateUrlNamePopup from "./UpdateUrlNamePopup";
import UpdateApiNamePopup from "./UpdateApiNamePopup";
import UpdateTabletPopup from "./UpdateTabletPopup";
import GridView from "../../../Common/GridView";

const SurveyShareGridView = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [loader, setLoader] = useState(false);
    const [deleteUrl, setDeleteUrl] = useState([]);
    const [shareId, setShareId] = useState(0);
    const [shareName, setShareName] = useState("");
    const [isRecurring, setIsRecurring] = useState(false);
    const [apiName, setApiName] = useState("");
    const [kioskMode, setKioskMode] = useState(false);
    const [contactForm, setContactForm] = useState(0);
    const [tabRecurringInterval, setTabRecurringInterval] = useState(1);
    const [urlRecurringInterval, setUrlRecurringInterval] = useState(1);

    const copyURL = (url) => {
        navigator.clipboard.writeText(encodeURI(url));
        toast.success('Copied to clipboard')
    }

    // const setActionButtons = (cell, row) => {
    //     return (
    //         <div className="dropdown float-right">
    //             <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
    //                 <i className="mdi mdi-dots-vertical text-secondary font-20"></i>
    //             </a>
    //             <div className="dropdown-menu dropdown-menu-right">
    //                 {(row.channel_name === "sms" || row.channel_name === "qr code" || row.channel_name === "api") ? null :
    //                     <span onClick={() => copyURL(row.survey_url)} className="dropdown-item notify-item cursor-pointer"><i className="mdi mdi-content-copy mr-1"></i><span>Copy URL</span></span>
    //                 }
    //                 {(row.channel_name === "api") ?
    //                     <span onClick={() => copyURL(row.survey_url)} className="dropdown-item notify-item cursor-pointer"><i className="mdi mdi-content-copy mr-1"></i><span>Copy Share Code</span></span>
    //                     : null
    //                 }
    //                 {row.channel_name === "qr code" ?
    //                     <span onClick={() => downloadQR(row.qr_image)} className="dropdown-item notify-item cursor-pointer"><i className="mdi mdi-download mr-1 "></i><span>Download QR</span></span>
    //                     : null
    //                 }
    //                 <Link className="dropdown-item notify-item" to={{ pathname: '/survey-response', state: { shareInfo: row, surveyInfo: props.surveyDetails } }}><i className="mdi mdi-message-arrow-left mr-1"></i><span>Responses</span></Link>
    //                 {row.responses > 0 ? null :
    //                     <span className="dropdown-item notify-item cursor-pointer" onClick={() => setDeleteUrl('audience/shareData/' + row.id)} data-toggle="modal" data-target="#deleteModal"><i className="mdi mdi-delete mr-1"></i><span>Delete</span></span>
    //                 }
    //             </div>
    //         </div>
    //     );
    // }

    const setActionButtons = (cell, row) => {
        return (
            <div className="dropdown float-right">
                <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical text-secondary font-20"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                    {(row.channel_name === "sms" || row.channel_name === "qr code" || row.channel_name === "api") ? null :
                        <span onClick={() => copyURL(row.survey_url)} className="dropdown-item notify-item cursor-pointer"><i className="mdi mdi-content-copy mr-1"></i><span>Copy URL</span></span>
                    }

                    {(row.channel_name === 'url') ?
                        <span onClick={() => {
                            setShareId(row.id)
                            setShareName(row.share_name)
                            setIsRecurring(row.is_recurring)
                            setUrlRecurringInterval(row.recurring_interval)
                        }} data-toggle="modal" data-target="#update-url-name-modal" className="dropdown-item notify-item cursor-pointer"><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></span>
                        : null
                    }

                    {(row.channel_name === "api") ?
                        <>
                            <span onClick={() => {
                                setShareId(row.id)
                                setApiName(row.share_name)
                            }} data-toggle="modal" data-target="#update-api-name-modal" className="dropdown-item notify-item cursor-pointer"><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></span>
                            <span onClick={() => copyURL(row.survey_url)} className="dropdown-item notify-item cursor-pointer"><i className="mdi mdi-content-copy mr-1"></i><span>Copy Share Code</span></span>
                        </>
                        : null
                    }

                    {(row.channel_name === 'tab_mode') ?
                        <span onClick={() => {
                            setShareId(row.id)
                            setShareName(row.share_name)
                            setContactForm(row.tab_mode_key_id)
                            setIsRecurring(row.is_recurring)
                            setKioskMode(row.kiosk_mode)
                            setTabRecurringInterval(row.recurring_interval)
                        }} data-toggle="modal" data-target="#update-tablet-modal" className="dropdown-item notify-item cursor-pointer"><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></span>
                        : null
                    }

                    {row.channel_name === "qr code" ?
                        <span onClick={() => downloadQR(row.qr_image)} className="dropdown-item notify-item cursor-pointer"><i className="mdi mdi-download mr-1 "></i><span>Download QR</span></span>
                        : null
                    }
                    <Link className="dropdown-item notify-item" to={{ pathname: '/survey-response', state: { shareInfo: row, surveyInfo: props.surveyDetails } }}><i className="mdi mdi-message-arrow-left mr-1"></i><span>Responses</span></Link>
                    {row.responses > 0 ? null :
                        <span className="dropdown-item notify-item cursor-pointer" onClick={() => setDeleteUrl('audience/shareData/' + row.id)} data-toggle="modal" data-target="#deleteModal"><i className="mdi mdi-delete mr-1"></i><span>Delete</span></span>
                    }
                </div>
            </div>
        );
    }

    const playPauseShare = (playPauseUrl, id) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/' + playPauseUrl + '/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                props.gridReload();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const setPlayPauseBtn = (cell, row) => {
        if (cell) {
            return <img className="cursor-pointer" onClick={() => playPauseShare('pauseShare', row.id)} src="/assets/images/icons/pause.svg" />
        } else {
            return <img className="cursor-pointer" onClick={() => playPauseShare('resumeShare', row.id)} src="/assets/images/icons/play.svg" />
        }
    }

    const setShareIcon = (cell) => {
        if (cell === "sms") {
            return <i className="mdi text-primary mdi-cellphone-message font-20"></i>
        } else if (cell === "url") {
            return <i className="mdi text-primary mdi-link-variant-plus font-20"></i>
        } else if (cell === "qr code") {
            return <i className="mdi text-primary mdi-qrcode font-20"></i>
        } else if (cell === "social media") {
            return <i className="uil text-primary uil-share-alt font-20"></i>
        } else if (cell === "api") {
            return <i className="font-20 mdi text-primary mdi-code-not-equal-variant"></i>
        } else if (cell === "tab_mode") {
            return <i className="font-20 mdi text-primary mdi-tablet"></i>
        }
    }

    const changeKioskMode = (id) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/kioskMode/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                props.gridReload();
                toast.success(response.data.message);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    const kioskModeAction = (cell, row) => {
        return (
            <div className="custom-control custom-switch ml-2">
                <input type="checkbox" onChange={() => changeKioskMode(row.id)} className="custom-control-input" id={"customSwitch_" + row.id} checked={row.kiosk_mode} />
                <label className="custom-control-label" for={"customSwitch_" + row.id}></label>
            </div>
        )
    }

    const downloadQR = (imgData) => {
        let a = document.createElement("a");
        a.href = imgData;
        a.download = "QR.png";
        a.click();
    };

    const messagesSentCount = (cell, row) => {
        if (row.channel_name === 'sms') {
            return cell;
        } else {
            return null;
        }
    }

    const columns = [{
        dataField: 'channel_name',
        text: 'Channel',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: setShareIcon
    }, {
        dataField: 'share_name',
        text: 'Share Name'
    }, {
        dataField: 'created_on',
        text: 'Created On',
        formatter: dateFormat
    }, {
        dataField: 'modified_on',
        text: 'Modified On',
        formatter: dateFormat
    }, {
        dataField: 'smsCount',
        text: 'Messages Sent',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' },
        formatter: messagesSentCount
    }, {
        dataField: 'responses',
        text: 'Responses',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' }
    }, {
        dataField: 'completion_rate',
        text: 'Completion Rate',
        headerStyle: { textAlign: 'center' },
        style: { textAlign: 'center' }
    }, {
        dataField: 'kiosk_mode',
        text: 'Kiosk Mode',
        formatter: kioskModeAction
    }, {
        dataField: 'share_status',
        text: '',
        formatter: setPlayPauseBtn
    }, {
        dataField: 'id',
        classes: 'py-0',
        formatter: setActionButtons
    }];

    return (
        <>
            <DeleteModal deleteUrl={deleteUrl} reloadGrid={props.gridReload} />
            <UpdateUrlNamePopup surveyId={props.surveyDetails.id} shareId={shareId} shareName={shareName} recurringInterval={urlRecurringInterval} setRecurringInterval={setUrlRecurringInterval} recurring={isRecurring} setRecurring={setIsRecurring} reloadGrid={props.gridReload} />
            <UpdateApiNamePopup surveyId={props.surveyDetails.id} shareId={shareId} apiName={apiName} reloadGrid={props.gridReload} />
            <UpdateTabletPopup surveyId={props.surveyDetails.id} shareId={shareId} shareName={shareName} recurringInterval={tabRecurringInterval} setRecurringInterval={setTabRecurringInterval} contactForm={contactForm} setContactForm={setContactForm} isRecurring={isRecurring} setIsRecurring={setIsRecurring} kioskMode={kioskMode} setKioskMode={setKioskMode} reloadGrid={props.gridReload} />

            <GridView
                classes="table-hover-custom"
                gridData={props.gridData}
                columns={columns}
                totalSize={props.totalSize}
                sizePerPage={10}
                keyField="id"
                fetchData={(page, size) => props.gridReload(page, size)} />

            {loader ? <Loader /> : null}
        </>
    );
}

export default SurveyShareGridView;