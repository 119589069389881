import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import DropDown from '../../../../Common/DropDown';
import ChannelsInputField from "../../../../Common/ChannelsInputField";

const AddEmailModal = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [esp, setEsp] = useState("0");
    const [inputFieldsUI, setInputFieldsUI] = useState([]);

    const addEsp = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'audience/emailKeysAPI';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeAddEspModal').click();
                document.getElementById('addEspForm').reset();
                props.fetchEspAvailed();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchFormFields = (id) => {
        setEsp(id);
        let URL = process.env.REACT_APP_API_KEY + 'master/getChannelFields/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                generateFieldsUI(response.data.data);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const generateFieldsUI = (data) => {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
            arr.push(<ChannelsInputField name={data[i].name} />);
        }
        setInputFieldsUI(arr);
    }

    return (
        <div className="modal fade" id="addEmailModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <form onSubmit={addEsp} id="addEspForm" className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">Add ESP</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>ESP<span className="text-danger">*</span></label>
                            <DropDown url="audience/getChannels?channel_type=email" value={esp} option_label="name" onchange={(event) => fetchFormFields(event.target.value)} name="channel_type" id="service-providers-select" />
                        </div>
                        {inputFieldsUI}
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeAddEspModal" className="btn btn-outline-secondary" data-dismiss="modal">CLOSE</button>
                        <button type="submit" className="btn btn-primary">ADD</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddEmailModal;