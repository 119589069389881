import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const SettingsSideMenu = (props) => {

    const history = useHistory();

    const currentLocation = history.location.pathname;

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    useEffect(() => {
        document.body.removeAttribute("data-leftbar-compact-mode");
    });

    return (
        <div className="left-side-menu settings-side-menu mm-show" style={{ position: 'fixed' }}>
            <Link to="/" className="logo text-center logo-light">
                <span className="logo-lg">
                    <img src={logo ? logo : "/assets/images/logo.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '200px', padding: '4px' }} />
                </span>
                <span className="logo-sm">
                    <img src={logo ? logo : "/assets/images/logo_sm.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '200px', padding: '4px' }} />
                </span>
            </Link>
            <Link to="/" className="logo text-center logo-dark">
                <span className="logo-lg">
                    <img src={logo ? logo : "/assets/images/logo-dark.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '200px', padding: '4px' }} />
                </span>
                <span className="logo-sm">
                    <img src={logo ? logo : "/assets/images/logo_sm_dark.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '200px', padding: '4px' }} />
                </span>
            </Link>
            <div className="h-100 mm-active" id="left-side-menu-container" data-simplebar="init"><div className="simplebar-wrapper" style={{ margin: "0px" }}><div className="simplebar-height-auto-observer-wrapper"><div className="simplebar-height-auto-observer"></div></div><div className="simplebar-mask"><div className="simplebar-offset" style={{ right: "0px" }}><div className="simplebar-content-wrapper" style={{ height: "100%", overflow: "hidden scroll" }}><div className="simplebar-content" style={{ padding: "0px", width: "100%" }}>
                <ul className="metismenu side-nav mm-show">
                    <li className="side-nav-item">
                        <Link to="" className="side-nav-link" aria-expanded="false">
                            <i className="mdi mdi-arrow-left ml-0 text-dark"></i>
                            <span>Home</span>
                        </Link>
                    </li>
                    <li className="side-nav-item">
                        <a href="#general" data-toggle="collapse" className="side-nav-link" aria-expanded="true">
                            <span>General Settings</span>
                            <span className="menu-arrow"></span>
                        </a>
                    </li>
                    <div className="collapse show" id="general">
                        <ul className="side-nav-second-level">
                            <li className="side-nav-link">
                                <Link to="/organization-setting" className={(currentLocation === "/organization-setting" ? "text-primary" : null)}>Organization Setting</Link>
                            </li>
                            {/* <li>
                                    <Link to="/survey-throttling">Survey Throttling</Link>
                                </li> */}
                        </ul>
                    </div>
                    <li className="side-nav-item">
                        <a href="#users" data-toggle="collapse" className="side-nav-link" aria-expanded="true">
                            <span>User Settings</span>
                            <span className="menu-arrow"></span>
                        </a>
                    </li>
                    <div className="collapse show" id="users">
                        <ul className="side-nav-second-level" aria-expanded="false">
                            <li className="side-nav-link">
                                <Link to="/users" className={(currentLocation === "/users" ? "text-primary" : null)}>Users</Link>
                            </li>
                            <li className="side-nav-link">
                                <Link to="/teams" className={(currentLocation === "/teams" || currentLocation === "/view-team" || currentLocation === "/edit-team" ? "text-primary" : null)}>Teams</Link>
                            </li>
                            <li className="side-nav-link">
                                <Link to="/roles" className={(currentLocation === "/roles" || currentLocation === "/create-role" || currentLocation === "/update-role" || currentLocation === "/view-users" ? "text-primary" : null)}>Roles</Link>
                            </li>
                        </ul>
                    </div>
                    <li className="side-nav-item">
                        <a href="#integrations" data-toggle="collapse" className="side-nav-link" aria-expanded="true">
                            <span>Integrations</span>
                            <span className="menu-arrow"></span>
                        </a>
                    </li>
                    <div className="collapse show" id="integrations">
                        <ul className="side-nav-second-level" aria-expanded="false">
                            <li className="side-nav-link">
                                <Link to="/channel-integration" className={(currentLocation === "/channel-integration" || currentLocation === "/sms-integration" ? "text-primary" : null)}>Channel</Link>
                            </li>
                            {/* <li className="side-nav-link">
                                <Link to="/app-integration" className={(currentLocation === "/app-integration" ? "text-primary" : null)}>App</Link>
                            </li> */}
                            <li className="side-nav-link">
                                <Link to="/contact-form" className={(currentLocation === "/app-integration" ? "text-primary" : null)}>Contact Form</Link>
                            </li>
                        </ul>
                    </div>
                </ul>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
        </div>
    );
}

export default SettingsSideMenu;