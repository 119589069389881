import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const GridView = (props) => {

    var propsPage = props.page;

    var propsSizePerPage = props.sizePerPage;
    var totalSize = props.totalSize;

    // const options = {
    //     hideSizePerPage: false,
    //     hidePageListOnlyOnePage: true,
    //     firstPageText: 'First',
    //     prePageText: 'Prev',
    //     nextPageText: 'Next',
    //     lastPageText: 'Last',
    //     nextPageTitle: 'First page',
    //     prePageTitle: 'Pre page',
    //     firstPageTitle: 'Next page',
    //     lastPageTitle: 'Last page'
    // }

    const handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
        props.fetchData(page, sizePerPage, sortField, sortOrder)
    }

    return <BootstrapTable
        classes={props.classes}
        headerClasses={props.headerClasses}
        remote
        keyField={props.keyField}
        data={props.gridData}
        columns={props.columns}
        defaultSorted={props.defaultSorted}
        page={propsPage}
        sizePerPage={propsSizePerPage}
        totalSize={totalSize}
        selectRow={props.selectRow}
        className='grid-view-table'

        bordered={false}
        bootstrap4
        hover
        noDataIndication="No record found"
        condensed={true}

        pagination={paginationFactory({ propsPage, propsSizePerPage, totalSize })}
        onTableChange={handleTableChange} />
}

export default GridView;