import { Link } from 'react-router-dom';

const TopBar = (props) => {

    const logOut = () => {
        localStorage.removeItem('fms-access-token');
        window.location.reload();
        //history.push('/login');
    }

    return (
        <div className="navbar-custom">
            <ul className="list-unstyled topbar-right-menu float-right mb-0">
                <li className="dropdown notification-list d-lg-none">
                    <Link className="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                        <i className="dripicons-search noti-icon"></i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
                        <form className="p-3">
                            <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                        </form>
                    </div>
                </li>
                {/* <li className="dropdown notification-list" style={{ padding: 'calc(31px / 2) 0px calc(31px / 2) 10px' }}>
                    <Link className="btn btn-light dropdown-toggle arrow-none" style={{ background: '#E5ECF0', padding: '8.2px 11.3px' }} data-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                        <i className="mdi mdi-bell" style={{ color: '#90A2AC' }}></i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-border-top dropdown-menu-animated dropdown-lg">
                        <div className="dropdown-item noti-title">
                            <h5 className="m-0">
                                <span className="float-right">
                                    <Link className="text-dark">
                                        <small>Clear All</small>
                                    </Link>
                                </span>Notification
                            </h5>
                        </div>
                        <div style={{ maxHeight: "230px" }} data-simplebar>
                            <Link className="dropdown-item notify-item">
                                <div className="notify-icon bg-primary">
                                    <i className="mdi mdi-comment-account-outline"></i>
                                </div>
                                <p className="notify-details">Caleb Flakelar commented on Admin
                                    <small className="text-muted">1 min ago</small>
                                </p>
                            </Link>
                            <Link className="dropdown-item notify-item">
                                <div className="notify-icon bg-info">
                                    <i className="mdi mdi-account-plus"></i>
                                </div>
                                <p className="notify-details">New user registered.
                                    <small className="text-muted">5 hours ago</small>
                                </p>
                            </Link>
                            <Link className="dropdown-item notify-item">
                                <div className="notify-icon">
                                    <img src="/assets/images/users/avatar-2.jpg" className="img-fluid rounded-circle" alt="" /> </div>
                                <p className="notify-details">Cristina Pride</p>
                                <p className="text-muted mb-0 user-msg">
                                    <small>Hi, How are you? What about our next meeting</small>
                                </p>
                            </Link>
                            <Link className="dropdown-item notify-item">
                                <div className="notify-icon bg-primary">
                                    <i className="mdi mdi-comment-account-outline"></i>
                                </div>
                                <p className="notify-details">Caleb Flakelar commented on Admin
                                    <small className="text-muted">4 days ago</small>
                                </p>
                            </Link>
                            <Link className="dropdown-item notify-item">
                                <div className="notify-icon">
                                    <img src="/assets/images/users/avatar-4.jpg" className="img-fluid rounded-circle" alt="" /> </div>
                                <p className="notify-details">Karen Robinson</p>
                                <p className="text-muted mb-0 user-msg">
                                    <small>Wow ! this admin looks good and awesome design</small>
                                </p>
                            </Link>
                            <Link className="dropdown-item notify-item">
                                <div className="notify-icon bg-info">
                                    <i className="mdi mdi-heart"></i>
                                </div>
                                <p className="notify-details">Carlos Crouch liked
                                    <b>Admin</b>
                                    <small className="text-muted">13 days ago</small>
                                </p>
                            </Link>
                        </div>
                        <Link className="dropdown-item text-center text-primary notify-item notify-all">
                            View All
                        </Link>
                    </div>
                </li> */}
                <li className="dropdown notification-list">
                    <Link className="nav-link dropdown-toggle nav-user arrow-none mr-0" data-toggle="dropdown" role="button" aria-haspopup="false"
                        aria-expanded="false" style={{ padding: 'calc(15.5px) 40px' }}>
                        <span className="account-user-avatar">
                            <img src={props.userInfo.profile_pic ? process.env.REACT_APP_API_MEDIA_KEY + props.userInfo.profile_pic : "/assets/images/users/user.png"} alt="user" className="img-fluid rounded" />
                        </span>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu dropdown-menu-border-top profile-dropdown">
                        <div className=" dropdown-header noti-title">
                            <h6 className="text-overflow m-0">Welcome {props.userInfo.first_name} {props.userInfo.last_name}!</h6>
                        </div>
                        <Link to="/profile" className="dropdown-item notify-item">
                            <i className="mdi mdi-account mr-1"></i>
                            <span>Profile</span>
                        </Link>
                        {props.userInfo.permissions.settings_manage?
                            <Link to="/settings" className="dropdown-item notify-item">
                                <i className="mdi mdi-cog mr-1"></i>
                                <span>Settings</span>
                            </Link>
                            : null}
                        <Link onClick={logOut} className="dropdown-item notify-item">
                            <i className="mdi mdi-power mr-1"></i>
                            <span>Logout</span>
                        </Link>
                    </div>
                </li>
            </ul>
            <button className="button-menu-mobile open-left disable-btn">
                <i className="mdi mdi-menu"></i>
            </button>
        </div>
    );
}
export default TopBar;