import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { dateFormat } from "../../../../Utils/date_formatting";
import DuplicateContactFormPopup from "./DuplicateContactFormPopup";

const ContactFormGridView = (props) => {

    const [duplicateContactFormId, setDuplicateContactFormId] = useState();

    const setActionButtons = (cell, row) => {
        return (
            <div className="dropdown float-right">
                <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical text-secondary font-20"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-border-top">
                    <Link className="dropdown-item notify-item" data-toggle="modal" onClick={() => props.setContactFormData(JSON.parse(row.form_json))} data-target="#contact-form-preview"><i className="mdi mdi-eye mr-1"></i><span>View</span></Link>
                    <Link className="dropdown-item notify-item" onClick={() => setDuplicateContactFormId(row.id)} data-toggle="modal" data-target="#duplicate-contact-form-modal"><i className="mdi mdi-file-multiple mr-1"></i><span>Duplicate</span></Link>
                    <Link className="dropdown-item notify-item" to={{ pathname: '/update-contact-form', state: { isUpdate: true, formId: row.id, formName: row.name, formData: JSON.parse(row.form_json) } }} ><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></Link>
                    <Link className="dropdown-item notify-item" onClick={() => props.setDeleteUrl('audience/updateTabModeData/' + row.id)} data-toggle="modal" data-target="#deleteModal"><i className="mdi mdi-delete mr-1"></i><span>Delete</span></Link>
                </div>
            </div>
        );
    }

    const columns = [{
        dataField: 'name',
        text: 'Form Name'
    }, {
        dataField: 'created_on',
        text: 'Created On',
        formatter: (cell, row) => { return dateFormat(cell) + ' by ' + row.created_by },
        sort: true
    }, {
        dataField: 'modified_on',
        text: 'Modified On',
        formatter: (cell, row) => { return dateFormat(cell) + ' by ' + row.modified_by },
        sort: true
    }, {
        dataField: 'id',
        formatter: setActionButtons
    }];

    return (
        <>
            <DuplicateContactFormPopup duplicateContactFormId={duplicateContactFormId} reloadGrid={props.fetchContactForms} />
            <BootstrapTable
                keyField="id"
                data={props.gridData}
                columns={columns}
                bordered={false}
                bootstrap4
                hover
                noDataIndication="No record found" />
        </>
    )
}

export default ContactFormGridView;