import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { dateFormat, dateTimeFormat } from "../../../Utils/date_formatting";
import { permissionName } from "../../../Utils/string_formatting";

const CustomerResponse = ({ issueId, issueDetails }) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [responses, setResponses] = useState([]);
    const [audienceDetailsUI, setAudienceDetailsUI] = useState();

    const fetchResponses = () => {
        let URL = process.env.REACT_APP_API_KEY + 'issues/getResponseInfo/' + issueId;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setResponses(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(() => {
        if (issueDetails.id) {
            fetchResponses()
            fetchAudienceInfo();
        }
    }, [issueDetails]);

    const fetchAudienceInfo = () => {
        let id = issueDetails.audience_id;
        let URL = process.env.REACT_APP_API_KEY + 'audience/getSingleAudienceNormalized/' + id;
        if (id) {
            axios({
                method: "get",
                url: URL,
                headers: {
                    Authorization: 'Token ' + TOKEN
                }
            }).then(function (response) {
                if (response.data.status === 1) {
                    generateInputFields(response.data.data)
                } else if (response.data.status === 0) {
                    toast.error(response.data.message);
                } else {
                    toast.error('Someting went wrong');
                }
            }).catch(function (error) {
                toast.error(error.toString());
            });
        }
    }

    const generateInputFields = (data) => {
        let arr = [];
        let primaryFields = Object.keys(data[0]);
        for (let i = 0; i < primaryFields.length; i++) {
            if (primaryFields[i] === "_id" ||
                primaryFields[i] === "id" ||
                primaryFields[i] === "data_set_id" ||
                primaryFields[i] === "org_id" ||
                primaryFields[i] === "source_type" ||
                primaryFields[i] === "is_active" ||
                primaryFields[i] === "secondary_data" ||
                primaryFields[i] === "created_by" ||
                primaryFields[i] === "created_on" ||
                primaryFields[i] === "modified_by" ||
                primaryFields[i] === "emailValid" ||
                primaryFields[i] === "nested_data_id" ||
                primaryFields[i] === "phoneValid" ||
                primaryFields[i] === "repeatedData" ||
                primaryFields[i] === "wrongGender") {
            } else {
                arr.push(<div className="col-md-3">
                    <h5 className="text-muted font-weight-normal">{permissionName(primaryFields[i])}</h5>
                    <p>{data[0][primaryFields[i]]}</p>
                </div>)
            }
        }
        setAudienceDetailsUI(arr);
    }

    return (
        <>
            <hr />
            <div className="row">
                <div className="col-md-3">
                    <div className="">
                        <h5 className="text-muted font-weight-normal">Survey Name</h5>
                        <p>{issueDetails.survey_name}</p>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="">
                        <h5 className="text-muted font-weight-normal">Channel</h5>
                        <p>{permissionName(issueDetails.channel_type ? issueDetails.channel_type : '')}</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="">
                        <h5 className="text-muted font-weight-normal">Share Name</h5>
                        <p>{issueDetails.share_name}</p>
                    </div>
                </div>
                <div className="col-md-4 d-flex justify-content-between align-items-center">
                    <div className="">
                        <h5 className="text-muted font-weight-normal">Submitted Time</h5>
                        <p>{dateTimeFormat(issueDetails.created_on ? issueDetails.created_on : '')}</p>
                    </div>
                </div>
            </div>
            <hr />
            {issueDetails.audience_id ?
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                                <h4>Audience Details</h4>
                                <a href="#audience-details" style={{ height: 'fit-content' }}
                                    onClick={() => {
                                        document.getElementById("rotate-arrow").classList.toggle('mdi-rotate-180');
                                    }}
                                    data-toggle="collapse" className="btn btn-sm btn-outline-dark" aria-expanded="true"><i id={"rotate-arrow"} className="uil uil-angle-down mdi-rotate-180"></i></a>
                            </div>
                            <div className="row collapse show" id="audience-details">
                                {audienceDetailsUI}
                            </div>
                        </div>
                    </div>
                    <hr />
                </>
                : null}
            <h4>Customer Response</h4>
            <div className="row mt-3">
                {responses.map((obj, i) => {
                    return <div className="col-md-12 mt-2">
                        <div className="faq-question-q-box font-12">Q.{i + 1}</div>
                        <h4 className="faq-question" data-wow-delay=".1s">{obj.question?.default ? obj.question?.default : obj.question}</h4>
                        {Array.isArray(obj.answer) ?
                            obj.answer.map((obj) => {
                                return <p className="faq-answer mb-1">{obj.default}</p>
                            })
                            :
                            obj.answer === "" ? <><p className="mb-4"></p></> :
                                <p className="faq-answer mb-2">{obj.answer}</p>
                        }
                    </div>
                })}
            </div>
        </>
    )
}

export default CustomerResponse;
