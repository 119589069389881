import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../Common/Loader";
import { permissionName, trimString } from "../../Utils/string_formatting";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { dateFormat } from "../../Utils/date_formatting";
import Select from "react-select";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import GridView from "../Common/GridView";

const Responses = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [loader, setLoader] = useState(false);
    const [surveyResponse, setSurveyResponse] = useState([]);
    const [respondants, setRespondants] = useState([]);
    const [surveyAudienceDetails, setSurveyAudienceDetails] = useState([]);
    const [start, setStartDate] = useState(moment().subtract(29, 'days'));
    const [end, setEndDate] = useState(moment());

    const [tags, setTags] = useState([]);
    const [segments, setSegments] = useState([]);
    const [channels, setChannels] = useState([]);
    const [shares, setShares] = useState([]);
    const [survey, setSurvey] = useState([]);
    const [totalSize, setTotalSize] = useState(0);

    const fetchRespondants = (page = 1, size = 10) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'response/getSurveyResponsesAudienceList';
        const formData = new FormData(document.getElementById('sidePopupFilterForm'));
        formData.append('from_date', start.format('YYYY-MM-DD'));
        formData.append('to_date', end.format('YYYY-MM-DD'));
        formData.append('page_count', size);
        formData.append('page_no', page);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setRespondants(response.data.data)
                setTotalSize(response.data.total_count);
                if (response.data.data.length > 0) {
                    fetchSurveyAudienceDetails(response.data.data[0].id)
                } else {
                    setLoader(false);
                }
                //fetchShares(surveyId);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
                setLoader(false);
            } else {
                toast.error('Someting went wrong');
                setLoader(false);
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchSurveyAudienceDetails = (id) => {
        var elements = document.getElementsByClassName("reports-scroll-bg");
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('selectedCard');
        }
        document.getElementById("respondant_card_" + id).classList.add('selectedCard')

        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'response/getDetailedResponse/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setSurveyAudienceDetails(response.data.data)
                setSurveyResponse(response.data.data.response_data)
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(fetchRespondants, [])

    const downloadCSV = () => {
        setLoader(true);
        const formData = new FormData(document.getElementById('sidePopupFilterForm'));
        formData.append('from_date', start.format('YYYY-MM-DD'));
        formData.append('to_date', end.format('YYYY-MM-DD'));
        if (document.getElementsByClassName('survey_id')[0]) {
            formData.append('survey_id', document.getElementsByClassName('survey_id')[0].value);
        }
        let URL = process.env.REACT_APP_API_KEY + 'response/getInCsv';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchSegments = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setSegments(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchTags = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setTags(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchChannels = () => {
        let URL = process.env.REACT_APP_API_KEY + 'master/getChannelTypes';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i], label: permissionName(data[i]) })
                }
                setChannels(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchShares = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getShareData';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: data[i].share_name })
                }
                setShares(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchSurvey = () => {
        let URL = process.env.REACT_APP_API_KEY + 'survey/allSurvey';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: data[i].name })
                }
                setSurvey(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchSurvey, []);
    useEffect(fetchTags, []);
    useEffect(fetchSegments, []);
    useEffect(fetchChannels, []);
    useEffect(fetchShares, []);

    const onDateChanged = (start, end) => {
        setStartDate(start);
        setEndDate(end);
        const formData = new FormData(document.getElementById('sidePopupFilterForm'));
        formData.append('from_date', start.format('YYYY-MM-DD'));
        formData.append('to_date', end.format('YYYY-MM-DD'));
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'response/getSurveyResponsesAudienceList';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setRespondants(response.data.data);
            setTotalSize(response.data.total_count);
            if (response.data.data.length > 0) {
                fetchSurveyAudienceDetails(response.data.data[0].id)
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    };

    const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

    const gridFilter = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('from_date', start.format('YYYY-MM-DD'));
        formData.append('to_date', end.format('YYYY-MM-DD'));
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'response/getSurveyResponsesAudienceList';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setRespondants(response.data.data);
            setTotalSize(response.data.total_count);
            if (response.data.data.length > 0) {
                fetchSurveyAudienceDetails(response.data.data[0].id)
            }
            setLoader(false);
            document.getElementById('closeFilterModal').click();
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const search = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(document.getElementById('sidePopupFilterForm'));
        formData.append('search_string', document.getElementById('searchRespondant').value);
        formData.append('from_date', start.format('YYYY-MM-DD'));
        formData.append('to_date', end.format('YYYY-MM-DD'));
        let URL = process.env.REACT_APP_API_KEY + 'response/getSurveyResponsesAudienceList';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            setRespondants(response.data.data);
            setTotalSize(response.data.total_count);
            if (response.data.data.length > 0) {
                fetchSurveyAudienceDetails(response.data.data[0].id)
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const setShareIcon = (cell) => {
        if (cell === "sms") {
            return <i className="mdi mdi-cellphone-message me-1 mr-1"></i>
        } else if (cell === "url") {
            return <i className="mdi mdi-link-variant-plus me-1 mr-1"></i>
        } else if (cell === "qr code") {
            return <i className="mdi mdi-qrcode me-1 mr-1"></i>
        } else if (cell === "social media") {
            return <i className="uil uil-share-alt me-1 mr-1"></i>
        } else if (cell === "api") {
            return <i className="me-1 mr-1 mdi mdi-code-not-equal-variant"></i>
        } else if (cell === "tab_mode") {
            return <i className="me-1 mr-1 mdi mdi-tablet"></i>
        }
    }

    const setRespondantDetails = (cell, row) => {
        return <div onClick={() => fetchSurveyAudienceDetails(row.id)} id={"respondant_card_" + row.id} className="d-flex justify-content-between align-items-start px-2 py-2 reports-scroll-bg">
            <div className="d-flex">
                <div className="d-flex flex-column">
                    <label title={row.first_name} className="form-check-label cursor-pointer">{trimString(row.first_name, 25)}</label>
                    <div className="w-100 overflow-hidden">
                        <small className="text-muted">{setShareIcon(row.channel_type)}{permissionName(row.channel_type).toUpperCase()}</small>
                    </div>
                </div>
            </div>
            <p className="text-muted">{dateFormat(row.created_on ? row.created_on : '')}</p>
        </div>
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex text-nowrap">
                                            <DateRangePicker
                                                initialSettings={{
                                                    startDate: start.toDate(),
                                                    endDate: end.toDate(),
                                                    ranges: {
                                                        Today: [moment().toDate(), moment().toDate()],
                                                        Yesterday: [
                                                            moment().subtract(1, 'days').toDate(),
                                                            moment().subtract(1, 'days').toDate(),
                                                        ],
                                                        'Last 7 Days': [
                                                            moment().subtract(6, 'days').toDate(),
                                                            moment().toDate(),
                                                        ],
                                                        'Last 30 Days': [
                                                            moment().subtract(29, 'days').toDate(),
                                                            moment().toDate(),
                                                        ],
                                                        'This Month': [
                                                            moment().startOf('month').toDate(),
                                                            moment().endOf('month').toDate(),
                                                        ],
                                                        'Last Month': [
                                                            moment().subtract(1, 'month').startOf('month').toDate(),
                                                            moment().subtract(1, 'month').endOf('month').toDate(),
                                                        ],
                                                    },
                                                }}
                                                onCallback={onDateChanged}>
                                                <div id="reportrange" className="form-control">
                                                    <i className="fa fa-calendar"></i>&nbsp;
                                                    <span>{label}</span> <i className="fa fa-caret-down"></i>
                                                </div>
                                            </DateRangePicker>

                                            <button type="button" onClick={downloadCSV} className="btn btn-outline-primary btn-sm-block mx-2"><i className="mdi mdi-download mr-1"></i>CSV</button>
                                            <button type="button" data-toggle="modal" data-target="#right-modal" className="btn btn-outline-primary btn-sm-block mr-2 dropdown-toggle arrow-none"><i className="mdi mdi-tune mr-1"></i>Filter</button>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Responses</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Responses - {totalSize}</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="card col-md-4 p-3 responses-table" style={{ maxHeight: '830px' }}>
                            <div className="form-group mb-2">
                                <form onSubmit={search} className="input-group">
                                    <input type="text" id="searchRespondant" name="search" className="form-control form-control-white border-light" placeholder="Search" />
                                    <div className="input-group-append cursor-pointer">
                                        <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                            <i className="mdi mdi-magnify font-20"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>

                            {/* {respondants.map((obj, index) => {
                                return (
                                    <>
                                        <div onClick={() => fetchSurveyAudienceDetails(obj.id)} id={"respondant_card_" + obj.id} className="d-flex justify-content-between align-items-start px-2 py-2 reports-scroll-bg">
                                            <div className="d-flex">
                                                <div className="d-flex flex-column">
                                                    <label title={obj.first_name} className="form-check-label cursor-pointer">{trimString(obj.first_name, 30)}</label>
                                                    <div className="w-100 overflow-hidden">
                                                        <small className="text-muted">{setShareIcon(obj.channel_type)}{permissionName(obj.channel_type).toUpperCase()}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-muted">{dateFormat(obj.created_on ? obj.created_on : '')}</p>
                                        </div>
                                        <hr className="my-0" />
                                    </>
                                )
                            })} */}

                            <GridView
                                headerClasses="d-none"
                                classes="respondantsGrid"
                                gridData={respondants}
                                columns={[{ dataField: 'first_name', text: 'Respondants', formatter: setRespondantDetails }]}
                                totalSize={totalSize}
                                sizePerPage={10}
                                keyField="id"
                                fetchData={(page, size) => fetchRespondants(page, size)} />
                        </div>

                        <div className="col-md-8 pl-4 ml-auto">
                            <div className="card mb-2 shadow-none">
                                <div className="p-3">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div className="avatar-sm">
                                                <span className="avatar-title rounded">{surveyAudienceDetails.first_name ? surveyAudienceDetails.first_name[0] : null}</span>
                                            </div>
                                        </div>
                                        <div className="col pl-0">
                                            <Link to={surveyAudienceDetails?.nested_data_id === null ? "/audience" : "/audience-details/" + surveyAudienceDetails?.nested_data_id} target="_blank">
                                                <span className="text-primary font-weight-bold">{surveyAudienceDetails.first_name} {surveyAudienceDetails.second_name}</span>
                                            </Link>
                                            <p className="text-muted mb-0">
                                                {surveyAudienceDetails.phone ?
                                                    <>
                                                        <i className="mdi mdi-phone mr-1"></i>{surveyAudienceDetails.phone}
                                                    </>
                                                    : null}
                                                {surveyAudienceDetails.email ?
                                                    <>
                                                        <span className="mx-2">|</span>
                                                        <i className="mdi mdi-email mr-1"></i> {surveyAudienceDetails.email}
                                                    </>
                                                    : null}
                                            </p>
                                        </div>
                                        {/* <div className="col-auto">
                                                    <p className="text-muted">
                                                        9d : 17hr : 38min : 8sec
                                                    </p>
                                                </div> */}
                                    </div>
                                </div>

                                <div className="row px-3">
                                    <div className="col-md-11">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Survey Name</h5>
                                                <p>{surveyAudienceDetails?.survey_name}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Share Name</h5>
                                                <p>{surveyAudienceDetails?.share_name}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Channel</h5>
                                                <p>{permissionName(surveyAudienceDetails?.channel_type ? surveyAudienceDetails?.channel_type : '')}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Response Date</h5>
                                                <p>{dateFormat(surveyAudienceDetails?.created_on ? surveyAudienceDetails?.created_on : '')}</p>
                                            </div>
                                        </div>
                                        {/* <div className="row collapse" id="audience-more-details">
                                             <div className="col-md-3">
                                                        <h5 className="text-muted font-weight-normal">Started Time</h5>
                                                        <p>{surveyAudienceDetails?.started_time}</p>
                                                    </div> 
                                             <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Source</h5>
                                                <p>{surveyAudienceDetails?.source}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">IP Address</h5>
                                                <p>{surveyAudienceDetails?.ip_address}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Location of Respondent</h5>
                                                <p>{surveyAudienceDetails?.source}</p>
                                            </div> 
                                        </div> */}
                                        <hr />

                                        <div className="audience-details-content">
                                            <div className="row collapse" id="response-more-details">
                                                <div className="col-md-12 mt-2">
                                                    <h4>Response</h4>
                                                    {surveyResponse.length === 0 ? <p>No Response Found</p> :
                                                        surveyResponse.map((obj, i) => {
                                                            return <div className="col-md-12 mt-2">
                                                                <div className="faq-question-q-box font-12">Q.{i + 1}</div>
                                                                <h4 className="faq-question" data-wow-delay=".1s">{obj.question?.default ? obj.question?.default : obj.question}</h4>
                                                                {Array.isArray(obj.answer) ?
                                                                    obj.answer.map((obj) => {
                                                                        return <p className="faq-answer mb-1">{obj.default}</p>
                                                                    })
                                                                    :
                                                                    obj.answer === "" ? <><p className="mb-4"></p></> :
                                                                        <p className="faq-answer mb-2">{obj.answer}</p>
                                                                }
                                                            </div>
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-1">
                                        <a href="#audience-more-details" type="button" data-toggle="collapse" aria-expanded="true" className="btn btn-sm btn-outline-dark mt-2"><i className="uil uil-angle-down"></i></a>
                                    </div> */}
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card px-2">
                                            {surveyResponse.length === 0 ? <p>No Response Found</p> :
                                                surveyResponse.map((obj, i) => {
                                                    return <div className="col-md-12 mt-2">
                                                        <div className="faq-question-q-box font-12">Q.{i + 1}</div>
                                                        <h4 className="faq-question" data-wow-delay=".1s">{obj.question?.default ? obj.question?.default : obj.question}</h4>
                                                        {Array.isArray(obj.answer) ?
                                                            obj.answer.map((obj) => {
                                                                return <p className="faq-answer mb-1">{obj.default}</p>
                                                            })
                                                            :
                                                            obj.answer === "" ? <><p className="mb-4"></p></> :
                                                                <p className="faq-answer mb-2">{obj.answer}</p>
                                                        }
                                                    </div>
                                                })}
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade" id="right-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-lg modal-right" style={{ minWidth: 400, maxWidth: 600 }}>
                                        <form onSubmit={gridFilter} id="sidePopupFilterForm" className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title" id="myLargeModalLabel">Filter Survey Responses</h4>
                                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                                                </button>
                                            </div>
                                            <div className="modal-body" style={{ maxWidth: '400px' }}>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Surveys</label>
                                                        <Select options={survey} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="survey_id" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Segments</label>
                                                        <Select options={segments} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="segments" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Tags</label>
                                                        <Select options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="tags" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Shares</label>
                                                        <Select options={shares} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="share_id" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Channels</label>
                                                        <Select options={channels} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="channel_type" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer align-footer-bottom">
                                                <button type="button" id="closeFilterModal" className="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                                                <button type="submit" className="btn btn-primary">Apply Filters</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loader ? <Loader /> : null}
            </div>
        </div>
    );
}

export default Responses;
