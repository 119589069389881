import React, { useState, useEffect } from "react";
import "survey-analytics/survey.analytics.css";
import "tabulator-tables/dist/css/tabulator.css";
import "survey-analytics/survey.analytics.tabulator.css";
import * as SurveyAnalyticsTabulator from "survey-analytics/survey.analytics.tabulator";
import SurveyDropDown from "../Common/SurveyDropDown";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import { permissionName } from "../../Utils/string_formatting";
import * as SurveyAnalytics from "survey-analytics";
import * as Survey from "survey-react";
import Loader from "../Common/Loader";
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import $ from 'jquery';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import Reports from "../Settings/Reports/Reports";

const ResponseAnalysis = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [surveyId, setSurveyId] = useState("0");
    const [survey, setSurvey] = useState("0");
    const [tags, setTags] = useState([]);
    const [segments, setSegments] = useState([]);
    const [channels, setChannels] = useState([]);
    const [shares, setShares] = useState([]);
    const [surveyQuestionsJson, setSurveyQuestionsJson] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [fields, setFields] = useState({});
    const [mongoQuery, setMongoQuery] = useState("");
    const [start, setStartDate] = useState(moment().subtract(29, 'days'));
    const [end, setEndDate] = useState(moment());
    const [tableView, setTableView] = useState(false);
    const [responseCount, setResponseCount] = useState(0);

    const onSurveyChange = (data) => {
        setSurvey(data);
        if (data === "0") {
            setResponseCount(0)
        } else {
            setSurveyId(JSON.parse(data).id)
            fetchShares(JSON.parse(data).id);
            setSurveyQuestionsJson(JSON.parse(JSON.parse(data).questions));
            fetchSurveyResponses(JSON.parse(data).id, JSON.parse(JSON.parse(data).questions))
        }
    }

    const gridFilter = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyId);
        formData.append('mongo_query', (mongoQuery === undefined ? 0 : mongoQuery));
        formData.append('from_date', start.format('YYYY-MM-DD'));
        formData.append('to_date', end.format('YYYY-MM-DD'));
        setShowLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'response/responseFilters';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            renderResponses(response.data.data, surveyQuestionsJson)
            setShowLoader(false);
            document.getElementById('closeFilterModal').click();
        }).catch(function (error) {
            setShowLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchSegments = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setSegments(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchTags = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setTags(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchChannels = () => {
        let URL = process.env.REACT_APP_API_KEY + 'master/getChannelTypes';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i], label: permissionName(data[i]) })
                }
                setChannels(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchShares = (id) => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getShareData?survey_id=' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: data[i].share_name })
                }
                setShares(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchTags, []);
    useEffect(fetchSegments, []);
    useEffect(fetchChannels, []);

    const fetchSurveyResponses = (id, surveyJson) => {
        setShowLoader(true);
        const formData = new FormData();
        formData.append('survey_id', id);
        formData.append('from_date', start.format('YYYY-MM-DD'));
        formData.append('to_date', end.format('YYYY-MM-DD'));
        setShowLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'response/responseFilters';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            renderResponses(response.data.data, surveyJson)
            setShowLoader(false);
        }).catch(function (error) {
            setShowLoader(false);
            toast.error(error.toString());
        });

    }

    useEffect(() => {
        onSurveyChange(survey);
    }, [tableView])


    // const renderResponses = (responseData, surveyJson) => {
    //     var surveyResultNode = document.getElementById("surveyResult");
    //     surveyResultNode.innerHTML = "";
    //     var survey = new Survey.Model(surveyJson);
    //     var visPanel = new SurveyAnalytics.VisualizationPanel(
    //         survey.getAllQuestions(),
    //         responseData,
    //         { labelTruncateLength: 27 }
    //     );
    //     visPanel.showHeader = true;
    //     visPanel.render(surveyResultNode);
    // }

    const renderResponses = (responseData, surveyJson) => {
        setResponseCount(responseData.length)
        var tempJson = JSON.stringify(surveyJson).replaceAll('emotionsratings', 'rating');
        if (tableView) {
            var surveyResultNode = document.getElementById("surveyResult");
            surveyResultNode.innerHTML = "";
            var survey = new Survey.Model(JSON.parse(tempJson));
            var visPanel = new SurveyAnalyticsTabulator.Tabulator(
                survey,
                responseData,
                { labelTruncateLength: 27 }
            );
            visPanel.showHeader = true;
            visPanel.render(surveyResultNode);
        } else {
            var surveyResultNode = document.getElementById("surveyResult");
            surveyResultNode.innerHTML = "";
            var survey = new Survey.Model(JSON.parse(tempJson));
            var visPanel = new SurveyAnalytics.VisualizationPanel(
                survey.getAllQuestions(),
                responseData,
                { labelTruncateLength: 27 }
            );
            visPanel.showHeader = true;
            visPanel.render(surveyResultNode);
        }
    }

    const downloadCSV = () => {
        setShowLoader(true);
        const formData = new FormData(document.getElementById('sidePopupFilterForm'));
        formData.append('survey_id', surveyId);
        formData.append('from_date', start.format('YYYY-MM-DD'));
        formData.append('to_date', end.format('YYYY-MM-DD'));
        let URL = process.env.REACT_APP_API_KEY + 'response/getInCsv?survey_id=' + surveyId;
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
            setShowLoader(false);
        }).catch(function (error) {
            setShowLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchPrimarySecondaryFields = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getAllFields';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            generateFields(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const generateFields = (data) => {
        let obj = {};
        for (let i = 0; i < data.length; i++) {
            if (data[i] === "" || data[i] === "id" || data[i] === "data_set_id" || data[i] === "org_id") {
                //do nothing
            } else {
                obj = Object.assign(obj, { [data[i]]: { label: permissionName(data[i]), type: 'text' } });
            }
        }
        setFields(obj);
    }

    const config = {
        ...MaterialConfig,
        fields: fields
    };

    const queryValue = { "id": QbUtils.uuid(), "type": "group" };

    useEffect(() => {
        $(document).on('click', '.filterToggleMenu', function (e) {
            e.preventDefault();
            if ($('.filterToggleMenu').hasClass('collapsed')) {
                $('.filterToggleMenu i').removeClass("mdi-eye").addClass("mdi-eye-off");
            } else {
                $('.filterToggleMenu i').removeClass("mdi-eye-off").addClass("mdi-eye");
            }
        });
    }, []);

    const [tree, setTree] = useState(QbUtils.checkTree(QbUtils.loadTree(queryValue), config));

    const renderBuilder = (props) => (
        <div className="query-builder-container" style={{ padding: '10px' }}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    )

    const onChange = (immutableTree, config) => {
        setTree(immutableTree);
        setMongoQuery(JSON.stringify(QbUtils.mongodbFormat(immutableTree, config)));
    }

    const renderResult = ({ tree: immutableTree, config }) => (
        <div className="query-builder-result">
            <div><pre style={{ whiteSpace: 'pre-wrap', fontSize: 14, fontWeight: 600 }}>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div>
        </div>
    );

    useEffect(fetchPrimarySecondaryFields, []);

    const onDateChanged = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">

                                            <input type="checkbox" checked={tableView} onChange={() => setTableView(!tableView)} id="switch12" data-switch="primary" />
                                            <label htmlFor="switch12" className="mr-2 border p-2" style={{ alignSelf: "center" }} data-on-label="Table" data-off-label="Chart"></label>

                                            {survey === "0" ? null :
                                                <>
                                                    <button type="button" onClick={downloadCSV} className="btn btn-outline-primary btn-sm-block mr-2"><i className="mdi mdi-download mr-1"></i>CSV</button>
                                                    <button type="button" data-toggle="modal" data-target="#right-modal" className="btn btn-outline-primary btn-sm-block mr-2 dropdown-toggle arrow-none"><i className="mdi mdi-tune mr-1"></i>Filter</button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Responses</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Analytics ({responseCount})</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={gridFilter} className="row bg-white rounded mx-0 pt-2 d-flex justify-content-between">
                        <div className="col-md-6">
                            <div className="form-group mx-2">
                                <label>Select Survey</label>
                                <SurveyDropDown value={survey} onchange={(event) => onSurveyChange(event.target.value)} name="survey" />
                            </div>
                        </div>
                        {/* <div className="col-md-2 form-group">
                            <div className="form-group">
                                <label for="example-date">From Date</label>
                                <input className="form-control" name="from_date" value={fromDate} onChange={(event) => { setFromDate(event.target.value) }} type="date" />
                            </div>
                        </div>
                        <div className="col-md-2 form-group">
                            <div className="form-group">
                                <label for="example-date">To Date</label>
                                <input className="form-control" name="to_date" value={toDate} onChange={(event) => { setToDate(event.target.value) }} type="date" />
                            </div>
                        </div> */}
                        <div className="col-md-5">
                            <div className="form-group mx-2">
                                <label>Select Date Range</label>
                                <DateRangePicker
                                    initialSettings={{
                                        startDate: start.toDate(),
                                        endDate: end.toDate(),
                                        ranges: {
                                            Today: [moment().toDate(), moment().toDate()],
                                            Yesterday: [
                                                moment().subtract(1, 'days').toDate(),
                                                moment().subtract(1, 'days').toDate(),
                                            ],
                                            'Last 7 Days': [
                                                moment().subtract(6, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'Last 30 Days': [
                                                moment().subtract(29, 'days').toDate(),
                                                moment().toDate(),
                                            ],
                                            'This Month': [
                                                moment().startOf('month').toDate(),
                                                moment().endOf('month').toDate(),
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                            ],
                                        },
                                    }}
                                    onCallback={onDateChanged}>
                                    <div id="reportrange" className="form-control">
                                        <i className="fa fa-calendar"></i>&nbsp;
                                        <span>{label}</span> <i className="fa fa-caret-down"></i>
                                    </div>
                                </DateRangePicker>
                            </div>
                        </div>

                        <div className="col-md-1 form-group" style={{ alignSelf: 'center', marginTop: '20px' }}>
                            <button type="submit" disabled={survey === "0"} className="btn btn-primary"><i className="mdi mdi-magnify"></i> </button>
                        </div>
                    </form>
                    <div className="modal fade" id="right-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-right" style={{ minWidth: 400, maxWidth: 600 }}>
                            <form onSubmit={gridFilter} id="sidePopupFilterForm" className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="myLargeModalLabel">Filter Survey Responses</h4>
                                    <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ maxWidth: '400px' }}>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Segments</label>
                                            <Select options={segments} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"segments"} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Tags</label>
                                            <Select options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"tags"} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Shares</label>
                                            <Select options={shares} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"share_id"} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Channels</label>
                                            <Select options={channels} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"channel_type"} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Query Filter</label>
                                            <div className="d-flex justify-content-between">
                                                {renderResult({ tree, config })}
                                                {mongoQuery ?
                                                    <button className="filterToggleMenu" style={{ border: 'none', color: '#313a46', backgroundColor: 'transparent', fontSize: '24px', cursor: 'pointer' }} data-toggle="collapse" data-target="#queryBuilderContainer"><i className="mdi mdi-eye"></i></button>
                                                    : null
                                                }
                                            </div>
                                            <div id="queryBuilderContainer" className="collapse show mt-n2">
                                                <Query {...config} value={tree} onChange={onChange} renderBuilder={renderBuilder} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer align-footer-bottom">
                                    <button type="button" id="closeFilterModal" className="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Apply Filters</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            {survey === "0" ? null :
                                <div id="surveyResult"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {showLoader ? <Loader /> : null}
            <Reports />
        </div>
    );
}
export default ResponseAnalysis;