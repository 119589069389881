import React, { useEffect } from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";

const ViewTeam = (props) => {

    const [teamName, setTeamName] = useState();
    const [teamDescription, setTeamDescription] = useState();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        setTeamName(props.location.state.teamDetails.team_name);
        setTeamDescription(props.location.state.teamDetails.description);
        setUsers(props.location.state.teamDetails.team_user_list);
    }, [props.location.state]);


    const setStatus = (cell) => {
        return cell ? <span className="badge badge-success-lighten  m-0 round-badge text-center">ACTIVE</span> : <span className="badge badge-warning-lighten  round-badge text-center">INACTIVE</span>;
    }

    const columns = [{
        dataField: 'name',
        text: 'Name'
    }, {
        dataField: 'email',
        text: 'Email'
    }, {
        dataField: 'role_name',
        text: 'Role'
    }, {
        dataField: 'user_status',
        text: 'Status',
        formatter: setStatus
    }];

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Settings</a></li>
                                        <li className="breadcrumb-item"><a>User Settings</a></li>
                                        <li className="breadcrumb-item"><Link to="/teams">Teams</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">View Team</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/teams"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>{teamName}</h4>
                                    <p>{teamDescription}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label>Users</label>
                            <BootstrapTable
                                keyField='id'
                                data={users}
                                bootstrap4
                                bordered={false}
                                hover
                                columns={columns}
                                pagination={paginationFactory(options)}
                                noDataIndication="No record found" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewTeam;