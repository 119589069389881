const ServiceCard = (props) => {

    return (
        <div className="col-md-2">
            <div className="card border p-2 mb-0 cursor-pointer shadow-none bg-light" data-toggle="modal" data-target={props.modalId} onClick={() => document.getElementById('service-providers-select').value = 0}>
                <div className="text-center">
                    <img src={props.src} className="img-fluid" alt="" style={{ height: '64px', objectFit: "contain" }} />
                    <h6>{props.name}</h6>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard;