import Loader from '../Common/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from "react";
import IssuesGridView from "./IssuesGridView";

import { permissionName, trimString } from "../../Utils/string_formatting";
import { Link } from "react-router-dom";
import { dateFormat } from "../../Utils/date_formatting";
import Select from "react-select";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import GridView from "../Common/GridView";
import MyIssuesGridView from './MyIssuesGridView';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import './styles/styles.scss'
import { statusLabelMapHelpers, statusMapHelpers, statusToNumMapHelpers, statusToValueMapHelpers } from './helpers';

const MyIssues = ({ userInfo }) => {

  const TOKEN = localStorage.getItem('fms-access-token');

  const [issues, setIssues] = useState([]);
  const [loader, setLoader] = useState(false);
  const [myIssues, setMyIssues] = useState(true);
  const [totalSize, setTotalSize] = useState(0);

  const [priorities, setPriorities] = useState([]);
  const [filterPriorities, setFilterPriorities] = useState([]);

  const [status, setStatus] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterStatusValue, setFilterStatusValue] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);


  const [surveyAudienceDetails, setSurveyAudienceDetails] = useState([]);
  const [surveyResponse, setSurveyResponse] = useState([]);
  const [surveyResponseInfo, setSurveyResponseInfo] = useState([]);
  const [start, setStartDate] = useState(moment().subtract(29, 'days'));
  const [end, setEndDate] = useState(moment());
  const [statusList, setStatusList] = useState([]);

  const [tags, setTags] = useState([]);
  const [segments, setSegments] = useState([]);
  const [channels, setChannels] = useState([]);
  const [shares, setShares] = useState([]);
  const [survey, setSurvey] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);

  const [nestedDataId, setNestedDataId] = useState(null);
  const [responseId, setResponseId] = useState(null);

  const [statusFilters, setStatusFilters] = useState([]);
  const [buttonClickFilters, setButtonClickFilters] = useState([]);

  const fetchUsers = () => {
    setLoader(true);
    let URL = process.env.REACT_APP_API_KEY + 'addUser';
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        let data = response.data.data;

        let arr1 = [];
        for (let i = 0; i < data.length; i++) {
          arr1.push({ value: data[i].user_id, label: permissionName(data[i].name) })
        }

        setAssignedTo(arr1);
      }
      setLoader(false);
    }).catch(function (error) {
      toast.error(error.toString());
      setLoader(false);
    });
  }

  useEffect(() => {
    fetchMyIssues(undefined, undefined, buttonClickFilters);
  }, [buttonClickFilters])


  const fetchMyIssues = (page = 1, size = 10, buttonClickFilters) => {
    setLoader(true);
    const formData = new FormData();
    // formData.append('my_issues', myIssues ? 1 : 0)
    formData.append('my_issues', 0)
    formData.append('page_count', size);
    formData.append('page_no', page);
    formData.append('from_date', start.format('YYYY-MM-DD'));
    formData.append('to_date', end.format('YYYY-MM-DD'));
    let URL = process.env.REACT_APP_API_KEY + 'issues/get_my_issues';
    if (buttonClickFilters) {
      buttonClickFilters.forEach(x => formData.append('status', x));
    }
    axios({
      method: "post",
      url: URL,
      data: formData,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      setLoader(false);
      setIssues(response.data.data);
      setTotalSize(response.data.total_count);
      setStatusList([
        {
          "id": 1,
          "status": "open",
          "color": "#fa5c7c",
          "count": response.data.open_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 1)[0].count : response.data.open_count
        },
        {
          "id": 2,
          "status": "work_in_progress",
          "color": "#e91e63",
          "count": response.data.work_in_progress_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 2)[0].count : response.data.work_in_progress_count
        },
        {
          "id": 3,
          "status": "resolved",
          "color": "#39afd1",
          "count": response.data.resolved_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 3)[0].count : response.data.resolved_count
        },
        {
          "id": 4,
          "status": "overdue",
          "color": "#ff7600",
          "count": response.data.overdue_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 4)[0].count : response.data.overdue_count
        },
        {
          "id": 5,
          "status": "closed",
          "color": "#0acf97",
          "count": response.data.closed_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 5)[0].count : response.data.closed_count
        },
        {
          "id": 6,
          "status": "reopened",
          "color": "#666666",
          "count": response.data.reopened_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 6)[0].count : response.data.reopened_count
        }
      ]);
      if (response.data.data.length > 0) {
        fetchSurveyAudienceDetails(response.data.data[0].nested_data_id, response.data.data[0].response_id)
      } else {
        setSurveyAudienceDetails([])
        setLoader(false);
      }
    }).catch(function (error) {
      setLoader(false);
      toast.error(error.toString());
    });
  }

  useEffect(() => {
    fetchMyIssues()
    fetchUsers()
  }, [myIssues]);



  const fetchSurveyAudienceDetails = (nested_data_id, response_id) => {
    setNestedDataId(nested_data_id)
    setResponseId(response_id)
    var elements = document.getElementsByClassName("reports-scroll-bg");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('selectedCard');
    }
    document.getElementById("respondant_card_" + nested_data_id).classList.add('selectedCard')

    setLoader(true);
    let URL = process.env.REACT_APP_API_KEY + 'issues/get_single_audience_issues';
    const formData = new FormData();
    formData.append('nested_data_id', nested_data_id);
    formData.append('response_id', response_id);
    axios({
      method: "Post",
      url: URL,
      data: formData,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        setSurveyAudienceDetails(response.data.data);
        let status_data = response?.data?.status_list || [];
        status_data = status_data.filter(({ status }) => statusMapHelpers[status] !== 'Total');
        fetchSurveyAudienceResponseDetails(response_id);
      } else if (response.data.status === 0) {
        toast.error(response.data.message);
      } else {
        toast.error('Someting went wrong');
      }
      setLoader(false);
    }).catch(function (error) {
      setLoader(false);
      toast.error(error.toString());
    });
  }


  const fetchSurveyAudienceResponseDetails = (response_id) => {
    var elements = document.getElementsByClassName("reports-scroll-bg");
    // for (let i = 0; i < elements.length; i++) {
    //   elements[i].classList.remove('selectedCard');
    // }
    // document.getElementById("respondant_card_" + response_id.id).classList.add('selectedCard')

    setLoader(true);
    let URL = process.env.REACT_APP_API_KEY + 'response/getDetailedResponse/' + response_id;
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        setSurveyResponseInfo(response.data.data);
        setSurveyResponse(response.data.data.response_data)
      } else if (response.data.status === 0) {
        toast.error(response.data.message);
      } else {
        toast.error('Someting went wrong');
      }
      setLoader(false);
    }).catch(function (error) {
      setLoader(false);
      toast.error(error.toString());
    });
  }




  const fetchSegments = () => {
    let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation';
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        let arr = [];
        let data = response.data.data;
        for (let i = 0; i < data.length; i++) {
          arr.push({ value: data[i].id, label: permissionName(data[i].name) })
        }
        setSegments(arr);
      }
    }).catch(function (error) {
      toast.error(error.toString());
    });
  }

  const fetchTags = () => {
    let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        let arr = [];
        let data = response.data.data;
        for (let i = 0; i < data.length; i++) {
          arr.push({ value: data[i].id, label: permissionName(data[i].name) })
        }
        setTags(arr);
      }
    }).catch(function (error) {
      toast.error(error.toString());
    });
  }

  const fetchChannels = () => {
    let URL = process.env.REACT_APP_API_KEY + 'master/getChannelTypes';
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        let arr = [];
        let data = response.data.data;
        for (let i = 0; i < data.length; i++) {
          arr.push({ value: data[i], label: permissionName(data[i]) })
        }
        setChannels(arr);
      }
    }).catch(function (error) {
      toast.error(error.toString());
    });
  }

  const fetchShares = () => {
    let URL = process.env.REACT_APP_API_KEY + 'audience/getShareData';
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        let arr = [];
        let data = response.data.data;
        for (let i = 0; i < data.length; i++) {
          arr.push({ value: data[i].id, label: data[i].share_name })
        }
        setShares(arr);
      }
    }).catch(function (error) {
      toast.error(error.toString());
    });
  }

  const fetchSurvey = () => {
    let URL = process.env.REACT_APP_API_KEY + 'survey/allSurvey';
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        let arr = [];
        let data = response.data.data;
        for (let i = 0; i < data.length; i++) {
          arr.push({ value: data[i].id, label: data[i].name })
        }
        setSurvey(arr);
      }
    }).catch(function (error) {
      toast.error(error.toString());
    });
  }

  useEffect(fetchSurvey, []);
  useEffect(fetchTags, []);
  useEffect(fetchSegments, []);
  useEffect(fetchChannels, []);
  useEffect(fetchShares, []);

  const onDateChanged = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    const formData = new FormData(document.getElementById('sidePopupFilterForm'));
    formData.append('from_date', start.format('YYYY-MM-DD'));
    formData.append('to_date', end.format('YYYY-MM-DD'));
    setLoader(true);
    let URL = process.env.REACT_APP_API_KEY + 'issues/get_my_issues';
    axios({
      method: "post",
      url: URL,
      data: formData,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      setIssues(response.data.data);
      setTotalSize(response.data.total_count);
      setStatusList([
        {
          "id": 1,
          "status": "open",
          "color": "#fa5c7c",
          "count": response.data.open_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 1)[0].count : response.data.open_count
        },
        {
          "id": 2,
          "status": "work_in_progress",
          "color": "#e91e63",
          "count": response.data.work_in_progress_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 2)[0].count : response.data.work_in_progress_count
        },
        {
          "id": 3,
          "status": "resolved",
          "color": "#39afd1",
          "count": response.data.resolved_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 3)[0].count : response.data.resolved_count
        },
        {
          "id": 4,
          "status": "overdue",
          "color": "#ff7600",
          "count": response.data.overdue_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 4)[0].count : response.data.overdue_count
        },
        {
          "id": 5,
          "status": "closed",
          "color": "#0acf97",
          "count": response.data.closed_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 5)[0].count : response.data.closed_count
        },
        {
          "id": 6,
          "status": "reopened",
          "color": "#666666",
          "count": response.data.reopened_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 6)[0].count : response.data.reopened_count
        }
      ]);
      if (response.data.data.length > 0) {
        fetchSurveyAudienceDetails(response.data.data[0].nested_data_id, response.data.data[0].response_id)
      } else {
        setLoader(false);
      }
    }).catch(function (error) {
      setLoader(false);
      toast.error(error.toString());
    });
  };

  const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

  const gridFilter = (event) => {
    setSelectedFilters(filterStatusValue);
    setButtonClickFilters(filterStatusValue.map(({ value }) => value));
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('from_date', start.format('YYYY-MM-DD'));
    formData.append('to_date', end.format('YYYY-MM-DD'));
    setLoader(true);
    let URL = process.env.REACT_APP_API_KEY + 'issues/get_my_issues';
    axios({
      method: "post",
      url: URL,
      data: formData,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      setIssues(response.data.data);
      setTotalSize(response.data.total_count);
      setStatusList([
        {
          "id": 1,
          "status": "open",
          "color": "#fa5c7c",
          "count": response.data.open_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 1)[0].count : response.data.open_count
        },
        {
          "id": 2,
          "status": "work_in_progress",
          "color": "#e91e63",
          "count": response.data.work_in_progress_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 2)[0].count : response.data.work_in_progress_count
        },
        {
          "id": 3,
          "status": "resolved",
          "color": "#39afd1",
          "count": response.data.resolved_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 3)[0].count : response.data.resolved_count
        },
        {
          "id": 4,
          "status": "overdue",
          "color": "#ff7600",
          "count": response.data.overdue_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 4)[0].count : response.data.overdue_count
        },
        {
          "id": 5,
          "status": "closed",
          "color": "#0acf97",
          "count": response.data.closed_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 5)[0].count : response.data.closed_count
        },
        {
          "id": 6,
          "status": "reopened",
          "color": "#666666",
          "count": response.data.reopened_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 6)[0].count : response.data.reopened_count
        }
      ]);
      if (response.data.data.length > 0) {
        fetchSurveyAudienceDetails(response.data.data[0].nested_data_id, response.data.data[0].response_id)
      } else {
        setLoader(false);
      }
      document.getElementById('closeFilterModal').click();
    }).catch(function (error) {
      setLoader(false);
      toast.error(error.toString());
    });
  }

  const search = (event, page = 1, size = 10) => {
    setLoader(true);
    event.preventDefault();
    // const formData = new FormData(document.getElementById('sidePopupFilterForm'));
    const formData = new FormData();
    formData.append('search_string', document.getElementById('searchIssues').value);
    // formData.append('from_date', start.format('YYYY-MM-DD'));
    // formData.append('to_date', end.format('YYYY-MM-DD'));
    formData.append('my_issues', 0)
    formData.append('page_count', size);
    formData.append('page_no', page);
    let URL = process.env.REACT_APP_API_KEY + 'issues/get_my_issues';
    axios({
      method: "post",
      url: URL,
      data: formData,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      setLoader(false);
      setIssues(response.data.data);
      setTotalSize(response.data.total_count);
      setStatusList([
        {
          "id": 1,
          "status": "open",
          "color": "#fa5c7c",
          "count": response.data.open_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 1)[0].count : response.data.open_count
        },
        {
          "id": 2,
          "status": "work_in_progress",
          "color": "#e91e63",
          "count": response.data.work_in_progress_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 2)[0].count : response.data.work_in_progress_count
        },
        {
          "id": 3,
          "status": "resolved",
          "color": "#39afd1",
          "count": response.data.resolved_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 3)[0].count : response.data.resolved_count
        },
        {
          "id": 4,
          "status": "overdue",
          "color": "#ff7600",
          "count": response.data.overdue_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 4)[0].count : response.data.overdue_count
        },
        {
          "id": 5,
          "status": "closed",
          "color": "#0acf97",
          "count": response.data.closed_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 5)[0].count : response.data.closed_count
        },
        {
          "id": 6,
          "status": "reopened",
          "color": "#666666",
          "count": response.data.reopened_count === 0 && statusList.length > 0 ? statusList.filter(({ id }) => id === 6)[0].count : response.data.reopened_count
        }
      ]);
      if (response.data.data.length > 0) {
        fetchSurveyAudienceDetails(response.data.data[0].nested_data_id, response.data.data[0].response_id)
      } else {
        setLoader(false);
      }
      //setTotalSize(response.data.total_count);
    }).catch(function (error) {
      setLoader(false);
      toast.error(error.toString());
    });
  }

  const setShareIcon = (cell) => {
    if (cell === "sms") {
      return <i className="mdi mdi-cellphone-message me-1 mr-1"></i>
    } else if (cell === "url" || cell === "long_url") {
      return <i className="mdi mdi-link-variant-plus me-1 mr-1"></i>
    } else if (cell === "qr code") {
      return <i className="mdi mdi-qrcode me-1 mr-1"></i>
    } else if (cell === "social media") {
      return <i className="uil uil-share-alt me-1 mr-1"></i>
    } else if (cell === "api") {
      return <i className="me-1 mr-1 mdi mdi-code-not-equal-variant"></i>
    } else if (cell === "tab_mode") {
      return <i className="me-1 mr-1 mdi mdi-tablet"></i>
    }
  }

  const setRespondantDetails = (cell, row) => {
    return <div onClick={() => {
      fetchSurveyAudienceDetails(row?.nested_data_id, row?.response_id);
    }} id={"respondant_card_" + row?.nested_data_id} className="d-flex justify-content-between align-items-start px-2 py-2 reports-scroll-bg">
      <div className="d-flex">
        <div className="d-flex flex-column">
          <label title={row?.audience_name} className="form-check-label cursor-pointer">{trimString(row?.audience_name, 25)}</label>
          <div className="w-100 overflow-hidden">
            <small className="text-muted">{setShareIcon(row?.channel_type)}{permissionName(row?.channel_type).toUpperCase()}</small>
          </div>
        </div>
      </div>
      <p className="text-muted">{dateFormat(row.created_on ? row.created_on : '')}</p>
    </div>
  }



  const fetchPriorities = () => {
    let URL = process.env.REACT_APP_API_KEY + 'master/getIssuePriorities';
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        setPriorities(response.data.data)
        let arr = [];
        let data = response.data.data;
        for (let i = 0; i < data.length; i++) {
          arr.push({ value: data[i].id, label: permissionName(data[i].priority) })
        }
        setFilterPriorities(arr);
      }
    }).catch(function (error) {
      toast.error(error.toString());
    });
  }

  const fetchStatus = () => {
    let URL = process.env.REACT_APP_API_KEY + 'master/getIssueStatus';
    axios({
      method: "get",
      url: URL,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        setStatus(response.data.data)
        let arr = [];
        let data = response.data.data;
        for (let i = 0; i < data.length; i++) {
          arr.push({ value: data[i].id, label: permissionName(data[i].status) })
        }
        setFilterStatus(arr);
      }
    }).catch(function (error) {
      toast.error(error.toString());
    });
  }

  useEffect(fetchPriorities, []);
  useEffect(fetchStatus, []);

  const onTransactionStatusChange = (audienceId, event) => {
    var statusId = event.target.value;
    if (Number(statusId) > 0) {
      let URL = process.env.REACT_APP_API_KEY + 'issues/update_transaction_issues';
      axios({
        method: "post",
        url: URL,
        headers: {
          Authorization: 'Token ' + TOKEN
        },
        data: {
          audience_id: audienceId,
          status_id: statusId
        }
      }).then(function (response) {
        if (response.data.status === 1) {
          toast.success(response.data.message);

          fetchSurveyAudienceDetails(nestedDataId, responseId)
        }
      }).catch(function (error) {
        toast.error(error.toString());
      });
    }
  }

  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right">
                  <div className="row mr-4">
                    <div className="col-md-12 d-flex text-nowrap mt-3">
                      <DateRangePicker
                        initialSettings={{
                          startDate: start.toDate(),
                          endDate: end.toDate(),
                          ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [
                              moment().subtract(1, 'days').toDate(),
                              moment().subtract(1, 'days').toDate(),
                            ],
                            'Last 7 Days': [
                              moment().subtract(6, 'days').toDate(),
                              moment().toDate(),
                            ],
                            'Last 30 Days': [
                              moment().subtract(29, 'days').toDate(),
                              moment().toDate(),
                            ],
                            'This Month': [
                              moment().startOf('month').toDate(),
                              moment().endOf('month').toDate(),
                            ],
                            'Last Month': [
                              moment().subtract(1, 'month').startOf('month').toDate(),
                              moment().subtract(1, 'month').endOf('month').toDate(),
                            ],
                          },
                        }}
                        onCallback={onDateChanged}>
                        <div id="reportrange" className="form-control">
                          <i className="fa fa-calendar"></i>&nbsp;
                          <span>{label}</span> <i className="fa fa-caret-down"></i>
                        </div>
                      </DateRangePicker>

                      <button type="button" data-toggle="modal" data-target="#right-modal" className="btn btn-outline-primary btn-sm-block ml-2 dropdown-toggle arrow-none"><i className="mdi mdi-tune mr-1"></i>Filter</button>
                    </div>
                  </div>
                </div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mt-3 mb-0 pb-0">
                    <li className="breadcrumb-item"><a>Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">My Issues</li>
                  </ol>
                </nav>
                <div className="page-title-box">
                  <h4 className="page-title">My Issues <span className='text-danger font-18' style={{ background: "yellow", borderRadius: '6px' }}>(This is a beta release, we welcome your feedback)</span></h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="card col-md-4 p-2 responses-table" style={{ maxHeight: '830px' }}>
              <div className="form-group mb-2">
                <form onSubmit={search} className="input-group">
                  <input type="text" id="searchIssues" name="search" className="form-control form-control-white border-light" placeholder="Search" />
                  <div className="input-group-append cursor-pointer">
                    <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                      <i className="mdi mdi-magnify font-20"></i>
                    </button>
                  </div>
                </form>
              </div>

              <GridView
                headerClasses="d-none"
                classes="respondantsGrid"
                gridData={issues}
                columns={[{ dataField: 'first_name', text: 'Respondants', formatter: setRespondantDetails }]}
                totalSize={totalSize}
                sizePerPage={10}
                keyField="id"
                fetchData={(page, size) => fetchMyIssues(page, size)} />
            </div>

            <div className="col-md-8 ml-auto transaction-view">
              {
                issues.length === 0 ?
                  <div className="card  shadow-none">
                    <div className="px-3 py-2">
                      <div className="row">
                        <h6 className="text-center">No Details Found</h6>
                      </div>
                    </div>
                  </div>
                  :
                  <>
                    <div className='d-flex align-items-center justify-content-around mb-2 mx-4'>
                      {statusList && statusList.map(({ status, count, color }) =>
                        <button
                          className={`status-filter-button status-${color.slice(1)} btn d-flex flex-column p-2 ${(selectedFilters.some(({ label }) => statusToValueMapHelpers[label] === status) && buttonClickFilters.length === 0) || buttonClickFilters.some(x => x === statusToNumMapHelpers[status]) ? 'active-status-button' : ''}`}
                          onClick={() => {
                            if (buttonClickFilters.includes(statusToNumMapHelpers[status]) || selectedFilters.some(({ value }) => value === statusToNumMapHelpers[status])) {
                              setButtonClickFilters(buttonClickFilters.filter(x => x !== statusToNumMapHelpers[status]));
                              setFilterStatusValue(filterStatusValue.filter(({ value }) => value !== statusToNumMapHelpers[status]));
                              setSelectedFilters(filterStatusValue.filter(({ value }) => value !== statusToNumMapHelpers[status]));
                            }
                            else {
                              setButtonClickFilters(prevState => [...prevState, ...filterStatusValue.map(({ value }) => value), statusToNumMapHelpers[status]]);
                              setFilterStatusValue(prevState => [...prevState, { value: statusToNumMapHelpers[status], label: statusLabelMapHelpers[status] }]);
                              setSelectedFilters(prevState => [...prevState, { value: statusToNumMapHelpers[status], label: statusLabelMapHelpers[status] }]);
                            }
                          }}
                        >
                          <div className='w-100 text-center' style={{ height: '30%' }}>
                            <h5>{count}</h5>
                          </div>
                          <div className='w-100 pt-1 text-center mt-2' style={{ height: '70%' }}>
                            <h6>{statusMapHelpers[status]}</h6>
                          </div>
                        </button>
                      )}
                    </div>
                    <div className='mt-4 text-primary' style={{ marginLeft: '22px' }}><h3>{surveyResponseInfo.first_name ? surveyResponseInfo.first_name : null}</h3></div>
                    <div>
                      {
                        surveyAudienceDetails.map((objj, indexOut) => {
                          return <>
                            <div className="card  shadow-none">
                              <div className="px-3 py-2">
                                <div className="row">
                                  <div class="d-flex w-100 justify-content-between">
                                    <h4 className='ml-2'>Transaction - {indexOut + 1}</h4>
                                  </div>
                                </div>
                              </div>
                              {objj?.survey_list?.length === 0 ?
                                <h6 className="text-center">No Survey Details Found</h6>
                                :
                                objj?.survey_list?.map((survey_obj, index) => {
                                  return (
                                    <>
                                      <div className="row card px-3 mx-3 bg-home">
                                        <div className="col-md-11">
                                          <div className="row">
                                            <div className="col-md-3">
                                              <h5 className="text-muted font-weight-normal">Survey Name</h5>
                                              <p>{survey_obj?.survey_name}</p>
                                            </div>
                                            <div className="col-md-3">
                                              <h5 className="text-muted font-weight-normal">Share Name</h5>
                                              <p>{survey_obj?.share_name}</p>
                                            </div>
                                            <div className="col-md-3">
                                              <h5 className="text-muted font-weight-normal">Channel</h5>
                                              <p>{permissionName(survey_obj?.channel ? survey_obj?.channel : '')}</p>
                                            </div>
                                            <div className="col-md-3">
                                              <h5 className="text-muted font-weight-normal">Response Date</h5>
                                              <p>{dateFormat(survey_obj?.response_date ? survey_obj?.response_date : '')}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <Tabs>
                                        <TabList>
                                          <Tab>Issues</Tab>
                                          <Tab>Response</Tab>
                                        </TabList>
                                        <TabPanel>
                                          {/* {survey_obj?.issue_list?.map((issue_obj, index) => {
                                                          return(
                                                              <div className="row px-3 py-1">
                                                                  <div className="col-md-12">
                                                                      <MyIssuesGridView gridData={issue_obj}  userInfo={userInfo} totalSize={totalSize} />
                                                                  </div>
                                                              </div>
                                                          ) 
                                                      })}  */}
                                          <div className="row px-3">
                                            <div style={{ float: 'right', paddingBottom: '5px', width: '100%', paddingLeft: '79%' }}>
                                              <select
                                                style={{ minWidth: 170 }}
                                                className="form-control w-auto mr-2"
                                                onChange={(event) => onTransactionStatusChange(objj?.audience_id, event)}
                                                name='status'>
                                                <option value={0}>--Select--</option>
                                                {filterStatus.map((obj) => {
                                                  return <option value={obj.value}>{obj.label}</option>
                                                })}
                                              </select>
                                            </div>
                                            <div className="col-md-12">
                                              <MyIssuesGridView gridData={survey_obj.issue_list} priorities={priorities} status={status} fetchIssues={fetchSurveyAudienceDetails} userInfo={userInfo} totalSize={totalSize} users={assignedTo} />
                                            </div>
                                          </div>
                                        </TabPanel>
                                        <TabPanel>
                                          <div className="col-md-8 my-issue-reponses">
                                            <div className="card mb-2 shadow-none">
                                              <div className="p-3">
                                                <div className="row align-items-center">
                                                  <div className="col-auto">
                                                    <div className="avatar-sm">
                                                      <span className="avatar-title rounded">{surveyResponseInfo.first_name ? surveyResponseInfo.first_name[0] : null}</span>
                                                    </div>
                                                  </div>
                                                  <div className="col pl-0">
                                                    <Link to={surveyAudienceDetails?.nested_data_id === null ? "/audience" : "/audience-details/" + surveyAudienceDetails?.nested_data_id} target="_blank">
                                                      <span className="text-primary font-weight-bold">{surveyResponseInfo.first_name} {surveyResponseInfo.second_name}</span>
                                                    </Link>
                                                    <p className="text-muted mb-0">
                                                      {surveyResponseInfo.phone ?
                                                        <>
                                                          <i className="mdi mdi-phone mr-1"></i>{surveyResponseInfo.phone}
                                                        </>
                                                        : null}
                                                      {surveyResponseInfo.email ?
                                                        <>
                                                          <span className="mx-2">|</span>
                                                          <i className="mdi mdi-email mr-1"></i> {surveyResponseInfo.email}
                                                        </>
                                                        : null}
                                                    </p>
                                                  </div>
                                                  {/* <div className="col-auto">
                                                          <p className="text-muted">
                                                              9d : 17hr : 38min : 8sec
                                                          </p>
                                                      </div> */}
                                                </div>
                                              </div>

                                              <div className="row px-3">
                                                <div className="col-md-12">
                                                  <div className="row">
                                                    <div className="col-md-3">
                                                      <h5 className="text-muted font-weight-normal">Survey Name</h5>
                                                      <p>{surveyResponseInfo?.survey_name}</p>
                                                    </div>
                                                    <div className="col-md-3">
                                                      <h5 className="text-muted font-weight-normal">Share Name</h5>
                                                      <p>{surveyResponseInfo?.share_name}</p>
                                                    </div>
                                                    <div className="col-md-3">
                                                      <h5 className="text-muted font-weight-normal">Channel</h5>
                                                      <p>{permissionName(surveyResponseInfo?.channel_type ? surveyResponseInfo?.channel_type : '')}</p>
                                                    </div>
                                                    <div className="col-md-3">
                                                      <h5 className="text-muted font-weight-normal">Response Date</h5>
                                                      <p>{dateFormat(surveyResponseInfo?.created_on ? surveyResponseInfo?.created_on : '')}</p>
                                                    </div>
                                                  </div>
                                                  {/* <div className="row collapse" id="audience-more-details">
                                                   <div className="col-md-3">
                                                              <h5 className="text-muted font-weight-normal">Started Time</h5>
                                                              <p>{surveyAudienceDetails?.started_time}</p>
                                                          </div> 
                                                   <div className="col-md-3">
                                                      <h5 className="text-muted font-weight-normal">Source</h5>
                                                      <p>{surveyAudienceDetails?.source}</p>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <h5 className="text-muted font-weight-normal">IP Address</h5>
                                                      <p>{surveyAudienceDetails?.ip_address}</p>
                                                  </div>
                                                  <div className="col-md-3">
                                                      <h5 className="text-muted font-weight-normal">Location of Respondent</h5>
                                                      <p>{surveyAudienceDetails?.source}</p>
                                                  </div> 
                                              </div> */}
                                                  <hr />

                                                  <div className="audience-details-content">
                                                    <div className="row collapse" id="response-more-details">
                                                      <div className="col-md-12 mt-2">
                                                        <h4>Response</h4>
                                                        {surveyResponse.length === 0 ? <p>No Response Found</p> :
                                                          surveyResponse.map((obj, i) => {
                                                            return <div className="col-md-12 mt-2">
                                                              <div className="faq-question-q-box font-12">Q.{i + 1}</div>
                                                              <h4 className="faq-question" data-wow-delay=".1s">{obj.question?.default ? obj.question?.default : obj.question}</h4>
                                                              {Array.isArray(obj.answer) ?
                                                                obj.answer.map((obj) => {
                                                                  return <p className="faq-answer mb-1">{obj.default}</p>
                                                                })
                                                                :
                                                                obj.answer === "" ? <><p className="mb-4"></p></> :
                                                                  <p className="faq-answer mb-2">{obj.answer}</p>
                                                              }
                                                            </div>
                                                          })}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* <div className="col-md-1">
                                              <a href="#audience-more-details" type="button" data-toggle="collapse" aria-expanded="true" className="btn btn-sm btn-outline-dark mt-2"><i className="uil uil-angle-down"></i></a>
                                          </div> */}
                                              </div>

                                              <div className="row">
                                                <div className="col-md-12">
                                                  <div className="card px-2">
                                                    {surveyResponse.length === 0 ? <p>No Response Found</p> :
                                                      surveyResponse.map((obj, i) => {
                                                        return <div className="col-md-12 mt-2">
                                                          <div className="faq-question-q-box font-12">Q.{i + 1}</div>
                                                          <h4 className="faq-question" data-wow-delay=".1s">{obj.question?.default ? obj.question?.default : obj.question}</h4>
                                                          {Array.isArray(obj.answer) ?
                                                            obj.answer.map((obj) => {
                                                              return <p className="faq-answer mb-1">{obj.default}</p>
                                                            })
                                                            :
                                                            obj.answer === "" ? <><p className="mb-4"></p></> :
                                                              <p className="faq-answer mb-2">{obj.answer}</p>
                                                          }
                                                        </div>
                                                      })}
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="modal fade" id="right-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-lg modal-right" style={{ minWidth: 400, maxWidth: 600 }}>
                                                  <form onSubmit={gridFilter} id="sidePopupFilterForm" className="modal-content">
                                                    <div className="modal-header">
                                                      <h4 className="modal-title" id="myLargeModalLabel">Filter Survey Responses</h4>
                                                      <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                                                      </button>
                                                    </div>
                                                    <div className="modal-body" style={{ maxWidth: '400px' }}>
                                                      <div className="row">
                                                        <div className="col-md-12 form-group">
                                                          <label>Surveys</label>
                                                          <Select options={survey} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="survey_id" />
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-12 form-group">
                                                          <label>Segments</label>
                                                          <Select options={segments} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="segments" />
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-12 form-group">
                                                          <label>Tags</label>
                                                          <Select options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="tags" />
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-12 form-group">
                                                          <label>Shares</label>
                                                          <Select options={shares} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="share_id" />
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-md-12 form-group">
                                                          <label>Channels</label>
                                                          <Select options={channels} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="channel_type" />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="modal-footer align-footer-bottom">
                                                      <button type="button" id="closeFilterModal" className="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                                                      <button type="submit" className="btn btn-primary">Apply Filters</button>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </TabPanel>
                                      </Tabs>
                                    </>
                                  )

                                })}
                              {/* <div className="row px-3 py-1">
                                                  <div className="col-md-12">
                                                      <MyIssuesGridView gridData={surveyAudienceDetails} fetchIssues={fetchSurveyAudienceDetails} userInfo={userInfo} totalSize={totalSize} />
                                                  </div>
                                                  </div> */}

                            </div>
                          </>
                        })
                      }
                    </div>
                  </>
              }
              <div className="modal fade" id="right-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-right" style={{ minWidth: 400, maxWidth: 600 }}>
                  <form onSubmit={gridFilter} id="sidePopupFilterForm" className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myLargeModalLabel">Filter Survey Responses</h4>
                      <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                      </button>
                    </div>
                    <div className="modal-body" style={{ maxWidth: '400px' }}>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label>Assigned To</label>
                          <Select options={assignedTo} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="assigned_list" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label>Priority</label>
                          <Select options={filterPriorities} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="priortiy" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label>Status</label>
                          <Select
                            options={filterStatus}
                            value={filterStatusValue}
                            onChange={(value) => { setFilterStatusValue(value); }}
                            placeholder="--Select--"
                            isMulti={true}
                            closeMenuOnSelect={false}
                            name="status" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label>Surveys</label>
                          <Select options={survey} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="survey_id" />
                        </div>
                      </div>
                      {/* <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Segments</label>
                                                        <Select options={segments} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="segments" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Tags</label>
                                                        <Select options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="tags" />
                                                    </div>
                                                </div> */}
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label>Shares</label>
                          <Select options={shares} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="share_id" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label>Channels</label>
                          <Select options={channels} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="channel_type" />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer align-footer-bottom">
                      <button type="button" id="closeFilterModal" className="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary">Apply Filters</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loader ? <Loader /> : null}
      </div>
    </div >
  );
}

export default MyIssues;


