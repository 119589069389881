import { Link } from "react-router-dom";

const PageNotFound = () => {

    return (
        <div className="content-page m-0">
            <div className="content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-4">
                            <div className="text-center">
                                <img src="/assets/images/file-searching.svg" height="90" alt="File not found Image" />
                                <h1 className="text-error mt-4">404</h1>
                                <h4 className="text-uppercase text-danger mt-3">Page Not Found</h4>
                                <p className="text-muted mt-3">It's looking like you may have taken a wrong turn. Don't worry... it
                                    happens to the best of us. Here's a
                                    little tip that might help you get back on track.</p>
                                <Link className="btn btn-primary mt-3" to="/"><i className="mdi mdi-reply"></i> Return Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;