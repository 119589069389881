import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import { dateFormat } from "../../../../Utils/date_formatting";

const TeamsGridView = (props) => {

    const setActionButtons = (cell, row) => {
        return (
            <div className="dropdown float-right">
                <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical text-secondary font-20"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                    <Link className="dropdown-item notify-item" to={{ pathname: '/edit-team', state: { teamDetails: row } }}><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></Link>
                    <Link className="dropdown-item notify-item" onClick={() => props.setDeleteUrl('team/' + row.id)} data-toggle="modal" data-target="#deleteModal"><i className="mdi mdi-delete mr-1"></i><span>Delete</span></Link>
                </div>
            </div>
        );
    }

    const viewTeamBtn = (cell, row) => {
        return (
            <Link className="mdi mdi-text-search text-secondary cursor-pointer font-20" to={{ pathname: '/view-team', state: { teamDetails: row } }}></Link>
        )
    }

    const columns = [{
        dataField: 'team_name',
        text: 'Team Name'
    }, {
        dataField: 'members_count',
        text: 'Members'
    }, {
        dataField: 'modified_on',
        text: 'Modified On',
        formatter: dateFormat
    }, {
        dataField: 'id',
        classes: 'py-0 text-center',
        text: 'View',
        headerStyle: { textAlign: 'center' },
        formatter: viewTeamBtn
    }, {
        dataField: 'id',
        classes: 'py-0',
        formatter: setActionButtons
    }];

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <BootstrapTable
            keyField='id'
            data={props.gridData}
            bootstrap4
            bordered={false}
            hover
            columns={columns}
            pagination={paginationFactory(options)}
            noDataIndication="No record found" />
    );
}

export default TeamsGridView;