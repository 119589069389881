import DeleteModal from "../../../../Common/DeleteModal";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AddCrmModal from "./AddCrmModal";
import CrmGridView from "./CrmGridView";
import ViewCrmModal from "./ViewCrmModal";
import EditCrmModal from "./EditCrmModal";
import { Link } from "react-router-dom";
import Loader from "../../../../Common/Loader";

const CrmIntegration = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [crmAvailed, setCrmAvailed] = useState([]);
    const [crmData, setCrmData] = useState([]);
    const [deleteUrl, setDeleteUrl] = useState([]);
    const [loader, setLoader] = useState(false);

    const fetchCrmAvailed = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/getTabModeListing';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setCrmAvailed(response.data.data);
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    useEffect(fetchCrmAvailed, []);

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3 mb-0 pb-0">
                                    <li className="breadcrumb-item"><Link>Settings</Link></li>
                                    <li className="breadcrumb-item"><a>Integrations</a></li>
                                    <li className="breadcrumb-item"><Link to="/channel-integration">App</Link></li>
                                    <li className="breadcrumb-item active">CRM</li>
                                </ol>
                            </nav>
                            <button type="button" data-toggle="modal" data-target="#addCrmModal" className="btn btn-primary btn-sm-block float-right">Add CRM</button>
                            <div className="page-title-box">
                                <h4 className="page-title"><Link to="/channel-integration"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>CRM Service Provider</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3 className="text-secondary m-3">Your CRM List</h3>
                                        <CrmGridView gridData={crmAvailed} setCrmData={setCrmData} setDeleteUrl={setDeleteUrl} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AddCrmModal gridData={crmAvailed} fetchCrmAvailed={fetchCrmAvailed} />
                    <EditCrmModal crmData={crmData} fetchCrmAvailed={fetchCrmAvailed} />
                    <ViewCrmModal crmData={crmData} />
                    <DeleteModal deleteUrl={deleteUrl} reloadGrid={fetchCrmAvailed} />
                    {loader ? <Loader /> : null}
                </div>
            </div>
        </div>
    );
}

export default CrmIntegration;