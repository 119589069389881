import React, { useEffect, useState } from "react";
import "survey-analytics/survey.analytics.css";
import "tabulator-tables/dist/css/tabulator.css";
import "survey-analytics/survey.analytics.tabulator.css";
import SurveyResponses from "../Common/SurveyResponses";
import { Link } from "react-router-dom";

const SurveyResponse = (props) => {

    const [surveyId, setSurveyId] = useState("");
    const [surveyName, setSurveyName] = useState("");
    const [surveyJson, setSurveyJson] = useState("");
    const [shareInfo, setShareInfo] = useState("");
    const [tableView, setTableView] = useState(false);
    const [responseCount, setResponseCount] = useState(0);

    useEffect(() => {
        setSurveyJson(JSON.parse(props.location.state.surveyInfo.json));
        setSurveyId(props.location.state.surveyInfo.id);
        setSurveyName(props.location.state.surveyInfo.name);
        setShareInfo(props.location.state.shareInfo);
    }, [props.location.state])

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item"><Link to="/surveys">Surveys</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Response</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box d-flex justify-content-between">
                                    <h4 className="page-title"><Link to="/surveys"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>{surveyName} ({responseCount})</h4>
                                    <input type="checkbox" checked={tableView} onChange={() => setTableView(!tableView)} id="switch12" data-switch="primary" />
                                    <label htmlFor="switch12" className="mr-1 border p-2" style={{ alignSelf: "center" }} data-on-label="Table" data-off-label="Chart"></label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mt-4">
                            {surveyId === "" ? null :
                                <SurveyResponses id={surveyId} surveyJson={surveyJson} responseCount={setResponseCount} tableView={tableView} shareInfo={shareInfo} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SurveyResponse;
