import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const SheetDetailsModal = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const history = useHistory();

    const [loader, setLoader] = useState(false);

    const getGoogleSheetsData = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'audience/getAudienceSheetData';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then((response) => {
            setLoader(false);
            if (response.data.status === 1) {
                document.getElementById('closeGoogleSheetModal').click();
                toast.success(response.data.message);
                history.push({
                    pathname: '/import-from-google-sheets',
                    state: { googleSheetsData: response.data.data, googleSheetsInfo: { sheet_id: formData.get('sheet_id'), tab_name: formData.get('tab_name') } }
                })
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch((error) => {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    return (
        <div className="modal fade text-left" id="sheetDetailsModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <form onSubmit={getGoogleSheetsData} className="modal-content ">
                    <div className="modal-header">
                        <h4 className="modal-title" id="staticBackdropLabel">Google Sheets</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group">
                            <label>Google Sheet ID<span className="text-danger">*</span></label>
                            <input type="text" name="sheet_id" className="form-control" required/>
                        </div>
                        <div className="form-group">
                            <label>Tab Name<span className="text-danger">*</span></label>
                            <input type="text" name="tab_name" className="form-control" required/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" id="closeGoogleSheetModal" data-dismiss="modal">Cancel</button>
                        <button className="btn btn-primary" type="submit" disabled={loader}>{loader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null}Continue</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SheetDetailsModal;