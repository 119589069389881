import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const AddAudienceModal = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [addField, setAddField] = useState(false);
    const [addFieldUI, setAddFieldUI] = useState([]);

    const addNewAudience = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let obj = {};
        for (var pair of formData.entries()) {
            obj = Object.assign(obj, { [pair[0]]: pair[1] });
        }
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataManual';
        axios({
            method: "post",
            url: URL,
            data: obj,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeAddAudienceModal').click();
                document.getElementById('addAudienceModalForm').reset();
                props.fetchAudience();
                setAddFieldUI([]);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const addNewField = () => {

        var newFieldName = document.getElementById('new_field_name').value;

        const formData = new FormData();
        formData.append('name', newFieldName);
        let URL = process.env.REACT_APP_API_KEY + 'audience/secondaryFields';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then((response) => {
            if (response.data.status === 1) {

                setAddFieldUI(addFieldUI => [...addFieldUI, <div className="row">
                    <div className="col-md-12 form-group">
                        <label>{newFieldName}</label>
                        <input type="text" name={newFieldName} required={true} className="form-control" />
                    </div>
                </div>]);

                document.getElementById('new_field_name').value = null;
                
                setAddField(false);

            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }


    return (
        <div className="modal fade" id="addAudienceModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-right">
                <form onSubmit={addNewAudience} id="addAudienceModalForm" className="modal-content">
                    {addField ?
                        <>
                            <div className="modal-header">
                                <h4 className="modal-title" id="myLargeModalLabel">Add New Field</h4>
                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Field Name<span className="text-danger">*</span></label>
                                        <input type="text" id="new_field_name" required={true} className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer px-5">
                                <button type="button" onClick={() => setAddField(false)} className="btn btn-outline-secondary">CANCEL</button>
                                <button type="button" onClick={() => addNewField()} className="btn btn-primary">ADD</button>
                            </div>
                        </>
                        :
                        <>
                            <div className="modal-header">
                                <h4 className="modal-title" id="myLargeModalLabel">Add Audience</h4>
                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>First Name<span className="text-danger">*</span></label>
                                        <input type="text" name="first_name" required={true} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Last Name<span className="text-danger">*</span></label>
                                        <input type="text" name="last_name" required={true} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Email<span className="text-danger">*</span></label>
                                        <input type="email" name="email" required={true} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Phone<span className="text-danger">*</span></label>
                                        <input type="text" name="phone" required={true} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 form-group">
                                        <label>Gender<span className="text-danger">*</span></label>
                                        <select name="gender" required={true} className="form-control">
                                            <option value="0">--Select--</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div className="col-md-7 form-group">
                                        <label>DOB<span className="text-danger">*</span></label>
                                        <input type="date" name="dob" required={true} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Address<span className="text-danger">*</span></label>
                                        <input type="text" name="address" required={true} className="form-control" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>City<span className="text-danger">*</span></label>
                                        <input type="text" name="city" required={true} className="form-control" />
                                    </div>
                                </div>
                                {addFieldUI}
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <p className="text-muted float-right text-right m-0" data-toggle="modal" data-target="#createTagModal">
                                            <small>
                                                <Link onClick={() => setAddField(true)} className="text-primary">Add new field</Link>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer px-5">
                                <button type="button" id="closeAddAudienceModal" className="btn btn-outline-secondary" data-dismiss="modal">CANCEL</button>
                                <button type="submit" className="btn btn-primary">CREATE</button>
                            </div>
                        </>
                    }
                </form>
            </div>
        </div>
    );
}

export default AddAudienceModal;