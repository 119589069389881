import { useEffect, useState } from 'react';
import GoogleFontsDropDown from '../../../Common/GoogleFontsDropDown';
import SurveyViewer from './SurveyViewer';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import Loader from "../../../Common/Loader";

const SurveyDesigner = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    const history = useHistory();

    const [surveyQuestionsJson, setSurveyQuestionsJson] = useState("");
    const [surveyDetails, setSurveyDetails] = useState({});
    const [questionColor, setQuestionColor] = useState();
    const [answerColor, setAnswerColor] = useState();
    const [backgroundColor, setBackgroundColor] = useState();
    const [buttonColor, setButtonColor] = useState();
    const [fontFamily, setFontFamily] = useState();
    const [formFace, setFormFace] = useState();
    const [scrollView, setScrollView] = useState();
    const [backgroungImageName, setBackgroungImageName] = useState();
    const [backgroungImage, setBackgroungImage] = useState(null);
    const [customThemesUI, setCustomThemesUI] = useState();
    const [adminThemesUI, setAdminThemesUI] = useState();
    //const [themeName, setThemeName] = useState("");
    const [showBranding, setShowBranding] = useState(true);
    const [surveyName, setSurveyName] = useState("");
    //const [recurring, setRecurring] = useState(false);
    const [loader, setLoader] = useState(true);

    setTimeout(() => {
        setLoader(false);
    }, 1000)

    useEffect(() => {
        if (props.location.state) {
            setSurveyQuestionsJson(props.location.state.surveyInfo.json);
            setSurveyDetails(props.location.state.surveyInfo);
            setQuestionColor(props.location.state.surveyInfo.theme_question_color);
            setAnswerColor(props.location.state.surveyInfo.theme_answer_color);
            setBackgroundColor(props.location.state.surveyInfo.theme_background_color);
            setButtonColor(props.location.state.surveyInfo.theme_button_color);
            setFontFamily(props.location.state.surveyInfo.theme_font);
            setFormFace(props.location.state.surveyInfo.survey_type);
            setScrollView(props.location.state.surveyInfo.survey_direction);
            setShowBranding(props.location.state.surveyInfo.branding_visible);
            //setRecurring(props.location.state.surveyInfo.is_recurring);
            setSurveyName(props.location.state.surveyInfo.name)
            document.getElementById('googleFontsDropDown').value = props.location.state.surveyInfo.theme_font;
            document.getElementById('formFace').value = props.location.state.surveyInfo.survey_type;
            //document.getElementById('scrollDirection').value = props.location.state.surveyInfo.survey_direction;
            if (props.location.state.surveyInfo.theme_background_image) {
                $(".content-page, .designer-container").css("background-image", 'url(' + process.env.REACT_APP_API_MEDIA_KEY + props.location.state.surveyInfo.theme_background_image + ')');
                $(".content-page, .designer-container").css("background-size", "cover");
            }
        } else {
            history.push('/surveys');
        }
    }, [props.location.state]);

    const backgroundColorChange = (color) => {
        setBackgroundColor(color);
        $(".content-page, .designer-container").css("background-image", 'url("")');
    }

    const setBackgroundImageAndName = (event) => {
        setBackgroungImageName(event.target.files[0] ? event.target.files[0].name : '');
        setBackgroungImage(event.target.files[0] ? event.target.files[0] : null);
        var getImagePath = URL.createObjectURL(event.target.files[0]);
        $(".content-page, .designer-container").css("background-image", 'url(' + getImagePath + ')');
        $(".content-page, .designer-container").css("background-size", "cover");
        $(".designer-container, .sv_body, .sv_row").css("background-color", '#ffffff00');
        $(".sv_header").css("background-color", '#ffffff00');
        $(".sv_main").css("background-color", '#ffffff00');
        $(".sv_body").css("background-color", '#ffffff00');

        setBackgroundColor('#ffffff00');
    }

    const resetBackround = () => {
        $(".designer-container, .sv_body, .sv_row").css("background-color", '#ffffff');
        $(".sv_header").css("background-color", '#ffffff');
        $(".sv_main").css("background-color", '#ffffff');
        setBackgroundColor('#ffffff');
        setBackgroungImageName('');
        setBackgroungImage(null);
    }

    const setQuestionsOnPageMode = (type) => {
        let qJson = JSON.parse(props.location.state.surveyInfo.json);
        if (type === "classic") {//classic view selected
            delete qJson["questionsOnPageMode"];
        } else if (type === "scroll") {//scroll view selected
            qJson["questionsOnPageMode"] = "questionPerPage";
        }
        setSurveyQuestionsJson(JSON.stringify(qJson))
    }

    useEffect(() => {
        let url = "https://fonts.googleapis.com/css2?family=";
        let font = "Noto Sans JP";
        url += font.replaceAll(" ", "+");
        url += "&display=swap";
        let link = document.createElement('link')
        link.href = url;
        link.rel = "stylesheet";
        link.type = "text/css";
        document.head.appendChild(link);
    }, []);

    const onFontFamilyChange = (fontName) => {
        setFontFamily(fontName);
        let url = "https://fonts.googleapis.com/css2?family=";
        url += fontName.replaceAll(" ", "+");
        url += "&display=swap";
        let link = document.createElement('link')
        link.href = url;
        link.rel = "stylesheet";
        link.type = "text/css";
        document.head.appendChild(link);
    }

    const updateMySurvey = () => {
        let URL = process.env.REACT_APP_API_KEY + 'survey/save_survey';
        const formData = new FormData();
        formData.append('name', document.getElementById('survey_name').value);
        formData.append('json', surveyQuestionsJson);
        formData.append('survey_id', surveyDetails.id);
        formData.append('status', 'designed');
        formData.append('theme_question_color', questionColor);
        formData.append('theme_answer_color', answerColor);
        formData.append('theme_background_color', backgroundColor);
        formData.append('theme_button_color', buttonColor);
        formData.append('theme_survey_header', false);
        formData.append('theme_progress_bar', false);
        formData.append('theme_show_question_number', false);
        formData.append('theme_font', fontFamily);
        formData.append('survey_direction', scrollView);
        formData.append('survey_type', formFace);
        formData.append('branding_visible', showBranding);
        //formData.append('is_recurring', recurring);
        formData.append('theme_background_image', backgroungImage);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setSurveyDetails(response.data.data)
                toast.success(response.data.message);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchAdminThemes = () => {
        let URL = process.env.REACT_APP_API_KEY + 'survey/adminThemes';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            renderThemesUI('admin', response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchCustomThemes = () => {
        let URL = process.env.REACT_APP_API_KEY + 'survey/customThemes';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            renderThemesUI('custom', response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchAdminThemes, []);
    useEffect(fetchCustomThemes, []);

    const renderThemesUI = (type, data) => {
        let arr = [];
        let theme_font_url = "https://fonts.googleapis.com/css2?family=";
        for (let i = 0; i < data.length; i++) {
            if (i === 0) {
                theme_font_url += data[i].font;
            } else {
                theme_font_url += "&family=" + data[i].font;
            }
            arr.push(<><div className="col-md-12" style={{ fontFamily: data[i].font }}>
                <div className="card mb-md-0 my-2 p-3 mr-1 border" style={{ backgroundImage: `url(${process.env.REACT_APP_API_MEDIA_KEY}${data[i].background_image})`, backgroundColor: data[i].background_color }}>
                    <h5 className="card-title mb-0" style={{ color: data[i].question_color }}>This is a sample question?</h5>
                    <p className="mb-1" style={{ color: data[i].answer_color }}>This is a sample answer for the above question.</p>
                    <div className="text-center">
                        {/* <button type="button" onClick={() => applyTheme(data[i])} className="btn btn-sm ml-2" style={{ backgroundColor: 'transparent', color: data[i].button_color, border: '1px solid' + data[i].button_color }}>Previous</button> */}
                        <button type="button" onClick={() => applyTheme(data[i])} className="btn btn-sm ml-2 float-right" style={{ backgroundColor: data[i].button_color, color: 'white' }}>Apply</button>
                    </div>
                </div>
                <h4 className="card-title mt-1 font-14" style={{ fontFamily: 'sans-serif', fontWeight: '500' }} >{data[i].name}</h4>
            </div>
                <hr /></>);
        }
        if (type === 'admin') {
            setAdminThemesUI(arr);
        } else if (type === 'custom') {
            setCustomThemesUI(arr);
        }

        theme_font_url = theme_font_url.replaceAll(" ", "+");
        theme_font_url += "&display=swap";
        let link = document.createElement('link')
        link.href = theme_font_url;
        link.rel = "stylesheet";
        link.type = "text/css";
        document.head.appendChild(link);
    }

    const applyTheme = (data) => {

        if (data.background_image) {
            //do nothing
        } else {
            resetBackround()
            $(".content-page, .designer-container").css("background-image", 'url("")');
        }

        $(".sv_container").css("font-family", data.font);
        $(".sv_main.sv_default_css .sv_container").css("color", data.question_color);
        $(".sv_prev_btn").css("border", "1px solid " + data.button_color);
        $(".sv_prev_btn").css("color", data.button_color);
        document.documentElement.style.setProperty('--custom-btn-primary-color', data.button_color);
        $(".sv_complete_btn").css("background-color", data.button_color);
        $(".sv_q_rating_item_text").css("border-color",);
        $(".sv_main .sv_q_rating_item .sv_q_rating_item_text").css("border", "1px solid " + data.button_color);
        $(".designer-container, .sv_body, .sv_row").css("background-color", data.background_color);
        $(".sv_header").css("background-color", data.background_color);
        document.documentElement.style.setProperty('--custom-color', data.button_color);
        document.documentElement.style.setProperty('--custom-shadow', (data.button_color) + '0F');

        setQuestionColor(data.question_color);
        setAnswerColor(data.answer_color);
        setBackgroundColor(data.background_color);
        setButtonColor(data.button_color);

        setQuestionsOnPageMode(data.survey_type)

        document.getElementById('theme_name').value = data.name;

        setFormFace(data.survey_type);
        document.getElementById('formFace').value = data.survey_type;

        setScrollView(data.survey_direction);
        document.getElementById('scrollDirection').value = data.survey_direction;

        setFontFamily(data.font);
        document.getElementById('googleFontsDropDown').value = data.font;

    }

    const saveCustomTheme = () => {
        let URL = process.env.REACT_APP_API_KEY + 'survey/save_survey_theme';
        const formData = new FormData();
        let themeName = document.getElementById('theme_name').value;
        if (themeName === "") {
            toast.error('Enter theme name');
        } else {
            formData.append('name', themeName);
            formData.append('question_color', questionColor);
            formData.append('answer_color', answerColor);
            formData.append('background_color', backgroundColor);
            formData.append('button_color', buttonColor);
            formData.append('survey_header', false);
            formData.append('progress_bar', false);
            formData.append('show_question_number', false);
            formData.append('font', fontFamily);
            formData.append('survey_direction', scrollView);
            formData.append('survey_type', formFace);
            if (backgroungImage) {
                formData.append('background_image', backgroungImage);
            }
            axios({
                method: "post",
                url: URL,
                data: formData,
                headers: {
                    Authorization: 'Token ' + TOKEN
                }
            }).then(function (response) {
                if (response.data.status === 1) {
                    fetchCustomThemes();
                    toast.success(response.data.message);
                } else if (response.data.status === 0) {
                    toast.error(response.data.message);
                }
            }).catch(function (error) {
                toast.error(error.toString());
            });
        }
    }

    const updateSurveyName = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyDetails.id);
        let URL = process.env.REACT_APP_API_KEY + 'survey/surveyNameEdit';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setSurveyDetails(response.data.data);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div className="content">
            <div className="survey-top-bar">
                <div className="row border-bottom" style={{ height: '70px' }}>
                    <div className="col-md-4 d-flex" style={{ alignItems: 'center' }}>
                        <Link to="/"><span className="logo-lg mx-2"><img src={logo ? logo : "/assets/images/logo.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '60px', paddingTop: '4px', paddingBottom: '4px' }} /></span></Link>
                        <Link to="/surveys" className=""><i className="dripicons-arrow-thin-left font-24 mr-1 text-dark"></i></Link>
                        <div className="form-group m-0 mr-2">
                            <form onSubmit={updateSurveyName} className="input-group survey-top-bar-input">
                                <input type="text" name="name" id="survey_name" defaultValue={surveyName} className="form-control form-control-white border-light survey-input" placeholder="Survey Name" />
                                <div className="input-group-append cursor-pointer">
                                    <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                        <i className="mdi mdi-content-save font-20"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        {/* <input className="form-control survey-input pt-1" defaultValue={surveyName} type="text" id="survey_name" /> */}
                    </div>
                    <div className="col-md-8 d-flex justify-content-between">
                        <ul className="nav nav-tabs nav-bordered justify-content-center" style={{ alignItems: 'end' }}>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-content', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-1-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Content</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="true" className="nav-link active">
                                    <i className="mdi text-primary mdi-numeric-2-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Design</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-3-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Workflow</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-4-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Share</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-results', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-5-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Results</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="mr-2 align-self-center">
                            <a href={surveyDetails.preview_url} target="_blank" type="button" style={{ height: 'fit-content' }} className="btn btn-outline-primary btn-sm ml-2 mr-1">Preview</a>
                            <button onClick={updateMySurvey} type="button" style={{ height: 'fit-content' }} className="btn btn-primary btn-sm ml-2 mr-1">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{ marginTop: '4.3rem' }}>
                    <div className="tab-content">
                        <div className="tab-pane show active">
                            <div className="row pl-2 pr-2" style={{ backgroundColor: '#ffffff' }}>
                                <div className="col-md-3 designer-nav-main">
                                    <div className="row">
                                        <div className="col-md-12 designer-nav">
                                            <button className="button-menu-mobile open-left disable-btn" data-toggle="collapse" data-target="#designer-content"><i className="mdi mdi-menu"></i></button>
                                            <ul className="nav nav-tabs nav-justified nav-bordered">
                                                <li className="nav-item">
                                                    <a href="#customize" data-toggle="tab" aria-expanded="false" className="nav-link active">
                                                        <span className="d-md-block">Customize</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#themes" data-toggle="tab" aria-expanded="true" className="nav-link">
                                                        <span className="d-md-block">Themes</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div id="designer-content" className="collapse mt-2 show">
                                        <div className="tab-content align-self-center" style={{ maxHeight: 'calc(82vh - 0px)', paddingTop: '1rem', overflow: 'auto', overflowX: 'hidden' }}>
                                            <div className="tab-pane active show px-1" id="customize"  >
                                                <div className="row mb-2">
                                                    <div className="col-md-12">
                                                        <label>Theme Name<span className="text-danger">*</span></label>
                                                        {/* <input className="form-control" value={themeName} onChange={(event) => setThemeName(event.target.value)} type="text" required={true} placeholder="Enter name of theme" name="survey_name" id="survey_name" /> */}
                                                        <input className="form-control" type="text" required={true} placeholder="Enter name of theme" id="theme_name" />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-md-12" >
                                                        <label>Form Face</label>
                                                        <select className="form-control" name="form_face" value={formFace} onChange={(event) => { setFormFace(event.target.value); setQuestionsOnPageMode(event.target.value) }} id="formFace">
                                                            <option value="classic">Classic</option>
                                                            <option value="scroll">Scroll</option>
                                                            {/* <option value={3}>Chat View</option> */}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className={formFace === "scroll" ? "row mb-3 d-block" : "row mb-3 d-none"} >
                                                    <div className="col-md-12" >
                                                        <label>Scroll Direction</label>
                                                        <select className="form-control" name="scroll_direction" value={scrollView} onChange={(event) => setScrollView(event.target.value)} id="scrollDirection">
                                                            <option value="horizontal">Horizontal</option>
                                                            <option value="vertical">Vertical</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="row text-center d-flex flex-row">
                                                    <div className="col-3">
                                                        <div className="form-group">
                                                            <input className="form-control color-picker-circular color-picker-circular-designer m-auto" type="color" value={questionColor} name="primary_color" onChange={(event) => setQuestionColor(event.target.value)} />
                                                            <p className="colorPickerLabel">Question</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 ">
                                                        <div className="form-group">
                                                            <input className="form-control color-picker-circular color-picker-circular-designer m-auto" type="color" value={answerColor} name="primary_color" onChange={(event) => setAnswerColor(event.target.value)} />
                                                            <p className="colorPickerLabel">Answer</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="form-group">
                                                            <input className="form-control color-picker-circular color-picker-circular-designer m-auto" type="color" value={backgroundColor} name="primary_color" onChange={(event) => backgroundColorChange(event.target.value)} />
                                                            <p className="colorPickerLabel">Background</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="form-group">
                                                            <input className="form-control color-picker-circular color-picker-circular-designer m-auto" type="color" value={buttonColor} name="primary_color" onChange={(event) => setButtonColor(event.target.value)} />
                                                            <p className="colorPickerLabel">Button</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-12">
                                                        <label>Font Family</label>
                                                        <GoogleFontsDropDown onchange={(event) => onFontFamilyChange(event.target.value)} id="googleFontsDropDown" defaultSelected={props.location.state.surveyInfo.theme_font} />
                                                    </div>
                                                </div>
                                                {/* <div className="row mb-2">
                                                    <div className="col-md-12" style={{ alignSelf: 'center' }}>
                                                        <label>Advance Background Setting</label>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row mt-2">
                                                            <div className="col-md-4">
                                                                <div className="custom-control custom-radio ">
                                                                    <input type="radio" id="customRadioImage" name="customRadioBackground" className="custom-control-input" />
                                                                    <label className="custom-control-label" for="customRadioImage">Image</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="custom-control custom-radio ">
                                                                    <input type="radio" id="customRadioGradient" name="customRadioBackground" className="custom-control-input" />
                                                                    <label className="custom-control-label" for="customRadioGradient">Gradient</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row mb-2">
                                                    <div className="col-md-12">
                                                        <label>Upload Image</label>
                                                        <div className="d-flex">
                                                            <div className="custom-file">
                                                                <input type="file" onChange={setBackgroundImageAndName} className="custom-file-input" id="inputGroupFile04" />
                                                                <label className="custom-file-label" for="inputGroupFile04">{backgroungImageName}</label>
                                                            </div>
                                                            {backgroungImageName ?
                                                                <button type="button" onClick={() => { setBackgroungImageName(null); setBackgroungImage(null); backgroundColorChange("#f7fafc"); }} className="btn btn-light closeBtn"><i className="mdi mdi-close"></i> </button>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-md-7" style={{ alignSelf: 'center' }}>
                                                        <label>Survey Header</label>
                                                    </div>
                                                    <div className="col-md-5 d-flex justify-content-end">
                                                        <input type="checkbox" id="survey-header-switch" data-switch="none" />
                                                        <label for="survey-header-switch" data-on-label="" data-off-label=""></label>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-7" style={{ alignSelf: 'center' }}>
                                                        <label>Progress Bar</label>
                                                    </div>
                                                    <div className="col-md-5 d-flex justify-content-end">
                                                        <input type="checkbox" id="progress-bar-switch" data-switch="none" />
                                                        <label for="progress-bar-switch" data-on-label="" data-off-label=""></label>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-7" style={{ alignSelf: 'center' }}>
                                                        <label>Show Question Number</label>
                                                    </div>
                                                    <div className="col-md-5 d-flex justify-content-end">
                                                        <input type="checkbox" id="show-qstn-no-switch" data-switch="none" />
                                                        <label for="show-qstn-no-switch" data-on-label="" data-off-label=""></label>
                                                    </div>
                                                </div>
                                                <hr /> */}
                                                {/* <div className="row">
                                                    <div className="col-md-12" style={{ alignSelf: 'center' }}>
                                                        <label>Button Style</label>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row mt-2">
                                                            <div className="col-md-4">
                                                                <div className="custom-control custom-radio ">
                                                                    <input type="radio" id="customRadioFilled" name="customRadioButton" className="custom-control-input" />
                                                                    <label className="custom-control-label" for="customRadioFilled">Filled</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="custom-control custom-radio ">
                                                                    <input type="radio" id="customRadioBorder" name="customRadioButton" className="custom-control-input" />
                                                                    <label className="custom-control-label" for="customRadioBorder">Border</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4"></div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row mb-2">
                                                    <div className="col-md-7" style={{ alignSelf: 'center' }}>
                                                        <label>Branding</label>
                                                    </div>
                                                    <div className="col-md-5 d-flex justify-content-end">
                                                        <input type="checkbox" id="progress-bar-switch" data-switch="none" checked={showBranding} onChange={() => setShowBranding(!showBranding)} />
                                                        <label for="progress-bar-switch" data-on-label="" data-off-label=""></label>
                                                    </div>
                                                </div>
                                                {/* <div className="row mb-2">
                                                    <div className="col-md-7" style={{ alignSelf: 'center' }}>
                                                        <label>Recurring</label>
                                                    </div>
                                                    <div className="col-md-5 d-flex justify-content-end">
                                                        <input type="checkbox" id="recurring_switch" data-switch="none" checked={recurring} onChange={() => setRecurring(!recurring)} />
                                                        <label for="recurring_switch" data-on-label="" data-off-label=""></label>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-md-12 mb-2">
                                                        <button type="button" onClick={saveCustomTheme} style={{ height: 'fit-content' }} className="btn btn-primary btn-sm float-right">Save Theme</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane" id="themes">
                                                <div className="row justify-content-center">
                                                    <div className="row">
                                                        <div className="col-md-12 designer-nav">
                                                            <button className="button-menu-mobile open-left disable-btn" data-toggle="collapse" data-target="#designer-content"><i className="mdi mdi-menu"></i></button>
                                                            <ul className="nav nav-tabs nav-justified nav-bordered">
                                                                <li className="nav-item">
                                                                    <a href="#predefined" data-toggle="tab" aria-expanded="true" className="nav-link active">
                                                                        <span className="d-md-block">Predefined</span>
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a href="#custom" data-toggle="tab" aria-expanded="false" className="nav-link">
                                                                        <span className="d-md-block">Custom</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div id="designer-content" className="collapse mt-2 show">
                                                        <div className="tab-content align-self-center" style={{ maxHeight: 'calc(82vh - 0px)', paddingTop: '1rem', overflow: 'auto', overflowX: 'hidden' }}>
                                                            <div className="tab-pane show active" id="predefined">
                                                                {adminThemesUI}
                                                            </div>
                                                            <div className="tab-pane" id="custom">
                                                                {customThemesUI}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 p-0 ml-auto">
                                    <SurveyViewer
                                        surveyDetails={surveyDetails}
                                        surveyQuestionsJson={surveyQuestionsJson}
                                        questionColor={questionColor}
                                        answerColor={answerColor}
                                        backgroundColor={backgroundColor}
                                        buttonColor={buttonColor}
                                        fontFamily={fontFamily}
                                        formFace={formFace}
                                        scrollView={scrollView}
                                        mg={0} />
                                    {showBranding ?
                                        surveyDetails.branding_type === "custom" ?
                                            <img src={process.env.REACT_APP_API_MEDIA_KEY + surveyDetails.branding_file} alt="image" className="img-fluid img-thumbnail float-right mr-2" width="130" style={{ padding: '0.35rem', backgroundColor: '#ffffff8a', border: 'none', position: 'fixed', right: '1rem', bottom: '1rem' }} />
                                            : surveyDetails.branding_type === "default" ?
                                                <img src="/assets/images/logo.png" alt="image" className="img-fluid img-thumbnail float-right mr-2" width="130" style={{ padding: '0.35rem', backgroundColor: '#ffffff8a', border: 'none', position: 'fixed', right: '1rem', bottom: '1rem' }} />
                                                : surveyDetails.branding_type === "" ? null
                                                    : null
                                        : null}
                                </div>
                                {loader ? <Loader /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SurveyDesigner;
