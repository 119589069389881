import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link, useHistory } from "react-router-dom";
import Loader from "../../../Common/Loader";

const EditTeam = (props) => {

    const history = useHistory();

    const TOKEN = localStorage.getItem('fms-access-token');

    const [teamName, setTeamName] = useState();
    const [teamDescription, setTeamDescription] = useState();
    const [users, setUsers] = useState([]);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [teamUsers, setTeamUsers] = useState([]);

    useEffect(() => {
        setTeamName(props.location.state.teamDetails.team_name);
        setTeamDescription(props.location.state.teamDetails.description);
        fetchUsers(props.location.state.teamDetails.id)
    }, [props.location.state]);


    const fetchUsers = (id) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'getEditTeam?team_id=' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setUsers(response.data.data);
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    const updateTeam = () => {
        setUpdateLoader(true);
        const formData = new FormData();
        formData.append('org_users', teamUsers);
        formData.append('name', teamName);
        formData.append('description', teamDescription);
        let URL = process.env.REACT_APP_API_KEY + 'team/' + props.location.state.teamDetails.id;
        axios({
            method: "put",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setUpdateLoader(false);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                history.push('/teams');
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.warning("Something went wrong!");
            }
        }).catch(function (error) {
            setUpdateLoader(false);
            toast.error(error.toString());
        });
    }

    const setStatus = (cell, row) => {
        if (row.is_team_member) {
            setTeamUsers(teamUsers => [...teamUsers, row.id])
        }
        return row.user_status ? <span className="badge badge-success-lighten  m-0 round-badge text-center">ACTIVE</span> : <span className="badge badge-warning-lighten  round-badge text-center">INACTIVE</span>;
    }

    const columns = [{
        dataField: 'name',
        text: 'Name'
    }, {
        dataField: 'email',
        text: 'Email'
    }, {
        dataField: 'role_name',
        text: 'Role'
    }, {
        dataField: 'id',
        text: 'Status',
        formatter: setStatus
    }
        // {
        //     dataField: 'modified_on',
        //     text: 'Modified On',
        //     formatter: dateFormat
        // }
    ];

    const selectRow = {
        mode: 'checkbox',
        selected: teamUsers,
        clickToSelect: true,
        onSelect: (row, isSelect) => {
            if (isSelect) {
                setTeamUsers(teamUsers => [...teamUsers, row.id])
            } else {
                let permissionsArray = teamUsers;
                let index = permissionsArray.indexOf(row.id);
                if (index > -1) {
                    permissionsArray.splice(index, 1);
                }
                setTeamUsers(permissionsArray);
            }
        },
        onSelectAll: (isSelect, rows) => {
            if (isSelect) {
                let permissionsArray = teamUsers;
                for (let i = 0; i < rows.length; i++) {
                    permissionsArray.push(rows[i].id);
                }
                setTeamUsers(permissionsArray);
            } else {
                setTeamUsers([]);
            }
        }
    };

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <button className="btn btn-primary float-right" type="button" onClick={updateTeam}>{updateLoader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null} UPDATE</button>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Settings</a></li>
                                        <li className="breadcrumb-item"><a>User Settings</a></li>
                                        <li className="breadcrumb-item"><Link to="/teams">Teams</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Update Team</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/teams"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>{teamName}</h4>
                                    <p>{teamDescription}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Team Name</label>
                                <input type="text" name="name" value={teamName} onChange={(event) => setTeamName(event.target.value)} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label>Team Description</label>
                                <textarea className="form-control" value={teamDescription} onChange={(event) => setTeamDescription(event.target.value)} name="description"></textarea>
                            </div>
                            <label>Users</label>
                            <BootstrapTable
                                keyField='id'
                                data={users}
                                selectRow={selectRow}
                                bordered={false}
                                hover
                                bootstrap4
                                columns={columns}
                                pagination={paginationFactory(options)}
                                noDataIndication="No record found" />
                            {loader ? <Loader /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default EditTeam;