import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import InputField from "../Common/InputField";

const UpdateAudience = (props) => {
    
    const TOKEN = localStorage.getItem('fms-access-token');

    const audienceId = props.audienceId;

    const [audienceDetailsUI, setAudienceDetailsUI] = useState();

    useEffect(() => {
        document.getElementById('audienceDetailContainer').reset();
        let URL = process.env.REACT_APP_API_KEY + 'audience/getSingleAudience/' + audienceId;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                generateInputFields(response.data.data)
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }, [])

    const generateInputFields = (data) => {
        let arr = [];
        let primaryFields = Object.keys(data.primary_data);
        for (let i = 0; i < primaryFields.length; i++) {
            if (primaryFields[i] === "id" || primaryFields[i] === "data_set_id" || primaryFields[i] === "org_id" || primaryFields[i] === "source_type" || primaryFields[i] === "is_active" || primaryFields[i] === "secondary_data" || primaryFields[i] === "created_by" || primaryFields[i] === "created_on" || primaryFields[i] === "modified_by") {
                //do nothing
            } else {
                arr.push(<InputField name={primaryFields[i]} value={data.primary_data[primaryFields[i]]} readOnly={false} styleClass="col-md-4" />);
            }
        }

        let secondaryObjects = Object.keys(data.secondary_data);
        for (let i = 0; i < secondaryObjects.length; i++) {
            let secondaryFields = Object.keys(data.secondary_data[secondaryObjects[i]]);
            for (let j = 0; j < secondaryFields.length; j++) {
                arr.push(<InputField name={secondaryFields[j]} value={data.secondary_data[secondaryObjects[i]][secondaryFields[j]]} readOnly={false} styleClass="col-md-4" />);
            }
        }
        setAudienceDetailsUI(arr);
    }

    const updateAudienceDetail = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataManual';
        axios({
            method: "post",
            url: URL,
            data: Object.fromEntries(formData),
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeUpdateAudienceModal').click();
                props.fetchAudience();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div className="modal fade" id="updateAudienceDetails" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <form onSubmit={updateAudienceDetail} id="audienceDetailContainer" className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">Update Audience Detail</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <input type="hidden" name="audience_id" value={audienceId} />
                            {audienceDetailsUI}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" id="closeUpdateAudienceModal" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary">Update</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UpdateAudience;