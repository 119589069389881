import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { dateFormat } from "../../../../Utils/date_formatting";
import { Link } from "react-router-dom";

const RolesGridView = (props) => {

    const setActionButtons = (cell, row) => {
        if (row.is_custom === true) {
            return (
                <div className="dropdown float-right">
                    <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                        <i className="mdi mdi-dots-vertical text-secondary font-20"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <Link className="dropdown-item notify-item" to={{ pathname: '/update-role', state: { roleDetails: row } }}><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></Link>
                        <Link className="dropdown-item notify-item" onClick={() => props.setDeleteUrl('roles/' + row.id)} data-toggle="modal" data-target="#deleteModal"><i className="mdi mdi-delete mr-1"></i><span>Delete</span></Link>
                    </div>
                </div>
            )
        }
    }

    const viewPermissionsBtn = (cell, row) => {
        return <Link className="text-primary" to={{ pathname: '/view-role', state: { roleDetails: row } }}>View Permissions</Link>
    }

    const viewUsersBtn = (cell, row) => {
        return <Link className="text-primary" to={{ pathname: '/view-users', state: { roleDetails: row } }}>View Users</Link>
    }
    //getUsersByRole/1
    const columns = [{
        dataField: 'role_name',
        text: 'Name',
        sort: true
    }, {
        dataField: 'role_desc',
        text: 'Description',
        sort: true
    }, {
        dataField: 'modified_on',
        text: 'Modified On',
        formatter: dateFormat
    },

    // {
    //     dataField: 'id',
    //     classes: 'py-0 text-center',
    //     text: '',
    //     headerStyle: { textAlign: 'center' },
    //     formatter: viewPermissionsBtn
    // }, 

    {
        dataField: 'sql_query',
        text: 'Query',
    },

    {
        dataField: 'id',
        classes: 'py-0 text-center',
        text: '',
        headerStyle: { textAlign: 'center' },
        formatter: viewUsersBtn
    }, {
        dataField: 'id',
        classes: 'py-0',
        formatter: setActionButtons
    }];

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <BootstrapTable
            keyField='id'
            data={props.gridData}
            bootstrap4
            bordered={false}
            hover
            columns={columns}
            pagination={paginationFactory(options)}
            noDataIndication="No record found" />
    );
}

export default RolesGridView;