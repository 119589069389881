import React, { useState, useEffect } from "react";
import "survey-analytics/survey.analytics.css";
import "tabulator-tables/dist/css/tabulator.css";
import "survey-analytics/survey.analytics.tabulator.css";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import { permissionName } from "../../Utils/string_formatting";
import Loader from "../Common/Loader";

const Analytics = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [reports, setReports] = useState([]);
    // const [survey, setSurvey] = useState("0");
    // const [tags, setTags] = useState([]);
    // const [segments, setSegments] = useState([]);
    // const [channels, setChannels] = useState([]);
    // const [shares, setShares] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    const fetchReports = () => {
        setShowLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'reports/dashboardList';
        axios({
            method: "post",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setReports(response.data.data);
            }
            setShowLoader(false);
        }).catch(function (error) {
            setShowLoader(false);
            toast.error(error.toString());
        });
    }

    // const gridFilter = (event) => {
    //     event.preventDefault();
    //     const formData = new FormData(event.target);
    //     setShowLoader(true);
    //     let URL = process.env.REACT_APP_API_KEY + 'reports/dashboardList';
    //     axios({
    //         method: "post",
    //         url: URL,
    //         data: formData,
    //         headers: {
    //             Authorization: 'Token ' + TOKEN
    //         }
    //     }).then(function (response) {
    //         setShowLoader(false);
    //         setReports(response.data.data);
    //         document.getElementById('closeFilterModal').click();
    //     }).catch(function (error) {
    //         setShowLoader(false);
    //         toast.error(error.toString());
    //     });
    // }

    // const fetchSurvey = () => {
    //     let URL = process.env.REACT_APP_API_KEY + 'survey/allSurvey';
    //     axios({
    //         method: "get",
    //         url: URL,
    //         headers: {
    //             Authorization: 'Token ' + TOKEN
    //         }
    //     }).then(function (response) {
    //         if (response.data.status === 1) {
    //             let arr = [];
    //             let data = response.data.data;
    //             for (let i = 0; i < data.length; i++) {
    //                 arr.push({ value: data[i].id, label: data[i].name })
    //             }
    //             setSurvey(arr);
    //         }
    //     }).catch(function (error) {
    //         toast.error(error.toString());
    //     });
    // }

    // const fetchSegments = () => {
    //     let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation';
    //     axios({
    //         method: "get",
    //         url: URL,
    //         headers: {
    //             Authorization: 'Token ' + TOKEN
    //         }
    //     }).then(function (response) {
    //         if (response.data.status === 1) {
    //             let arr = [];
    //             let data = response.data.data;
    //             for (let i = 0; i < data.length; i++) {
    //                 arr.push({ value: data[i].id, label: permissionName(data[i].name) })
    //             }
    //             setSegments(arr);
    //         }
    //     }).catch(function (error) {
    //         toast.error(error.toString());
    //     });
    // }

    // const fetchTags = () => {
    //     let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
    //     axios({
    //         method: "get",
    //         url: URL,
    //         headers: {
    //             Authorization: 'Token ' + TOKEN
    //         }
    //     }).then(function (response) {
    //         if (response.data.status === 1) {
    //             let arr = [];
    //             let data = response.data.data;
    //             for (let i = 0; i < data.length; i++) {
    //                 arr.push({ value: data[i].id, label: permissionName(data[i].name) })
    //             }
    //             setTags(arr);
    //         }
    //     }).catch(function (error) {
    //         toast.error(error.toString());
    //     });
    // }

    // const fetchChannels = () => {
    //     let URL = process.env.REACT_APP_API_KEY + 'master/getChannelTypes';
    //     axios({
    //         method: "get",
    //         url: URL,
    //         headers: {
    //             Authorization: 'Token ' + TOKEN
    //         }
    //     }).then(function (response) {
    //         if (response.data.status === 1) {
    //             let arr = [];
    //             let data = response.data.data;
    //             for (let i = 0; i < data.length; i++) {
    //                 arr.push({ value: data[i], label: permissionName(data[i]) })
    //             }
    //             setChannels(arr);
    //         }
    //     }).catch(function (error) {
    //         toast.error(error.toString());
    //     });
    // }

    // const fetchShares = () => {
    //     let URL = process.env.REACT_APP_API_KEY + 'audience/getShareData';
    //     axios({
    //         method: "get",
    //         url: URL,
    //         headers: {
    //             Authorization: 'Token ' + TOKEN
    //         }
    //     }).then(function (response) {
    //         if (response.data.status === 1) {
    //             let arr = [];
    //             let data = response.data.data;
    //             for (let i = 0; i < data.length; i++) {
    //                 arr.push({ value: data[i].id, label: data[i].share_name })
    //             }
    //             setShares(arr);
    //         }
    //     }).catch(function (error) {
    //         toast.error(error.toString());
    //     });
    // }

    const runHellical = () => {
        let URL = 'http://helical.helloapps.io:8085/helloapps/j_spring_security_logout';
        axios({
            method: "get",
            url: URL,
        });
    }

    useEffect(fetchReports, []);
    //useEffect(runHellical, []);

    //useEffect(fetchSurvey, []);
    // useEffect(fetchTags, []);
    // useEffect(fetchSegments, []);
    // useEffect(fetchChannels, []);
    // useEffect(fetchShares, []);

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                {/* <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <button type="button" data-toggle="modal" data-target="#right-modal" className="btn btn-outline-primary btn-sm-block mr-2 dropdown-toggle arrow-none"><i className="mdi mdi-tune mr-1"></i>Filter</button>
                                        </div>
                                    </div>
                                </div> */}
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Analytics</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Analytics</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="modal fade" id="right-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-right" style={{ minWidth: 400, maxWidth: 600 }}>
                            <form onSubmit={gridFilter} id="sidePopupFilterForm" className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="myLargeModalLabel">Filter Survey Responses</h4>
                                    <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ maxWidth: '400px' }}>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Surveys</label>
                                            <Select options={survey} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="survey_id" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Segments</label>
                                            <Select options={segments} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="segments" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Tags</label>
                                            <Select options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="tags" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Shares</label>
                                            <Select options={shares} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="share_id" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Channels</label>
                                            <Select options={channels} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="channel_type" />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer align-footer-bottom">
                                    <button type="button" id="closeFilterModal" className="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Apply Filters</button>
                                </div>
                            </form>
                        </div>
                    </div> */}



                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div style={{ backgroundColor: 'rgb(247, 250, 252)' }}>

                                {reports.map((obj, i) => {

                                    return <div class="card">
                                        <div class="card-body py-2 px-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="d-flex justify-content-between">
                                                        <h4 className="text-muted"><i class="mdi mdi-file-document mr-1"></i>{obj.name}</h4>
                                                        <a href={"#issue-reports_" + i}
                                                            onClick={() => {
                                                                document.getElementById("rotate-arrow-outer_20").classList.toggle('mdi-rotate-180');
                                                            }}
                                                            type="button" data-toggle="collapse" aria-expanded="true" class="btn btn-sm btn-outline-dark my-1">
                                                            <i id={"rotate-arrow-outer_20"} class="uil uil-angle-down"></i>
                                                        </a>
                                                    </div>
                                                    <div class="row ">
                                                        <div class="col-md-12">
                                                            <div class="row collapse mt-3" id={"issue-reports_" + i}>
                                                                {obj.reports_list.map((obj) => {
                                                                    return <div className="col-md-4">
                                                                        <div class="card mb-1 shadow-none border">
                                                                            <div class="p-2">
                                                                                <div class="row align-items-center">
                                                                                    <div class="col-auto">
                                                                                        <i class="mdi mdi-file-edit widget-icon bg-primary-lighten text-primary"></i>
                                                                                    </div>
                                                                                    <div class="col ps-0 pl-0">
                                                                                        <a href={obj.report_url} target="_blank" class="text-primary">
                                                                                            <h5>{obj.report_name}</h5>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader ? <Loader /> : null}
        </div>
    );
}

export default Analytics;


// const Analytics = () => {
//     return (
//         <div className="content-page">
//             <div className="content">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="page-title-box">
//                                 <nav aria-label="breadcrumb">
//                                     <ol className="breadcrumb mt-3 mb-0 pb-0">
//                                         <li className="breadcrumb-item"><a>Home</a></li>
//                                         <li className="breadcrumb-item active" aria-current="page">Analytics</li>
//                                     </ol>
//                                 </nav>

//                                 <div className="row">
//                                     <iframe className="col-md-12 mt-4 vh-100" src="http://helical.helloapps.io:8085/helloapps/">
//                                     </iframe>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div >
//     )
// }

// export default Analytics;