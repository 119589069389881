import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { dateFormat } from "../../Utils/date_formatting";
import GridView from "../Common/GridView";

const ViewImports = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [imports, setImports] = useState([]);
    const [totalSize, setTotalSize] = useState(0);

    const fetchImports = (page = 1, size = 10) => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceImports';
        const formData = new FormData();
        formData.append('page_count', size);
        formData.append('page_no', page);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setImports(response.data.data);
            setTotalSize(response.data.total_count);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchImports, []);

    const columns = [{
        dataField: 'audience_data_name',
        text: 'Import Name',
        sort: true
    }, {
        dataField: 'item_created',
        text: 'Created',
        sort: true
    }, {
        dataField: 'item_updated',
        text: 'Updated',
        sort: true
    }, {
        dataField: 'item_errors',
        text: 'Errors',
        sort: true
    }, {
        dataField: 'source_type',
        text: 'Source',
        sort: true
    }, {
        dataField: 'tags',
        text: 'Tags',
        sort: true
    }, {
        dataField: 'created_on',
        text: 'Created On',
        formatter: dateFormat,
        sort: true
    }, {
        dataField: 'updated_by',
        text: 'Updated By',
        sort: true
    }];

    return (
        <div id="viewImportsModal" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="fullWidthModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-full-width">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="fullWidthModalLabel">All Imports</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <GridView
                            classes="table-hover-custom"
                            gridData={imports}
                            columns={columns}
                            totalSize={totalSize}
                            sizePerPage={10}
                            keyField="id"
                            fetchData={(page, size) => fetchImports(page, size)} />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">CLOSE</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewImports;