import { useEffect, useState } from "react";
import axios from "axios";
import { numberToTime } from "../../Utils/date_formatting";

const TimeZoneDropDown = (props) => {

    const [data, setData] = useState([]);

    const fetchData = () => {
        var arr = [];
        let URL = process.env.REACT_APP_API_KEY + props.url;
        axios.get(URL)
            .then((response) => {
                for (let i = 0; i < response.data.data.length; i++) {
                    arr.push({ 'id': response.data.data[i].id, 'label': '(GMT ' + numberToTime(response.data.data[i].gmt_offset) + ') ' + response.data.data[i].timezone })
                }
                setData(arr);
            });
    }

    useEffect(fetchData, []);

    return (
        <select required={props.required} id={props.id} name={props.name} disabled={props.disabled} onChange={props.onchange} value={props.value} className={"form-control " + props.className}>
            <option value={0} key={0}>--Select--</option>
            {data.map((e, key) => {
                return <option key={key} value={e.id}>{e.label}</option>;
            })}
        </select>
    );
}

export default TimeZoneDropDown;