import { useEffect, useState } from "react";
import axios from "axios";

const SurveyDropDown = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [data, setData] = useState([]);

    const fetchData = () => {
        var arr = [];
        let URL = process.env.REACT_APP_API_KEY + 'survey/allSurvey';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then((response) => {
            for (let i = 0; i < response.data.data.length; i++) {
                arr.push({ 'id': JSON.stringify({ id: response.data.data[i].id, questions: response.data.data[i].json }), 'label': response.data.data[i].name })
            }
            setData(arr);
        });
    }

    useEffect(fetchData, []);

    return (
        <select required={props.required} id={props.id} name={props.name} disabled={props.disabled} onChange={props.onchange} value={props.value} className={"form-control " + props.className}>
            <option value={0} key={0}>--Select--</option>
            {data.map((e, key) => {
                return <option key={key} value={e.id}>{e.label}</option>;
            })}
        </select>
    );
}

export default SurveyDropDown;