import axios from "axios";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { dateFormat } from "../../Utils/date_formatting";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import DeleteModal from "../Common/DeleteModal";
import DuplicateSurveyPopup from "./DuplicateSurveyPopup";

const SurveyGridView = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [deleteUrl, setDeleteUrl] = useState([]);
    const [duplicateSyrveyId, setDuplicateSyrveyId] = useState();

    const setActionButtons = (cell, row) => {
        return (
            <div className="dropdown float-right">
                <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical text-secondary font-20"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-border-top">
                    <a className="dropdown-item notify-item" href={row.preview_url} target="_blank"><i className="mdi mdi-eye mr-1"></i><span>Preview</span></a>
                    <Link className="dropdown-item notify-item" to={{ pathname: '/survey-content', state: { surveyInfo: row } }}><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></Link>

                    {row.status === "closed" ? null :
                        <Link className="dropdown-item notify-item" to={{ pathname: '/survey-share', state: { surveyInfo: row } }}><i className="mdi mdi-share mr-1"></i><span>Share</span></Link>
                    }

                    <Link className="dropdown-item notify-item" to={{ pathname: '/survey-work-flow', state: { surveyInfo: row } }}><i className="mdi mdi-directions-fork mr-1"></i><span>Workflow</span></Link>

                    <Link className="dropdown-item notify-item" onClick={() => setDuplicateSyrveyId(row.id)} data-toggle="modal" data-target="#duplicate-survey-modal"><i className="mdi mdi-file-multiple mr-1"></i><span>Duplicate</span></Link>
                    {row.resp_count === 0 ?
                        <Link className="dropdown-item notify-item" onClick={() => setDeleteUrl('survey/delete_survey/' + row.id)} data-toggle="modal" data-target="#deleteModal"><i className="mdi mdi-delete mr-1"></i><span>Delete</span></Link>
                        : null}
                    {/* <Link className="dropdown-item notify-item" to={{ pathname: '/survey-response', state: { surveyInfo: row } }}><i className="mdi mdi-message-arrow-left mr-1"></i><span>Responses</span></Link> */}
                    <Link className="dropdown-item notify-item" to='/responses'><i className="mdi mdi-message-arrow-left mr-1"></i><span>Responses</span></Link>
                    {row.status === "closed" ?
                        <Link className="dropdown-item notify-item" onClick={() => openSurvey(row.id)}><i className="mdi mdi-play-circle mr-1"></i><span>Open</span></Link>
                        :
                        <Link className="dropdown-item notify-item" onClick={() => closeSurvey(row.id)}><i className="mdi mdi-pause-circle mr-1"></i><span>Close</span></Link>
                    }
                </div>
            </div>
        );
    }

    const openSurvey = (id) => {
        let URL = process.env.REACT_APP_API_KEY + 'survey/openSurvey';
        const formData = new FormData();
        formData.append('survey_id', id);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                props.fetchSurveys();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const closeSurvey = (id) => {
        let URL = process.env.REACT_APP_API_KEY + 'survey/closeSurvey';
        const formData = new FormData();
        formData.append('survey_id', id);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                props.fetchSurveys();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const setStatus = (cell) => {
        if (cell === "created") {
            return <span className="badge badge-danger-lighten py-1 px-3 round-badge text-center">CREATED</span>
        } else if (cell === "designed") {
            return <span className="badge badge-warning-lighten py-1 px-3 round-badge text-center">DESIGNED</span>
        } else if (cell === "shared") {
            return <span className="badge badge-success-lighten  m-0 round-badge text-center">SHARED</span>
        } else if (cell === "closed") {
            return <span className="badge badge-secondary-lighten  m-0 round-badge text-center">CLOSED</span>
        }
    }

    const columns = [{
        dataField: 'name',
        text: 'Survey Name',
        sort: true
    }, {
        dataField: 'creating_for',
        text: 'What you created?'
    }, {
        dataField: 'status',
        text: 'Status',
        formatter: setStatus
    }, {
        dataField: 'modified_on',
        text: 'Modified On',
        formatter: (cell, row) => { return dateFormat(cell) + ' by ' + row.modified_by },
        sort: true
    }, {
        dataField: 'question_count',
        text: 'Questions',
        sort: true
    }, {
        dataField: 'resp_count',
        text: 'Responses',
        sort: true
    }, {
        dataField: 'completion_rate',
        text: 'Completion Rate',
        formatter: (cell) => { return cell + '%' },
        sort: true
    },
    // {
    //     dataField: 'created_by',
    //     text: 'Created By',
    //     formatter: () => { return 'John Doe' },
    //     sort: true
    // }, 
    {
        dataField: 'id',
        classes: 'py-0',
        formatter: setActionButtons
    }];

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <>
            <DeleteModal deleteUrl={deleteUrl} reloadGrid={props.fetchSurveys} />
            <DuplicateSurveyPopup duplicateSyrveyId={duplicateSyrveyId} reloadGrid={props.fetchSurveys} />
            <BootstrapTable
                keyField="id"
                data={props.gridData}
                columns={columns}
                bordered={false}
                bootstrap4
                hover
                condensed={true}
                pagination={paginationFactory(options)}
                noDataIndication="No record found"
            />
        </>
    );
}

export default SurveyGridView;