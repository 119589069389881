import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select'

const MapFieldsGridView = (props) => {

    const [disabledOptions, setDisabledOptions] = useState([]);

    const onSelectChange = (e, compName) => {
        let mappingStatus = document.getElementById(compName + 'mappingStatus');
        let oldValue = document.getElementsByName(compName)[0].value;
        let tempDisabledOptions = disabledOptions;
        if (tempDisabledOptions.includes(oldValue)) {
            let index = tempDisabledOptions.indexOf(oldValue);
            if (index > -1) {
                tempDisabledOptions.splice(index, 1);
            }
        }
        if (e) {
            if (e.value === "") {
                mappingStatus.innerHTML = '<span class="badge badge-danger-lighten p-1">Unmapped</span>';
            } else {
                tempDisabledOptions.push(e.value);
                mappingStatus.innerHTML = '<span class="badge badge-success-lighten p-1">Mapped</span>';
            }
        } else {
            mappingStatus.innerHTML = '<span class="badge badge-danger-lighten p-1">Unmapped</span>';
        }
        setDisabledOptions(tempDisabledOptions);
    }

    const checkDisabled = (e) => {
        return disabledOptions.includes(e.value);
    }

    const setSampleContent = (cell) => {
        let arr = [];
        for (let i = 0; i < cell.length; i++) {
            arr.push(<li>{cell[i]}</li>);
        }
        return (
            <ul className="list-unstyled" style={{ marginBottom: 0 }}>
                {arr}
            </ul>
        )
    }

    const setMappingStatus = (cell, row) => {
        return <p id={row.columnHeader + "mappingStatus"}><span className="badge badge-danger-lighten p-1">Unmapped</span></p>
    }

    const columns = [{
        dataField: '',
        text: 'Status',
        formatter: setMappingStatus,
        headerStyle: { width: '10%' },
    }, {
        dataField: 'columnHeader',
        text: 'Column Header',
        headerStyle: { width: '25%' },
    }, {
        dataField: 'sampleContent',
        text: 'Content Sample',
        formatter: setSampleContent,
        headerStyle: { width: '40%' },
    }, {
        dataField: '',
        text: 'Contact Property',
        headerStyle: { width: '25%' },
        style: { textAlign: 'center' },
        formatter: (cell, row = null, rowIndex, extraData) => {
            return (
                <div className="mappingDropDown">
                    <Select
                        options={extraData}
                        placeholder="--Select--"
                        onChange={(event) => onSelectChange(event, row.columnHeader)}
                        isSearchable={true}
                        isClearable={true}
                        isOptionDisabled={checkDisabled}
                        name={row.columnHeader}
                        menuPlacement="auto"
                        menuPortalTarget={document.body} />
                </div>
            )
        },
        formatExtraData: props.properties
    }];

    return (
        <BootstrapTable
            keyField='id'
            data={props.gridData}
            hover
            columns={columns} />
    )
}

export default MapFieldsGridView;

