import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader";

const ViewUsers = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [roleName, setRoleName] = useState();
    const [users, setUsers] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setRoleName(props.location.state.roleDetails.role_name);
        fetchUsers(props.location.state.roleDetails.id);
    }, [props.location.state]);

    const fetchUsers = (id) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'getUsersByRole/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setUsers(response.data.data);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const setStatus = (cell) => {
        return cell ? <span className="badge badge-success-lighten  m-0 round-badge text-center">ACTIVE</span> : <span className="badge badge-warning-lighten  round-badge text-center">INACTIVE</span>;
    }

    const columns = [{
        dataField: 'name',
        text: 'Name'
    }, {
        dataField: 'email',
        text: 'Email'
    }, {
        dataField: 'role_name',
        text: 'Role'
    }, {
        dataField: 'is_active',
        text: 'Status',
        formatter: setStatus
    }];

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Settings</a></li>
                                        <li className="breadcrumb-item"><a>User Settings</a></li>
                                        <li className="breadcrumb-item"><Link to="/roles">Roles</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Users</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/roles"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>{roleName} - Users</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label>Users</label>
                            <BootstrapTable
                                keyField='id'
                                data={users}
                                bootstrap4
                                bordered={false}
                                hover
                                columns={columns}
                                pagination={paginationFactory(options)}
                                noDataIndication="No record found" />
                            {loader ? <Loader /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewUsers;