import React from "react";
import * as Survey from "survey-react";
import "survey-react/modern.css";

const ContactFormPreview = ({ contactFormData }) => {

    const survey = new Survey.Model(contactFormData);

    return (
        <div id="contact-form-preview" className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="fullWidthModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="fullWidthModalLabel">Survey Preview</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="row">
                            <div className="col-12">
                                <Survey.Survey model={survey} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer" style={{ paddingTop: '1rem' }}>
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal" >Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactFormPreview;