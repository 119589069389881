import { permissionName } from "../../Utils/string_formatting";

const InputField = (props) => {
    return (
        <div className={"form-group " + props.styleClass}>
            <label>{permissionName(props.name)}</label>
            <input type="text" name={props.name} required={props.required} defaultValue={props.value} readOnly={props.readOnly} className="form-control bg-white" />
        </div>
    );
}

export default InputField;