import { useEffect, useState } from "react";
import ChannelsInputField from "../../../../Common/ChannelsInputField";
import $ from 'jquery';

const ViewSmsModal = (props) => {

    const ssp = props.sspData;

    const [inputFieldsUI, setInputFieldsUI] = useState([]);

    const generateFields = () => {
        let arr = [];
        let obj = $.each(ssp, function (key, value) {
            if (value === "" || value === null) {
                delete ssp[key];
            }
        });
        let keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === "modified_on" || keys[i] === "is_active" || keys[i] === "id" || keys[i] === "created_on" || keys[i] === "channel_type_id") {
                //do nothing
            } else {
                arr.push(<ChannelsInputField name={keys[i]} value={ssp[keys[i]]} readOnly={true} />);
            }
        }
        setInputFieldsUI(arr);
    }

    useEffect(() => {
        generateFields()
    }, [ssp]);

    return (
        <div className="modal fade" id="viewSmsModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">SSP Details</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        {inputFieldsUI}
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeModal" className="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewSmsModal;