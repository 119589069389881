import { Link } from "react-router-dom";
import SheetDetailsModal from "../ImportFromGoogleSheets/SheetDetailsModal";

const ImportOptions = () => {

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item"><Link to="/audience">Audience</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Import</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/audience"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>Import Contacts</h4>
                                </div>
                                <h3>How do you like to import contacts to your account?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row import-contacts card-body">
                                <div className="col-lg-4">
                                    <Link to="import-from-csv">
                                        <div className="card ribbon-box border rounded-0 text-white">
                                            <div className="card-body p-4">
                                                <div className="text-center">
                                                    <img className="my-3" src="/assets/images/icons/csv.svg" width="50" alt="Generic placeholder image" />
                                                    <h3 className="m-0 font-weight-normal text-muted">CSV</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4">
                                    <Link data-toggle="modal" data-target="#sheetDetailsModal">
                                        <div className="card ribbon-box border rounded-0 text-white">
                                            <div className="card-body p-4">
                                                <div className="text-center">
                                                    <img className="my-3" src="/assets/images/icons/google-sheets.svg" width="50" alt="Generic placeholder image" />
                                                    <h3 className="m-0 font-weight-normal text-muted">Google Sheets</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4">
                                    <Link>
                                        <div className="card ribbon-box border rounded-0 text-white">
                                            <div className="card-body p-4">
                                                <div className="ribbon-two ribbon-two-primary"><span className="font-10">Coming Soon!</span></div>
                                                <div className="ribbon-content text-center">
                                                    <img className="my-3" src="/assets/images/icons/gmail.svg" width="50" alt="Generic placeholder image" />
                                                    <h3 className="m-0 font-weight-normal text-muted">Gmail Contacts</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4">
                                    <Link>
                                        <div className="card ribbon-box border rounded-0 text-white">
                                            <div className="card-body p-4">
                                                <div className="ribbon-two ribbon-two-primary"><span className="font-10">Coming Soon!</span></div>
                                                <div className="ribbon-content text-center">
                                                    <img className="my-3" src="/assets/images/icons/google-symbol.svg" width="50" alt="Generic placeholder image" />
                                                    <h3 className="m-0 font-weight-normal text-muted">G Suite Contacts</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4">
                                    <Link>
                                        <div className="card ribbon-box border rounded-0 text-white">
                                            <div className="card-body p-4">
                                                <div className="ribbon-two ribbon-two-primary"><span className="font-10">Coming Soon!</span></div>
                                                <div className="ribbon-content text-center">
                                                    <img className="my-3" src="/assets/images/icons/crm.svg" width="50" alt="Generic placeholder image" />
                                                    <h3 className="m-0 font-weight-normal text-muted">CRM</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-4">
                                    <Link>
                                        <div className="card ribbon-box border rounded-0 text-white">
                                            <div className="card-body p-4">
                                                <div className="ribbon-two ribbon-two-primary"><span className="font-10">Coming Soon!</span></div>
                                                <div className="ribbon-content text-center">
                                                    <img className="my-3" src="/assets/images/icons/api.svg" width="50" alt="Generic placeholder image" />
                                                    <h3 className="m-0 font-weight-normal text-muted">API</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <SheetDetailsModal />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImportOptions;