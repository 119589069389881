import axios from "axios";
import { toast } from "react-toastify";

const ChangePasswordModal = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    //const [loader, setLoader] = useState(false);

    const updatePassword = (event) => {
        //setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'changePassword';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            //setLoader(false);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeModal').click();
                document.getElementById('updatePasswordForm').reset();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            //setLoader(false);
            toast.error(error.toString());
        });
    }

    return (
        <div className="modal fade" id="updatePasswordModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm">
                <form onSubmit={updatePassword} id="updatePasswordForm" className="modal-content ">
                    <div className="modal-header">
                        <h4 className="modal-title" id="staticBackdropLabel">Change Password</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Old Password<span className="text-danger">*</span></label>
                            <input type="password" name="old_password" className="form-control" required/>
                        </div>
                        <div className="form-group">
                            <label>New Password<span className="text-danger">*</span></label>
                            <input type="password" name="new_password" className="form-control" required/>
                        </div>
                        <div className="form-group">
                            <label>Confirm Password<span className="text-danger">*</span></label>
                            <input type="password" name="new_password" className="form-control" required/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeModal" className="btn btn-outline-secondary" data-dismiss="modal">CANCEL</button>
                        <button type="submit" className="btn btn-primary">SAVE</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ChangePasswordModal;