import React from "react";
import { useEffect, useState } from "react";
import { firstCharCaps, permissionName } from "../../../../Utils/string_formatting";
import PermissionsGridView from "../../../Common/PermissionsGridView";
import { Link } from "react-router-dom";
import $ from 'jquery';

const ViewRole = (props) => {

    const [roleName, setRoleName] = useState([]);
    const [roleDescription, setRoleDescription] = useState([]);
    const [permissionsUI, setPermissionsUI] = useState([]);

    useEffect(() => {
        setRoleName(props.location.state.roleDetails.role_name);
        setRoleDescription(props.location.state.roleDetails.role_desc);
        processPermissions(props.location.state.roleDetails.permissions);
    }, [props.location.state]);

    const processPermissions = (data) => {
        let UI = [];
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let arr = [];
            let list = data[keys[i]];

            let keyss = [];
            $.each(list, function (key, value) {
                if (value[Object.keys(value)[0]] === true) {
                    keyss.push(list[key]);
                }
            });

            for (let j = 0; j < keyss.length; j++) {
                arr.push({ id: keys[i] + '_' + Object.keys(keyss[j])[0], name: permissionName(Object.keys(keyss[j])[0]), value: Object.keys(keyss[j])[0] })
            }
            UI.push(<PermissionsGridView gridData={arr} gridName={firstCharCaps(keys[i])} />)
        }
        setPermissionsUI(UI);
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Settings</a></li>
                                        <li className="breadcrumb-item"><a>User Settings</a></li>
                                        <li className="breadcrumb-item"><Link to="/roles">Roles</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Permissions</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/roles"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>{roleName}</h4>
                                    <p>{roleDescription}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h4>Permissions</h4>
                            <div className="row">
                                {permissionsUI}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewRole;
