import { useEffect, useState } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from "../Components/Dashboard/Dashboard";
import Login from "../Components/Login/Login";
import SideMenu from '../Components/Common/SideMenu';
import TopBar from "../Components/Common/TopBar";
import Survey from "../Components/Survey/Survey";
import Audience from "../Components/Audience/Audience";
import ImportFromCSV from "../Components/Audience/ImportFromCSV/ImportFromCSV";
import ImportOptions from "../Components/Audience/ImportAudience/ImportOptions";
import Segments from "../Components/Segments/Segments";
import AddSegment from "../Components/Segments/AddSegment";
import SettingsSideMenu from "../Components/Settings/SettingsSideMenu";
import Users from "../Components/Settings/UserManagement/Users/Users";
import Teams from "../Components/Settings/UserManagement/Teams/Teams";
import Roles from "../Components/Settings/UserManagement/Roles/Roles";
import AddRole from "../Components/Settings/UserManagement/Roles/AddRole";
import { toast, ToastContainer } from "react-toastify";
import UpdateRole from "../Components/Settings/UserManagement/Roles/UpdateRole";
import EmailIntegration from "../Components/Settings/Integrations/ChannelIntegration/Email/EmailIntegration";
import SmsIntegration from "../Components/Settings/Integrations/ChannelIntegration/SMS/SmsIntegration";
import Profile from "../Components/Settings/Profile/Profile";
import Organization from "../Components/Settings/Organization/Organization";
import SurveyThrottling from "../Components/Settings/Organization/SurveyThrottling";
import ImportFromGoogleSheets from "../Components/Audience/ImportFromGoogleSheets/ImportFromGoogleSheets";
import axios from "axios";
import { adjust } from '../Utils/color_formatting';
import UpdateSegment from "../Components/Segments/UpdateSegment";
import PageNotFound from "../Components/Common/PageNotFound";
import AppIntegration from "../Components/Settings/Integrations/AppIntegration/AppIntegration";
import ChannelIntegration from "../Components/Settings/Integrations/ChannelIntegration/ChannelIntegration";
import ViewSegment from "../Components/Segments/ViewSegment";
import EditTeam from "../Components/Settings/UserManagement/Teams/EditTeam";
import ViewTeam from "../Components/Settings/UserManagement/Teams/ViewTeam";
import ViewRole from "../Components/Settings/UserManagement/Roles/ViewRole";
import ViewUsers from "../Components/Settings/UserManagement/Roles/ViewUsers";
import ForgotPassword from "../Components/Login/ForgotPassword";
import SurveyResponse from "../Components/Survey/SurveyResponse";
import SurveyDesigner from "../Components/Survey/SurveyBuilder/SurveyDesign/SurveyDesigner";
import SurveyShare from "../Components/Survey/SurveyBuilder/SurveyShare/SurveyShare";
import SurveyResult from "../Components/Survey/SurveyBuilder/SurveyResult/SurveyResult";
import SurveyContent from "../Components/Survey/SurveyBuilder/SurveyContent/SurveyContent";
import ShareViaSMS from "../Components/Survey/SurveyBuilder/SurveyShare/ShareViaSMS/ShareViaSMS";
import ShareViaSocialMedia from "../Components/Survey/SurveyBuilder/SurveyShare/ShareViaSocialMedia/ShareViaSocialMedia";
import ShareViaQR from "../Components/Survey/SurveyBuilder/SurveyShare/ShareViaQR/ShareViaQR";
import SurveyWorkFlow from "../Components/Survey/SurveyBuilder/SurveyWorkFlow/SurveyWorkFlow";
import AllIssues from "../Components/Issues/AllIssues";
// import IssueDetails from "../Components/Issues/IssueDetails";
import CreatePassword from "../Components/Login/CreatePassword";
import AudienceDetails from "../Components/Audience/AudienceDetails";
import AddWorkFlow from "../Components/Survey/SurveyBuilder/SurveyWorkFlow/AddWorkFlow";
import UpdateWorkFlow from "../Components/Survey/SurveyBuilder/SurveyWorkFlow/UpdateWorkFlow";
import SubTaskDetails from "../Components/Issues/SubTaskDetails";
import Trend from "../Components/TrendAnalysis/Trend";
import CrmIntegration from "../Components/Settings/Integrations/AppIntegration/CRM/CrmIntegration";
import ContactForm from "../Components/Settings/Integrations/ContactForm/ContactForm";
import CreateContactForm from "../Components/Settings/Integrations/ContactForm/CreateContactForm";
import Analytics from "../Components/Analytics/Analytics";
import Responses from "../Components/Responses/Responses";
import ReportView from "../Components/Analytics/ReportView";
import ResponseAnalysis from "../Components/ResponseAnalysis/ResponseAnalysis";
import MyIssues from "../Components/Issues/MyIssues";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import AdvancedAnalytics from "../Components/AdvancedAnalytics/AdvancedAnalytics";
import EmbeddedDashboardPage from "../Components/AdvancedAnalytics/EmbeddedDashboardPage";
import IssueDetailsPage from "../Components/Issues/IssueDetailsPage";

const BaseLayout = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hasAccess, setHasAccess] = useState(false);

    const [userInfo, setUserInfo] = useState();
    const [organizationInfo, setOrganizationInfo] = useState([]);
    const [primaryColor, setPrimaryColor] = useState("#727CF5");
    const [secondaryColor, setSecondaryColor] = useState("#727CF5");

    const TOKEN = localStorage.getItem('fms-access-token');

    const fetchProfileDetails = () => {
        let URL = process.env.REACT_APP_API_KEY + 'getUserData';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setUserInfo(response.data.data);
                if (response.data.data.id > 0) {
                    setIsLoggedIn(true);
                    setHasAccess(response.data.data.permissions.settings_manage);
                } else {
                    setIsLoggedIn(false);
                }
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            //toast.error(error.toString());
        });
    }

    const fetchAccountDetails = () => {
        let URL = process.env.REACT_APP_API_KEY + 'orgSettings';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setOrganizationInfo(response.data.data);
                setPrimaryColor(response.data.data.primary_color);
                setSecondaryColor(response.data.data.secondry_color);
            } else if (response.data.status === 0) {
                //Organization does not exist
                //toast.error(response.data.message)
                setOrganizationInfo([]);
            }
        }).catch(function (error) {
            //toast.error(error.toString());
        });
    }

    useEffect(fetchAccountDetails, []);
    useEffect(fetchProfileDetails, []);

    useEffect(() => {
        document.documentElement.style.setProperty('--custom-primary-color', primaryColor ? primaryColor : '#000000');
        document.documentElement.style.setProperty('--custom-primary-dark-color', adjust(primaryColor, -20));
        document.documentElement.style.setProperty('--custom-focus-color', primaryColor + '26');
        document.documentElement.style.setProperty('--custom-secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--custom-focus-secondary-color', secondaryColor + '26');
    }, [primaryColor, secondaryColor]);

    return (
        <div className="wrapper">
            <Router>
                <ToastContainer />
                <Switch>
                    <Route exact path="/login">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Dashboard userInfo={userInfo} organizationInfo={organizationInfo} fetchUserData={fetchProfileDetails} /></> : <Login />}
                    </Route>

                    <Route path="/ForgetPassword/:token" render={(props) => <ForgotPassword {...props} />} />
                    <Route path="/CreatePassword/:token" render={(props) => <CreatePassword {...props} />} />

                    <Route exact path="/">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Dashboard userInfo={userInfo} organizationInfo={organizationInfo} fetchUserData={fetchProfileDetails} /></> : <Login />}
                    </Route>

                    <Route exact path="/profile">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Profile userInfo={userInfo} reloadProfileDetails={fetchProfileDetails} /></> : <Login />}
                    </Route>


                    <Route exact path="/surveys">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Survey /></> : <Login />}
                    </Route>

                    <Route exact path="/survey-content" render={(props) => isLoggedIn ? <SurveyContent {...props} primaryColor={primaryColor} organizationInfo={organizationInfo} /> : <Login />} />

                    <Route exact path="/survey-design" render={(props) => isLoggedIn ? <SurveyDesigner {...props} organizationInfo={organizationInfo} /> : <Login />} />

                    <Route exact path="/survey-work-flow" render={(props) => isLoggedIn ? <SurveyWorkFlow {...props} primaryColor={primaryColor} organizationInfo={organizationInfo} /> : <Login />} />
                    <Route exact path="/survey-add-work-flow" render={(props) => isLoggedIn ? <AddWorkFlow {...props} primaryColor={primaryColor} organizationInfo={organizationInfo} /> : <Login />} />
                    <Route exact path="/survey-update-work-flow" render={(props) => isLoggedIn ? <UpdateWorkFlow {...props} primaryColor={primaryColor} organizationInfo={organizationInfo} /> : <Login />} />

                    <Route exact path="/survey-share" render={(props) => isLoggedIn ? <SurveyShare {...props} organizationInfo={organizationInfo} /> : <Login />} />

                    <Route exact path="/survey-share/sms" render={(props) => isLoggedIn ? <ShareViaSMS {...props} organizationInfo={organizationInfo} /> : <Login />} />
                    <Route exact path="/survey-share/social-media" render={(props) => isLoggedIn ? <ShareViaSocialMedia {...props} organizationInfo={organizationInfo} /> : <Login />} />
                    <Route exact path="/survey-share/qr" render={(props) => isLoggedIn ? <ShareViaQR {...props} organizationInfo={organizationInfo} /> : <Login />} />

                    <Route exact path="/survey-results" render={(props) => isLoggedIn ? <SurveyResult {...props} organizationInfo={organizationInfo} /> : <Login />} />


                    <Route exact path="/audience">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Audience /></> : <Login />}
                    </Route>

                    <Route exact path="/audience-details/:id" render={(props) => isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><AudienceDetails {...props} /></> : <Login />} />

                    <Route exact path="/import-options">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><ImportOptions /></> : <Login />}
                    </Route>

                    <Route exact path="/import-from-csv">
                        {isLoggedIn ? <ImportFromCSV /> : <Login />}
                    </Route>

                    <Route exact path="/import-from-google-sheets" render={(props) => isLoggedIn ? <ImportFromGoogleSheets {...props} /> : <Login />} />

                    <Route exact path="/segments">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Segments /></> : <Login />}
                    </Route>

                    <Route exact path="/add-segment" render={(props) => isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><AddSegment {...props} /></> : <Login />} />

                    <Route exact path="/edit-segment" render={(props) => isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><UpdateSegment {...props} /></> : <Login />} />

                    <Route exact path="/view-segment/:id" render={(props) => isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><ViewSegment {...props} /></> : <Login />} />

                    <Route exact path="/survey-response" render={(props) => isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><SurveyResponse {...props} /></> : <Login />} />

                    <Route exact path="/responses">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Responses /></> : <Login />}
                    </Route>

                    <Route exact path="/analytics">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Analytics /></> : <Login />}
                    </Route>
                    <Route exact path="/analytics/report-view" render={(props) => isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><ReportView {...props} /></> : <Login />} />
                    <Route exact path="/advanced-analytics">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><AdvancedAnalytics /></> : <Login />}
                    </Route>
                    <Route exact path="/advanced-analytics/*">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><EmbeddedDashboardPage /></> : <Login />}
                    </Route>
                    <Route exact path="/all-issues">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><AllIssues userInfo={userInfo} /></> : <Login />}
                    </Route>

                    <Route exact path="/my-issues">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><MyIssues userInfo={userInfo} /></> : <Login />}
                    </Route>

                    <Route exact path="/issue-details/:id" render={(props) => isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><IssueDetailsPage userInfo={userInfo}  {...props} /></> : <Login />} />

                    <Route exact path="/issue-details/sub-task-details/:id" render={(props) => isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><SubTaskDetails userInfo={userInfo}  {...props} /></> : <Login />} />


                    <Route exact path="/trend-analysis">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Trend /></> : <Login />}
                    </Route>
                    <Route exact path="/response-analysis">
                        {isLoggedIn ? <><SideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><ResponseAnalysis /></> : <Login />}
                    </Route>

                    {hasAccess ?
                        <>
                            <Route exact path="/settings">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Organization reloadAccountDetails={fetchAccountDetails} /></> : <Login />}
                            </Route>

                            <Route exact path="/organization-setting">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Organization reloadAccountDetails={fetchAccountDetails} /></> : <Login />}
                            </Route>

                            <Route exact path="/survey-throttling">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><SurveyThrottling /></> : <Login />}
                            </Route>

                            <Route exact path="/users">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Users /></> : <Login />}
                            </Route>

                            <Route exact path="/teams">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Teams /></> : <Login />}
                            </Route>

                            <Route exact path="/edit-team" render={(props) => isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><EditTeam {...props} /></> : <Login />} />

                            <Route exact path="/view-team" render={(props) => isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><ViewTeam {...props} /></> : <Login />} />

                            <Route exact path="/roles">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><Roles /></> : <Login />}
                            </Route>

                            <Route exact path="/create-role">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><AddRole /></> : <Login />}
                            </Route>

                            <Route exact path="/update-role" render={(props) => isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><UpdateRole {...props} reloadProfileDetails={fetchProfileDetails} /></> : <Login />} />

                            <Route exact path="/view-role" render={(props) => isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><ViewRole {...props} /></> : <Login />} />

                            <Route exact path="/view-users" render={(props) => isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><ViewUsers {...props} /></> : <Login />} />

                            <Route exact path="/channel-integration">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><ChannelIntegration /></> : <Login />}
                            </Route>

                            <Route exact path="/email-integration">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><EmailIntegration /></> : <Login />}
                            </Route>

                            <Route exact path="/sms-integration">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><SmsIntegration /></> : <Login />}
                            </Route>

                            <Route exact path="/crm-integration">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><CrmIntegration /></> : <Login />}
                            </Route>

                            <Route exact path="/app-integration">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><AppIntegration /></> : <Login />}
                            </Route>

                            <Route exact path="/contact-form">
                                {isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><ContactForm /></> : <Login />}
                            </Route>

                            <Route exact path="/create-contact-form" render={(props) => isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><CreateContactForm primaryColor={primaryColor} {...props} /></> : <Login />} />
                            <Route exact path="/update-contact-form" render={(props) => isLoggedIn ? <><SettingsSideMenu userInfo={userInfo} organizationInfo={organizationInfo} /><TopBar userInfo={userInfo} /><CreateContactForm primaryColor={primaryColor} {...props} /></> : <Login />} />

                        </>
                        : null}

                    <Route path="*">
                        <PageNotFound />
                    </Route>

                </Switch>
            </Router>
        </div>
    );
}

export default BaseLayout;