import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { dateFormat } from "../../Utils/date_formatting";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import DeleteModal from "../Common/DeleteModal";

const SegmentsGridView = (props) => {

    const [deleteUrl, setDeleteUrl] = useState([]);

    const setActionButtons = (cell, row) => {
        return (
            <div className="dropdown float-right">
                <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical text-secondary font-20"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                    <Link className="dropdown-item notify-item" to={{ pathname: '/edit-segment', state: { segmentDetail: row } }}><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></Link>
                    <Link className="dropdown-item notify-item" onClick={() => setDeleteUrl('audience/deleteSegment/' + cell)} data-toggle="modal" data-target="#deleteModal"><i className="mdi mdi-delete mr-1"></i><span>Delete</span></Link>
                </div>
            </div>
        );
    }

    const viewSegmentBtn = (cell, row) => {
        return (
            <Link to={{ pathname: '/view-segment/' + cell }}>
                <i className="mdi mdi-text-search text-secondary cursor-pointer font-20"></i>
            </Link>
        )
    }

    const gridColumns = [{
        dataField: 'name',
        text: 'Name',
        sort: true
    }, {
        dataField: 'sql_query',
        text: 'Query',
        sort: true
    }, 
    //{
    //     dataField: 'audience_count',
    //     text: 'Total Contacts',
    //     sort: true
    // }, 
    {
        dataField: 'created_on',
        text: 'Created On',
        formatter: dateFormat,
        sort: true
    }, {
        dataField: 'id',
        classes: 'py-0 text-center',
        text: 'View',
        headerStyle: { textAlign: 'center' },
        formatter: viewSegmentBtn
    }, {
        dataField: 'id',
        classes: 'py-0',
        formatter: setActionButtons
    }];

    const options = {
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        firstPageText: 'First',
        prePageText: 'Prev',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page'
    }

    return (
        <>
            <DeleteModal deleteUrl={deleteUrl} reloadGrid={props.fetchSegments} />
            <BootstrapTable
                keyField="id"
                data={props.gridData}
                columns={gridColumns}
                bordered={false}
                bootstrap4
                hover
                condensed={true}
                pagination={paginationFactory(options)}
                noDataIndication="No record found"
            />
        </>
    )
}

export default SegmentsGridView;