import axios from "axios";
import { toast } from "react-toastify";
import SurveyShareGridView from "./SurveyShareGridView";
import { useEffect, useState } from "react";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ApiNamePopup from "./ApiNamePopup";
import CreateUrlNamePopup from "./CreateUrlNamePopup";
import TabletPopup from "./TabletPopup";

const SurveyShare = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    const history = useHistory();

    const [surveyDetails, setSurveyDetails] = useState({});
    const [loader, setLoader] = useState(false);
    const [shareData, setShareData] = useState([]);
    const [totalSize, setTotalSize] = useState(0);

    useEffect(() => {
        if (props.location.state) {
            setSurveyDetails(props.location.state.surveyInfo);
        } else {
            history.push('/surveys');
        }
    }, [props.location.state]);

    const fetchShareData = (page = 1, size = 10) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/shareDataGrid';
        const formData = new FormData();
        formData.append('survey_id', props.location.state.surveyInfo.id);
        formData.append('page_count', size);
        formData.append('page_no', page);
        axios({
            method: "post",
            data: formData,
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            setShareData(response.data.data);
            setTotalSize(response.data.total_count)
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    useEffect(fetchShareData, []);

    const copyLongURL = () => {
        navigator.clipboard.writeText(encodeURI(surveyDetails.url));
        toast.success('Survey URL copied to clipboard')
    }

    const generateShortURL = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/shareByUrl';
        const formData = new FormData();
        formData.append('survey_id', surveyDetails.id);
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                navigator.clipboard.writeText(encodeURI(response.data.survey_url));
                toast.success('Survey Short URL copied to clipboard');
                fetchShareData();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const updateSurveyName = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyDetails.id);
        let URL = process.env.REACT_APP_API_KEY + 'survey/surveyNameEdit';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setSurveyDetails(response.data.data);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div className="content">
            <div className="survey-top-bar">
                <div className="row border-bottom" style={{ height: '70px' }}>
                    <div className="col-md-4 d-flex" style={{ alignItems: 'center' }}>
                        <Link to="/"><span className="logo-lg mx-2"><img src={logo ? logo : "/assets/images/logo.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '60px', paddingTop: '4px', paddingBottom: '4px' }} /></span></Link>
                        <Link to="/surveys" className=""><i className="dripicons-arrow-thin-left font-24 mr-1 text-dark"></i></Link>
                        <div className="form-group m-0 mr-2">
                            <form onSubmit={updateSurveyName} className="input-group survey-top-bar-input">
                                <input type="text" name="name" defaultValue={surveyDetails.name} className="form-control form-control-white border-light survey-input" placeholder="Survey Name" />
                                <div className="input-group-append cursor-pointer">
                                    <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                        <i className="mdi mdi-content-save font-20"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-8 d-flex justify-content-between">
                        <ul className="nav nav-tabs nav-bordered justify-content-center" style={{ alignItems: 'end' }}>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-content', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-1-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Content</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="true" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-2-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Design</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-3-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Workflow</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link active">
                                    <i className="mdi text-primary mdi-numeric-4-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Share</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-results', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-5-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Results</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="mr-2 mt-1">
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{ marginTop: '4.3rem' }}>
                    <div className="tab-content">
                        <div className="tab-pane show active" id="main_share">
                            <div className="row px-2">
                                <div className="col-12">
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <h3 className="text-center" style={{ color: "black" }}>Share your survey with following link</h3>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-2">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" value={encodeURI(surveyDetails.url)} placeholder="https://xyz.com..." aria-label="https://xyz.com..." />
                                                    <div className="input-group-append">
                                                        <button onClick={copyLongURL} className="btn btn-success" type="button"><i className="mdi mdi-content-copy mr-1"></i>COPY URL</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-center"><a className="text-primary font-weight-bold" onClick={generateShortURL}>CREATE AND COPY SHORT URL</a></p>
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>
                                    <div className="row mt-4 share-icons bg-white">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8 my-4">
                                            <h3 className="text-center" style={{ color: "black" }}>Create a new share from below</h3>
                                            <div className="row text-center mt-3">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2">
                                                    <Link to={{ pathname: '/survey-share/sms', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="text-body fw-semibold">
                                                        <div className="border p-2 mt-4 mt-lg-0 rounded">
                                                            <i className="mdi text-primary mdi-cellphone-message"></i>
                                                            <p className="m-0 align-middle">SMS</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-md-2">
                                                    <a data-toggle="modal" data-target="#create-url-name-modal" className="text-body fw-semibold">
                                                        <div className="border p-2 mt-4 mt-lg-0 rounded">
                                                            <i className="mdi text-primary mdi-link-variant-plus"></i>
                                                            <p className="m-0 align-middle">Create URL</p>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <Link to={{ pathname: '/survey-share/qr', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="text-body fw-semibold">
                                                        <div className="border p-2 mt-4 mt-lg-0 rounded cursor-pointer" data-toggle="modal" data-target="#share-via-qr">
                                                            <i className="mdi text-primary mdi-qrcode"></i>
                                                            <p className="m-0 align-middle">QR Code</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-md-2">
                                                    <Link to={{ pathname: '/survey-share/social-media', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="text-body fw-semibold">
                                                        <div className="border p-2 mt-4 mt-lg-0 rounded">
                                                            <i className="uil text-primary uil-share-alt"></i>
                                                            <p className="m-0 align-middle">Social</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="row text-center mt-3">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-2">
                                                    <a className="text-body fw-semibold" data-toggle="modal" data-target="#api-name-modal">
                                                        <div className="border p-2 mt-4 mt-lg-0 rounded">
                                                            <i className="mdi text-primary mdi-code-not-equal-variant"></i>
                                                            <p className="m-0 align-middle">API</p>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a className="text-body fw-semibold" data-toggle="modal" data-target="#tablet-modal">
                                                        <div className="border ribbon-box p-2 mt-4 mt-lg-0 rounded">
                                                            <div className="ribbon-content">
                                                                <i className="mdi text-primary mdi-tablet"></i>
                                                                <p className="m-0 align-middle">Tablet</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col-md-2">
                                                    <a className="text-body fw-semibold">
                                                        <div className="border ribbon-box p-2 mt-4 mt-lg-0 rounded">
                                                            <div className="ribbon-two ribbon-two-primary"><span className="font-10">Coming Soon!</span></div>
                                                            <div className="ribbon-content">
                                                                <i className="mdi text-primary mdi-email-send"></i>
                                                                <p className="m-0 align-middle">Send Email</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                {/* <div className="col-md-2">
                                                    <a className="text-body fw-semibold">
                                                        <div className="border ribbon-box p-2 mt-4 mt-lg-0 rounded">
                                                            <div className="ribbon-two ribbon-two-primary"><span className="font-10">Coming Soon!</span></div>
                                                            <div className="ribbon-content">
                                                                <i className="mdi  text-primary mdi-code-tags"></i>
                                                                <p className="m-0 align-middle">Embed</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div> */}
                                                <div className="col-md-2">
                                                    <a className="text-body fw-semibold">
                                                        <div className="border ribbon-box p-2 mt-4 mt-lg-0 rounded">
                                                            <div className="ribbon-two ribbon-two-primary"><span className="font-10">Coming Soon!</span></div>
                                                            <div className="ribbon-content">
                                                                <i className="mdi text-primary mdi-whatsapp"></i>
                                                                <p className="m-0 align-middle">WhatsApp</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2"></div>
                                    </div>
                                    <div className="row mt-4 mb-3">
                                        <div className="col-md-1">
                                        </div>
                                        <div className="col-md-10">
                                            <h3 className="text-center mb-3" style={{ color: "black" }}>All Shares</h3>
                                            <div className="row mt-1">
                                                <div className="col-md-12">
                                                    <SurveyShareGridView gridData={shareData} surveyDetails={surveyDetails} gridReload={fetchShareData} totalSize={totalSize} />
                                                    {loader ? <Loader /> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                        </div>
                                    </div>
                                    <ApiNamePopup surveyId={surveyDetails.id} reloadGrid={fetchShareData} />
                                    <TabletPopup surveyId={surveyDetails.id} reloadGrid={fetchShareData} />
                                    <CreateUrlNamePopup surveyId={surveyDetails.id} reloadGrid={fetchShareData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SurveyShare;