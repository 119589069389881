import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AddTeamModal from "./AddTeamModal";
import TeamsGridView from './TeamsGridView';
import DeleteModal from '../../../Common/DeleteModal';
import { Link } from "react-router-dom";
import Loader from "../../../Common/Loader";

const Teams = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [users, setUsers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [deleteUrl, setDeleteUrl] = useState([]);
    const [loader, setLoader] = useState(false);

    const fetchUsers = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'addUser';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setUsers(response.data.data);
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchTeams = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'addTeam';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setTeams(response.data.data);
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(fetchTeams, []);
    useEffect(fetchUsers, []);

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <button type="button" data-toggle="modal" data-target="#addTeamModal" className="btn btn-primary btn-sm-block float-right">ADD TEAM</button>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><Link to="/settings">Settings</Link></li>
                                        <li className="breadcrumb-item"><a>User Settings</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Teams</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Teams</h4>
                                    <p>Create a team and add users to effectively manage your surveys and account.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TeamsGridView gridData={teams} setDeleteUrl={setDeleteUrl} />
                        </div>
                    </div>
                    <AddTeamModal gridData={users} fetchTeams={fetchTeams} />
                    <DeleteModal deleteUrl={deleteUrl} reloadGrid={fetchTeams} />
                    {loader ? <Loader /> : null}
                </div>
            </div>
        </div>
    );
}

export default Teams;