import React, { useEffect, useState } from "react";
import * as SurveyAnalytics from "survey-analytics";
import "survey-analytics/survey.analytics.css";
import "tabulator-tables/dist/css/tabulator.css";
import "survey-analytics/survey.analytics.tabulator.css";
import * as SurveyAnalyticsTabulator from "survey-analytics/survey.analytics.tabulator";
import * as Survey from "survey-react";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "./Loader";

const SurveyResponses = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [showLoader, setShowLoader] = useState(true);

    const fetchSurveyResponses = (surveyId, surveyJson, shareId) => {
        const formData = new FormData();
        formData.append('survey_id', surveyId);
        if (shareId) {
            formData.append('share_id', shareId);
        }
        setShowLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'response/responseFilters';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            renderResponses(response.data.data, surveyJson)
            setShowLoader(false);
        }).catch(function (error) {
            setShowLoader(false);
            toast.error(error.toString());
        });
    }

    const renderResponses = (responseData, surveyJson) => {
        props.responseCount(responseData.length)
        var tempJson = JSON.stringify(surveyJson).replaceAll('emotionsratings', 'rating');
        if (props.tableView) {
            var surveyResultNode = document.getElementById("surveyResult");
            surveyResultNode.innerHTML = "";
            var survey = new Survey.Model(JSON.parse(tempJson));
            var visPanel = new SurveyAnalyticsTabulator.Tabulator(
                survey,
                responseData,
                { labelTruncateLength: 27 }
            );
            visPanel.showHeader = true;
            visPanel.render(surveyResultNode);
        } else {
            var surveyResultNode = document.getElementById("surveyResult");
            surveyResultNode.innerHTML = "";
            var survey = new Survey.Model(JSON.parse(tempJson));
            var visPanel = new SurveyAnalytics.VisualizationPanel(
                survey.getAllQuestions(),
                responseData,
                { labelTruncateLength: 27 }
            );
            visPanel.showHeader = true;
            visPanel.render(surveyResultNode);
        }
    }

    // const renderResponses = (responseData, surveyJson) => {
    //     var tempJson = JSON.stringify(surveyJson).replaceAll('emotionsratings', 'rating');
    //     var surveyResultNode = document.getElementById("surveyResult");
    //     surveyResultNode.innerHTML = "";
    //     var survey = new Survey.Model(JSON.parse(tempJson));
    //     var visPanel = new SurveyAnalytics.VisualizationPanel(
    //         survey.getAllQuestions(),
    //         responseData,
    //         { labelTruncateLength: 27 }
    //     );
    //     visPanel.showHeader = true;
    //     visPanel.render(surveyResultNode);
    // }

    useEffect(() => {
        fetchSurveyResponses(props.id, props.surveyJson, props.shareInfo?.id);
    }, [props]);

    return (
        <>
            <div id="surveyResult"></div>
            {showLoader ? <Loader /> : null}
        </>
    );
}

export default SurveyResponses;