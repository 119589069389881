import { useEffect } from "react";
import { useState } from "react";
import FileDragDropUpload from "../../Common/FileDragDropUpload";
import MapFieldsGridView from "../ImportAudience/MapFieldsGridView";
import csvToJSON from 'csv-file-to-json';
import AddPropertyModal from "../ImportAudience/AddPropertyModal";
import { Link, useHistory } from "react-router-dom";
import Select from 'react-select'
import CreateTagModal from "../ImportAudience/CreateTagModal";
import axios from "axios";
import { permissionName } from "../../../Utils/string_formatting";
import { toast } from "react-toastify";

const ImportFromCSV = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [csvGridData, setCsvGridData] = useState([]);
    const [properties, setProperties] = useState([]);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (csvData.length > 0) {
            csvJSON(csvData)
        }
    }, [csvData])

    const csvJSON = (csv) => {
        let reader = new FileReader();
        reader.onload = function () {
            parseJSONForGrid(csvToJSON({ data: reader.result }))
        };
        reader.readAsBinaryString(csv[0]);
    };

    const parseJSONForGrid = (data) => {
        let gridJson = [];
        let header = Object.keys(data.splice(0, 1)[0]);
        for (let i = 0; i < header.length; i++) {
            let values = [];
            for (let j = 1; j <= (data.length - 1); j++) {
                values.push(data[j][header[i]]);
                if (j === 5) {
                    break
                }
            }
            let obj = { 'columnHeader': header[i], 'sampleContent': values }
            gridJson.push(obj)
        }
        setCsvGridData(gridJson);
        document.getElementById('tab_2').click()
    }

    const finishImport = (event) => {
        setLoader(true);
        let mappedProperties = [];
        for (let i = 0; i < csvGridData.length; i++) {
            let tempKey = csvGridData[i].columnHeader;
            if (tempKey !== "") {
                mappedProperties.push({ [tempKey]: document.getElementsByName(tempKey)[0].value });
            }
        }
        let selectedKeys = [];
        let element = document.getElementsByName('selected_tags');
        for (let i = 0; i < element.length; i++) {
            selectedKeys.push(element[i].value);
        }
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('source_type', 'CSV');
        let URL = process.env.REACT_APP_API_KEY + 'audience/AudienceDataCSV';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                history.push('/audience');
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else if (response.data.status === 2) {
                toast.warning(response.data.message);
                history.push('/audience');
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchProperties = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getAllFields';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i] === "id" || data[i] === "data_set_id" || data[i] === "org_id") {
                        //do nothing
                    } else {
                        arr.push({ value: data[i], label: permissionName(data[i]) })
                    }
                }
                setProperties(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchTags = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setTags(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }
    
    const fetchSampleCsv = () => {
        let URL = process.env.REACT_APP_API_KEY + 'master/getSampleFile';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                var link = document.createElement("a");
                link.download = "SampleCSV";
                link.href = process.env.REACT_APP_API_MEDIA_KEY + response.data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchProperties, []);
    useEffect(fetchTags, []);

    return (
        <div className="content">
            <div className="row card text-center px-2 " style={{ position: 'fixed', zIndex: '999', backgroundColor: '#ffffff', width: '100%' }}>
                <div className="col-12 d-flex justify-content-between align-items-center"  >
                    <Link to="import-options" type="button" style={{ height: 'fit-content' }} className="btn btn-outline-primary btn-sm"><i className="uil uil-arrow-left mr-1"></i>Back</Link>
                    <h2 className="text-dark h2">Import from CSV</h2>
                    <button type="button" style={{ height: 'fit-content', visibility: "hidden" }} className="btn btn-primary btn-sm"><i className="uil uil-arrow-right mr-1"></i>Next</button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-tabs nav-bordered mb-3 justify-content-center border-top" style={{ position: 'fixed', marginTop: '3.3rem', width: '100%', zIndex: '999', backgroundColor: '#f7fafc' }}>
                        <li className="nav-item">
                            <a href="#home-b1" id="tab_1" data-toggle="tab" aria-expanded="false" className="nav-link active">
                                <i className="mdi text-primary mdi-numeric-1-box mr-1" style={{ fontSize: 18 }}></i>
                                <span className="d-sm-inline">Upload</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#profile-b1" id="tab_2" aria-disabled={csvData.length > 0 ? false : true} data-toggle="tab" aria-expanded="true" className="nav-link">
                                <i className="mdi text-primary mdi-numeric-2-box mr-1" style={{ fontSize: 18 }}></i>
                                <span className="d-sm-inline">Map Fields</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#settings-b1" id="tab_3" aria-disabled={csvData.length > 0 ? false : true} data-toggle="tab" aria-expanded="false" className="nav-link">
                                <i className="mdi text-primary mdi-numeric-3-box mr-1" style={{ fontSize: 18 }}></i>
                                <span className="d-sm-inline">Save Details</span>
                            </a>
                        </li>
                    </ul>
                    <form onSubmit={finishImport} className="tab-content text-center" style={{ marginTop: '7.5rem', zIndex: '-1' }}>
                        <div className="tab-pane show active" id="home-b1">
                            <p style={{ fontSize: 'xx-large', color: "black" }}>Upload your file</p>
                            <p>Before uploading, please make sure that your file is in CSV format. Here’s a <a className="text-primary" onClick={fetchSampleCsv}>sample file</a> for testing.</p>
                            <FileDragDropUpload csvData={setCsvData} />
                        </div>
                        <div className="tab-pane" id="profile-b1">
                            <p style={{ fontSize: 'xx-large', color: "black" }}>Map Contact Properties</p>
                            <p style={{ width: '70%', margin: 'auto' }}>Each column header from the file should be mapped to a corresponding property in the Survey Sparrow contact info. We have tried to map a few based on the header names. For headers that are not mapped, use the dropdown to point to a property. You can also create a new property or choose “Do not import” to ignore the contents.</p>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="m-3">
                                        <p style={{ margin: 0, textAlign: "left" }}><b>Note : </b>If you do not find you required property in the dropdown. You can add your custom property by clicking <Link className="text-primary" data-toggle="modal" data-target="#addPropertyModal"><b>here</b></Link>.</p>
                                        <MapFieldsGridView gridData={csvGridData} properties={properties} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="settings-b1" style={{ height: '75vh' }}>
                            <p style={{ fontSize: 'xx-large', color: "black" }}>Almost done!</p>
                            <div className="mx-2">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <p className="text-muted float-left m-0"><small>For your future reference, give a name to this import.</small><span className="text-danger">*</span></p>
                                            <input className="form-control" type="text" name="audience_data_name" required={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="form-group" style={{ display: "grid" }}>
                                            <div>
                                                <p className="text-muted float-left text-left m-0"><small>Assign a tag to these contacts</small><span className="text-danger">*</span></p>
                                                <p className="text-muted float-right text-right m-0" data-toggle="modal" data-target="#createTagModal"><small>or <Link className="text-primary">Create a New Tag</Link></small></p>
                                            </div>
                                            <Select options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"audience_data_tags"} />
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-0 text-center d-grid" style={{ display: 'grid' }}>
                                            <button className="btn btn-primary mt-2 mb-4" type="submit" disabled={loader}>{loader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null}Finish Import</button>
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <AddPropertyModal fetchProperties={fetchProperties} />
                    <CreateTagModal fetchTags={fetchTags} />
                </div>
            </div>
        </div>
    );
}

export default ImportFromCSV;