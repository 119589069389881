import axios from "axios";
import { toast } from "react-toastify";
import DropDown from '../../../Common/DropDown';

const UpdateTabletPopup = ({ surveyId, shareId, shareName, recurringInterval, setRecurringInterval, contactForm, setContactForm, isRecurring, setIsRecurring, kioskMode, setKioskMode, reloadGrid }) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const createCRM = (event) => {
        event.preventDefault();
        let URL = process.env.REACT_APP_API_KEY + 'audience/shareByTabMode/' + shareId;
        const formData = new FormData(event.target);
        formData.append('kiosk_mode', kioskMode);
        formData.append('is_recurring', isRecurring);
        formData.append('survey_id', surveyId);
        axios({
            method: "put",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                document.getElementById('closeUpdateTabletPopup').click();
                document.getElementById('updateTabletForm').reset();
                toast.success(response.data.message);
                reloadGrid();
                setIsRecurring(false);
                setKioskMode(false);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div id="update-tablet-modal" className="modal fade" data-backdrop="static" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <form onSubmit={createCRM} id="updateTabletForm" className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Update Tablet Details</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label>Share Name<span className="text-danger">*</span></label>
                            <input className="form-control" type="text" defaultValue={shareName} name="share_name" required={true} placeholder="Enter share name" />
                        </div>
                        <div className="form-group">
                            <label>Available Contact Forms<span className="text-danger">*</span></label>
                            <DropDown url="audience/getTabModeListing" onchange={(event) => setContactForm(event.target.value)} value={contactForm} option_label="name" name="tab_mode_key_id" />
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-12 d-flex">
                                <label className="mr-2">Kiosk Mode &nbsp;</label>
                                <input type="checkbox" id="kiosk_switch" data-switch="none" checked={kioskMode} onChange={() => setKioskMode(!kioskMode)} />
                                <label for="kiosk_switch" data-on-label="" data-off-label=""></label>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-12 d-flex">
                                <label className="mr-2">Auto Refresh</label>
                                <input type="checkbox" id="recurriddng_switch" data-switch="none" checked={isRecurring} onChange={() => setIsRecurring(!isRecurring)} />
                                <label for="recurriddng_switch" data-on-label="" data-off-label=""></label>
                            </div>
                        </div>
                        {isRecurring ?
                            <div className="row mb-2">
                                <div className="form-group col-md-8">
                                    <label>Refresh Duration<span className="text-danger">*</span></label>
                                    <input className="form-control" value={recurringInterval} onChange={(event) => setRecurringInterval(event.target.value)} type="number" min="1" name="recurring_interval" required={true} placeholder="Enter duration in seconds" />
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeUpdateTabletPopup" className="btn btn-outline-secondary" data-dismiss="modal">CANCEL</button>
                        <button type="submit" className="btn btn-primary">UPDATE</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UpdateTabletPopup;