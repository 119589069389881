//import SurveyResponses from "../../../Common/SurveyResponses";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader";
import { permissionName } from "../../../../Utils/string_formatting";
import { dateFormat } from "../../../../Utils/date_formatting";
import Select from "react-select";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";

const SurveyResult = (props) => {

    const history = useHistory();

    const TOKEN = localStorage.getItem('fms-access-token');

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    const [loader, setLoader] = useState(false);
    const [surveyResponse, setSurveyResponse] = useState([]);
    const [respondants, setRespondants] = useState([]);
    const [surveyDetails, setSurveyDetails] = useState({});
    //const [tableView, setTableView] = useState(false);
    //const [responseCount, setResponseCount] = useState(0);
    const [surveyAudienceDetails, setSurveyAudienceDetails] = useState([]);
    const [start, setStartDate] = useState(moment().subtract(29, 'days'));
    const [end, setEndDate] = useState(moment());

    const [tags, setTags] = useState([]);
    const [segments, setSegments] = useState([]);
    const [channels, setChannels] = useState([]);
    const [shares, setShares] = useState([]);


    useEffect(() => {
        if (props.location.state) {
            setSurveyDetails(props.location.state.surveyInfo);
            fetchRespondants(props.location.state.surveyInfo.id)
        } else {
            history.push('/surveys');
        }
    }, [props.location.state]);

    const fetchRespondants = (surveyId) => {
        setLoader(true);
        const formData = new FormData();
        formData.append('survey_id', surveyId);
        let URL = process.env.REACT_APP_API_KEY + 'response/getSurveyResponsesAudienceList';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setRespondants(response.data.data)
                if (response.data.data.length > 0) {
                    fetchSurveyAudienceDetails(response.data.data[0].id)
                } else {
                    setLoader(false);
                }
                fetchShares(surveyId);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
                setLoader(false);
            } else {
                toast.error('Someting went wrong');
                setLoader(false);
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchSurveyAudienceDetails = (id) => {
        var elements = document.getElementsByClassName("reports-scroll-bg");

        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('selectedCard');
        }

        document.getElementById("respondant_card_" + id).classList.add('selectedCard')

        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'response/getDetailedResponse/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setSurveyAudienceDetails(response.data.data)
                setSurveyResponse(response.data.data.response_data)
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
            setLoader(false);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const updateSurveyName = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyDetails.id);
        let URL = process.env.REACT_APP_API_KEY + 'survey/surveyNameEdit';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setSurveyDetails(response.data.data);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchSegments = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setSegments(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchTags = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setTags(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchChannels = () => {
        let URL = process.env.REACT_APP_API_KEY + 'master/getChannelTypes';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i], label: permissionName(data[i]) })
                }
                setChannels(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchShares = (id) => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getShareData?survey_id=' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: data[i].share_name })
                }
                setShares(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchTags, []);
    useEffect(fetchSegments, []);
    useEffect(fetchChannels, []);

    const onDateChanged = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');

    const gridFilter = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyDetails.id);
        formData.append('from_date', start.format('YYYY-MM-DD'));
        formData.append('to_date', end.format('YYYY-MM-DD'));
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'response/responseFilters';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {

            setLoader(false);
            document.getElementById('closeFilterModal').click();
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const search = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyDetails.id);
        formData.append('search_string', formData.get('search'));
        let URL = process.env.REACT_APP_API_KEY + 'response/getSurveyResponsesAudienceList';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            setRespondants(response.data.data);
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }


    return (
        <div className="content">
            <div className="survey-top-bar">
                <div className="row border-bottom" style={{ height: '70px' }}>
                    <div className="col-md-4 d-flex" style={{ alignItems: 'center' }}>
                        <Link to="/"><span className="logo-lg mx-2"><img src={logo ? logo : "/assets/images/logo.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '60px', paddingTop: '4px', paddingBottom: '4px' }} /></span></Link>
                        <Link to="/surveys" className=""><i className="dripicons-arrow-thin-left font-24 mr-1 text-dark"></i></Link>
                        {/* <h3 className="h3 muted mt-1">{surveyDetails.name}</h3> */}
                        {/* <input className="form-control survey-input pt-1" value={surveyDetails.name}  type="text"/> */}
                        <div className="form-group m-0 mr-2">
                            <form onSubmit={updateSurveyName} className="input-group survey-top-bar-input">
                                <input type="text" name="name" defaultValue={surveyDetails.name} className="form-control form-control-white border-light survey-input" placeholder="Survey Name" />
                                <div className="input-group-append cursor-pointer">
                                    <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                        <i className="mdi mdi-content-save font-20"></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-8 d-flex justify-content-between">
                        <ul className="nav nav-tabs nav-bordered justify-content-center" style={{ alignItems: 'end' }}>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-content', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-1-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Content</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="true" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-2-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Design</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-3-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Workflow</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-4-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Share</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-results', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link active">
                                    <i className="mdi text-primary mdi-numeric-5-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Results</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="mr-2 mt-1">
                            {/* <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} type="button" style={{ height: 'fit-content' }} className="btn btn-primary btn-sm ml-2 mr-1">Next<i className="uil uil-arrow-right ml-1"></i></Link> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-12" style={{ marginTop: '4.3rem' }}>
                    <div className="tab-content">
                        <div className="tab-pane show active" id="main_share">
                            <div className="row px-2">
                                <div className="col-md-1"></div>
                                <div className="col-10">
                                    <div className="row mt-4 px-3 page-title-box d-flex justify-content-between">
                                        <h4 className="page-title">{surveyDetails.name} ({responseCount})</h4>
                                        <input type="checkbox" checked={tableView} onChange={() => setTableView(!tableView)} id="switch12" data-switch="primary" />
                                        <label htmlFor="switch12" className="mr-1 border p-2" style={{ alignSelf: "center" }} data-on-label="Table" data-off-label="Chart"></label>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            {surveyDetails.id ? <SurveyResponses id={surveyDetails.id} responseCount={setResponseCount} tableView={tableView} surveyJson={surveyDetails.json} /> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            <div className="content-page bg-white" style={{ marginLeft: '0px' }}>
                <div className="content">
                    <div className="row">
                        <div className="col-md-3 pl-3 pr-0 bg-secondary-custom" style={{ position: 'fixed', minHeight: '100vh' }}>
                            <div className="mt-4">
                                <div className="form-group m-0 mr-3">
                                    <form onSubmit={search} className="input-group">
                                        <input type="text" name="search" className="form-control form-control-white border-light" placeholder="Search" />
                                        <div className="input-group-append cursor-pointer">
                                            <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                                <i className="mdi mdi-magnify font-20"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                {/* <div className="d-flex justify-content-between mt-1 mr-3 survey-results-sort">
                                    <div className="d-flex" style={{ alignItems: 'baseline' }}>
                                        <i class="mdi mdi-sort-reverse-variant mdi-rotate-180 mdi-18px text-primary"></i>
                                        <select class="form-control cursor-pointer form-select form-select-sm select-custom">
                                            <option selected>Most Recent</option>
                                            <option value="1">Most Recent</option>
                                            <option value="2">Most Recent</option>
                                            <option value="3">Most Recent</option>
                                        </select>
                                    </div>

                                    <a href="" class="action-icon text-primary"> <i class="mdi mdi-tune  mdi-18px"></i></a>
                                </div> */}
                            </div>
                            <div className="mt-3 reports-scroll card">
                                {respondants.map((obj, index) => {
                                    return (
                                        <>
                                            <div onClick={() => fetchSurveyAudienceDetails(obj.id)} id={"respondant_card_" + obj.id} className="d-flex justify-content-between align-items-start px-2 py-2 reports-scroll-bg">
                                                <div className="d-flex">
                                                    {/* <input className="mt-1 mr-1 me-1 cursor-pointer" type="checkbox" value="" aria-label="..." /> */}
                                                    <div className="d-flex flex-column">
                                                        <label className="form-check-label cursor-pointer">{obj.first_name}</label>
                                                        <div className="w-100 overflow-hidden">
                                                            <small className="text-muted"><i className="mdi mdi-email-outline me-1 mr-1"></i>{permissionName(obj.channel_type)}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-muted">{dateFormat(obj.created_on ? obj.created_on : '')}</p>
                                            </div>
                                            <hr className="my-0" />
                                        </>
                                    )
                                })

                                    // <div className="d-flex justify-content-between align-items-start px-2 py-2">
                                    //     <div className="d-flex">
                                    //         <input className=" mt-1 mr-1 me-1" id="customCheck4" type="checkbox" value="" aria-label="..." />
                                    //         <div className="d-flex flex-column">
                                    //             <label className="form-check-label" for="customCheck4">Gaurav Bhalla</label>
                                    //             <div className="w-100 overflow-hidden">
                                    //                 <small className="text-muted"><i className="mdi mdi-email-outline me-1 mr-1"></i>Mail</small>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    //     <p className="text-muted">11:30am</p>
                                    // </div>

                                }
                            </div>
                        </div>
                        <div className="col-md-9  pt-2 pl-4 ml-auto">
                            <div className="card mb-2 shadow-none">
                                <div className="p-3">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div className="avatar-sm">
                                                <span className="avatar-title rounded">A</span>
                                            </div>
                                        </div>
                                        <div className="col pl-0">
                                            <Link to={"/audience-details/" + surveyAudienceDetails?.nested_data_id}>
                                                <span className="text-dark font-weight-bold">{surveyAudienceDetails.first_name} {surveyAudienceDetails.second_name}</span>
                                            </Link>
                                            <p className="text-muted mb-0">
                                                {surveyAudienceDetails.phone ?
                                                    <>
                                                        <i className="mdi mdi-phone mr-1"></i>{surveyAudienceDetails.phone}
                                                    </>
                                                    : null}
                                                {surveyAudienceDetails.email ?
                                                    <>
                                                        <span className="mx-2">|</span>
                                                        <i className="mdi mdi-email mr-1"></i> {surveyAudienceDetails.email}
                                                    </>
                                                    : null}
                                            </p>
                                        </div>
                                        {/* <div className="col-auto">
                                                    <p className="text-muted">
                                                        9d : 17hr : 38min : 8sec
                                                    </p>
                                                </div> */}
                                    </div>
                                </div>

                                <div className="row px-3">
                                    <div className="col-md-11">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Survey Name</h5>
                                                <p>{surveyAudienceDetails?.survey_name}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Share Name</h5>
                                                <p>{surveyAudienceDetails?.share_name}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Channel</h5>
                                                <p>{permissionName(surveyAudienceDetails?.channel_type ? surveyAudienceDetails?.channel_type : '')}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Response Date</h5>
                                                <p>{dateFormat(surveyAudienceDetails?.created_on ? surveyAudienceDetails?.created_on : '')}</p>
                                            </div>
                                        </div>
                                        {/* <div className="row collapse" id="audience-more-details">
                                             <div className="col-md-3">
                                                        <h5 className="text-muted font-weight-normal">Started Time</h5>
                                                        <p>{surveyAudienceDetails?.started_time}</p>
                                                    </div>
                                             <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Source</h5>
                                                <p>{surveyAudienceDetails?.source}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">IP Address</h5>
                                                <p>{surveyAudienceDetails?.ip_address}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <h5 className="text-muted font-weight-normal">Location of Respondent</h5>
                                                <p>{surveyAudienceDetails?.source}</p>
                                            </div>
                                        </div> */}
                                        <hr />

                                        <div className="audience-details-content">
                                            <div className="row collapse" id="response-more-details">
                                                <div className="col-md-12 mt-2">
                                                    <h4>Response</h4>
                                                    {surveyResponse.length === 0 ? <p>No Response Found</p> :
                                                        surveyResponse.map((obj, i) => {
                                                            return <div className="col-md-12 mt-2">
                                                                <div className="faq-question-q-box font-12">Q.{i + 1}</div>
                                                                <h4 className="faq-question" data-wow-delay=".1s">{obj.question?.default ? obj.question?.default : obj.question}</h4>
                                                                {Array.isArray(obj.answer) ?
                                                                    obj.answer.map((obj) => {
                                                                        return <p className="faq-answer mb-1">{obj.default}</p>
                                                                    })
                                                                    :
                                                                    obj.answer === "" ? <><p className="mb-4"></p></> :
                                                                        <p className="faq-answer mb-2">{obj.answer}</p>
                                                                }
                                                            </div>
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-1">
                                        <a href="#audience-more-details" type="button" data-toggle="collapse" aria-expanded="true" className="btn btn-sm btn-outline-dark mt-2"><i className="uil uil-angle-down"></i></a>
                                    </div> */}
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card px-2">
                                            {surveyResponse.length === 0 ? <p>No Response Found</p> :
                                                surveyResponse.map((obj, i) => {
                                                    return <div className="col-md-12 mt-2">
                                                        <div className="faq-question-q-box font-12">Q.{i + 1}</div>
                                                        <h4 className="faq-question" data-wow-delay=".1s">{obj.question?.default ? obj.question?.default : obj.question}</h4>
                                                        {Array.isArray(obj.answer) ?
                                                            obj.answer.map((obj) => {
                                                                return <p className="faq-answer mb-1">{obj.default}</p>
                                                            })
                                                            :
                                                            obj.answer === "" ? <><p className="mb-4"></p></> :
                                                                <p className="faq-answer mb-2">{obj.answer}</p>
                                                        }
                                                    </div>
                                                })}
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade" id="right-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-lg modal-right" style={{ minWidth: 400, maxWidth: 600 }}>
                                        <form onSubmit={gridFilter} id="sidePopupFilterForm" className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title" id="myLargeModalLabel">Filter Survey Responses</h4>
                                                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                                                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                                                </button>
                                            </div>
                                            <div className="modal-body" style={{ maxWidth: '400px' }}>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Segments</label>
                                                        <Select options={segments} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"segments"} />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Select Date Range</label>
                                                        <DateRangePicker
                                                            initialSettings={{
                                                                startDate: start.toDate(),
                                                                endDate: end.toDate(),
                                                                ranges: {
                                                                    Today: [moment().toDate(), moment().toDate()],
                                                                    Yesterday: [
                                                                        moment().subtract(1, 'days').toDate(),
                                                                        moment().subtract(1, 'days').toDate(),
                                                                    ],
                                                                    'Last 7 Days': [
                                                                        moment().subtract(6, 'days').toDate(),
                                                                        moment().toDate(),
                                                                    ],
                                                                    'Last 30 Days': [
                                                                        moment().subtract(29, 'days').toDate(),
                                                                        moment().toDate(),
                                                                    ],
                                                                    'This Month': [
                                                                        moment().startOf('month').toDate(),
                                                                        moment().endOf('month').toDate(),
                                                                    ],
                                                                    'Last Month': [
                                                                        moment().subtract(1, 'month').startOf('month').toDate(),
                                                                        moment().subtract(1, 'month').endOf('month').toDate(),
                                                                    ],
                                                                },
                                                            }}
                                                            onCallback={onDateChanged}>
                                                            <div id="reportrange" className="form-control">
                                                                <i className="fa fa-calendar"></i>&nbsp;
                                                                <span>{label}</span> <i className="fa fa-caret-down"></i>
                                                            </div>
                                                        </DateRangePicker>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Tags</label>
                                                        <Select options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"tags"} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Shares</label>
                                                        <Select options={shares} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"share_id"} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <label>Channels</label>
                                                        <Select options={channels} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"channel_type"} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer align-footer-bottom">
                                                <button type="button" id="closeFilterModal" className="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                                                <button type="submit" className="btn btn-primary">Apply Filters</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {loader ? <Loader /> : null}
                </div>
            </div>
        </div >
    );
}

export default SurveyResult;