import { useEffect, useState } from "react";
import { adjust } from "../../Utils/color_formatting";
import TimeZoneDropDown from "../Common/TimeZoneDropDown";
import axios from "axios";
import { toast } from "react-toastify";

const OrganizationRegistration = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [timezone, setTimezone] = useState("0");
    const [primaryColor, setPrimaryColor] = useState("#6668E1");
    const [secondaryColor, setSecondaryColor] = useState("#FF836A");

    useEffect(() => {
        document.documentElement.style.setProperty('--custom-primary-color', primaryColor ? primaryColor : '#000000');
        document.documentElement.style.setProperty('--custom-primary-dark-color', adjust(primaryColor, -20));
        document.documentElement.style.setProperty('--custom-focus-color', primaryColor + '26');
        document.documentElement.style.setProperty('--custom-secondary-color', secondaryColor);
        document.documentElement.style.setProperty('--custom-focus-secondary-color', secondaryColor + '26');
    }, [primaryColor, secondaryColor]);

    const registerOrganization = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'orgSettings';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeModal').click();
                document.getElementById('orgRegForm').reset();
                props.fetchOrgData();
                props.fetchUserData();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <>
            <button type="button" id="org_reg_btn" data-backdrop="static" className="btn btn-danger d-none" data-toggle="modal" data-target="#org_reg">Danger Header</button>
            <div id="org_reg" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="danger-header-modalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <form onSubmit={registerOrganization} id="orgRegForm" className="modal-content">
                        <div className="modal-header modal-colored-header bg-danger">
                            <h4 className="modal-title" id="danger-header-modalLabel">Organization Registration</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Organization Name<span className="text-danger">*</span></label>
                                <input type="text" name="org_name" className="form-control" required/>
                            </div>
                            <div className="form-group">
                                <label>Timezone<span className="text-danger">*</span></label>
                                <TimeZoneDropDown url="timezone" onchange={event => setTimezone(event.target.value)} value={timezone} name="timezone" />
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Primary Color</label>
                                        <input className="form-control color-picker-circular" type="color" value={primaryColor} name="primary_color" onChange={(event) => setPrimaryColor(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Secondary Color</label>
                                        <input className="form-control color-picker-circular" type="color" value={secondaryColor} name="secondry_color" onChange={(event) => setSecondaryColor(event.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="closeModal" className="btn btn-light d-none" data-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-danger">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default OrganizationRegistration;