import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "../../../Common/Loader";
import ContactFormGridView from "./ContactFormGridView";
import DeleteModal from "../../../Common/DeleteModal";
import ContactFormPreview from "./ContactFormPreview";

const ContactForm = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const defaultForm = {
        "logoPosition": "right",
        "pages": [
            {
                "name": "page1",
                "elements": [
                    {
                        "type": "text",
                        "name": "first_name",
                        "title": "First Name",
                        "isRequired": true
                    },
                    {
                        "type": "text",
                        "name": "last_name",
                        "title": "Last Name"
                    },
                    {
                        "type": "text",
                        "name": "phone",
                        "title": "Phone",
                        "isRequired": true,
                        "inputType": "tel"
                    },
                    {
                        "type": "text",
                        "name": "email",
                        "title": "Email",
                        "inputType": "email"
                    }
                ]
            }
        ]
    }

    const [contactForms, setContactForms] = useState([]);
    const [loader, setLoader] = useState(false);
    const [deleteUrl, setDeleteUrl] = useState([]);
    const [contactFormData, setContactFormData] = useState(defaultForm);

    const fetchContactForms = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/getTabModeListing';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setContactForms(response.data.data);
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    useEffect(fetchContactForms, []);

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3 mb-0 pb-0">
                                    <li className="breadcrumb-item"><Link>Settings</Link></li>
                                    <li className="breadcrumb-item"><a>Integrations</a></li>
                                    <li className="breadcrumb-item"><Link to="/channel-integration">Channel</Link></li>
                                    <li className="breadcrumb-item active">Contact Form</li>
                                </ol>
                            </nav>
                            <Link to={{ pathname: '/create-contact-form', state: { isUpdate: false, formId: null, formName: null, formData: defaultForm } }} type="button" className="btn btn-primary btn-sm-block float-right">CREATE CONTACT FORM</Link>
                            <div className="page-title-box">
                                <h4 className="page-title">Contact Forms</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3 className="text-secondary m-3">Your Contact Form List</h3>
                                        <ContactFormGridView gridData={contactForms} setContactFormData={setContactFormData} fetchContactForms={fetchContactForms} setDeleteUrl={setDeleteUrl} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DeleteModal deleteUrl={deleteUrl} reloadGrid={fetchContactForms} />
                    <ContactFormPreview contactFormData={contactFormData} />
                    {loader ? <Loader /> : null}
                </div>
            </div>
        </div>
    );
}

export default ContactForm;