import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';

import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import './styles.scss';
import Reports from '../Settings/Reports/Reports';
import { Link, NavLink } from 'react-router-dom';
import Loader from '../Common/Loader';


const AdvancedAnalytics = () => {
  const [dashboardList, setDashboardList] = useState([]);
  const [loader, setLoader] = useState(false);

  const superSetDashboardList = () => {
    let URL = process.env.REACT_APP_API_KEY + 'reports/supersetDashboardList';
    const TOKEN = localStorage.getItem('fms-access-token');
    setLoader(true);
    axios({
      method: "post",
      url: URL,
      headers: {
        Authorization: 'Token ' + TOKEN
      }
    }).then(function (response) {
      if (response.data.status === 1) {
        setLoader(false);
        setDashboardList(response.data.data.dashboard_list);
        localStorage.setItem('SUPERSET_DOMAIN', response.data.data.domain);
        localStorage.setItem('SUPERSET_TOKEN', response.data.token_data.access_token);
      }
    }).catch(function (error) {
      setLoader(false);
      toast.error(error.toString());
    });
  }

  useEffect(() => {
    superSetDashboardList();
  }, [!dashboardList]);

  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mt-3 mb-0 pb-0">
                    <li className="breadcrumb-item"><a>Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Advanced Analytics</li>
                  </ol>
                </nav>
                <div className="page-title-box">
                  <h4 className="page-title">Advanced Analytics</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mt-4">
              <div style={{ backgroundColor: 'rgb(247, 250, 252)' }}>

                {dashboardList.map((obj, i) => {

                  return <div class="card">
                    <div class="card-body py-2 px-3">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="d-flex justify-content-between">
                            <h4 className="text-muted"><i class="mdi mdi-file-document mr-1"></i>{obj.name}</h4>
                            <a href={"#issue-reports_" + i}
                              onClick={() => {
                                document.getElementById("rotate-arrow-outer_20").classList.toggle('mdi-rotate-180');
                              }}
                              id='toogle-button'
                              type="button" checked data-toggle="collapse" aria-expanded="true" class="btn btn-sm btn-outline-dark my-1">
                              <i id={"rotate-arrow-outer_20"} class="uil uil-angle-down"></i>
                            </a>
                          </div>
                          <div class="row ">
                            <div class="col-md-12">
                              <div class="row expand mt-3" id={"issue-reports_" + i}>
                                {obj.reports_list.map((obj) => {
                                  return <div className="col-md-4">
                                    <div class="card mb-1 shadow-none border">
                                      <div class="p-2">
                                        <div class="row align-items-center">
                                          <div class="col-auto">
                                            <i class="mdi mdi-file-edit widget-icon bg-primary-lighten text-primary"></i>
                                          </div>
                                          <div class="col ps-0 pl-0">
                                            <Link
                                              class="text-primary"
                                              role={'link'}
                                              to={{ pathname: window.location.pathname + `/${obj.name}` }}
                                              target='_blank'
                                              onClick={() => {
                                                localStorage.setItem('ID_OBJ', JSON.stringify({ "id": obj.id, "embedId": obj.embed_id }))
                                              }}
                                            >
                                              <h5>{obj.name}</h5>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader ? <Loader /> : null}
    </div >
  );
}

export default AdvancedAnalytics;