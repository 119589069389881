import axios from "axios";
import { useEffect, useState } from "react";
import OrganizationRegistration from "./OrganizationRegistration";
import ReactApexChart from 'react-apexcharts'
import { toast } from "react-toastify";
import Loader from '../Common/Loader';

const Dashboard = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [loader, setLoader] = useState(false);
    const [organizationInfo, setOrganizationInfo] = useState({});
    const [dashboardData, setDashboardData] = useState([]);
    const [doughnutCount, setDoughnutCount] = useState([]);
    const [doughnutLabels, setDoughnutLabels] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const [lineChartDates, setLineChartDates] = useState([]);

    const fetchDashboardData = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'response/responsesByChannels';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                setLoader(false);
                setDashboardData(response.data.data);
                setDoughnutCount(response.data.data.doughnut.count);
                setDoughnutLabels(response.data.data.doughnut.labels);
                setLineChartData(response.data.data.line_chart.data);
                setLineChartDates(response.data.data.line_chart.dates);
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchAccountDetails = () => {
        let URL = process.env.REACT_APP_API_KEY + 'orgSettings';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                fetchDashboardData();
                document.getElementById('closeModal').click();
                setOrganizationInfo(response.data.data);
            } else if (response.data.status === 0) {
                //Organization does not exist
                setOrganizationInfo(response.data.data);
                document.getElementById('org_reg_btn').click();
            }
        }).catch(function (error) {
            //toast.error(error.toString());
        });
    }

    useEffect(fetchAccountDetails, []);

    // const responseTrend = {
    //     series: [{
    //         name: "Desktops",
    //         data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 34, 67, 12]
    //     }],
    //     options: {
    //         chart: {
    //             height: 350,
    //             type: 'line',
    //             zoom: {
    //                 enabled: false
    //             }
    //         },
    //         dataLabels: {
    //             enabled: false
    //         },
    //         stroke: {
    //             curve: 'straight'
    //         },

    //         grid: {
    //             row: {
    //                 colors: ['#f3f3f3', 'transparent'],
    //                 opacity: 0.5
    //             },
    //         },
    //         xaxis: {
    //             categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //         }
    //     },
    // }

    const respondedByChannel = {
        series: doughnutCount,
        options: {
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            labels: doughnutLabels,
        },
    };

    // const surveyShareStatus = {
    //     series: [44, 55, 67, 83, 34],
    //     options: {
    //         chart: {
    //             height: 350,
    //             type: 'radialBar',
    //         },
    //         plotOptions: {
    //             radialBar: {
    //                 dataLabels: {
    //                     name: {
    //                         fontSize: '22px',
    //                     },
    //                     value: {
    //                         fontSize: '16px',
    //                     },
    //                     total: {
    //                         show: true,
    //                         label: 'Total',
    //                         formatter: function (w) {
    //                             return 249
    //                         }
    //                     }
    //                 }
    //             }
    //         },
    //         labels: ['Send', 'Delivered', 'Opens', 'Clicked', 'Responses'],
    //     },
    // };

    const campaignMatrices = {
        series: lineChartData,
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 3,
                curve: 'straight',
                dashArray: 0
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                }
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            xaxis: {
                categories: lineChartDates,
            },

            tooltip: {
                y: [
                    {
                        title: {
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val;
                            }
                        }
                    }
                ]
            },
            grid: {
                borderColor: '#f1f1f1',
            }
        },
    }

    // const npsTrend = {
    //     series: [{
    //         name: 'Detrators',
    //         data: [44, -55, 41, 67, 22, 43]
    //     }, {
    //         name: 'Passives',
    //         data: [13, 23, 20, -8, 13, 27]
    //     }, {
    //         name: 'Promoters',
    //         data: [11, 17, 15, 15, 21, -14]
    //     }],
    //     options: {
    //         chart: {
    //             type: 'bar',
    //             height: 350,
    //             stacked: true,
    //             toolbar: {
    //                 show: true
    //             },
    //             zoom: {
    //                 enabled: true
    //             }
    //         },
    //         responsive: [{
    //             breakpoint: 480,
    //             options: {
    //                 legend: {
    //                     position: 'bottom',
    //                     offsetX: -10,
    //                     offsetY: 0
    //                 }
    //             }
    //         }],
    //         plotOptions: {
    //             bar: {
    //                 horizontal: false,
    //                 borderRadius: 10
    //             },
    //         },
    //         xaxis: {
    //             type: 'datetime',
    //             categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT',
    //                 '01/05/2011 GMT', '01/06/2011 GMT'
    //             ],
    //         },
    //         legend: {
    //             position: 'right',
    //             offsetY: 40
    //         },
    //         fill: {
    //             opacity: 1
    //         }
    //     },
    // };

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    {organizationInfo ?
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb mt-3 mb-0 pb-0">
                                                <li className="breadcrumb-item"><a>Home</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                                            </ol>
                                        </nav>
                                        <div className="page-title-box">
                                            <h4 className="page-title">Dashboard</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-12">
                                    <div className="card py-2 px-4">
                                        <h4>Response Trend</h4>
                                        <ReactApexChart options={responseTrend.options} series={responseTrend.series} type="line" height={350} />
                                    </div>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card py-2 px-4">
                                        <h4>Responses by Channel</h4>
                                        <ReactApexChart options={respondedByChannel.options} series={respondedByChannel.series} type="donut" height={350} />
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="card py-2 px-4">
                                        <h4>Survey Share Status</h4>
                                        <ReactApexChart options={surveyShareStatus.options} series={surveyShareStatus.series} type="radialBar" height={350} />
                                    </div>
                                </div> */}

                                <div className="col-md-6">
                                    <div className="card py-2 px-4">
                                        <h4>Responses by Campaign</h4>
                                        <ReactApexChart options={campaignMatrices.options} series={campaignMatrices.series} type="line" height={350} />
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="card py-2 px-4">
                                        <h4>NPS Trend</h4>
                                        <ReactApexChart options={npsTrend.options} series={npsTrend.series} type="bar" height={350} />
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card text-center p-3 tilebox-one">
                                        <i className="uil uil-users-alt float-right"></i>
                                        <h3 className="">{dashboardData.total_audience}</h3>
                                        <h5 className="text-muted font-weight-normal">Total Audience</h5>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card text-center p-3 tilebox-one">
                                        <i className="mdi mdi-bell-cancel-outline float-right"></i>
                                        <h3 className="">{dashboardData.total_responses}</h3>
                                        <h5 className="text-muted font-weight-normal">Total Responses</h5>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card text-center p-3 tilebox-one">
                                        <i className="mdi mdi-message-alert-outline float-right"></i>
                                        <h3 className="">{dashboardData.total_surveys}</h3>
                                        <h5 className="text-muted font-weight-normal">Total Surveys</h5>
                                    </div>
                                </div>
                                {/* <div className="col-lg-3">
                                    <div className="card text-center p-3 tilebox-one">
                                        <i className="mdi mdi-list-status float-right"></i>
                                        <h3 className="">5</h3>
                                        <h5 className="text-muted font-weight-normal">Response Rate</h5>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-3">
                                    <div className="card text-center p-3 tilebox-one">
                                        <i className="uil uil-smile float-right"></i>
                                        <h3 className="">20</h3>
                                        <h5 className="text-muted font-weight-normal">NPS</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card text-center p-3 tilebox-one">
                                        <i className="mdi mdi-gauge-low float-right"></i>
                                        <h3 className="">3.5</h3>
                                        <h5 className="text-muted font-weight-normal">CSAT</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card text-center p-3 tilebox-one">
                                        <i className="mdi mdi-bug-outline float-right"></i>
                                        <h3 className="">5</h3>
                                        <h5 className="text-muted font-weight-normal">Open Issues</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card text-center p-3 tilebox-one">
                                        <i className="mdi mdi-bug-check-outline float-right"></i>
                                        <h3 className="">20</h3>
                                        <h5 className="text-muted font-weight-normal">Closed Issues</h5>
                                    </div>
                                </div>
                            </div> */}
                        </>
                        : <OrganizationRegistration fetchOrgData={fetchAccountDetails} fetchUserData={props.fetchUserData} />}
                </div>
                {loader ? <Loader /> : null}
            </div>
        </div>
    );
}

export default Dashboard;