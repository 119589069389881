import Loader from '../Common/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { permissionName } from '../../Utils/string_formatting';
import Select from 'react-select'
import 'suneditor/dist/css/suneditor.min.css';
import ViewAttachmentModal from './ViewAttachmentModal';
import AddAttachmentModal from './AddAttachmentModal';
import CustomerResponse from './CustomerResponse/CustomerResponse';
import Timeline from './Timeline/Timeline';
import Comments from './IssueSummary/Comments';
import IssueSummary from './IssueSummary/IssueSummary';
import TopDetails from './TopDetails';

const SubTaskDetails = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [issueDetails, setIssueDetails] = useState([]);
    const [loader, setLoader] = useState(false);
    const [tags, setTags] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [status, setStatus] = useState([]);
    const [users, setUsers] = useState([]);
    const [team, setTeam] = useState([]);
    const [typess, setTypess] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState("0");
    const [selectedStatus, setSelectedStatus] = useState("0");
    const [issueSummary, setIssueSummary] = useState(null);
    const [tat, setTat] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);
    const [userInfo, setUserInfo] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [timeline, setTimeline] = useState([]);
    const [mentionUsers, setMentionUsers] = useState([]);

    useEffect(() => {
        fetchIssueDetails(props.match.params.id);
        setUserInfo(props.userInfo);
        fetchTimeline(props.match.params.id);
    }, [props.userInfo])

    const fetchIssueDetails = (id) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'issues/getAssignedIssue/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            setIssueDetails(response.data.data);
            setSelectedPriority(response.data.data.priority);
            setSelectedStatus(response.data.data.status);
            setIssueSummary(response.data.data.summary);
            setTat(Number(response.data.data.tat_time_left));
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchTags = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setTags(arr);
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchPriorities = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'master/getIssuePriorities';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                setPriorities(response.data.data)
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchStatus = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'master/getIssueStatus';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                setStatus(response.data.data)
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchTeams = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'addTeam';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].team_name) })
                }
                setTeam(arr);
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const fetchUsers = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'addUser';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            // if (response.data.status === 1) {
            //     let arr = [];
            //     let data = response.data.data;
            //     for (let i = 0; i < data.length; i++) {
            //         arr.push({ value: data[i].user_id, label: permissionName(data[i].name) })
            //     }
            //     setUsers(arr);
            // }

            if (response.data.status === 1) {
                let data = response.data.data;

                let arr1 = [];
                for (let i = 0; i < data.length; i++) {
                    arr1.push({ value: data[i].user_id, label: permissionName(data[i].name) })
                }
                setUsers(arr1);

                let arr2 = [];
                for (let i = 0; i < data.length; i++) {
                    arr2.push({ id: data[i].user_id, display: permissionName(data[i].first_name) + '(' + data[i].email + ')' })
                }
                setMentionUsers(arr2);
            }

            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    const fetchTypes = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'issues/issue_type';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setTypess(arr);
            }
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    const fetchAttachments = () => {
        let URL = process.env.REACT_APP_API_KEY + 'issues/getIssueAttachement/' + issueDetails.id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setAttachments(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchTimeline = (id) => {
        let URL = process.env.REACT_APP_API_KEY + 'issues/getTimelineData/' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setTimeline(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(fetchPriorities, []);
    useEffect(fetchTypes, []);
    useEffect(fetchStatus, []);
    useEffect(fetchTeams, []);
    useEffect(fetchUsers, []);
    useEffect(fetchTags, []);

    const selectFormatter = (data) => {
        if (data) {
            for (let i = 0; i < data.length; i++) {
                data[i]['label'] = data[i]['name']
                data[i]['value'] = data[i]['id']
            }
            return data;
        }
    }

    const updateIssue = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        if (formData.get('owner_list') === null) {
            for (let i = 0; i < issueDetails.owner_list.length; i++) {
                formData.append('owner_list', issueDetails.owner_list[i].id)
            }
        }
        if (issueSummary) {
            formData.append('summary', issueSummary);
        }
        let URL = process.env.REACT_APP_API_KEY + 'issues/updateAssignedIssue/' + issueDetails.id;
        axios({
            method: "put",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then((response) => {
            if (response.data.status === 1) {
                fetchIssueDetails(issueDetails.id);
                fetchTimeline(issueDetails.id);
                toast.success(response.data.message);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
            setLoader(false);
        }).catch((error) => {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTat(tat - 1)
            setTimeLeft(Math.floor(tat / (60 * 60 * 24)) + 'd : ' +
                Math.abs(Math.floor((tat / (60 * 60)) % 24)) + 'hr : ' +
                Math.abs(Math.floor((tat / 60) % 60)) + 'min : ' +
                Math.abs(Math.floor((tat) % 60)) + 'sec');
            //setTimeLeft("Overdue")
            return () => clearInterval(intervalId);
        }, 1000);
        return () => clearInterval(intervalId);
    });

    const checkOwner = () => {
        if (issueDetails.owner_list) {
            for (let i = 0; i < issueDetails.owner_list.length; i++) {
                if (issueDetails.owner_list[i].email === userInfo.email) {
                    return false;
                }
            }
            if (issueDetails.owner_list.length === 0) {
                return false;
            }
            return true;
        }
    }

    const enableDisableStatus = () => {
        let statusValue = null;

        for (let i = 0; i < status.length; i++) {
            if (issueDetails.status === status[i].id) {
                statusValue = status[i].status;
                break
            }
        }

        if (statusValue === 'open' || statusValue === 'work_in_progress' || statusValue === 'resolved') {
            return false
        }

        if (statusValue === 'overdue' || statusValue === 'reopened' || statusValue === 'closed') {
            if (checkOwner()) {
                return true
            }
        }
    }

    const addStatusCheck = (obj) => {
        if (obj.status === 'overdue') {
            return <option disabled={true} value={obj.id}>{permissionName(obj.status)}</option>
        } else if (obj.status === 'reopened' || obj.status === 'closed') {
            if (checkOwner()) {
                return <option disabled={true} value={obj.id}>{permissionName(obj.status)}</option>
            } else {
                return <option value={obj.id}>{permissionName(obj.status)}</option>
            }
        } else {
            let statusValue = null;

            for (let i = 0; i < status.length; i++) {
                if (issueDetails.status === status[i].id) {
                    statusValue = status[i].status;
                    break
                }
            }

            if ('closed' === statusValue) {
                if (obj.status === 'resolved' || obj.status === 'reopened') {
                    return <option value={obj.id}>{permissionName(obj.status)}</option>
                } else {
                    return <option disabled={true} value={obj.id}>{permissionName(obj.status)}</option>
                }
            } else {
                return <option value={obj.id}>{permissionName(obj.status)}</option>
            }
        }
    }

    return (
        <div className="content-page">
            <div className="content survey-ims">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item"><Link to='/issues'>Issues</Link></li>
                                        <li className="breadcrumb-item"><Link onClick={() => props.history.goBack()}>Issues Details</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Sub Task Details</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><i className="mdi mdi-arrow-left mr-2 text-dark cursor-pointer" onClick={() => props.history.goBack()}></i>{issueDetails.main_issue_name} (Sub-Task of <span className='cursor-pointer' onClick={() => props.history.goBack()}> Issue ID : {issueDetails.org_issue_id}</span>  )</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">
                            <ul className="nav nav-pills bg-nav-pills nav-justified">
                                <li className="nav-item">
                                    <a href="#issue-summary" data-toggle="tab" aria-expanded="true" className="nav-link rounded-0 active">
                                        <i className="mdi mdi-home-variant d-md-none d-block"></i>
                                        <span className="d-none d-md-block">Sub Task Summary</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#customer-response" data-toggle="tab" aria-expanded="false" className="nav-link rounded-0 ">
                                        <i className="mdi mdi-account-circle d-md-none d-block"></i>
                                        <span className="d-none d-md-block">Customer Response</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#timeline" data-toggle="tab" aria-expanded="false" className="nav-link rounded-0">
                                        <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                                        <span className="d-none d-md-block">Timeline</span>
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane show active" id="issue-summary">
                                    <div className="card">
                                        <div className="card-body py-2 px-4">
                                            <TopDetails issueDetails={issueDetails} timeLeft={timeLeft} />
                                            <IssueSummary type="Sub Task" issueDetails={issueDetails} subTask={[]} status={status} fetchTimeline={fetchTimeline} />
                                        </div>
                                    </div>
                                    <Comments issueId={issueDetails.id} fetchTimeline={fetchTimeline} mentionUsers={mentionUsers} />
                                </div>
                                <div className="tab-pane" id="customer-response">
                                    <div className="card">
                                        <div className="card-body py-2 px-4">
                                            <TopDetails issueDetails={issueDetails} timeLeft={timeLeft} />
                                            <CustomerResponse issueId={issueDetails.id} issueDetails={issueDetails} />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="timeline">
                                    <div className="card">
                                        <div className="card-body py-2 px-4">
                                            <TopDetails issueDetails={issueDetails} timeLeft={timeLeft} />
                                            <Timeline issueDetails={issueDetails} timeline={timeline} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <form onSubmit={updateIssue} className="col-md-12">
                                            <div className="form-group">
                                                <label for="projectname">Due Date</label>
                                                <input type="datetime-local" readOnly={checkOwner()} defaultValue={issueDetails.due_date ? issueDetails?.due_date?.substr(0, 16) : null} name="due_date" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label for="projectname">Tags</label>
                                                {issueDetails.tag_list ? <Select defaultValue={selectFormatter(issueDetails.tag_list)} options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} className="basic-multi-select" classNamePrefix="select" name={"tags"} menuPlacement="auto" menuPortalTarget={document.body} /> : null}
                                            </div>
                                            <div className="form-group">
                                                <label for="project-overview">Status</label>
                                                <select className="form-control m-0" disabled={enableDisableStatus()} name='status' value={selectedStatus} onChange={(event) => setSelectedStatus(event.target.value)}>
                                                    {status.map((obj, i) => {
                                                        return addStatusCheck(obj)
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label for="project-overview">Priority</label>
                                                <select className="form-control m-0" name="priority" value={selectedPriority} onChange={(event) => setSelectedPriority(event.target.value)}>
                                                    {priorities.map((obj, i) => {
                                                        return <option value={obj.id}>{permissionName(obj.priority)}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label for="projectname">Assigned To (Users)</label>
                                                {issueDetails.assigned_user_list ? <Select defaultValue={selectFormatter(issueDetails.assigned_user_list)} options={users} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"user_list"} /> : null}
                                            </div>
                                            <div className="form-group">
                                                <label for="projectname">Assigned To (Teams)</label>
                                                {issueDetails.assigned_team_list ? <Select defaultValue={selectFormatter(issueDetails.assigned_team_list)} options={team} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"team_list"} /> : null}
                                            </div>
                                            <div className="form-group">
                                                <label for="project-overview">Type</label>
                                                {issueDetails.type_list ? <Select defaultValue={selectFormatter(issueDetails.type_list)} options={typess} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"type_list"} /> : null}
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-block">Update</button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center">
                                <div className="col-12">
                                    <button type="button" onClick={fetchAttachments} data-toggle="modal" data-target="#viewAttachments" className="btn btn-outline-primary btn-block mb-2">View Attachments</button>
                                    <button type="button" data-toggle="modal" data-target="#addAttachments" className="btn btn-outline-primary btn-block mb-2">Add Attachments</button>
                                </div>
                                <ViewAttachmentModal attachments={attachments} />
                                <AddAttachmentModal issueId={issueDetails.id} />
                            </div>
                        </div>
                    </div>
                    {loader ? <Loader /> : null}
                </div>
            </div>
        </div>
    );
}

export default SubTaskDetails;


