import { Link } from "react-router-dom";
import { dateFormat } from "../../Utils/date_formatting";
import { permissionName } from "../../Utils/string_formatting";

const TopDetails = ({ issueDetails, timeLeft }) => {

    return (
        <div className="card mb-2 shadow-none">
            <div className="p-1">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <div className="avatar-sm">
                            <span className="avatar-title rounded">{issueDetails.audience_name ? issueDetails.audience_name[0] : null}</span>
                        </div>
                    </div>
                    <div className="col pl-0">
                        <Link to={issueDetails.audience_nested_id ? "/audience-details/" + issueDetails.audience_nested_id : "/audience"} target="_blank"><span className="text-primary font-weight-bold"> {issueDetails.audience_name} (Issue ID : {issueDetails.org_issue_id})</span></Link>
                        <p className="text-muted mb-0">Reported via {permissionName(issueDetails.channel_type ? issueDetails.channel_type : '')} on {dateFormat(issueDetails.created_on ? issueDetails.created_on : '')}</p>
                    </div>
                    <div className="col-auto">
                        <p className={timeLeft[0] === '-' ? "text-danger" : "text-success"}><i className="mdi mdi-clock mr-1"></i>{timeLeft}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopDetails;