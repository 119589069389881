import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

const PermissionsGridView = (props) => {

    const columns = [{
        dataField: 'name',
        text: props.gridName
    }];

    return (
        <div className="col-md-4 mb-4">
            <BootstrapTable
                keyField='id'
                data={props.gridData}
                selectRow={props.selectRow}
                hover
                bordered={false}
                columns={columns}
                noDataIndication="No permission found" />
        </div>
    )
}

export default PermissionsGridView;