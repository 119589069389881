import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { permissionName } from "../../../../Utils/string_formatting";
//import PermissionsGridView from "../../../Common/PermissionsGridView";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import $ from 'jquery';
import Select from "react-select";

const AddRole = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const history = useHistory();

    const [roleName, setRoleName] = useState("");
    const [roleDesc, setRoleDesc] = useState("");
    const [fields, setFields] = useState({});
    const [sqlQuery, setSqlQuery] = useState("");
    const [mongoQuery, setMongoQuery] = useState("");
    const [immTree, setImmTree] = useState("");
    const [settingManage, setSettingManage] = useState(false);

    const [audience, setAudience] = useState(false);
    const [segments, setSegments] = useState(false);
    const [surveys, setSurveys] = useState(false);
    const [responses, setResponses] = useState(false);
    const [analytics, setAnalytics] = useState(false);
    const [trendAnalysis, setTrendAnalysis] = useState(false);
    const [issues, setIssues] = useState(false);

    const [shares, setShares] = useState([]);

    const fetchShares = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getShareData';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: data[i].share_name })
                }
                setShares(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchPrimarySecondaryFields = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getAllFields';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            generateFields(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const generateFields = (data) => {
        let obj = {};
        for (let i = 0; i < data.length; i++) {
            if (data[i] === "" || data[i] === "id" || data[i] === "data_set_id" || data[i] === "org_id") {
                //do nothing
            } else {
                obj = Object.assign(obj, { [data[i]]: { label: permissionName(data[i]), type: 'text' } });
            }
        }
        setFields(obj);
    }

    const config = {
        ...MaterialConfig,
        fields: fields
    };

    const queryValue = { "id": QbUtils.uuid(), "type": "group" };

    useEffect(() => {
        fetchShares()
        $(document).on('click', '.filterToggleMenu', function (e) {
            e.preventDefault();
            if ($('.filterToggleMenu').hasClass('collapsed')) {
                $('.filterToggleMenu i').removeClass("mdi-eye").addClass("mdi-eye-off");
            } else {
                $('.filterToggleMenu i').removeClass("mdi-eye-off").addClass("mdi-eye");
            }
        });
    }, []);

    const [tree, setTree] = useState(QbUtils.checkTree(QbUtils.loadTree(queryValue), config));

    const renderBuilder = (props) => (
        <div className="query-builder-container" style={{ padding: '10px' }}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    )

    const onChange = (immutableTree, config) => {
        setTree(immutableTree);
        setMongoQuery(JSON.stringify(QbUtils.mongodbFormat(immutableTree, config)));
        setSqlQuery(JSON.stringify(QbUtils.sqlFormat(immutableTree, config)));
        let jsonTree = QbUtils.getTree(immutableTree);
        setImmTree(jsonTree);
    }

    const renderResult = ({ tree: immutableTree, config }) => (
        <div className="query-builder-result">
            {/* <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div> */}
            {/* <div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre></div> */}
            <div><pre style={{ whiteSpace: 'pre-wrap', fontSize: 14, fontWeight: 600 }}>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div>
            {/* <div><pre style={{ whiteSpace: 'pre-wrap', fontSize: 14, fontWeight: 600 }}>{(QbUtils.sqlFormat(immutableTree, config))}</pre></div> */}
            {/* <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div> */}
        </div>
    );

    useEffect(fetchPrimarySecondaryFields, []);

    //const [permissionsUI, setPermissionsUI] = useState([]);
    //const [permissions, setPermissions] = useState([]);

    // var permissionsArray = [];

    // const fetchPermissions = () => {
    //     let URL = process.env.REACT_APP_API_KEY + 'permissionList';
    //     axios({
    //         method: "get",
    //         url: URL,
    //         headers: {
    //             Authorization: 'Token ' + TOKEN
    //         }
    //     }).then(function (response) {
    //         if (response.data.status === 1) {
    //             processPermissions(response.data.data);
    //         }
    //     }).catch(function (error) {
    //         toast.error(error.toString());
    //     });
    // }

    // useEffect(fetchPermissions, []);

    // const processPermissions = (data) => {
    //     let UI = [];
    //     let keys = Object.keys(data);
    //     for (let i = 0; i < keys.length; i++) {
    //         let arr = [];
    //         let list = data[keys[i]];
    //         for (let j = 0; j < list.length; j++) {
    //             arr.push({ id: keys[i] + '_' + list[j], name: permissionName(list[j]), value: list[j] })
    //         }
    //         UI.push(<PermissionsGridView gridData={arr} gridName={firstCharCaps(keys[i])} selectRow={selectRow} />)
    //     }
    //     setPermissionsUI(UI);
    // }

    // const selectRow = {
    //     mode: 'checkbox',
    //     clickToSelect: true,
    //     hideSelectAll: true,
    //     onSelect: (row, isSelect) => {
    //         if (isSelect) {
    //             permissionsArray.push(row.id);
    //         } else {
    //             let index = permissionsArray.indexOf(row.id);
    //             if (index > -1) {
    //                 permissionsArray.splice(index, 1);
    //             }
    //         }
    //         setPermissions(permissionsArray)
    //     },
    //     onSelectAll: (isSelect, rows) => {
    //         if (isSelect) {
    //             for (let i = 0; i < rows.length; i++) {
    //                 permissionsArray.push(rows[i].id);
    //             }
    //         } else {
    //             permissionsArray = [];
    //         }
    //         setPermissions(permissionsArray)
    //     }
    // };

    // const submitRole = (event) => {
    //     event.preventDefault();
    //     const formData = new FormData(event.target);
    //     formData.append('permissions', permissions);
    //     let URL = process.env.REACT_APP_API_KEY + 'roles';
    //     axios({
    //         method: "post",
    //         url: URL,
    //         data: formData,
    //         headers: {
    //             Authorization: 'Token ' + TOKEN
    //         }
    //     }).then(function (response) {
    //         if (response.data.id > 0) {
    //             toast.success('New Role Created');
    //             document.getElementById('addRoleForm').reset();
    //             history.push('/roles');
    //         } else if (response.data.status === 0) {
    //             toast.error(response.data.message);
    //         }
    //     }).catch(function (error) {
    //         toast.error(error.toString());
    //     });
    // }

    const submitRole = () => {
        const formData = new FormData();
        let URL = process.env.REACT_APP_API_KEY + 'roles';

        if (mongoQuery) {
            formData.append('mongo_query', mongoQuery);
        } else {
            formData.append('mongo_query', "{}");
        }

        if (sqlQuery) {
            formData.append('sql_query', sqlQuery);
        } else {
            formData.append('sql_query', "{}");
        }

        formData.append('role_name', roleName);
        //formData.append('query_tree', JSON.stringify(tree));
        formData.append('query_tree', JSON.stringify(immTree));
        formData.append('role_desc', roleDesc);
        formData.append('settings_manage', settingManage);

        formData.append('show_audience', audience);
        formData.append('show_segments', segments);
        formData.append('show_survey', surveys);
        formData.append('show_response', responses);
        formData.append('show_analytics', analytics);
        formData.append('show_trends', trendAnalysis);
        formData.append('show_issues', issues);

        let e = document.getElementsByName("share_list");
        let arr = [];

        for (let i = 0; i < e.length; i++) {
            arr.push(e[i].value)
        }

        formData.append('share_list', arr);

        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                history.push('roles');
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div className="content-page">
            <div className="content">
                {/* <form onSubmit={submitRole} id="addRoleForm" className="container-fluid"> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            {/* <button type="submit" className="btn btn-primary">CREATE</button> */}
                                            <button type="button" onClick={submitRole} className="btn btn-primary">CREATE</button>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Settings</a></li>
                                        <li className="breadcrumb-item"><a>User Settings</a></li>
                                        <li className="breadcrumb-item"><Link to="/roles">Roles</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Create Role</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/roles"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>Create Role</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="name">Name of Role<span className="text-danger">*</span></label>
                                        <input className="form-control" type="text" value={roleName} onChange={(event) => setRoleName(event.target.value)} placeholder="Enter role name" name="role_name" required />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label for="name">Description of Role<span className="text-danger">*</span></label>
                                        <input className="form-control" type="text" value={roleDesc} onChange={(event) => setRoleDesc(event.target.value)} placeholder="Enter role description" name="role_desc" required />
                                    </div>
                                </div>
                            </div>


                            <h4 className="mt-2">Menu Access</h4>
                            <div className="row  ml-0">
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="audience" checked={audience} onChange={() => setAudience(!audience)} />
                                    <label className="custom-control-label" for="audience">Audience</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="segments" checked={segments} onChange={() => setSegments(!segments)} />
                                    <label className="custom-control-label" for="segments">Segments</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="surveys" checked={surveys} onChange={() => setSurveys(!surveys)} />
                                    <label className="custom-control-label" for="surveys">Surveys</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="responses" checked={responses} onChange={() => setResponses(!responses)} />
                                    <label className="custom-control-label" for="responses">Responses</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="analytics" checked={analytics} onChange={() => setAnalytics(!analytics)} />
                                    <label className="custom-control-label" for="analytics">Analytics</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="trendAnalysis" checked={trendAnalysis} onChange={() => setTrendAnalysis(!trendAnalysis)} />
                                    <label className="custom-control-label" for="trendAnalysis">Trend Analysis</label>
                                </div>
                                <div className="col-2 custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="issues" checked={issues} onChange={() => setIssues(!issues)} />
                                    <label className="custom-control-label" for="issues">All Issues</label>
                                </div>
                            </div>

                            <h4 className="mt-3">Other Access</h4>
                            <div className="row">
                                <div className="custom-control custom-switch ml-2">
                                    <input type="checkbox" className="custom-control-input" id="settingManage" checked={settingManage} onChange={(event) => setSettingManage(!settingManage)} />
                                    <label className="custom-control-label" for="settingManage">Settings Access</label>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label for="name">Shares</label>
                                        <Select options={shares} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name="share_list" />
                                    </div>
                                </div>
                            </div>

                            {/* <h4>Manage Permissions</h4>
                            <div className="row">
                                {permissionsUI}
                            </div> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h4 className="mt-3">Filter By</h4>
                            <div className="card p-3">
                                <div className="d-flex justify-content-between">
                                    {renderResult({ tree, config })}
                                    {mongoQuery ?
                                        <button className="filterToggleMenu" style={{ border: 'none', color: '#313a46', backgroundColor: 'transparent', fontSize: '24px', cursor: 'pointer' }} data-toggle="collapse" data-target="#queryBuilderContainer"><i className="mdi mdi-eye"></i></button>
                                        : null
                                    }
                                </div>
                                <div id="queryBuilderContainer" className="collapse show">
                                    <Query {...config} value={tree} onChange={onChange} renderBuilder={renderBuilder} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </form> */}
                </div>
            </div>
        </div>
    );
}

export default AddRole;
