export const dateFormat = (data) => {
    return data.slice(0, 10);
}

export const dateTimeFormat = (data) => {
    return data.slice(0, 16).replace('T', ' ');
}

export const numberToTime = (number) => {
    let sign = (number >= 0) ? 1 : -1;
    number = number * sign;
    let hour = Math.floor(number);
    let decpart = number - hour;
    let min = 1 / 60;
    decpart = min * Math.round(decpart / min);
    let minute = Math.floor(decpart * 60) + '';
    if (minute.length < 2) {
        minute = '0' + minute;
    }
    sign = sign == 1 ? '' : '-';
    return sign + hour + ':' + minute;
}

export const getTodaysDate = () => {
    let date = new Date();
    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
}

export const getPastMonthDate = () => {
    let date = new Date();
    date.setDate(date.getDate() - 30);
    return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
}

export const tatDateTime = (tat) => {
    return Math.floor(tat / (60 * 60 * 24)) + 'd : ' +
        Math.abs(Math.floor((tat / (60 * 60)) % 24)) + 'hr : ' +
        Math.abs(Math.floor((tat / 60) % 60)) + 'min : ' +
        Math.abs(Math.floor((tat) % 60)) + 'sec';
}