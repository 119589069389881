import { useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from 'axios';
import { toast } from 'react-toastify';
import { trimString } from "../../Utils/string_formatting";

const AddAttachmentModal = ({ issueId, onClose }) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles);
        }
    });

    const uploadAttachment = (event) => {
        event.preventDefault();
        if (files.length > 0) {
            const formData = new FormData(event.target);
            formData.append('issue_id', issueId);
            let URL = process.env.REACT_APP_API_KEY + 'issues/addIssueAttachement';
            axios({
                method: "post",
                url: URL,
                data: formData,
                headers: {
                    Authorization: 'Token ' + TOKEN
                }
            }).then((response) => {
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    document.getElementById('closeAddAttachmentPopup').click();
                    if (onClose) {
                        onClose();
                    }
                } else if (response.data.status === 0) {
                    toast.error(response.data.message);
                } else {
                    toast.error('Someting went wrong');
                }
            }).catch((error) => {
                toast.error(error.toString());
            });
        } else {
            toast.error('Upload some file please')
        }
    }

    const removeFile = (index) => {
        let arr = files;
        arr.splice(index, 1);
        setFiles(arr);
    }

    return (
        <div id="addAttachments" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="fullWidthModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg  modal-dialog-centered">
                <form onSubmit={uploadAttachment} className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="fullWidthModalLabel">Upload Attachment</h4>
                        <button type="button" onClick={() => { setFiles([]); if (onClose) {onClose();} }} className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <section className="container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <div id="inputFileContainer">
                                    <div className="dz-message needsclick">
                                        <i className="h1 text-muted dripicons-cloud-upload"></i>
                                        <h4>Drop file here or click to upload.</h4>
                                    </div>
                                </div>
                                <input name="files" {...getInputProps()} />
                            </div>
                        </section>

                        <div className="row mx-1 mt-2 no-gutters">
                            {files.map((obj, index) => {
                                return <div className="col-4"><div className="card m-1 shadow-none border">
                                    <div className="p-2">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded">
                                                        <i className="dripicons-document-new font-10"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col p-0">
                                                <span className="text-muted font-weight-bold">{trimString(obj.name, 15)}</span>
                                            </div>
                                            <span onClick={() => removeFile(index)} className="btn btn-link btn btn-sm text-muted">
                                                <i className="dripicons-cross"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div></div>
                            })}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeAddAttachmentPopup" onClick={() => { setFiles([]); if(onClose) {onClose();} }} className="btn btn-outline-secondary" data-dismiss="modal">CLOSE</button>
                        <button type="submit" className="btn btn-primary">Upload</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddAttachmentModal;

