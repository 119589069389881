import React, { useEffect, useState } from "react";
import "emotion-ratings/dist/emotion-ratings.js";
import "survey-creator/survey-creator.css";
import axios from 'axios';
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import * as SurveyCreator from "survey-creator";
import Loader from "../../../Common/Loader";

const CreateContactForm = (props) => {

    var creator;

    const history = useHistory();

    const TOKEN = localStorage.getItem('fms-access-token');

    const [loader, setLoader] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);

    setTimeout(() => {
        setLoader(false);
    }, 3000)

    const options = {
        showEmbededSurveyTab: true,
        showLogicTab: true,
        showTranslationTab: true,
        questionTypes: ["checkbox", "comment", "dropdown", "radiogroup", "text", "boolean"]
    };

    SurveyCreator.StylesManager.applyTheme("modern");

    useEffect(() => {
        creator = new SurveyCreator.SurveyCreator(null, options);
        creator.saveSurveyFunc = createUpdateContactForm;
        creator.showToolbox = "right";
        creator.showPropertyGrid = "right";
        creator.rightContainerActiveItem("toolbox");
        creator.render("surveyCreatorContainer");

        if (props.location.state) {
            creator.text = JSON.stringify(props.location.state.formData);
            setIsUpdate(props.location.state.isUpdate);
            document.getElementById('form_name').value = props.location.state.formName;
        }

        document.getElementsByClassName('svd_toolbar')[0].style.display = 'none';
        document.getElementsByClassName('svd_commercial_container')[0].style.display = 'none';
        document.getElementsByClassName('navbar-default container-fluid nav nav-tabs editor-tabs svd-tabs svd-light-bg-color')[0].style.display = 'none';
    })


    var mainColor = props.primaryColor;
    var mainHoverColor = "#727cf5";
    var textColor = "#4a4a4a";
    var headerColor = "#4a4a4a";
    var headerBackgroundColor = "#4a4a4a";
    var bodyContainerBackgroundColor = "#f8f8f8";

    var defaultThemeColorsSurvey = SurveyCreator.StylesManager.ThemeColors["default"];
    defaultThemeColorsSurvey["$main-color"] = mainColor;
    defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
    defaultThemeColorsSurvey["$text-color"] = textColor;
    defaultThemeColorsSurvey["$header-color"] = headerColor;
    defaultThemeColorsSurvey["$header-background-color"] = headerBackgroundColor;
    defaultThemeColorsSurvey["$body-container-background-color"] = bodyContainerBackgroundColor;

    var defaultThemeColorsEditor = SurveyCreator.StylesManager.ThemeColors["default"];
    defaultThemeColorsEditor["$primary-color"] = mainColor;
    defaultThemeColorsEditor["$secondary-color"] = mainColor;
    defaultThemeColorsEditor["$primary-hover-color"] = mainHoverColor;
    defaultThemeColorsEditor["$primary-text-color"] = textColor;
    defaultThemeColorsEditor["$selection-border-color"] = mainColor;

    const createUpdateContactForm = () => {

        let URL = "";

        if (isUpdate) {
            URL = process.env.REACT_APP_API_KEY + 'audience/updateTabModeData/' + props.location.state.formId;
        } else {
            URL = process.env.REACT_APP_API_KEY + 'audience/addTabModeData';
        }

        const formData = new FormData();
        formData.append('name', document.getElementById('form_name').value);
        formData.append('form_json', creator.text);
        axios({
            method: isUpdate ? "put" : "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                history.push("/contact-form");
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <button onClick={createUpdateContactForm} className="btn btn-primary float-right" type="button">{isUpdate ? 'UPDATE' : 'CREATE'}</button>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><Link>Settings</Link></li>
                                        <li className="breadcrumb-item"><a>Integrations</a></li>
                                        <li className="breadcrumb-item"><Link to="/channel-integration">Channel</Link></li>
                                        <li className="breadcrumb-item active"><Link to="/contact-form">Contact Form</Link></li>
                                        <li className="breadcrumb-item active">{isUpdate ? 'Update' : 'Create'} Contact Form</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title"><Link to="/contact-form"> <i className="mdi mdi-arrow-left mr-2 text-dark"></i></Link>{isUpdate ? 'Update' : 'Create'} Contact Form</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-1">
                                <div className="row mt-3 ml-3">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label for="name">Form Name</label>
                                            <input className="form-control" type="text" placeholder="Enter form name" name="form_name" id="form_name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="col-12 d-flex">
                                            <div id="surveyCreatorContainer" className="p-0 ml-0 border-0" />
                                        </div>
                                    </div>
                                </div>
                                {loader ? <Loader /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateContactForm;
