import Select from "react-select";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { permissionName } from "../../../../../Utils/string_formatting";
import AudienceGridView from "./AudienceGridView";
import TestSmsModal from "./TestSmsModal";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import $ from 'jquery';
import ChannelDropDown from "../../../../Common/ChannelDropDown";

const ShareViaSMS = (props) => {

    const history = useHistory();

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    const TOKEN = localStorage.getItem('fms-access-token');

    const [ssp, setSsp] = useState("0");

    const [segments, setSegments] = useState([]);
    const [tags, setTags] = useState([]);
    const [property, setProperty] = useState([]);
    const [sendTo, setSendTo] = useState("segment");
    const [surveyDetails, setSurveyDetails] = useState({});
    const [individualUsers, setIndividualUsers] = useState([]);

    var smsBody = "Hey, Can you spare a moment to take this survey {survey_url}?"

    $('#sms_body_view').val(smsBody);

    useEffect(() => {
        if (props.location.state) {
            setSurveyDetails(props.location.state.surveyInfo);
        } else {
            history.push('/surveys');
        }
    }, [props.location.state]);

    const fetchSegments = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setSegments(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchTags = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceDataTags';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    arr.push({ value: data[i].id, label: permissionName(data[i].name) })
                }
                setTags(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const fetchProperties = () => {
        let URL = process.env.REACT_APP_API_KEY + 'audience/getAllFields';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                let arr = [];
                let data = response.data.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i] === "id" || data[i] === "data_set_id" || data[i] === "org_id") {
                        //do nothing
                    } else {
                        arr.push(<a className="dropdown-item" onClick={() => addTagToMessage(data[i])}>{permissionName(data[i])}</a>)
                    }
                }
                arr.push(<a className="dropdown-item" onClick={() => addTagToMessage('survey_url')}>Survey URL</a>)
                setProperty(arr);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const addTagToMessage = (tagName) => {
        let txt = '{' + tagName + '}';
        var cursorPos = $('#sms_body').prop('selectionStart');
        var v = $('#sms_body').val();
        var textBefore = v.substring(0, cursorPos);
        var textAfter = v.substring(cursorPos, v.length);
        $('#sms_body').val(textBefore + txt + textAfter);
        document.getElementById('sms_body_view').value = textBefore + txt + textAfter;
    }

    useEffect(fetchProperties, []);
    useEffect(fetchTags, []);
    useEffect(fetchSegments, []);

    const saveSendSMS = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('users', individualUsers);
        formData.append('survey_id', surveyDetails.id);
        let URL = process.env.REACT_APP_API_KEY + 'audience/shareSurvey';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                //history.push('/survey-share');
                document.getElementById('smsShareBack').click();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    // const renderMessageOnPreview = (data) => {
    //     document.getElementById('sms_body_view').value = data;
    // }

    return (
        <div className="content">
            <div className="survey-top-bar">
                <div className="row border-bottom" style={{ height: '70px' }}>
                    <div className="col-md-4 d-flex" style={{ alignItems: 'center' }}>
                        <Link to="/"><span className="logo-lg mx-2"><img src={logo ? logo : "/assets/images/logo.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '60px', paddingTop: '4px', paddingBottom: '4px' }} /></span></Link>
                        <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} className=""><i className="dripicons-arrow-thin-left font-24 mr-1 text-dark"></i></Link>
                        <input className="form-control survey-input pt-1" value={surveyDetails.name} type="text" />
                    </div>
                    <div className="col-md-8 d-flex justify-content-between">
                        <ul className="nav nav-tabs nav-bordered justify-content-center" style={{ alignItems: 'end' }}>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-content', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-1-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Content</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="true" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-2-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Design</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-3-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Workflow</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link active">
                                    <i className="mdi text-primary mdi-numeric-4-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Share</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-results', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-5-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Results</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="mr-2 mt-1">
                            {/* <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} type="button" style={{ height: 'fit-content' }} className="btn btn-primary btn-sm ml-2 mr-1">Next<i className="uil uil-arrow-right ml-1"></i></Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{ marginTop: '3.3rem' }}>
                    <div className="row">
                        <form onSubmit={saveSendSMS} className="col-md-5 card card-body mb-0">
                            <Link id="smsShareBack" to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }}>
                                <i className="mdi mdi-arrow-left"></i>
                                <span>Back</span>
                            </Link>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="simpleinput">Share Name<span className="text-danger">*</span></label>
                                        <input type="text" required={true} placeholder="Enter share name here" name="name" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>SMS Service Provider<span className="text-danger">*</span></label>
                                        <ChannelDropDown url="audience/getChannelsSms" value={ssp} option_label="name" option_id="id" onchange={(event) => setSsp(event.target.value)} name="sms_service_provides" id="edit_user_role" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between">
                                        <label>Send To<span className="text-danger">*</span></label>
                                        <div className="">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio3" name="sendTo" checked={sendTo === "segment"} onChange={() => setSendTo("segment")} className="custom-control-input" required />
                                                <label className="custom-control-label" for="customRadio3">Segment</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio4" name="sendTo" checked={sendTo === "tags"} onChange={() => setSendTo("tags")} className="custom-control-input" required />
                                                <label className="custom-control-label" for="customRadio4">Tags</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="customRadio5" name="sendTo" checked={sendTo === "individual"} onChange={() => setSendTo("individual")} className="custom-control-input" required />
                                                <label className="custom-control-label" for="customRadio5">Individual</label>
                                            </div>
                                        </div>
                                    </div>
                                    {sendTo === "segment" ?
                                        <div className="">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <Select options={segments} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"segments"} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : sendTo === "tags" ?
                                            <div className="">
                                                <div className="form-group">
                                                    <Select options={tags} placeholder="--Select--" isMulti={true} closeMenuOnSelect={false} name={"tags"} />
                                                </div>
                                            </div>
                                            : sendTo === "individual" ?
                                                <div className="">
                                                    <AudienceGridView individualUsers={setIndividualUsers} />
                                                </div>
                                                : null
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between">
                                            <label>Message</label>
                                            <div className="dropdown">
                                                <a className="dropdown-toggle arrow-none" type="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ADD REPLACEMENT TAG</a>
                                                <div className="dropdown-menu dropdown-menu-border-top  dropdown-menu-right dropdown-menu-animated dropdown-md">
                                                    <div style={{ maxHeight: '200px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                        {property}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea className="form-control" id="sms_body" placeholder="Type your message here" rows="5" defaultValue={smsBody} onChange={(event) => document.getElementById('sms_body_view').value = event.target.value} required={true} name="sms_body"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-between">
                                    <button className="btn btn-outline-primary" type="button" data-toggle="modal" data-target="#testSmsModal">Test SMS</button>
                                    <button className="btn btn-primary" type="submit">Save &amp; Send</button>
                                </div>
                            </div>
                        </form>
                        <div className="col-md-7">
                            <h4 className="mt-4 text-center">Sharing Preview</h4>
                            <div className="mb-5 d-flex justify-content-center sms-phone" style={{ height: '100vh' }}>
                                <img src="/assets/images/phone-black.svg" width="350" className="" style={{ position: 'absolute' }} />
                                <textarea className="form-control" style={{ width: '14.5rem', height: '7rem', marginTop: '15rem', zIndex: '1' }} id="sms_body_view" ></textarea>
                            </div>
                        </div>
                        <TestSmsModal />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShareViaSMS;
