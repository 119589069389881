import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import AddCommentAttachmentModal from "./AddCommentAttachmentModal";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { mentionUserName, trimString } from "../../../Utils/string_formatting";
import { MentionsInput, Mention } from 'react-mentions';
TimeAgo.addDefaultLocale(en);

const Comments = ({ issueId, fetchTimeline, mentionUsers }) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [comments, setComments] = useState([]);
    const [files, setFiles] = useState([]);
    const [mentionComments, setMentionComments] = useState("");
    const [mentionReply, setMentionReply] = useState("");

    const timeAgo = new TimeAgo('en-US')

    const fetchComments = () => {
        let URL = process.env.REACT_APP_API_KEY + 'issues/getCommentOnIssue/' + issueId;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setComments(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    useEffect(() => {
        if (issueId) {
            fetchComments()
        }
    }, [issueId]);

    const addComment = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('comment_type', 'comment');
        formData.append('issue_id', issueId);
        let URL = process.env.REACT_APP_API_KEY + 'issues/commentOnIssue';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then((response) => {
            if (response.data.status === 1) {
                fetchComments()
                toast.success(response.data.message);
                document.getElementById('addCommentForm').reset();
                setFiles([]);
                fetchTimeline(issueId);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
            setMentionComments("")
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    const addReply = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('comment_type', 'reply');
        formData.append('issue_id', issueId);
        let URL = process.env.REACT_APP_API_KEY + 'issues/commentOnIssue';
        var cid = formData.get('comment_id');
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then((response) => {
            if (response.data.status === 1) {
                fetchComments()
                toast.success(response.data.message);
                document.getElementById('replyBtn_' + cid).click();
                document.getElementById('addReplyForm_' + cid).reset();
                fetchTimeline(issueId);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
            setMentionReply("")
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    return (
        <div className="card">
            <div className="card-body py-2 px-4">
                <h4>Comments ({comments ? comments?.length : 0})</h4>
                <hr />
                {comments?.map((obj, i) => {
                    return <><div className="card mb-0 mt-2 shadow-none">
                        <div className="p-1">
                            <div className="row">
                                <div className="col-auto">
                                    <div className="avatar-sm">
                                        <span className="avatar-title rounded">{obj.created_by[0]}</span>
                                    </div>
                                </div>
                                <div className="col pl-0">
                                    <a href="" className="text-dark font-weight-bold">{obj.created_by}</a>
                                    <p className="text-muted mb-0">{obj.comment} </p>
                                    <div className="row mt-1 no-gutters">
                                        {obj.attachement_data?.map((obj) => {
                                            return <div className="col-6">
                                                <div className="card mr-1 mb-1 shadow-none border btn-rounded">
                                                    <div className="p-1">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                                <div className="avatar-xs ">
                                                                    <span className="avatar-title rounded-circle">
                                                                        <i className="dripicons-document-new font-10"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col p-0">
                                                                <span className="text-muted font-weight-bold">{trimString(obj.file_name, 20)}</span>
                                                            </div>
                                                            <div className="col-auto">
                                                                <a href={process.env.REACT_APP_API_MEDIA_KEY + obj.file} target={"_blank"} className="mr-1 text-muted">
                                                                    <i className="mdi mdi-download font-18"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <a className="text-primary" type="button" data-toggle="collapse" id={"replyBtn_" + obj.id} data-target={"#collapseExample_" + i} aria-expanded="false" aria-controls="collapseExample"><i className="mdi mdi-reply font-16 mr-1"></i>Reply</a>
                                </div>
                                <div className="col-auto">
                                    <p className="text-muted">{timeAgo.format(Date.now() - obj.time_since_commented * 1000)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="collapse" id={"collapseExample_" + i}>
                            <div className="mt-2 pl-5">
                                <form onSubmit={addReply} id={"addReplyForm_" + obj.id} className="needs-validation" name="chat-form">
                                    <div className="row">
                                        <div className="col">
                                            {/* <input type="text" name="comment" className="form-control chat-input" placeholder="Enter your reply" required={true} /> */}
                                            <MentionsInput rows="1" name="comment" required={true} className="mentions-input-custom chat-input border rounded" placeholder="Enter your reply" value={mentionReply} onChange={(event) => setMentionReply(event.target.value)}>
                                                <Mention trigger="@" data={mentionUsers} displayTransform={(id, display) => { return '@' + display }} />
                                            </MentionsInput>
                                            <input type="hidden" name="comment_id" value={obj.id} />
                                        </div>
                                        <div className="col-auto d-grid">
                                            <button type="submit" className="btn btn-primary chat-send waves-effect waves-light">Send</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {obj.replies?.map((obj, i) => {
                            return <div className="card mb-2 shadow-none pl-5">
                                <div className="p-1">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar-sm">
                                                <span className="avatar-title rounded">{obj.created_by[0]}</span>
                                            </div>
                                        </div>
                                        <div className="col pl-0">
                                            <a href="" className="text-dark font-weight-bold">{obj.created_by}</a>
                                            <p className="text-muted mb-0">{obj.comment}</p>
                                        </div>
                                        <div className="col-auto">
                                            <p className="text-muted">{timeAgo.format(Date.now() - obj.time_since_commented * 1000)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </>
                })
                }

                <div className="row">
                    <div className="col">
                        <div className="my-2 border rounded">
                            <form onSubmit={addComment} id="addCommentForm" className="comment-area-box">
                                {/* <textarea rows="4" name="comment" required={true} className="form-control border-0 resize-none" placeholder="Enter your comments"></textarea> */}
                                <MentionsInput
                                    rows="4"
                                    name="comment"
                                    required={true}
                                    className="mentions-input-custom"
                                    placeholder="Enter your comments"
                                    value={mentionComments}
                                    onChange={(event) => setMentionComments(event.target.value)}>

                                    <Mention
                                        trigger="@"
                                        appendSpaceOnAdd={true}
                                        data={mentionUsers}
                                        displayTransform={(id, display) => {
                                            return '@' + display
                                        }} />
                                </MentionsInput>
                                <AddCommentAttachmentModal files={files} setFiles={setFiles} />
                                <div className="p-2 bg-light d-flex justify-content-end align-items-center">
                                    <button type="button" className="btn btn-sm px-2 ml-1 mr-2 font-16 btn-light" data-toggle="modal" data-target="#addCommentsAttachments"><i className="uil uil-paperclip"></i>{files.length === 0 ? null : files.length}</button>
                                    <button type="submit" className="btn  btn-primary">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Comments;