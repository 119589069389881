import React from "react";
import * as Survey from "survey-react";
import "survey-react/modern.css";
import { useEffect } from "react";
import $ from 'jquery';
import * as widgets from "surveyjs-widgets";

const SurveyViewer = (props) => {

    var survey = new Survey.Model(props.surveyQuestionsJson);

    widgets.emotionsratings(Survey);
    widgets.nouislider(Survey);

    useEffect(() => {
        setCustomCss();
    });

    const setCustomCss = () => {
        $(".sv_q_rating_max_text").css("margin-left", - $(".sv_q_rating_max_text:eq(0)").width());
        $(".sv_container").css("font-family", props.fontFamily);
        $(".sv_main.sv_default_css .sv_container").css("color", props.questionColor);
        $(".sv_prev_btn").css("border", "1px solid " + props.buttonColor);
        $(".sv_prev_btn").css("color", props.buttonColor);
        document.documentElement.style.setProperty('--custom-btn-primary-color', props.buttonColor);
        $(".sv_complete_btn").css("background-color", props.buttonColor);
        $(".sv_q_rating_item_text").css("border-color",);
        $(".sv_main .sv_q_rating_item .sv_q_rating_item_text").css("border", "1px solid " + props.buttonColor);
        $(".designer-container, .sv_body, .sv_row").css("background-color", props.backgroundColor);
        $(".sv_header").css("background-color", props.backgroundColor);
        document.documentElement.style.setProperty('--custom-color', props.buttonColor);
        document.documentElement.style.setProperty('--custom-shadow', (props.buttonColor) + '0F');

        setTimeout(function () {
            $(".sv_q_rating_max_text").css("margin-left", - $(".sv_q_rating_max_text:eq(0)").width());
        }, 2500);
    }


    if (props.formFace === "scroll" && props.scrollView === "horizontal") {
        survey.onCurrentPageChanging.add(function (sender, options) {
            var x = document.getElementsByClassName('sv_nav')[0];
            var y = document.getElementsByClassName('sv_p_root')[0];
            if (options.isNextPage) {
                y.classList.add("animRtoL");
                x.classList.add("animRtoL");
            } else if (options.isPrevPage) {
                y.classList.add("animLtoR");
                x.classList.add("animLtoR");
            }
            setTimeout(function () {
                x.classList.remove("animLtoR");
                x.classList.remove("animRtoL");
                y.classList.remove("animLtoR");
                y.classList.remove("animRtoL");
            }, 500);
            setTimeout(function () {
                setCustomCss();
            }, 10);
        });
    } else if (props.formFace === "scroll" && props.scrollView === "vertical") {
        survey.onCurrentPageChanging.add(function (sender, options) {
            var x = document.getElementsByClassName('sv_nav')[0];
            var y = document.getElementsByClassName('sv_p_root')[0];
            if (options.isNextPage) {
                y.classList.add("animDtoU");
                x.classList.add("animDtoU");
            } else if (options.isPrevPage) {
                y.classList.add("animUtoD");
                x.classList.add("animUtoD");
            }
            setTimeout(function () {
                x.classList.remove("animUtoD");
                x.classList.remove("animDtoU");
                y.classList.remove("animUtoD");
                y.classList.remove("animDtoU");
            }, 500);
            setTimeout(function () {
                setCustomCss();
            }, 10);
        });
    }

    const completedClassicViewSurvey = (e) => {
        setTimeout(function () {
            $(".sv_main .sv_body").css("background-color", props.backgroundColor);
        });
    }

    return (
        <div className="content-page designer-container" style={{ backgroundColor: "white", marginLeft: props.mg }}>
            <div className="row mt-2">
                <div className="col-12 pl-3">
                    <Survey.Survey model={survey} onCompleting={completedClassicViewSurvey} />
                </div>
            </div>
        </div>
    );
}

export default SurveyViewer;