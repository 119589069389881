import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const ReportView = (props) => {

    const history = useHistory();

    const [reportUrl, setReportUrl] = useState("");

    useEffect(() => {
        if (props.location.state) {
            setReportUrl(props.location.state.report_url);
        } else {
            history.push('/analytics');
        }
    }, []);

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Analytics</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Report Name</h4>
                                </div>
                                <div className="row">
                                    <iframe className="col-md-12 mt-4 vh-100" src={"http://www.codianlabs.com"}>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
}

export default ReportView;