const TestSmsModal = () => {

    return (
        <div className="modal fade" id="testSmsModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="mySmallModalLabel">Test SMS</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form action="#">
                            <div className="form-group">
                                <label for="emailaddress1">Phone Number<span className="text-danger">*</span></label>
                                <input className="form-control" type="text" id="emailaddress1" required="" placeholder="Enter name of survey" required />
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">CANCEL</button>
                        <button type="submit" className="btn btn-primary">SEND</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TestSmsModal;