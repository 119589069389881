import axios from "axios";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as htmlToImage from 'html-to-image';
import Loader from "../../../../Common/Loader";

const ShareViaQR = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    const history = useHistory();

    const [surveyDetails, setSurveyDetails] = useState({});
    const [title, setTitle] = useState("How would you rate your experience?");
    const [description, setDescription] = useState("Take a quick survey by scanning the QR Code and tell us about your experience at the event.");
    const [submitLoader, setSubmitLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [branding, setBranding] = useState(true);
    const [qrUrl, setQrUrl] = useState("");

    useEffect(() => {
        if (props.location.state) {
            setSurveyDetails(props.location.state.surveyInfo);
        } else {
            history.push('/surveys');
        }
    }, [props.location.state]);

    const submitShareQR = (event) => {
        setSubmitLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyDetails.id);
        let URL = process.env.REACT_APP_API_KEY + 'audience/shareByQRCode';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setSubmitLoader(false);
            if (response.data.status === 1) {
                setQrUrl(response.data.survey_url);
                //toast.success(response.data.message);
                saveQrImage(response.data.id);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setSubmitLoader(false);
            toast.error(error.toString());
        });
    }

    const saveQrImage = (shareId) => {
        setLoader(true);
        htmlToImage.toJpeg(document.getElementById('qrCard'))
            .then(function (dataUrl) {
                const formData = new FormData();
                formData.append('share_id', shareId);
                formData.append('qr_image', dataUrl);
                let URL = process.env.REACT_APP_API_KEY + 'audience/updateQrCode';
                axios({
                    method: "post",
                    url: URL,
                    data: formData,
                    headers: {
                        Authorization: 'Token ' + TOKEN
                    }
                }).then(function (response) {
                    setLoader(false);
                    if (response.data.status === 1) {
                        toast.success(response.data.message);
                    } else if (response.data.status === 0) {
                        toast.error(response.data.message);
                    } else {
                        toast.error('Someting went wrong');
                    }
                }).catch(function (error) {
                    setLoader(false);
                    toast.error(error.toString());
                });
            });
    }

    const printQR = () => {
        setLoader(true);
        htmlToImage.toJpeg(document.getElementById('qrCard'))
            .then(function (dataUrl) {
                let link = document.createElement('a');
                link.download = 'qr.jpeg';
                link.href = dataUrl;
                link.click();
                setLoader(false);
            });
    }

    return (
        <div className="content">
            <div className="survey-top-bar">
                <div className="row border-bottom" style={{ height: '70px' }}>
                    <div className="col-md-4 d-flex" style={{ alignItems: 'center' }}>
                        <Link to="/"><span className="logo-lg mx-2"><img src={logo ? logo : "/assets/images/logo.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '60px', paddingTop:'4px', paddingBottom:'4px' }} /></span></Link>
                        <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} className=""><i className="dripicons-arrow-thin-left font-24 mr-1 text-dark"></i></Link>
                        <input className="form-control survey-input pt-1" value={surveyDetails.name} type="text" />
                    </div>
                    <div className="col-md-8 d-flex justify-content-between">
                        <ul className="nav nav-tabs nav-bordered justify-content-center" style={{ alignItems: 'end' }}>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-content', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-1-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Content</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="true" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-2-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Design</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-3-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Workflow</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link active">
                                    <i className="mdi text-primary mdi-numeric-4-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Share</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-results', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-5-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Results</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="mr-2 mt-1">
                            {/* <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} type="button" style={{ height: 'fit-content' }} className="btn btn-primary btn-sm ml-2 mr-1">Next<i className="uil uil-arrow-right ml-1"></i></Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{ marginTop: '4.3rem' }}>
                    <div className="row">
                        <form onSubmit={submitShareQR} className="col-md-5 card card-body mb-0">
                            <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }}>
                                <i className="mdi mdi-arrow-left"></i>
                                <span>Back</span>
                            </Link>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="simpleinput">Share Name<span className="text-danger">*</span></label>
                                        <input type="text" required={true} placeholder="Enter share name here" name="share_name" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="simpleinput">QR Code Title<span className="text-danger">*</span></label>
                                        <input type="text" name="title" value={title} onChange={(event) => setTitle(event.target.value)} className="form-control" placeholder="How would you rate your experience?" required />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="example-textarea">Description<span className="text-danger">*</span></label>
                                        <textarea className="form-control" name="description" value={description} onChange={(event) => setDescription(event.target.value)} rows="5" placeholder="Take a quick survey by scanning the QR Code and tell us about your experience at the event" required></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between">
                                        <p className="font-weight-bold">Remove HelloApps branding</p>
                                        <input type="checkbox" id="switch0" checked={branding} onChange={() => setBranding(!branding)} data-switch="none" />
                                        <label for="switch0" data-on-label="" data-off-label=""></label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-primary mt-2 float-right" disabled={submitLoader}>{submitLoader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null}SHARE</button>
                                </div>
                            </div>
                        </form>
                        <div className="col-md-7">
                            <h4 className="mt-4 text-center mb-3">Sharing Preview</h4>
                            <div className="row justify-content-center mb-3">
                                <div className="col-md-6">
                                    <div className="card border" id="qrCard">
                                        <div className="card-body justify-content-center text-center p-4 ">
                                            <h3 className="text-primary mb-3"><u>{title}</u></h3>
                                            <div className={qrUrl ? "" : "blurr-back"}>
                                                <QRCode id="qr_code_viewer" value={qrUrl ? qrUrl : ""} size={128} /><br />
                                            </div>
                                            <button type="button" className="btn btn-outline-secondary btn-rounded m-3">Scan here</button>
                                            <p style={{ color: '#7A8B96' }}>{description}</p>
                                            {branding ?
                                                surveyDetails.branding_type === "custom" ?
                                                    <img src={process.env.REACT_APP_API_MEDIA_KEY + surveyDetails.branding_file} height="30" alt="image" style={{ objectFit: 'cover' }} />
                                                    : surveyDetails.branding_type === "default" ?
                                                        <img src="/assets/images/logo.png" height="30" alt="image" style={{ objectFit: 'cover' }} />
                                                        : surveyDetails.branding_type === "" ? null
                                                            : null
                                                : null}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        {/* <div className="col-md-5"><button type="button" onClick={printQR} className="btn btn-outline-primary w-100" disabled={!qrUrl} ><i className="mdi mdi-printer mr-1"></i>PRINT</button></div>
                                        <div className="col-md-7"><button type="button" onClick={downloadQR} className="btn btn-outline-primary w-100" disabled={!qrUrl} ><i className="mdi mdi-download mr-1"></i>DOWNLOAD QR</button></div> */}
                                        <div className="col-md-12"><button type="button" onClick={printQR} className="btn btn-outline-primary w-100" disabled={!qrUrl} ><i className="mdi mdi-download mr-1"></i>DOWNLOAD QR</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader ? <Loader /> : null}
        </div>
    );
}

export default ShareViaQR;