import { trimString } from "../../Utils/string_formatting";

const ViewAttachmentModal = ({ attachments, onClose, parentId }) => {

    return (
        <div id="viewAttachments" className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="fullWidthModalLabel" aria-hidden="true" data-backdrop="static">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="fullWidthModalLabel">Attachments ({attachments?.length})</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={() => {if (onClose) {onClose()}}}>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        {attachments?.length === 0 ? <h4 className="text-center w-100">No Attachments Found</h4> :
                            attachments?.map((obj, i) => {
                                return (<div className="col-md-6">
                                    <div className="card mb-1 shadow-none border bg-secondary-custom">
                                        <div className="p-2">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <div className="avatar-sm">
                                                        <span className="avatar-title bg-primary text-light rounded">{obj.file_extension}</span>
                                                    </div>
                                                </div>
                                                <div className="col ps-0">
                                                    <a href="javascript:void(0);" className="text-muted fw-bold">{trimString(obj.file_name, 20)}</a>
                                                    <p className="mb-0">{obj.file_size}</p>
                                                </div>
                                                <div className="col-auto">
                                                    <a href={process.env.REACT_APP_API_MEDIA_KEY + obj.file} target={"_blank"} className="btn btn-link btn-lg text-muted">
                                                        <i className="mdi mdi-download"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-outline-secondary" onClick={() => { if (onClose) { onClose() } }} data-dismiss="modal">CLOSE</button>
                </div>
            </div>
        </div>
        </div >
    )
}

export default ViewAttachmentModal;

