import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";

const CrmGridView = (props) => {

    const setDeleteButton = (cell, row) => {
        return (
            <div className="dropdown float-right">
                <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-dots-vertical text-secondary font-20"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-border-top">
                    <Link className="dropdown-item notify-item" data-toggle="modal" onClick={() => props.setCrmData(row)} data-target="#viewCrmModal"><i className="mdi mdi-eye mr-1"></i><span>View</span></Link>
                    <Link className="dropdown-item notify-item" onClick={() => props.setCrmData(row)} data-toggle="modal" data-target="#editCrmModal"><i className="mdi mdi-pencil mr-1"></i><span>Edit</span></Link>
                    <Link className="dropdown-item notify-item" onClick={() => props.setDeleteUrl('audience/crmKeysAPI/' + row.id)} data-toggle="modal" data-target="#deleteModal"><i className="mdi mdi-delete mr-1"></i><span>Delete</span></Link>
                </div>
            </div>
        );
    }

    const columns = [{
        dataField: 'name',
        text: 'Configuration Name'
    }, {
        dataField: 'id',
        formatter: setDeleteButton
    }];

    return (
        <BootstrapTable
            keyField="id"
            data={props.gridData}
            columns={columns}
            bordered={false}
            bootstrap4
            hover
            noDataIndication="No record found" />
    )
}

export default CrmGridView;