import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import axios from "axios";
import { toast } from "react-toastify";

const ShareViaSocialMedia = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    let logo = props.organizationInfo.logo;

    if (props.organizationInfo.logo) {
        logo = process.env.REACT_APP_API_MEDIA_KEY + props.organizationInfo.logo;
    }

    const history = useHistory();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("Built with love by Hello Apps, a FREE online survey software which lets you collect feedback naturally, gain insights and make better decisions.");
    const [loader, setLoader] = useState(false);
    const [surveyDetails, setSurveyDetails] = useState({});
    const [shareUrl, setShareUrl] = useState("");

    useEffect(() => {
        if (props.location.state) {
            setSurveyDetails(props.location.state.surveyInfo);
            setTitle(props.location.state.surveyInfo.name);
        } else {
            history.push('/surveys');
        }
    }, [props.location.state]);

    const submitShareSocialMedia = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('survey_id', surveyDetails.id);
        let URL = process.env.REACT_APP_API_KEY + 'audience/shareBySocialMedia';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                setShareUrl(response.data.survey_url);
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    return (
        <div className="content">
            <div className="survey-top-bar">
                <div className="row border-bottom" style={{ height: '70px' }}>
                    <div className="col-md-4 d-flex" style={{ alignItems: 'center' }}>
                        <Link to="/"><span className="logo-lg mx-2"><img src={logo ? logo : "/assets/images/logo.png"} alt="" height="60" style={{ objectFit: "contain", maxWidth: '60px', paddingTop: '4px', paddingBottom: '4px' }} /></span></Link>
                        <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} className=""><i className="dripicons-arrow-thin-left font-24 mr-1 text-dark"></i></Link>
                        <input className="form-control survey-input pt-1" value={surveyDetails.name} type="text" />
                    </div>
                    <div className="col-md-8 d-flex justify-content-between">
                        <ul className="nav nav-tabs nav-bordered justify-content-center" style={{ alignItems: 'end' }}>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-content', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-1-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Content</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="true" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-2-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Design</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-work-flow', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-3-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Workflow</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link active">
                                    <i className="mdi text-primary mdi-numeric-4-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Share</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={{ pathname: '/survey-results', state: { surveyInfo: surveyDetails } }} data-toggle="tab" aria-expanded="false" className="nav-link">
                                    <i className="mdi text-primary mdi-numeric-5-box mr-1" style={{ fontSize: 18 }}></i>
                                    <span className="d-sm-inline">Results</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="mr-2 mt-1">
                            {/* <Link to={{ pathname: '/survey-design', state: { surveyInfo: surveyDetails } }} type="button" style={{ height: 'fit-content' }} className="btn btn-primary btn-sm ml-2 mr-1">Next<i className="uil uil-arrow-right ml-1"></i></Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{ marginTop: '4.3rem' }}>
                    <div className="row">
                        <form onSubmit={submitShareSocialMedia} className="col-md-5 card card-body mb-0">
                            <Link to={{ pathname: '/survey-share', state: { surveyInfo: surveyDetails } }}>
                                <i className="mdi mdi-arrow-left"></i>
                                <span>Back</span>
                            </Link>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="simpleinput">Share Name<span className="text-danger">*</span></label>
                                        <input type="text" required={true} placeholder="Enter share name here" name="share_name" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="simpleinput">Title<span className="text-danger">*</span></label>
                                        <input type="text" name="title" value={title} onChange={(event) => setTitle(event.target.value)} className="form-control" placeholder="Test Surevy" required />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="example-textarea">Description<span className="text-danger">*</span></label>
                                        <textarea className="form-control" name="description" value={description} onChange={(event) => setDescription(event.target.value)} rows="5" placeholder="Built with love by HelloApps, a FREE online survey software which lets you collect feedback naturally, gain insights and make better decisions" required></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="font-weight-bold">Upload OG Image</p>
                                    <button type="button" onClick={() => document.getElementById('browseOgFile').click()} className="btn btn-outline-secondary" ><i className="uil uil-upload mr-1"></i>upload og image</button>
                                    <input type="file" onChange={(event) => document.getElementById('f_share').src = window.URL.createObjectURL(event.target.files[0])}  name="social_share_image" id="browseOgFile" className="form-control-file d-none" />
                                    <div className="text-danger"><small>Upload a JPEG or PNG image. Get best results when the image is 1200 x 630 pixels</small></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-primary mt-2 float-right" disabled={loader}>{loader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null}SHARE</button>
                                </div>
                            </div>
                        </form>
                        <div className="col-md-7">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <ul className="nav nav-tabs nav-justified nav-bordered mb-3 mt-4 mx-5">
                                        <li className="nav-item">
                                            <a href="#share-facebook" data-toggle="tab" aria-expanded="true" className="nav-link active">
                                                <span className="d-md-block">Facebook</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#share-twitter" data-toggle="tab" aria-expanded="false" className="nav-link ">
                                                <span className="d-md-block">Twitter</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane show active" id="share-facebook">
                                    <div className="row justify-content-center">
                                        <div className="col-md-8">
                                            <div className=" card border rounded  mb-3">
                                                <div className="card-body p-0">
                                                    <div className="p-3">
                                                        <div className="media mb-3">
                                                            <img className="mr-2 rounded-circle" src="/assets/images/users/avatar-1.jpg" alt="Generic placeholder image" height="40" />
                                                            <div className="media-body">
                                                                <h5 className="m-0 font-16">AutomationLabs</h5>
                                                                <p className="text-muted font-12"><small>September 20th 2021, 9:40:37 pm<i className="ml-1 mdi mdi-earth"></i></small></p>
                                                            </div>
                                                        </div>
                                                        <p className="font-14 mb-1" style={{ fontWeight: '400' }}>Please spare a few moments to take this survey :)</p>
                                                        <p className="mb-1" style={{ color: '#3F97FF' }}>#survey #automationlabs</p>
                                                    </div>
                                                    <img className="mb-0" id="f_share" src="/assets/images/social_share.png" className="img-fluid" style={{ width: '100%', height: '220px', objectFit: 'cover' }} alt="Product-img" />
                                                    <div className=" p-2 bg-light">
                                                        <a href="javascript: void(0);" className="text-muted font-12 d-inline-block mt-2">AUTOMATIONLABS.HELLOAPPS.COM</a>
                                                        <p className="font-14 mb-1" style={{ fontWeight: '400' }}>{title}</p>
                                                        <p className="text-muted font-12 d-inline-block">{description}</p>
                                                    </div>
                                                    <div className="px-4 mb-2">
                                                        <div className="mt-2 d-flex justify-content-between">
                                                            <a href="javascript: void(0);" className="btn btn-sm btn-link text-danger"><i className="mdi mdi-heart-circle"></i>3K</a>
                                                            <a href="javascript: void(0);" className="btn btn-sm btn-link text-muted">24 Share</a>
                                                        </div>
                                                        <hr className="mt-0 mb-0" />
                                                        <div className=" px-5 d-flex justify-content-between">
                                                            <a href="javascript: void(0);" className="btn btn-sm btn-link text-dark font-weight-bold"><i className="uil uil-thumbs-up text-muted"></i> Like</a>
                                                            <a href="javascript: void(0);" className="btn btn-sm btn-link text-dark font-weight-bold"><i className="mdi mdi-comment-outline"></i> Comment</a>
                                                            <a href="javascript: void(0);" className="btn btn-sm btn-link text-dark font-weight-bold"><i className="mdi mdi-share-outline text-muted"></i> Share</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {shareUrl ?
                                                <FacebookShareButton url={shareUrl} quote={title} className="Demo__some-network__share-button w-100">
                                                    <button type="button" className="btn btn-primary w-100 mb-4" ><i className="uil uil-facebook mr-1"></i>Share on Facebook</button>
                                                </FacebookShareButton>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="share-twitter">
                                    <div className="row justify-content-center">
                                        <div className="col-md-8">
                                            <div className=" card border rounded  mb-3">
                                                <div className="card-body p-0">
                                                    <div className="p-3">
                                                        <div className="media mb-3">
                                                            <img className="mr-2 rounded-circle" src="/assets/images/users/avatar-1.jpg" alt="Generic placeholder image" height="40" />
                                                            <div className="media-body">
                                                                <h5 className="m-0 font-16">AutomationLabs</h5>
                                                                <p className="text-muted font-12"><small>@automationlabs</small></p>
                                                            </div>
                                                            <svg className="float-right" width="20" height="20" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18.066 9.645C18.249 13.685 15.236 18.189 9.902 18.189C8.28 18.189 6.771 17.713 5.5 16.898C7.024 17.078 8.545 16.654 9.752 15.709C8.496 15.686 7.435 14.855 7.068 13.714C7.519 13.8 7.963 13.775 8.366 13.665C6.985 13.387 6.031 12.143 6.062 10.812C6.45 11.027 6.892 11.156 7.363 11.171C6.084 10.316 5.722 8.627 6.474 7.336C7.89 9.074 10.007 10.217 12.394 10.337C11.975 8.541 13.338 6.81 15.193 6.81C16.018 6.81 16.765 7.159 17.289 7.717C17.943 7.589 18.559 7.349 19.113 7.02C18.898 7.691 18.443 8.253 17.85 8.609C18.431 8.539 18.985 8.385 19.499 8.156C19.115 8.734 18.629 9.24 18.066 9.645Z" fill="#1da1f2"></path></svg>
                                                        </div>
                                                        <p className="font-14 mb-1" style={{ fontWeight: '400' }}>Please spare a few moments to take this survey :)</p>
                                                        <p className="mb-0 font-weight-bold" style={{ color: '#3F97FF' }}>#surveysparrow #conversationalsurveys</p>
                                                    </div>
                                                    <div className="card border mx-3 mb-3">
                                                        <div className="card-body p-0">
                                                            <img className="mb-0" src="/assets/images/social_share.png" className="img-fluid" style={{ width: '100%', height: '220px', objectFit: 'cover' }} alt="Product-img" />
                                                            <div className="p-2 border-top">
                                                                <p className="font-14 mb-0" style={{ fontWeight: '500' }}>test2</p>
                                                                <p className="text-muted font-12 d-inline-block mb-0">Built with love by SurveySparrow, a FREE online survey software which lets you collect feedback naturally, gain insights and make better decisions.</p>
                                                                <a href="javascript: void(0);" className="text-muted font-11 d-inline-block">automationlabs.surveysparrow.com</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 mb-2 d-flex justify-content-between">
                                                        <p className="text-muted font-12"><small>3K Likes  •  8:11 AM - Sep 27th, 2021</small></p>
                                                        <a><i className="ml-1 uil uil-info-circle text-muted"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            {shareUrl ?
                                                <TwitterShareButton url={shareUrl} title={title} className="Demo__some-network__share-button w-100" >
                                                    <button type="button" className="btn btn-primary w-100 mb-4" ><i className="uil uil-twitter mr-1"></i>Share on Twitter</button>
                                                </TwitterShareButton>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShareViaSocialMedia;