import { Link } from "react-router-dom";
import { tatDateTime } from "../../../Utils/date_formatting";
import { permissionName } from "../../../Utils/string_formatting";
import axios from "axios";
import { toast } from "react-toastify";

const SubTasks = ({ issueDetails, subTask, status, userInfo }) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const getStyle = (subTaskObj) => {
        let txtColor = '#000000';
        let bgColor = '#ffffff';
        for (let i = 0; i < status.length; i++) {
            if (subTaskObj.status === status[i].id) {
                txtColor = status[i].color;
                bgColor = status[i].color + '27';
            }
        }
        return { color: txtColor, backgroundColor: bgColor }
    }

    const changeStatus = (data, id) => {
        for (let i = 0; i < status.length; i++) {
            if (Number(id) === status[i].id) {
                document.getElementById('custom_status_' + data.id).style.color = status[i].color;
                document.getElementById('custom_status_' + data.id).style.backgroundColor = status[i].color + '27';
                break;
            }
        }
        let URL = process.env.REACT_APP_API_KEY + 'issues/changeIssueStatus/' + data.id + '?status=' + id;
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            if (response.data.status === 1) {
                toast.success(response.data.message);
            }
        }).catch(function (error) {
            toast.error(error.toString());
        });
    }

    const checkOwner = () => {
        for (let i = 0; i < issueDetails?.owner_list?.length; i++) {
            if (issueDetails.owner_list[i].email === userInfo.email) {
                return false;
            }
        }
        if (issueDetails?.owner_list?.length === 0) {
            return false;
        }
        return true;
    }

    const enableDisableStatus = () => {
        let statusValue = null;
        for (let i = 0; i < status.length; i++) {
            if (issueDetails.status === status[i].id) {
                statusValue = status[i].status;
                break
            }
        }
        if (statusValue === 'open' || statusValue === 'work_in_progress' || statusValue === 'resolved') {
            return false
        }
        if (statusValue === 'overdue' || statusValue === 'reopened' || statusValue === 'closed') {
            if (checkOwner()) {
                return true
            }
        }
    }

    const addStatusCheck = (obj) => {
        if (obj.status === 'overdue') {
            return <option disabled={true} value={obj.id}>{permissionName(obj.status)}</option>
        } else if (obj.status === 'reopened' || obj.status === 'closed') {
            if (checkOwner()) {
                return <option disabled={true} value={obj.id}>{permissionName(obj.status)}</option>
            } else {
                return <option value={obj.id}>{permissionName(obj.status)}</option>
            }
        } else {
            let statusValue = null;
            for (let i = 0; i < status.length; i++) {
                if (issueDetails.status === status[i].id) {
                    statusValue = status[i].status;
                    break
                }
            }
            if ('closed' === statusValue) {
                if (obj.status === 'resolved' || obj.status === 'reopened') {
                    return <option value={obj.id}>{permissionName(obj.status)}</option>
                } else {
                    return <option disabled={true} value={obj.id}>{permissionName(obj.status)}</option>
                }
            } else {
                return <option value={obj.id}>{permissionName(obj.status)}</option>
            }
        }
    }

    return (
        <>
            <hr />
            <h4 >Sub Task</h4>
            {subTask.length === 0 ? <p>No Sub Task</p> :
                subTask.map((obj) => {
                    return <div className="card mb-2 shadow-none border bg-home">
                        <div className="p-2">
                            <div className="row align-items-center">
                                <div className="col">
                                    <Link className="text-primary font-weight-bold" to={{ pathname: "/issue-details/sub-task-details/" + obj.id }}><b>{obj.name}</b></Link>
                                </div>
                                <div className="col">
                                    <h5><i className="mdi mdi-account text-muted mr-1"></i>{obj.assigned_list[0]?.name + ' +' + (obj.assigned_list.length - 1).toString()}</h5>
                                </div>
                                <div className="col">
                                    <h6 className={tatDateTime(obj.tat_time_left)[0] === '-' ? "text-danger" : "text-success"}><i className="mdi mdi-clock mr-1"></i>{tatDateTime(obj.tat_time_left)}</h6>
                                </div>
                                <div className="col">
                                    <select className="form-control m-0 select-custom" style={getStyle(obj)} disabled={enableDisableStatus()} name='status' id={'subtask_custom_status_' + obj.id} defaultValue={obj.status} onChange={(event) => changeStatus(event.target.value)}>
                                        {status.map((obj, i) => {
                                            return addStatusCheck(obj)
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
        </>
    )
}

export default SubTasks;