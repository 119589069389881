import axios from 'axios';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import GoogleLogin from 'react-google-login';
//import LinkedIn from 'react-linkedin-login-oauth2';

const Login = () => {

    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const submitLogin = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'loginUser';
        axios({
            method: "post",
            url: URL,
            data: formData
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                toast.success(response.data.message);
                localStorage.setItem('fms-access-token', response.data.data.token);
                history.push('/');
                window.location.reload();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const submitSignup = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        if (formData.get('password') === formData.get('password2')) {
            let URL = process.env.REACT_APP_API_KEY + 'signUp';
            axios({
                method: "post",
                url: URL,
                data: formData
            }).then(function (response) {
                setLoader(false);
                if (response.data.status === 1) {
                    toast.success(response.data.message);
                    document.getElementById('userLogin').click();
                    document.getElementById('signUpForm').reset();
                } else if (response.data.status === 0) {
                    if(response.data.data){
                        toast.error(response.data.data[Object.keys(response.data.data)[0]][0]);
                    }else{
                        toast.error(response.data.message);
                    }
                } else {
                    toast.error('Someting went wrong');
                }
            }).catch(function (error) {
                setLoader(false);
                toast.error(error.toString());
            });
        } else {
            setLoader(false);
            toast.error('Enter both passwords correctly');
        }
    }

    const submitForgotPassword = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'sendOTP';
        axios({
            method: "post",
            url: URL,
            data: formData
        }).then(function (response) {
            setLoader(false);
            if (response.data.status === 1) {
                setEmailSent(true);
                toast.success(response.data.message);
                history.push('/');
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.toString());
        });
    }

    const onSuccessGoogleLogin = (data) => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'googleLogin';
        axios({
            method: "post",
            url: URL,
            data: { access_token: data.accessToken }
        }).then(function (response) {
            setLoader(false);
            //if (response.data.status === 1) {
            //toast.success(response.data.message);
            localStorage.setItem('fms-access-token', response.data.key);
            history.push('/');
            window.location.reload();
            // } else if (response.data.status === 0) {
            //     toast.error(response.data.message);
            // } else {
            //     toast.error('Someting went wrong');
            // }
        }).catch(function (error) {
            setLoader(false);
            toast.error(error.response.data[Object.keys(error.response.data)[0]][0]);
        });
    }

    return (
        <div className="account-pages authentication-bg ">
            <div className="container">
                <div className="row justify-content-center ">
                    <div className="col-lg-5">
                        <div className="card mt-5">
                            <div className="card-header pt-4 pb-4 text-center bg-primary">
                                <a href="index.html">
                                    <span><img src="/assets/images/logo.png" alt="" height="18" /></span>
                                </a>
                            </div>
                            <div className="card-body p-3">
                                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                    <li className="nav-item">
                                        <a href="#user-login" id="userLogin" data-toggle="tab" aria-expanded="false" className="nav-link active">
                                            <h5>Login</h5>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#user-signup" data-toggle="tab" aria-expanded="true" className="nav-link">
                                            <h5>Signup</h5>
                                        </a>
                                    </li>
                                    <li className="nav-item d-none">
                                        <a href="#forgot-password" id="forgotPassword" data-toggle="tab" aria-expanded="true" className="nav-link">
                                            <h5>Forgot Password</h5>
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane show active" id="user-login">
                                        <form onSubmit={submitLogin} id="loginForm">
                                            <div className="form-group">
                                                <label>Email<span className="text-danger">*</span></label>
                                                <input className="form-control" type="email" name="email" required={true} placeholder="Enter your email" />
                                            </div>
                                            <div className="form-group">
                                                <small onClick={() => document.getElementById('forgotPassword').click()} className="text-muted float-right cursor-pointer">Forgot your password?</small>
                                                <label>Password<span className="text-danger">*</span></label>
                                                <input className="form-control" type="password" name="password" required={true} placeholder="Enter your password" />
                                            </div>
                                            <div className="form-group mb-0 text-center">
                                                <button className="btn btn-primary" type="submit">{loader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null} Log In </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane" id="user-signup">
                                        <form onSubmit={submitSignup} id="signUpForm">
                                            <div className="form-group">
                                                <label>Email<span className="text-danger">*</span></label>
                                                <input className="form-control" type="email" name="email" required={true} placeholder="Enter your email" />
                                            </div>
                                            <div className="form-group">
                                                <label>Password<span className="text-danger">*</span></label>
                                                <input className="form-control" type="password" name="password" required={true} placeholder="Enter your password" />
                                            </div>
                                            <div className="form-group">
                                                <label>Confirm Password<span className="text-danger">*</span></label>
                                                <input className="form-control" type="password" name="password2" required={true} placeholder="Confirm your password" />
                                            </div>
                                            <div className="form-group mb-0 text-center">
                                                <button className="btn btn-primary" type="submit">{loader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null} Signup </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-pane" id="forgot-password">
                                        {emailSent ?
                                            <h5 className="text-dark-50 text-center mt-0 font-weight-bold">Link to reset password sent successfully on your email address.</h5>
                                            :
                                            <form onSubmit={submitForgotPassword} id="forgotPasswordForm">
                                                <div className="text-center w-80 m-auto">
                                                    <h5 className="text-dark-50 text-center mt-0 font-weight-bold">Reset Password</h5>
                                                    <p className="text-muted">Enter your email address and we'll send you an email with instructions to reset your password.</p>
                                                </div>
                                                <div className="form-group">
                                                    <label>Email address<span className="text-danger">*</span></label>
                                                    <input className="form-control" type="email" name="email" required={true} placeholder="Enter your email" />
                                                </div>
                                                <div className="form-group mb-0 text-center">
                                                    <button className="btn btn-primary" type="submit">{loader ? <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span> : null} Submit </button>
                                                </div>
                                            </form>
                                        }
                                    </div>
                                </div>
                                <div className="text-center mt-3">
                                    <ul className="social-list list-inline">
                                        {/* <li className="list-inline-item">
                                            <LinkedIn
                                                clientId="81lx5we2omq9xh"
                                                renderElement={renderProps => (<Link onClick={renderProps.onClick} className="social-list-item border-info text-info"><i className="mdi mdi-linkedin"></i></Link>)}
                                                onSuccess={onSuccessGoogleLogin}
                                                redirectUri="http://localhost:3000/linkedin" />
                                        </li> */}
                                        {/* <li className="list-inline-item">
                                            <GoogleLogin
                                                clientId="742626952526-iprn5r5c0vq17ds9j57ctl1ge3fne1bl.apps.googleusercontent.com"
                                                render={renderProps => (<Link onClick={renderProps.onClick} className="social-list-item border-danger text-danger"><i className="mdi mdi-google"></i></Link>)}
                                                onSuccess={onSuccessGoogleLogin}
                                                cookiePolicy={'single_host_origin'} />
                                        </li> */}
                                        {/* <li className="list-inline-item">
                                            <Link className="social-list-item border-info text-info"><i className="mdi mdi-microsoft-windows"></i></Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;