import SegmentInfoModal from "./SegmentInfoModal";
import SegmentsGridView from "./SegmentsGridView"
import Loader from '../Common/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from "react";

const Segments = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [loader, setLoader] = useState(false);
    const [segments, setSegments] = useState([]);

    const fetchSegments = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            setSegments(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    useEffect(fetchSegments, []);

    const search = (event) => {
        setLoader(true);
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'audience/audienceSegmentation?search_string=' + formData.get('search');
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setLoader(false);
            setSegments(response.data.data);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <div className="row">
                                        <div className="col-md-12 d-flex">
                                            <div className="form-group m-0 mr-2">
                                                <form onSubmit={search} className="input-group">
                                                    <input type="text" name="search" className="form-control form-control-white border-light" placeholder="Search" />
                                                    <div className="input-group-append cursor-pointer">
                                                        <button type="submit" className="input-group-text bg-white border-light text-dark py-0">
                                                            <i className="mdi mdi-magnify font-20"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                            <button type="button" className="btn btn-primary btn-sm-block float-right" data-toggle="modal" data-target="#segmentInfoModal" >ADD SEGMENT</button>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mt-3 mb-0 pb-0">
                                        <li className="breadcrumb-item"><a>Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Segments</li>
                                    </ol>
                                </nav>
                                <div className="page-title-box">
                                    <h4 className="page-title">Segments</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <SegmentsGridView gridData={segments} fetchSegments={fetchSegments} />
                        </div>
                    </div>
                    <SegmentInfoModal />
                    {loader ? <Loader /> : null}
                </div>
            </div>
        </div>
    );
}

export default Segments;