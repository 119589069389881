export const firstCharCaps = (data) => {
    return data.charAt(0).toUpperCase() + data.slice(1);
}

export const permissionName = (data) => {
   if(data){
    const arr = data.split("_");
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
   }else{
       return "";
   }
}

export const trimString = (data, len = 10) => {
    if(data){
        return data.length > len ? data.substring(0, len - 3) + "..." : data;
    }else{
        return null;
    }
}

export const mentionUserName = (data) => {
    const arr = data.split(" ");
    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join("_");
}