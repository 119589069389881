import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader";

const ChannelIntegration = () => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const [channels, setChannels] = useState([]);
    const [loader, setLoader] = useState(false);

    const fetchChannels = () => {
        setLoader(true);
        let URL = process.env.REACT_APP_API_KEY + 'audience/getAddedChannelTypes?get_type=channel';
        axios({
            method: "get",
            url: URL,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then(function (response) {
            setChannels(response.data.data);
            setLoader(false);
        }).catch(function (error) {
            toast.error(error.toString());
            setLoader(false);
        });
    }

    useEffect(fetchChannels, []);

    const setConfiguration = (cell) => {
        return <Link className="text-primary" to={cell}>CONFIGURE</Link>
    }

    const setStatus = (cell) => {
        return cell === "Success" ? <span className="badge badge-success-lighten  m-0 round-badge text-center">SUCCESS</span> : <span className="badge badge-warning-lighten  round-badge text-center">PENDING</span>;
    }

    const columns = [{
        dataField: 'name',
        text: ''
    }, {
        dataField: 'status',
        text: 'Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: setStatus
    }, {
        dataField: 'services_count',
        text: 'Availed'
    }, {
        dataField: 'path',
        classes: 'text-center',
        formatter: setConfiguration
    }];

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3 mb-0 pb-0">
                                    <li className="breadcrumb-item"><Link to="/settings">Settings</Link></li>
                                    <li className="breadcrumb-item"><a>Integrations</a></li>
                                    <li className="breadcrumb-item active">Channel</li>
                                </ol>
                            </nav>
                            <div className="page-title-box">
                                <h4 className="page-title">Channel Integration</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h3 className="text-secondary m-3">Channel Integration Status</h3>
                                        <BootstrapTable
                                            keyField="id"
                                            data={channels}
                                            columns={columns}
                                            bordered={false}
                                            bootstrap4
                                            hover
                                            noDataIndication="No record found" />
                                        {loader ? <Loader /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChannelIntegration;