import axios from "axios";
import { toast } from "react-toastify";

const AddPropertyModal = (props) => {

    const TOKEN = localStorage.getItem('fms-access-token');

    const createProperty = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        let URL = process.env.REACT_APP_API_KEY + 'audience/secondaryFields';
        axios({
            method: "post",
            url: URL,
            data: formData,
            headers: {
                Authorization: 'Token ' + TOKEN
            }
        }).then((response) => {
            if (response.data.status === 1) {
                toast.success(response.data.message);
                document.getElementById('closeModal').click();
                document.getElementById('createPropertyForm').reset();
                props.fetchProperties();
            } else if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.error('Someting went wrong');
            }
        }).catch((error) => {
            toast.error(error.toString());
        });
    }

    return (
        <div id="addPropertyModal" className="modal fade show" data-backdrop="static" tabIndex="-1" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-lg modal-right">
                <form onSubmit={createProperty} id="createPropertyForm" className="modal-content">
                    <div className="modal-header">
                        <h4 className="" id="rightModalLabel">CREATE A PROPERTY</h4>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#313a46'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z' /></svg>
                        </button>
                    </div>
                    <div className="modal-body pl-4 pr-4">
                        <div className="">
                            <h4 className="mt-0">Text in a modal</h4>
                            <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula.</p>
                        </div>
                        <div style={{ textAlign: 'justify' }}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label for="name">Name<span className="text-danger">*</span></label>
                                        <input className="form-control" type="text" placeholder="Enter property name" name="name" required />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label for="name">Description<span className="text-danger">*</span></label>
                                        <textarea className="form-control" name="description" placeholder="Enter property description" required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="closeModal" className="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary">Save changes</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddPropertyModal;